import styled from "styled-components";

export const Container = styled.div`
    padding: 0 32px;
`;

export const Title= styled.div`
  font-size: 36px;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 700;
`;

export const SubTitle= styled.div`
  font-size: 26px;
  line-height: 34px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 500;
  margin-top: 30px;
`;

export const CardsContainerDiv= styled.div`
  margin: 50px auto;
`;

export const CardDiv= styled.div`
  width: 65%;
  margin: 12px auto;
  border-radius: 15px;
  padding: 43px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: max-content;
  gap: 20px;
  align-items: stretch;
  &:nth-child(2n) {
    background-color: #80808018;
    flex-direction: row-reverse
  }
`;

export const CardText= styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTitle= styled.div`
  font-size: 23px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 700;
  margin-bottom: 32px;
`;

export const DescriptionCardsHolder= styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardDescription= styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

export const DescriptionItem= styled.div`
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.GREYED[100]};
  font-weight: 400;
  text-align: left;
  margin-left: 5px;
`;

export const DescriptionLargeSpan= styled.span`
  height: 19px;
  left: 0px;
  top: 0px;
  width: 19px;
  position: relative;
  display: flex;
  align-items: center;
  &::before {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 6px;
    content: "";
    opacity: 0;
    transform: scale(0);
    background: rgb(255, 255, 255);
    border: 1px solid ${({ theme }) => theme.colors.PRIMARY[15]};
    border-radius: 50%;
    transition: 0.25s;
  }
    &::after {
      background-color: ${({ theme }) => theme.colors.PRIMARY[800]};
      content: "";
      height: 9px;
      left: 5px;
      top: 5px;
      width: 9px;
      border-radius: 50%;
      transition: 0.25s;
    }
`;

export const SpanHolder= styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionSmallSpan= styled.span`
  left: 5px;
  top: 3px;
  display: flex;
  position: relative;
  height: 3px;
  width: 3px;
  margin-bottom: 5px;

  &::before{
    background-color: ${({ theme }) => theme.colors.PRIMARY[75]};
    content: "";
    height: 3px;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 3px;
    border-radius: 50%;
  }
`;

export const CardIcon= styled.div<{src?: string}>`
  padding: 20px;
  margin: auto;
  height: 120px;
  width: 140px;
  background-image: ${({ src }) => src ? `url(${src})` : "none"};
  background-size: cover;
`;