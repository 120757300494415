import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import {
  T360ConnectSection,
  T360ConnectSectionHeading,
  T360ConnectContent,
  T360,
  T360Heading,
  T360Content,
  IconDiv,
  Proprietary,
  ProprietaryHeading,
  ProprietaryContent,
  ARFDiv,
  DivIcon,
  AcceptIcon,
  RejectIcon,
  FurtherIcon,
} from "../../styles/client/Connect.styled";
import DummyData from "../../data/t360Connect.json";

const Connect = () => {
  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.CLIENT_T360_CONNECT);
  });
  // Dummy DATA Start
  const t360 = DummyData.t360;
  const proprietary = DummyData.proprietary;
  // Dummy DATA End
  return (
    <T360ConnectSection>
      <T360ConnectSectionHeading>
        <h1>T360 CONNECT</h1>
        <p>Keep track of requests and their activities</p>
      </T360ConnectSectionHeading>
      <T360ConnectContent>
        <T360>
          <IconDiv>
            <img src={DivIcon} alt="divicon" />
          </IconDiv>
          <T360Heading>
            <h2>T360</h2>
          </T360Heading>
          <hr />
          <T360Content>
            <div>
              <h2>BIGBANK AS FILIALAS</h2>
              <p>Lithuania</p>
            </div>
            <div style={{ marginTop: "30px" }}>
              <div className="contentdiv">
                <p>PID</p>
                <p>{t360.pid}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Name</p>
                <p>{t360.legalName}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Address</p>
                <p>{t360.legalAddress}</p>
              </div>
              <div className="contentdiv">
                <p>Registration Country</p>
                <p>{t360.registrationCountry}</p>
              </div>
              <div className="contentdiv">
                <p>SWIFT/BIC</p>
                <p>{t360.swift}</p>
              </div>
              <div className="contentdiv">
                <p>Status</p>
                <p>{t360.status}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Form</p>
                <p>{t360.legalForm}</p>
              </div>
            </div>
          </T360Content>
        </T360>
        <Proprietary>
          <ProprietaryHeading>
            <h2>PROPRIETARY</h2>
          </ProprietaryHeading>
          <hr />
          <ProprietaryContent>
            <div>
              <h2>BIGBANK AS FILIALAS</h2>
              <p>Lithuania</p>
            </div>
            <div style={{ marginTop: "30px" }}>
              <div className="contentdiv">
                <p>Legal Name</p>
                <p>{proprietary.legalName}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Address</p>
                <p>{proprietary.legalAddress}</p>
              </div>
              <div className="contentdiv">
                <p>Registration Country</p>
                <p>{proprietary.registrationCountry}</p>
              </div>
              <div className="contentdiv">
                <p>SWIFT/BIC</p>
                <p>{proprietary.swift}</p>
              </div>
              <div className="contentdiv">
                <p>Status</p>
                <p>{proprietary.status}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Form</p>
                <p>{proprietary.legalForm}</p>
              </div>
              <div className="contentdiv">
                <p>Incorporation Date</p>
                <p>{proprietary.incorporationDate}</p>
              </div>
              <div className="contentdiv">
                <p>Source Info Provider</p>
                <p>{proprietary.sourceInfoProvider}</p>
              </div>
              <div className="contentdiv">
                <p>Alternative Names</p>
                <p>{proprietary.alternativeName}</p>
              </div>
              <div className="contentdiv">
                <p>Entity Name</p>
                <p>{proprietary.entityType}</p>
              </div>
            </div>
          </ProprietaryContent>
        </Proprietary>
      </T360ConnectContent>
      <ARFDiv>
        <div className="ARFIcon">
          <img src={AcceptIcon} alt="accept icon" />
          <span className="accept-match">Accept Match</span>
        </div>
        <div className="ARFIcon">
          <img src={RejectIcon} alt="reject icon" />
          <span className="reject-match">Reject Match</span>
        </div>
        <div className="ARFIcon">
          <img src={FurtherIcon} alt="further enquiry icon" />
          <span className="further-enquire">FURTHER ENQUIRY</span>
        </div>
      </ARFDiv>
    </T360ConnectSection>
  );
};

export default Connect;
