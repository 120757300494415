export const STORAGE_PATH = "entityZip";
export const MOD = "6";
export const TO_GIT = "[1, 1, 1]";
export const FROM_GIT = "[1, 1, 1]";
export const CLIENT_HARDCODED_PID = "DEFEB13216";
export const CNS_INITIAL_VALUES = [
  "Confirm and Submit",
  "Confirm and Submit",
  "Confirm and Submit",
  "Confirm and Submit",
];
export const DIRECT_FO_PERCENTAGE = "5";
export const TOTAL_FO_PERCENTAGE = "25";
export const LIMIT = "100";
