import NoDataContent from "components/noData/NoDataContent";
import { FC } from "react";
import {
  Table,
  Text,
  TableCover,
  TH,
  TD,
  TopHoldingNoDataContainer,
} from "styles/party/funds/index.styled";
import { AssetClassType } from "types";

const AssetClass: FC<{ data: AssetClassType[]; title?: string }> = ({ data, title }) => {
  return (
    <TableCover>
      <div className="title-cover">{title ? <Text light>{title}</Text> : null}</div>
      {data.length === 0 ? (
        <TopHoldingNoDataContainer>
          <NoDataContent />
        </TopHoldingNoDataContainer>
      ) : (
        <Table>
          <thead>
            <tr>
              <TH position="center">Rank</TH>
              <TH position="center">Asset</TH>
              <TH position="center">%</TH>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => (
              <tr key={datum?.Rank}>
                <TD position="center">{datum?.Rank}</TD>
                <TD>{datum?.["Asset"]}</TD>
                <TD position="right">{datum?.Percentage}</TD>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </TableCover>
  );
};

export default AssetClass;
