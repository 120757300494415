import CrownAlert from "components/crownAlert/CrownAlert";
import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  useMemo,
} from "react";

interface ModalContextType {
  isOpen: boolean;
  menuToggle: (selectedField: string, selectedKey: string, streamId?: string, chatName?: string, selectedFund?: string) => void;
}

export const AliceModalContext = createContext<ModalContextType>({
  isOpen: false,
  menuToggle: () => {},
});

export const ModalProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [field, setField] = useState("");
  const [key, setKey] = useState("");
  const [fund, setFund]= useState("");

  const menuToggle = (name?: string, key?: string, fund?: string) => {
    setIsOpen(!isOpen);
    setField(name || "");
    setKey(key || "");
    setFund(fund || "");
  };

  const contextValue: ModalContextType = useMemo(
    () => ({
      isOpen,
      menuToggle,
    }),
    [isOpen],
  );

  return (
    <AliceModalContext.Provider value={contextValue}>
      {children}
      <CrownAlert
        alertIsActive={isOpen}
        menuToggle={menuToggle}
        selectedField={field}
        selectedKey={key}
        selectedFund= {fund}
      />
    </AliceModalContext.Provider>
  );
};

export const useAliceContext = () => useContext(AliceModalContext);
