import { CustomLoadingSpinner } from "styles/SectionSharedItems.styled";

/**
 * Custom loading spinner that can be used in every part of the application.
 */
function LoadingSpinner() {
  return (
    <CustomLoadingSpinner
      alt="Loading spinner"
      src={`${process.env.PUBLIC_URL}/assets/loadingSpinner.svg`}
    />
  );
}
export default LoadingSpinner;
