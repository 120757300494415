import styled from "styled-components";

export const CustomSubmitButton = styled.button`
  width: 100%;
  height: 48px;
  background-color: ${(props) => `${props.type === "reset" ? "#CC0066" : "#2e1a61"}`};
  overflow: hidden;
  border-radius: 4px;
  color: #ffffff;
  border: none;
  font-size: 14px;
  font-weight: 700;
  margin: 10px 0;
  opacity: ${(props) => `${props.disabled ? 0.3 : 1}`};

  &:hover {
    cursor: ${(props) => `${props.disabled ? "not-allowed" : "pointer"}`};
    opacity: ${(props) => `${props.disabled ? 0.1 : 0.9}`};
  }
`;
