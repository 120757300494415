import styled from "styled-components";

export const Container = styled.div<{progressText?: number}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    cursor: ${(props) => props.progressText !== 0 ? "pointer" : ""}
`;

export const CircularProgressContainer = styled.div<{ width?: number, height?: number, color?: string, progress?:number }>`
    position: relative;
    width: ${(props) => props.width ? `${props.width}px` : "100px"}; 
    height: ${(props) => props.height ? `${props.height}px` : "100px"}; 
    margin-bottom: 20px;
    border-radius: 50%;
    background: ${(props) => 
        `conic-gradient( 
        ${props.color ? props.color : "#547999"} ${props.progress ? props.progress * 3.6 : 1}deg, 
        #bcbcbc ${props.progress ? props.progress * 3.6 : 1}deg)`};

    &:before {
        content: "";
        position: absolute;
        height: 65%;
        width: 65%;
        background-color: #ffffff;
        border-radius: 50%;
        transform: translate(25%, 25%);
     }
`;

export const ProgressText = styled.span<{ color?: string }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    background: none;
    border-radius: 50%;
    color: ${(props) => props.color ? props.color : "#333333"};
    font-size: 18px;
    font-weight: bold;
    text-align: center
`;

export const ProgressNumber = styled.span`
    color: #333333;
`;
export const PercentSign = styled.span<{ color?: string }>`
    padding-bottom: 20px;
    color: ${(props) => props.color ? props.color : "#333333"};;
    font-size: 18px;
    vertical-align: bottom;
`;

export const Title = styled.div<{color?: string}>`
    text-align: center;
    font-size: 14px;
    color: ${(props) => props.color ? props.color : "#333333"};
    text-wrap: wrap;
`;