import { FunctionComponent } from "react";
import {
    BottomContentContainer,
    FiltersContainer,
    TableContainer
} from "styles/funds/Onboarding.styled";
import agentCodes from "data/agentCodesFile.json";
import useFilters from "hooks/useFilter";
import { Search } from "../filter/Search";
import { 
    Table,
    Thead,
    Tbody,
    Td,
} from "styles/admin/AdminTable.styled";
import { MasterBuylistContainer, Th, Tr } from "styles/funds/MasterBuylist.styled";

const HubwiseAgentCodesComponent: FunctionComponent<{data: typeof agentCodes}> =  ({data}) => {

    const { filteredData, setFilter } = useFilters(data);
    const setData = (str: string) => {
        setFilter({ search: str });
    };
    
    return (
        <MasterBuylistContainer>
            <BottomContentContainer>
                <FiltersContainer>
                    <Search setData={setData} />
                </FiltersContainer>
                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Fund Manager Name</Th>
                                <Th>Calastone OR EMX Account</Th>
                                <Th>Provider Code</Th>
                                <Th>Broker Code</Th>
                                <Th>Agent Code</Th>
                                <Th>Unit Holder Number</Th>
                                <Th>Account Designation</Th>
                                <Th>Account Name</Th>
                                <Th>EMX Provider ID</Th>
                                <Th>EMX Product ID</Th>
                                <Th>Bank Details</Th>
                                <Th>Administrator Name</Th>
                                <Th>Administrator Contact Details</Th>
                                <Th>Administrator Correspondence Address</Th>
                                <Th>Hubwise Notes</Th>
                                <Th>MI Reporting</Th>
                                <Th>Fund Manager Relationship Contact</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {filteredData.map((fundManager,index) => (
                                <Tr body key={index} data-testid="row">
                                    <Td>{fundManager["Fund Manager Name"]}</Td>
                                    <Td>{fundManager["Calastone OR EMX Account"]}</Td>
                                    <Td>{fundManager["Provider Code"]}</Td>
                                    <Td>{fundManager["Broker Code"]}</Td>
                                    <Td>{fundManager["Agent Code"]}</Td>
                                    <Td>{fundManager["Unit Holder Number"]}</Td>
                                    <Td>{fundManager["Account Designation"]}</Td>
                                    <Td>{fundManager["Account Name"]} %</Td>
                                    <Td>{fundManager["EMX Provider ID"]} %</Td>
                                    <Td>{fundManager["EMX Product ID"]} %</Td>
                                    <Td>{fundManager["Bank Details"]}</Td>
                                    <Td>{fundManager["Administrator Name"]}</Td>
                                    <Td>{fundManager["Administrator Contact Details"]}</Td>
                                    <Td>{fundManager["Administrator Correspondence Address"]}</Td>
                                    <Td>{fundManager["Hubwise Notes"]}</Td>
                                    <Td>{fundManager["MI Reporting"]}</Td>
                                    <Td>{fundManager["Fund Manager Relationship Contact"]}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </BottomContentContainer>
        </MasterBuylistContainer>
    )
};

export default HubwiseAgentCodesComponent;