import { useState } from "react";
import { Link } from "react-router-dom";
import { PATH, ROLE, ACCESS_PERMISSIONS } from "constants/index";
import {
  IconsContainer,
  IconsSeparator,
  StyledMenuIcon,
  Container,
} from "../../styles/admin/Navbar.styled";
import { HeaderType } from "../../types";
import CrownAlert from "../crownAlert/CrownAlert";
import UserProfileHeader from "../header/UserProfileHeader";
import NotificationTab from "../notificationTab/NotificationTab";
import HubwiseMobileMenu from "./HubwiseMobileMenu";
import { useAppData } from "../../hooks/useAppData";
import { Header } from "../../styles/hubwise/HubwiseNavbar.styled";

function HubwiseNavbar() {
  const { status, isUserPermission } = useAppData();

  const searchIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-search-white.svg`;
  const askAliceIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-ask-alice-white.svg`;
  const alertIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-alert-white.svg`;
  const notificationIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-notifications-white.svg`;
  const adminRoomsIconWhite =`${process.env.PUBLIC_URL}/assets/header-icon-admin-rooms-white.svg`;

  // for crowd alert
  const [alertIsActive, setAlertIsActive] = useState(false);
  const menuToggle = () => setAlertIsActive(!alertIsActive);

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  // for notification tab
  const [tabIsActive, setTabIsActive] = useState(false);
  const tabToggle = () => setTabIsActive(!tabIsActive);
  return (
    <Container>
      <StyledMenuIcon data-testid="menu" onClick={() => setShowMobileMenu(true)} />
      <Header>
        {isUserPermission([ACCESS_PERMISSIONS.PARTY_MANAGER]) && 
          <IconsSeparator>
            <Link to={!alertIsActive ? PATH.SEARCH : window.location.pathname}>
              <IconsContainer
                iconUrl={searchIconWhite}
                style={
                  window.location.pathname === PATH.SEARCH ||
                  window.location.pathname.includes(PATH.SEARCH_RESULT)
                    ? { opacity: "0.2" }
                    : { opacity: "1" }
                }
              />
            </Link>
          </IconsSeparator>
        }
        <IconsSeparator>
          <IconsContainer
            iconUrl={alertIconWhite}
            onClick={tabIsActive || alertIsActive ? undefined : menuToggle}
          />
          <CrownAlert alertIsActive={alertIsActive} menuToggle={menuToggle} />
        </IconsSeparator>
        {isUserPermission([ACCESS_PERMISSIONS.PARTY_MANAGER]) && 
          <IconsSeparator>
            <Link to={!alertIsActive ? PATH.ALERT : window.location.pathname}>
              <IconsContainer
                iconUrl={askAliceIconWhite}
                style={
                  window.location.pathname === PATH.ALERT ? { opacity: "0.2" } : { opacity: "1" }
                }
              />
            </Link>
          </IconsSeparator>
        }
        { isUserPermission([ACCESS_PERMISSIONS.PARTY_MANAGER]) && 
          <IconsSeparator>
            <IconsContainer
              iconUrl={notificationIconWhite}
              onClick={alertIsActive ? undefined : tabToggle}
            />
            <NotificationTab tabIsActive={tabIsActive} tabToggle={tabToggle} />
          </IconsSeparator>
        }
        { isUserPermission([ACCESS_PERMISSIONS.ADMIN_CONSOLE_ACCESS]) && 
          <IconsSeparator>
            <Link to={!alertIsActive ? PATH.ADMIN_ALICE_REQUEST : window.location.pathname}>
              <IconsContainer
                iconUrl={adminRoomsIconWhite}
                style={
                  window.location.pathname.includes(ROLE.ADMIN)
                    ? { opacity: "0.2" }
                    : { opacity: "1" }
                }
              />
            </Link>
          </IconsSeparator> 
        }
        <UserProfileHeader
          headerStyle={HeaderType.ADMIN}
          tabIsActive={tabIsActive}
          alertIsActive={alertIsActive}
        />
      </Header>
      <HubwiseMobileMenu show={showMobileMenu} close={() => setShowMobileMenu(false)} />
    </Container>
  );
}

export default HubwiseNavbar;
