export const FUNDS_TABS = ["Profile", "Admin"] as FUNDS_TAB[];

export enum FUNDS_TAB {
  DEFAULT = "",
  PROFILE = "Profile",
  ADMIN = "Admin",
}

export const FundManagerIdentifiers = [
  "Fund Manager Name",
  "Account Name",
  "Account Designation",
  "Calastone OR EMX Account",
  "Provider Code",
  "Broker Code",
  "Agent Code",
  "Unit Holder Number",
  "EMX Provider ID",
  "EMX Product ID",
  "MI Reporting"
]

export const FundManagerBankDetails = [
  "Bank Details",
  "Hubwise Notes"
]

export const FundAdministratorContactDetails = [
  "Administrator Name",
  "Administrator Correspondence Address",
  "Administrator Contact Details",
]

export const FundManagerContactDetails = [
  "Fund Manager Relationship Contact"
]