import {
  ModalBoxContainer,
  ModalOverlay,
  ModalBoxControls,
  ModalBoxCloseIcon,
  ModalBoxContent,
} from "styles/party/hierarchyGraph/HierarchyGraphModal.styled";
import { GRAPH_TYPE } from "constants/index";
import { DisplayCytoscape } from "./DisplayCytoscape";

const CytoScapeModal = ({
  dataForCytoscape,
  setShowModal,
}: {
  dataForCytoscape: any;
  setShowModal: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <div>
      <ModalOverlay onClick={() => setShowModal("")} />
      <ModalBoxContainer>
        <ModalBoxControls>
          <ModalBoxCloseIcon onClick={() => setShowModal("")} />
        </ModalBoxControls>
        <ModalBoxContent>
          <DisplayCytoscape dataForCytoscape={dataForCytoscape} graphType={GRAPH_TYPE.CY_CUB} />
        </ModalBoxContent>
      </ModalBoxContainer>
    </div>
  );
};

export default CytoScapeModal;
