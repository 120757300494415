// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the first section on top of a Profile in Party Manager, that we call GeneralDetails
 */

import {
    CenterContent,
    GeneralDetailsContainer,
    GeneralDetailsEntitySubTitle,
    GeneralDetailsEntityTitle,
    GeneralDetailsFGC,
    GeneralDetailsFieldName,
    GeneralDetailsRightColumnContent,
    GeneralDetailsSingleRow,
    TitleCover,
    Modal,
    Overlay,
    ModalContent,
    LizardImg,
    CloseModal,
    ButtonDiv,
    Button,
    OpenModal,
    WarningIcon,
  } from "styles/party/General.styled";
  import ModalComponent from "components/modal";
  import useModal from "hooks/useModal";
  import { GeneralDetailsTypeCcID } from "types/party";
  import AliceModalTooltip from "components/AliceModalTooltip";
  import { SingleRowContainer,
    SingleRowInternalContainer,
 } from "styles/SectionSharedItems.styled";
  import { fieldValueFilterFromBVD } from "components/FieldValueFilter";
  import { useState, useEffect } from "react";
  
  // dedicated function for each single row
  function GeneralDetailsRow({ fieldName, fieldValue }: { fieldName: string; fieldValue: string }) {
    const modal = useModal();
  
    const [parsedDate, setParsedDate]= useState<string>("");
  
    useEffect(()=>{
      if(fieldName === "Date of Incorporation") {
        const year = parseInt(fieldValue.substring(0, 4), 10);
        const month = parseInt(fieldValue.substring(4, 6), 10) - 1;
        const day = parseInt(fieldValue.substring(6, 8), 10);
        const newDateString= new Date(year, month, day).toLocaleString();
        const arr= newDateString.split(",");
        setParsedDate(arr[0]);
      }
    },[fieldName])
  
    return fieldValue && fieldValue.trim() !== "" ? (
      <SingleRowContainer>
        <SingleRowInternalContainer>
          <GeneralDetailsSingleRow>
            <GeneralDetailsFieldName>{fieldName}</GeneralDetailsFieldName>
            {/* use the alice modal tooltip component to display a crown icon when you hover over the text */}
            <AliceModalTooltip
              selectedField={fieldValueFilterFromBVD(fieldValue)}
              selectedKey={fieldName}
            >
              <div style={{ display: "flex" }}>
                { fieldName === "Date of Incorporation" && parsedDate !== undefined ? 
                  <>{parsedDate}</>
                  : fieldValueFilterFromBVD(fieldValue)}
                <WarningIcon>
                  {(fieldName === "National Legal Form" || fieldName === "Date of Incorporation") && (
                    <OpenModal onClick={modal.open} />
                  )}
                </WarningIcon>
              </div>
            </AliceModalTooltip>
          </GeneralDetailsSingleRow>
        </SingleRowInternalContainer>
        <ModalComponent controller={modal}>
          <Modal className="modal">
            <Overlay onClick={modal.close} />
            <ModalContent>
              <LizardImg />
              <CloseModal onClick={modal.close} />
              <h2>Under Investigation</h2>
              <p>We are in the process of verifying this information</p>
              <ButtonDiv>
                <Button onClick={modal.close}>Discard</Button>
                <Button onClick={modal.close}>Ok</Button>
              </ButtonDiv>
            </ModalContent>
          </Modal>
        </ModalComponent>
      </SingleRowContainer>
    ) : null;
  }
  
  function GeneralDetailsGroup({ data }: { data: [string, any][] }) {
    return (
      <GeneralDetailsFGC>
        {data.map(([name, field]: [string, string | any], j) =>
          Array.isArray(field) ? (
            field.map((item, i) => <GeneralDetailsGroup data={item} key={i} />)
          ) : (
            <GeneralDetailsRow fieldName={name} fieldValue={field} key={j} />
          ),
        )}
      </GeneralDetailsFGC>
    );
  }
  
  // main function
  export function GeneralDetailsCcId({ data, isAlert }: { data: GeneralDetailsTypeCcID; isAlert?: boolean }) {
    // variables that we use for specific special fields
    let entityTitle: string = "";
    let entitySubTitle: string = "";
    let entityCountry: string = "";
    // this is just for generic data
    const dataToDisplay: [string, any][] = [];
  
    if (!data || Object.entries(data)?.length === 0) {
      return null;
    }
    Object.entries(data)?.forEach(([name, field]: [string, string | any]) => {
      // this part is hardcoded, so has to be changed in the future!
      switch (name) {
        case "Legal Name English Version":
          entityTitle = field;
          break;
        case "Legal Name":
          entitySubTitle = field;
          break;
        case "Country":
          entityCountry = field;
          break;
        case "id":
          entityCountry = "";
          break;
        default:
          // generic data that don't need dedicated fields
          dataToDisplay.push([name, field]);
      }
    });
  
    if (!dataToDisplay?.length) return null;
    return (
      <GeneralDetailsContainer>
        <CenterContent>
          <GeneralDetailsRightColumnContent>
            {/* Here are the special fields that have a dedicated styling */}
            <TitleCover isAlert={isAlert!}>
              <GeneralDetailsEntityTitle>{entityTitle || entitySubTitle}</GeneralDetailsEntityTitle>
            </TitleCover>
            <GeneralDetailsEntitySubTitle>{entityCountry}</GeneralDetailsEntitySubTitle>
            {/* Then, the rest of the data */}
            <GeneralDetailsGroup data={dataToDisplay} />
          </GeneralDetailsRightColumnContent>
        </CenterContent>
      </GeneralDetailsContainer>
    );
  }
  