import { FunctionComponent, useState, useEffect } from "react";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import { 
  ContainerFunds,
  BannerRequests, 
  FundManagersContainer,
  FundManagersSectionHolder,
  BtnsHolder,
  ControlsHolder,
  ToggleBtnHolder,
  MainContentHolder,
  GeneralDetailsContainer,
  CustomerDetailsContainer,
  CustomerField,
  CustomerValue,
  CustomerContainer
} from "styles/funds/ClientTemplateRequest.styled";
import { GeneralDetailsTypeCcID, GeneralDetailsType, InstrumentsManagerType, CrtType } from "types/party";
import { GeneralDetailsCcId } from "components/party/funds/GeneralDetailsCcId";
import { SearchWrapper } from "../../styles/admin/AliceRequest.styled";
import { Search } from "components/filter/Search";
import useFilters from "hooks/useFilter";
import { FundManagerList } from "components/hubwise-dashboard/clientTemplateRequest/FundManagerList";
import AssetOnboardingNavHolderComponent from "components/hubwise-dashboard/clientTemplateRequest/AssetOnboardingSummaryNavContainer";
import { AddButton } from "styles/party/funds/ManageFunds.styled";
import { FundType } from "types/funds";
import { useParams } from "react-router-dom";
import { Accordion } from "components/party/accordion/Accordion";

const ClientTemplateRequestComponent: FunctionComponent<{
  dataForCcIdProfile: GeneralDetailsTypeCcID | GeneralDetailsType | undefined,
  fundManagers : InstrumentsManagerType[],
  allFunds: FundType[],
  fundsToAddArray: {[key: string]: {"Record Id": string; "ISIN": string; "SEDOL": string; selected?: boolean}[]}[]
  crtData?: CrtType,
  setFundsToAddArray:React.Dispatch<React.SetStateAction<{
    [key: string]: {
        "Record Id": string;
        ISIN: string;
        SEDOL: string;
        selected?: boolean;
    }[];
  }[]>>
}> = ({
  dataForCcIdProfile,
  fundManagers,
  allFunds,
  fundsToAddArray,
  crtData,
  setFundsToAddArray
}) => {
    const { track } = useTrackActivity();
  const [allSelected, setAllSelected]= useState<boolean>(false);
  const [anyFundSelected, setAnyFundSelected] = useState<boolean>(false);
  const [fundsToAdd, setFundsToAdd] = useState<{[key: string] : {[key: string]: {"Record Id": string; "ISIN": string, "SEDOL": string}[]}[]}>({});
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [filteredFunds, setFilteredFunds] = useState<FundType[] | object[]>([]);
  const [filteredByCategory, setFilterByCategory]= useState<InstrumentsManagerType[]>(fundManagers);
  const { filteredData, setFilter } = useFilters(((filteredFunds.length !== allFunds.length) && (filteredFunds.length !== 0)) ? filteredByCategory : fundManagers);
  const [toggleAll, setToggleAll] = useState(true);
  const [searchStr, setSearchStr]= useState<string>("");

  const { id: clientId } = useParams<{ id: string }>();

  const setData = (str: string) => {      
    setFilter({ search: str });
    setSearchStr(str);
  };

  useEffect(()=>{
    if(filteredFunds.length !== allFunds.length) {
      // new array to hold filtered data
      const newData: InstrumentsManagerType[] = [];
      // map over fund managers
      fundManagers.map((fundManager) => {
        // empty new filtered funds array
        const fundsL: FundType[]= [];
        // map over each fund
        if(fundManager["Instruments List"] && fundManager["Instruments List"].length > 0) {
          fundManager["Instruments List"].map((fund) => {
            if(filteredFunds.includes(fund)) {
              fundsL.push(fund);
            }
          })
        }
        if(fundsL.length > 0) {
          newData.push({PID: fundManager.PID, "Provider Code": fundManager["Provider Code"], "Legal Name [PID]": fundManager["Legal Name [PID]"], "Instruments List": fundsL});
        }
      })
      setFilterByCategory(newData);

    } else {
      setFilterByCategory([]);
    }
  },[filteredFunds])

    useEffectOnce(() => {
      track(ActivityType.LOAD_PAGE, TRACKER.CLIENT_REQUEST_TEMPLATE, {
      });
    });

    const handleSelectAll = () => {
      if(allSelected) {
        setFundsToAddArray((prevState)=> {
          const newState= [...prevState]
          newState.map((fm) => {
            const key = Object.keys(fm)[0];
            fm[key].map((fund) =>fund.selected= false)
          })
          return newState
        })
        setAllSelected(false);
      } else {
        setFundsToAddArray((prevState)=> {
          const newState= [...prevState]
          newState.map((fm) => {
            const key = Object.keys(fm)[0];
            fm[key].map((fund) => {
              allFunds.map((allFundsFund) => {
                if(allFundsFund["Record Id"] === fund["Record Id"]) {
                  if(allFundsFund.Status !== "3") {
                    fund.selected = true;
                  }
                }
              })
            })
          })
          return newState
        })
        const updatedData= fundsToAddArray.map((fm)=>{
          const key = Object.keys(fm)[0];
          return {[key]: fm[key].filter((fund) => fund.selected === true)}
        })
        setAllSelected(true);
        setAnyFundSelected(true);
        if(clientId && fundsToAddArray) {
          setFundsToAdd({[clientId] : updatedData});
        }
      }
    }

    const handleAddToFundLink = () => {
      // TODO: add here api call for sending emails when available instead of console.log
      console.log("Selected Funds: ", fundsToAdd);
      setFundsToAddArray((prevState)=> {
        const newState= [...prevState]
        newState.map((fm) => {
          const key = Object.keys(fm)[0];
          fm[key].map((fund) =>fund.selected= false)
        })
        return newState
      })
      setAllSelected(false);
      setAnyFundSelected(false);
    }

    const handleToggleAll = () => {
      setToggleAll(!toggleAll);
    }
  
    return (
      <ContainerFunds>
          <GeneralDetailsContainer>
            <Accordion title="General Customer Details" noTopBorder borderBottom>
              <BannerRequests>
                <GeneralDetailsCcId data={dataForCcIdProfile as GeneralDetailsTypeCcID} />
              </BannerRequests>
            </Accordion>
            <Accordion title="Customer" opened noTopBorder>
              <CustomerContainer>
                <CustomerDetailsContainer >
                  <CustomerField>Customer Code: </CustomerField>
                  <CustomerValue>{crtData?.["Customer Code"]}</CustomerValue>
                </CustomerDetailsContainer>
                <CustomerDetailsContainer>
                  <CustomerField>Customer Name: </CustomerField>
                  <CustomerValue>{crtData?.["Party Legal Name"]}</CustomerValue>
                </CustomerDetailsContainer>
              </CustomerContainer>
            </Accordion>
          </GeneralDetailsContainer>
          <MainContentHolder>
            <Accordion title="Asset Onboarding Summary" opened>
              <AssetOnboardingNavHolderComponent 
                allFunds={allFunds} 
                allFMs= {fundManagers} 
                filteredFMs= {filteredByCategory} 
                filteredFunds= {filteredFunds} 
                setFilteredFunds= {setFilteredFunds}/>
            </Accordion>
            <Accordion title="Fund Manager List" opened>
              <FundManagersSectionHolder>
                <SearchWrapper>
                  <Search setData={setData} />
                </SearchWrapper>
                <ControlsHolder>
                  <BtnsHolder>
                    <AddButton onClick={()=>handleSelectAll()}>{allSelected ? "Deselect all" : "Select all"}</AddButton>
                    <AddButton disabled={!anyFundSelected} onClick={()=>handleAddToFundLink()}>{"Send Request"}</AddButton>
                  </BtnsHolder>
                  <ToggleBtnHolder>
                    <AddButton onClick={()=>handleToggleAll()}>{toggleAll ? "Close all" : "Expand all"}</AddButton>
                  </ToggleBtnHolder>
                </ControlsHolder>
                <FundManagersContainer>
                  {filteredData.length > 0 && filteredData.map((fundManager: InstrumentsManagerType, index: number) => (
                      <FundManagerList 
                        key= {index} 
                        data={fundManager} 
                        fundsToAdd={fundsToAdd} 
                        setFundsToAdd={setFundsToAdd} 
                        setAnyFundSelected={setAnyFundSelected}
                        anyFundSelected= {anyFundSelected}
                        toggleAll={toggleAll}
                        searchStr= {searchStr}
                        fundManagers= {fundManagers}
                        clientId= {clientId}
                        allSelected= {allSelected}
                      />
                  ))}
                </FundManagersContainer>
              </FundManagersSectionHolder>
            </Accordion>

          </MainContentHolder>
          
         
          
      </ContainerFunds>
    );
  }
  
  export default ClientTemplateRequestComponent;
  