import { toggleScroll } from "utils";
import { useEffect } from "react";
import {
  MainContainer,
  DarkLayer,
  TopContainer,
  Title,
  CloseIcon,
  ContentContainer,
  closeIcon,
} from "styles/notification/NotificationTab.styled";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import NotificationsReader from "./NotificationsReader";

function NotificationTab({
  tabIsActive,
  tabToggle,
}: {
  tabIsActive: boolean;
  tabToggle: () => void;
}) {
  const { track } = useTrackActivity();

  useEffect(() => {
    toggleScroll(tabIsActive);
    if (tabIsActive) {
      track(ActivityType.BUTTON_CLICK, "Notification Bar", {
        button_clicked: "Notification Button",
        clicked_from: "Header",
        activity_info: "Notification Bar Opened",
      });
    }
  }, [tabIsActive]);

  function handleCloseModal() {
    tabToggle();
    track(ActivityType.BUTTON_CLICK, "Home", { activity_info: "Notification Bar Closed" });
  }

  return (
    <div>
      <DarkLayer
        style={{
          opacity: tabIsActive ? "20%" : "0%",
          visibility: tabIsActive ? "visible" : "hidden",
        }}
      />
      <MainContainer style={{ transform: tabIsActive ? "translateX(0px)" : "translateX(375px)" }}>
        <TopContainer>
          <Title>
            <b>Notifications</b>
          </Title>
          <CloseIcon iconURL={closeIcon} onClick={handleCloseModal} />
        </TopContainer>
        <hr
          style={{
            width: "335px",
            height: "0.1px",
            border: "0",
            borderTop: "0.1px #808080 solid",
          }}
        />
        <ContentContainer>
          <NotificationsReader />
        </ContentContainer>
      </MainContainer>
    </div>
  );
}
export default NotificationTab;
