// Copyright Marco Rapaccini, Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is just a function for processing data and prepare it for the Cytoscape graph.
 */

import {
  Neo4jResultItem,
  CyNode,
  CyEdge,
  RowNodeElement,
  RowRelationsElement,
} from "../types/cytoscape";
import { D3Node, HierarchyData } from "../types/d3";

export const processData = (resultsFromNeo4jCall: any, neo4jCallType: string): HierarchyData => {
  const itemsCollection: Neo4jResultItem[] = [];
  const cyNodesCollection: CyNode[] = [];
  const cyEdgesCollection: CyEdge[] = [];

  const newCyEdgesCollection: CyEdge[] = [];

  const edges: CyEdge[] = [];

  const d3NodesCollection: D3Node[] = [];

  resultsFromNeo4jCall.results[0].data.forEach((item: any, index: number) => {
    itemsCollection[index] = item;
  });

  // D3 data
  if (itemsCollection.length > 0) {
    const firstNode: D3Node = {
      id: itemsCollection[0].row[0][0].id,
      name: itemsCollection[0].row[0][0].name,
      parentId: "",
      directFO: "",
      totalFO: "",
    };
    d3NodesCollection.push(firstNode);

    // iterate on all the result items
    itemsCollection.forEach((resultItem: Neo4jResultItem) => {
      const currentHierarchyNode: D3Node = {
        id: resultItem.row[0][resultItem.row[0].length - 1].id,
        name: resultItem.row[0][resultItem.row[0].length - 1].name,
        parentId: resultItem.row[0][resultItem.row[0].length - 3].id,
        directFO: resultItem.row[0][resultItem.row[0].length - 2].directFO,
        totalFO: resultItem.row[0][resultItem.row[0].length - 2].totalFO,
      };

      // check for node duplicates
      let check = true;
      d3NodesCollection.forEach((node: D3Node) => {
        if (node.id === currentHierarchyNode.id) {
          check = false;
        }
        return check;
      });

      if (check) d3NodesCollection.push(currentHierarchyNode);
    });
  }

  // Cytoscape data
  if (itemsCollection.length > 0) {
    const topHierachyNode: CyNode = {
      data: {
        id: itemsCollection[0].row[0][0].id,
        name: itemsCollection[0].row[0][0].name,
        link: `/party/${itemsCollection[0].row[0][0].id}`,
        directFO: "",
        totalFO: "",
      },
    };
    cyNodesCollection.push(topHierachyNode);

    // iterate on all the result items
    itemsCollection.forEach((resultItem: Neo4jResultItem) => {
      // current node to be pushed in the array of cyNodes
      const currentHierarchyNode: CyNode = {
        data: {
          id: resultItem.row[0][resultItem.row[0].length - 1].id,
          name: resultItem.row[0][resultItem.row[0].length - 1].name,
          link: `/party/${resultItem.row[0][resultItem.row[0].length - 1].id}`,
          directFO: resultItem.row[0][resultItem.row[0].length - 2].directFO,
          totalFO: resultItem.row[0][resultItem.row[0].length - 2].totalFO,
        },
      };
      if (resultItem.row[0][0].id === topHierachyNode.data.id)
        cyNodesCollection.push(currentHierarchyNode);

      const currentItem: (RowNodeElement & RowRelationsElement)[] = resultItem.row[0];
      const currentMetaItem: any = resultItem.meta[0];

      currentItem.forEach((rowItem: RowNodeElement & RowRelationsElement, rowIndex: number) => {
        if (rowIndex === currentItem.length - 3) {
          const edgeToBePushed: CyEdge = {
            data: {
              source: rowItem.id,
              target: currentItem[rowIndex + 2].id,
              metaId: currentMetaItem[rowIndex + 1].id,
              direct: currentItem[rowIndex + 1].direct,
              directFO: currentItem[rowIndex + 1].directFO,
              total: currentItem[rowIndex + 1].total,
              totalFO: currentItem[rowIndex + 1].totalFO,
            },
          };

          const edgeAlreadyPresent = edges.some(
            (edge) => edge.data.metaId === edgeToBePushed.data.metaId,
          );
          const edgeSameLevel = edges.some(
            (edge) =>
              edge.data.source === edgeToBePushed.data.source &&
              edge.data.target === edgeToBePushed.data.target,
          );

          if (!edgeAlreadyPresent && !edgeSameLevel) {
            // add check here
            cyEdgesCollection.push(edgeToBePushed);
            edges.push(edgeToBePushed);
          }
        }
      });
    });
  }

  if (neo4jCallType === "SHO") {
    // let's exchange the order of the edges
    for (let i = 0; i <= cyEdgesCollection.length - 1; i++) {
      newCyEdgesCollection.push({
        data: {
          source: cyEdgesCollection[i].data.target,
          target: cyEdgesCollection[i].data.source,
          metaId: cyEdgesCollection[i].data.metaId,
          direct: cyEdgesCollection[i].data.direct,
          directFO: cyEdgesCollection[i].data.directFO,
          total: cyEdgesCollection[i].data.total,
          totalFO: cyEdgesCollection[i].data.totalFO,
        },
      });
    }
  } else {
    // let's exchange the order of the edges
    for (let i = cyEdgesCollection.length - 1; i >= 0; i--) {
      newCyEdgesCollection.push({
        data: {
          source: cyEdgesCollection[i].data.source,
          target: cyEdgesCollection[i].data.target,
          metaId: cyEdgesCollection[i].data.metaId,
          direct: cyEdgesCollection[i].data.direct,
          directFO: cyEdgesCollection[i].data.directFO,
          total: cyEdgesCollection[i].data.total,
          totalFO: cyEdgesCollection[i].data.totalFO,
        },
      });
    }
  }

  return {
    nodes: cyNodesCollection,
    edges: newCyEdgesCollection,
    d3Nodes: d3NodesCollection,
  };
};
