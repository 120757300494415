// --- [START] Styled Components ---
import { useRef, useState } from "react";
import useModal from "hooks/useModal";
import {
  Modal,
  ModalContent,
  ScrollableArea,
  SubmitButton,
  ButtonRow,
} from "styles/alert/docsUploadAndSavings/ConfirmDocumentsData.styled";
import { useToast } from "hooks/useToast";
import { CLIENT_HARDCODED_PID } from "config/payload";
import Title from "../Title";
import DocumentFromOCR from "./DocumentFromOCR";
import AlertWaitingIcon from "../AlertWaitingIcon";
import { CHECKMATE_CONTROLLER_GCF } from "../../../config/googleCloudFunctionsConfig";
import AlertFeedback from "../AlertFeedback";
import { useDetectOutsideClick } from "../../../hooks/useDetectOutsideClick";
import { generateRandomString } from "../../../utils/randomStringGenerator";
import { AlertFeedbackType, Metadata, OcrDocResponseItem } from "../../../types";
import { callGCFunction } from "../../../services/callGCFunction";
import { createControllerRealPayload } from "../../../services/alert";
import { useAppData } from "../../../hooks/useAppData";
import { getAccessToken } from "services/auth0/auth0";

function ConfirmDocumentsData({ ocrResponse }: { ocrResponse: OcrDocResponseItem[] }) {
  const { toast } = useToast();
  const [confirmedDocuments, setConfirmedDocuments] = useState<OcrDocResponseItem[]>(ocrResponse);
  const initialDocSavedState: boolean[] = ocrResponse.map(() => {
    return false;
  });
  const [docSavedChecks, setDocSavedChecks] = useState<boolean[]>(initialDocSavedState);
  const [showWaitingIcon, setShowWaitingIcon] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isActive] = useDetectOutsideClick(dropdownRef, true);

  const controllerResponse = useModal<AlertFeedbackType>();

  const { user } = useAppData();
  const token = getAccessToken();

  // by default let's assume we have no user email
  let userEmail: string = "";
  if(user?.email) userEmail = user?.email;

  const handleSubmitForController = () => {
    const randomString: string = generateRandomString(6);

    if (token !== "") {
      const meta: Metadata = {
        pid: CLIENT_HARDCODED_PID, // here we hardcoded Deutsche Bank's PID
        user_id: userEmail,
        event_id: `${CLIENT_HARDCODED_PID}_ev_${randomString}`,
        transaction_id: `${CLIENT_HARDCODED_PID}_tr_${randomString}`,
        date_time: new Date().toISOString(),
      };
      const dataForGCF: object = createControllerRealPayload(token ? token : "", meta, confirmedDocuments);

      setShowWaitingIcon(true);

      callGCFunction(CHECKMATE_CONTROLLER_GCF, dataForGCF, "CheckMate GCF controller", "checkMate")
        .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((data: AlertFeedbackType) => {
          controllerResponse.open(data);
        })
        .catch((err: Error) => toast(err.toString()))
        .finally(() => setShowWaitingIcon(false));
    }
  };

  return (
    <>
      {!controllerResponse && isActive && (
        <Modal>
          <ScrollableArea>
            <Title text="Please check the text fields for each document before submitting" />
            <ModalContent>
              {ocrResponse.map((item: OcrDocResponseItem, index: number) => {
                return (
                  <DocumentFromOCR
                    key={index.toString()}
                    ocrDocResponseItem={item}
                    setConfirmedDocuments={setConfirmedDocuments}
                    index={index}
                    setDocSavedChecks={setDocSavedChecks}
                  />
                );
              })}
              <ButtonRow>
                <SubmitButton
                  onClick={handleSubmitForController}
                  disabled={!docSavedChecks.every((value) => value)}
                >
                  Submit and Continue
                </SubmitButton>
              </ButtonRow>
            </ModalContent>
          </ScrollableArea>
        </Modal>
      )}
      {showWaitingIcon && <AlertWaitingIcon />}
      <AlertFeedback controller={controllerResponse} />
    </>
  );
}
export default ConfirmDocumentsData;
