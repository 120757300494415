// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * Component for having a default column filter.
 */

import { DefaultColumn } from "types/filterableTable";

// customcustom interface for the filter

// Define a default UI for filtering
export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: DefaultColumn) {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder="Search"
    />
  );
}
