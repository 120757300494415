import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import ActiveFundsComponent from "components/funds/fundManager/ActiveFundsComponent";

const ActiveFunds = () => {
    const { track } = useTrackActivity();

    useEffectOnce(()=>{
        track(ActivityType.LOAD_PAGE, TRACKER.FUND_PARTY_MANAGER_FM_ACTIVE_FUNDS);
    });
    
    return <ActiveFundsComponent />
}

export default ActiveFunds;