import styled from "styled-components";

export const UserDesktopViewContainer = styled.div``;

export const WidgetsContainer = styled.div`
    width: 98%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 769px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media (min-width: 769px) and (max-width: 1330px) {
      grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    }
    margin-top: 50px;
    gap: 50px;
`;

export const Widget = styled.div<{ display: string }>`
    width: 100%;
    @media (max-width: 600px) {
      width: 80vw;
    }
    padding: 12px;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
    display: ${({ display }) => display === "true" ? "block" : "none"};
`;

export const WidgetTitle= styled.div`
  font-size: 20px;
`;

export const EditWidget= styled.div`
    width: 10%;
`;

export const WidgetHeaderArea= styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const WidgetBodyArea= styled.div`
  width: 95%;
  margin-top: 20px;
`;

export const OptionsHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: right;
`;

export const ContextMenu = styled.div<{display: string; active: boolean}>`
  background-color: #EDEFEF;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
  width: min-content;
  align-self: end;
  margin-right: 15px;
  text-wrap: nowrap;
  display: ${({ display, active }) => ( display === "true" && active ) ? "block" : "none"};
`;