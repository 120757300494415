import { FunctionComponent } from "react";
import { 
    Container, 
    FundsContainer, 
    Title
} from "styles/funds/ActiveFunds.styled";
import ManagedFundsTable from "components/party/funds/ManagedFundsTable";

const ActiveFundsComponent: FunctionComponent =  () => {

    // TODO: get pid dynamically
    const partyId= "GB2B7TZLOYVXA";
      
    return  (
      <Container>
          <Title>Active Assets</Title>
          <FundsContainer>
            <ManagedFundsTable partyId={partyId} fm/>
          </FundsContainer>
      </Container>
    )
};

export default ActiveFundsComponent;