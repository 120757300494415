import { useContext, useRef, useEffect } from "react";
import {
  StyledContainer,
  UserProfileInfo,
  UserProfileInitials,
  MenuContainer,
} from "styles/header/UserProfileHeader.styled";
import { useLocation } from "react-router-dom";
import { toggleScroll } from "utils";
import { PATH } from "constants/index";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import { UserProfileProps } from "../../types";
import HeaderMenu from "./HeaderMenu";
import { AppContext } from "../../contexts/App";
import { useAppData } from "../../hooks/useAppData";
import { parseUserDataAuth0 } from "services/auth0/auth0";

function UserProfileHeader({ headerStyle, tabIsActive, alertIsActive }: UserProfileProps) {
  const { pathname } = useLocation();
  const IS_ADMIN = pathname.includes(PATH.ADMIN) || pathname.includes(PATH.CLIENT);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  const { status } = useAppData();
  const { track } = useTrackActivity();

  const { user } = useContext(AppContext);

  const filteredUser = parseUserDataAuth0(user);
  // toggle the menu to open and close
  const menuToggle = () => {
    // toggle only when tab and alert modal are not opened
    if (!tabIsActive && !alertIsActive) {
      setIsActive(!isActive);
    }
    if (!isActive)
      track(ActivityType.BUTTON_CLICK, "Header", { button_clicked: TRACKER.USER_PROFILE_OPEN });
    if (isActive)
      track(ActivityType.BUTTON_CLICK, "Header", { button_clicked: TRACKER.USER_PROFILE_CLOSE });
  };

  useEffect(() => {
    toggleScroll(isActive);
  }, [isActive]);

  return (
    <StyledContainer ref={dropdownRef}>
      {/* user clickable profile to be toggled */}
      <UserProfileInfo onClick={menuToggle} data-testid="profile-info">
        <UserProfileInitials headerStyle={headerStyle} status={status} data-testid="initials">
          {filteredUser?.initials}
        </UserProfileInitials>
      </UserProfileInfo>
      {/* The following <div> has the React reference that we use in combination with useDetectOutsideClick */}
      <MenuContainer
        visibility={isActive ? "visible" : "hidden"}
        isAdmin={IS_ADMIN}
        data-testid="menu-cont"
      >
        <HeaderMenu menuToggle={menuToggle} />
      </MenuContainer>
    </StyledContainer>
  );
}
export default UserProfileHeader;
