import styled from "styled-components";
import { HeaderStyle, HeaderType } from "types";

export const StyledContainer = styled.div`
  position: relative;
  z-index: 99;
`;
export const UserProfileInfo = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    background-color: rgba(256, 256, 256, 0.1);
  }
`;

export const UserProfileInitials = styled.div<{ headerStyle: HeaderStyle; status: string }>`
  height: 44px;
  width: 44px;
  line-height: 44px;
  background-color: ${({ theme }) => theme.colors.PRIMARY[800]};
  border-radius: 50%;
  text-align: center;
  color: #fff;
  box-shadow: ${(props) =>
    `0 0 0 1.5px ${
      props.headerStyle === HeaderType.DEFAULT || props.status === "dark"
        ? "#fff"
        : props.theme.colors.PRIMARY[400]
    }`};
`;

export const MenuContainer = styled.div<{ visibility: string; isAdmin?: boolean }>`
  position: absolute;
  top: calc(100% + 15px);
  right: ${({ isAdmin }) => (isAdmin ? "-33px" : "-80px")};
  padding-right: 0 !important;
  z-index: 99;
  background: ${({ theme }) => theme.colors.WHITE};
  visibility: ${(props) => props.visibility};
  display: flex;
  flex-direction: column;
  width: 400px;
  box-shadow:
    0 20px 24px ${({ theme }) => theme.colors.SHADOW[200]},
    0 8px 8px ${({ theme }) => theme.colors.SHADOW[200]};
  border-radius: 0 0 8px 8px;
  font-family: "Roboto Condensed", sans-serif;
  @media (max-width: 600px) {
    right: -20px;
  }
  @media (max-width: 768px) and (min-width: 601px) {
    right: -20px;
  }
`;
