import { IdentifiersSearchContainer } from "styles/IdentifierSearch.styled";
import {
  SearchInputForm,
  BasicSearchContainer,
  BasicSearchInput,
  SearchIconContainer,
  SearchIconButton,
  CancelIconContiner,
} from "styles/Search.styled";
import CancelIcon from "@material-ui/icons/Cancel";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import LoadingSpinner from "../LoadingSpinner";
import { SearchSuggestions } from "../searchSuggestion/SearchSuggestion";
import {useAuth0} from "@auth0/auth0-react";

export default function IdentifierSearch() {
  const { isAuthenticated } = useAuth0();
  const [input, setInput] = useState("");
  const [inputToSend, setInputToSend] = useState("");
  const [isReadyToGetSuggestions, setIsReadyToGetSuggestions] = useState(false);
  const [message, setMessage] = useState("");
  const { track } = useTrackActivity();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    setMessage(event.target.value);
  };

  const handleClick = () => {
    // clear input value
    setInput("");
    setMessage("");
    setIsReadyToGetSuggestions(false);
  };

  const getSuggestions = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (input.trim() !== "") {
      setInputToSend(input);
      setIsReadyToGetSuggestions(true);
    } else {
      setIsReadyToGetSuggestions(false);
    }
  };

  useEffect(() => {
    // set isReadyToGetSuggestions to false , if the input field is empty
    if (!input) {
      setIsReadyToGetSuggestions(false);
    }
  }, [input]);

  useEffect(() => {
    // focus the input on mounting the page
    inputRef.current?.focus();
  }, []);

  if (!isAuthenticated) return <LoadingSpinner />;

  return (
    <IdentifiersSearchContainer>
      <SearchInputForm autoComplete="off">
        <BasicSearchContainer>
          <BasicSearchInput>
            <input
              type="text"
              placeholder="Identifier Value"
              ref={inputRef}
              value={message}
              onChange={handleChange}
              name="identifierValue"
              autoComplete="off"
            />
          </BasicSearchInput>
          <SearchIconContainer>
            <SearchIconButton
              onClick={(event) => {
                getSuggestions(event);
                track(ActivityType.SEARCH, TRACKER.IDENTIFIER_SEARCH, { search_term: message });
              }}
            >
              <span />
            </SearchIconButton>
          </SearchIconContainer>
          {message !== "" ? (
            <CancelIconContiner>
              <CancelIcon onClick={handleClick} />
            </CancelIconContiner>
          ) : (
            ""
          )}
        </BasicSearchContainer>
      </SearchInputForm>
      {isReadyToGetSuggestions && (
        <SearchSuggestions
          searchString={inputToSend}
          noMargin
          isIdentifierSearch
          showTopSearches={false}
        />
      )}
    </IdentifiersSearchContainer>
  );
}
