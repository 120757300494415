// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { Accordion } from "components/party/accordion/Accordion";
import { 
    Container, 
    Title,
    ContentItem,
    QHolder,
    Section,
    QAccordionHolder,
    QRow,
    FormLabel,
    InputWrapper,
    Input,
    SubmitBtnHolder 
} from "styles/sippAdmin/sippAdmin.styled";
import { useState } from "react";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";

function SIPPExpressionWishesComponent() {
    const today = new Date().toISOString().split("T")[0];

    const formObj = {
        "Beneficiary Name": "",
        "Beneficiary Address Line 1": "",
        "Beneficiary Address Line 2": "",
        "Beneficiary Address Line 3": "",
        "Beneficiary Country": "",
        "Beneficiary City": "",
        "Beneficiary Post Code": "",
        "Percentage Split": "",
        "Relationship ( Father/ Mother/ Wife/ Sister etc. )": ""
    }
    const [formValues, setFormValues] = useState<{[key: string]: any}>(formObj); 

    const handleInputChange = (label: string, value: string) => {
        setFormValues(prevState => ({ ...prevState, [label]: value }));
    };

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        //TODO: Send formValues to backend
        console.log("Form submitted:", formValues);
    };

  return (
    <Container>
        <Title>
            Expression of Wishes/ Beneficiary
        </Title>
        <Accordion title="Screen" noTopBorder>
            <div>
                <QHolder onSubmit={handleSubmit}>
                    <Section>
                        <QAccordionHolder>  
                            {Object.entries(formValues).map(([label, value]) => (
                                label !== "Date of expression" && (
                                    <QRow key={label}>
                                        <FormLabel htmlFor={label}>{label}</FormLabel>
                                        <InputWrapper>
                                            <Input
                                                type="text"
                                                id={label}
                                                value={value}
                                                onChange={(e) => handleInputChange(label, e.target.value)}
                                            />
                                        </InputWrapper>
                                    </QRow>
                                )
                            ))}
                            <QRow>
                                <FormLabel htmlFor="Date of expression">Date of Expression</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="date"
                                        id="Date of expression"
                                        value={formValues["Date of expression"] ? formValues["Date of expression"] : today}
                                        onChange={(e) => handleInputChange("Date of expression", e.target.value)}
                                    />
                                </InputWrapper>
                            </QRow>
                        </QAccordionHolder>
                    </Section>
                </QHolder>
                <SubmitBtnHolder>
                    <SubmitButton
                        onClick={handleSubmit}
                        text={"Submit"}
                        type={"submit"}
                        disabled={false}
                    />
                </SubmitBtnHolder>
            </div>
        </Accordion>
        <Accordion title="Section Content" opened>
            <div>
                <ContentItem>Beneficiary name</ContentItem>
                <ContentItem>Beneficiary address</ContentItem>
                <ContentItem>Percentage split</ContentItem>
                <ContentItem>Relationship – Father/Mother/Wife/Sister etc</ContentItem>
                <ContentItem>Date of expression</ContentItem>
            </div>
        </Accordion>
    </Container>
  );
}

export default SIPPExpressionWishesComponent;
