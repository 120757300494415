import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 32px;
`;

export const TableContainer = styled.div`
    margin-top: 60px;
`;

export const Title= styled.div`
  font-size: 36px;
  line-height: 38px;
  color: #364073;
  font-weight: 700;
`;

export const WrapTable = styled.div`
  white-space: nowrap;
  display: block;
  overflow: auto;
  max-height: 55vh;
  max-width: 95%;
`;


export const RespTable = styled.div`
  width: 100%;
  display: table;
  border-collapse: collapse;
  // word-break: break-word;
`;

export const RespTableHeader = styled.div`
  display: table-header-group;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  background-color: rgba(224, 226, 236);
  word-break: break-word; // this is a temporary fixd for table width
  position: sticky;
  top: 0;
`;
export const TableHeaderCell = styled.div`
  display: table-cell;
  padding: 18px;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
`;

export const RespTableBody = styled.div`
  display: table-row-group;
`;

export const RespTableRow = styled.div<{ hasBorder?: boolean; clicked?: boolean }>`
    display: table-row;
    border-bottom: ${(props) => (props.hasBorder ? "1px solid rgba(74, 130, 166, 0.5)" : "none")};
    font-size: 16px;
    cursor: pointer;
    transform: ${(props) => (props.clicked ? "scale(0.98)" : "none")};
    box-shadow: ${(props) => (props.clicked ? "0 0 5px rgba(0, 0, 0, 0.3)" : "none")};
    transition: ${(props) => (props.clicked ? "transform 0.2s, box-shadow 0.2s" : "none")};
`;

export const TableBodyCell = styled.div<{marginRight?: boolean}>`
  display: table-cell;
  padding: 18px;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 16px;
  padding-right: ${(props) => props.marginRight ? "20px" : "0"};
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
  color: ${({ theme }) => (theme.colors.WHITE !== "#FFFFFF" ? "#fff" : theme.colors.DARK[400])};
  & a {
    color: inherit;
    &:visited {
      color: inherit;
    }
    &:hover {
      color: #364073;
      opacity: 0.9;
    }
  }
`;

export const TableContentContainer = styled.div<{
    noShadow?: boolean;
    noMarginTop?: boolean;
  }>`
    display: block;
    column-span: all;
    overflow-x: auto;
    margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    margin-bottom: 60px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border-radius: 8px;
    padding: 12px 0;
    padding-bottom: 37px;
    white-space: nowrap;
    &:last-child {
      margin-bottom: 0px;
    }
    &:first-child {
      margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    }
`;

export const Status = styled.div<{ status: string }>`
    height: max-content;
    width: max-content;
    padding: 5px;
    background-color: ${({ status, theme }) => {
        switch (status) {
        case "COMPLETED":
            return theme.colors.SECONDARY[50];
        case "INPROGRESS":
            return theme.colors.TERTIARY[100];
        case "CANCELLED":
            return theme.colors.PRIMARY[100];
        default:
            return theme.colors.PRIMARY[100];
        }
    }};
    color: ${({ status, theme }) => {
        switch (status) {
        case "COMPLETED":
            return theme.colors.SECONDARY[300];
        case "INPROGRESS":
            return theme.colors.TERTIARY[200];
        case "CANCELLED":
            return theme.colors.PRIMARY[200];
        default:
            return theme.colors.PRIMARY[100];
        }
    }};
    font-size: 12px;
    border-radius: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

export const HeaderActions = styled.div`
  display: flex;
  margin: 40px auto 60px;
  @media (max-width: 769px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
  align-items: center;
  gap: 12px;
`;

export const SearchWrapper = styled.div`
  margin-top: 30px;
  width: 320px;
`;