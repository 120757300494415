import {
    ReportHolder,
    ExecutiveSummary,
    ExecutiveSummaryTitle,
    ExecutiveSummaryContent,
    Field,
    Value,
    GroupDiv,
    ChartHolder,
    CircleHolder,
    TopContainer,
    ExecutiveSummaryP,
    NotVisibleDiv,
    hubwiseLogo,
    t360LogoPurple,
    HubwiseLogo,
    T360Logo,
    LogoHolder,
    OrgChartHolder,
    OrgChartDiv
} from "styles/client/Report.styled";
import React, {FunctionComponent, useState, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ReportSummaryType } from "types/funds";
import { Chart } from "react-google-charts";
import NoDataContent from "components/noData/NoDataContent";

const ReportSummary: FunctionComponent<{
    tableData: (string | { role: string; } | number)[][];
    reportSummaryData: ReportSummaryType | undefined;
    reportRef: React.RefObject<HTMLDivElement>
}> =  ({
    tableData,
    reportSummaryData,
    reportRef
}) => {
    const [fundManagerStats, setFundManagerStats]= useState<(string | number | null)[][]>([["Item", "Value"], ["SS&C Hubwise Active Fund Managers", 601], ["SS&C Hubwise Inactive Fund Managers", 102383]]);
    const containerRef = useRef<HTMLDivElement>(null);
    const [scale, setScale] = useState(1);

    useEffect(() => {
        const resizeChart = () => {
          if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            const chartWidth = 1200 // Base chart width (adjust to your actual chart width)
            setScale(Math.min(containerWidth / chartWidth, 0.9)); // Scale down, but not up
          }
        };
    
        // Initial sizing
        resizeChart();
    
        // Adjust on window resize
        window.addEventListener("resize", resizeChart);
    
        // Cleanup
        return () => window.removeEventListener("resize", resizeChart);
      }, []);

    const options = {
        legend: { position: "none" },
        hAxis: {
            minValue: 0.1,
        },
        isStacked: true, // Stack bars horizontally
        colors: ["#3ebc77", "#84cefd", "#528cbc", "#937a46", "#51e48a"], // Bar colors 
    };

    const donutOptions = {
        legend: {
          position: "right",
        },
        pieSliceText: "percentage",
        slices: {
          0: { color: "#00c8c3", offset: 0.2 },
          1: { color: "#177f7a" },
        },
        tooltip: { isHtml: true },
      };
    
    const orgChartOptions = {
        allowHtml: true, // Allow HTML in tooltips for styling
        size: "large", // Other options: 'medium', 'small'
        nodeClass: "custom-node", // Custom class for nodes
    };

    const snapshotData = {
        "File Date": "2024-12-16",
        "Original File Name": "T360_Buylist_File_12.19.xlsx",
        "Number of Buylists": 138,
        "Total Number of Fund Managers": 409,
        "Total Number of Other Instruments Managers": 1519,
        "Total Number of Direct FM Relationships": 142,
        "Total Number of Direct IM Relationships": 8,
        "Total Number of Funds": 6022,
        "Total Number of Other Instruments": 2657,
        "Ringfenced Funds": 2219,
        "Ringfenced Other Instruments": 635,
        "Standard Funds": 3803,
        "Standard Other Instruments": 2022,
        "Total Number of Allfunds Enabled Funds": 2236,
        "Total Number of Allfunds Enabled Other Instruments": 0,
        "Ringfenced Funds Allfunds Enabled": 919,
        "Ringfenced Other Instruments Allfunds Enabled": 0,
        "Standard Funds Allfunds Enabled": 1317,
        "Standard Other Instruments Allfunds Enabled": 0,
        "Ringfenced Allfunds Funds Direct FM": 120,
        "Ringfenced Allfunds Other Instruments Direct FM": 0,
        "Standard Allfunds Funds Direct FM": 712,
        "Standard Allfunds Other Instruments Direct FM": 0,
        "Ringfenced Funds no Allfunds Enabled no Direct FM": 169,
        "Ringfenced Other Instruments no Allfunds Enabled no Direct FM": 631,
        "Standard Funds no Allfunds Enabled no Direct FM": 84,
        "Standard Other Instruments no Allfunds Enabled no Direct FM": 2010,
        "Ringfenced Other Instruments Types Count": 9,
        "Ringfenced Other Instruments Types Split": {
            "EQTY": 514,
            "GOVT": 51,
            "STRC": 21,
            "DERV": 14,
            "SPRO": 11,
            "PREF": 10,
            "DEBT": 9,
            "EQTM": 3,
            "BOND": 2
        },
        "Standard Other Instruments Types Count": 1,
        "Standard Other Instruments Types Split": {
            "EQTY": 2022
        },
        "Ringfenced Funds SMFKB Enabled": 1589,
        "Ringfenced Other Instruments SMFKB Enabled": 14,
        "Standard Funds SMFKB Enabled": 1794,
        "Standard Other Instruments SMFKB Enabled": 1
    }

    const orgChartData = [
        ["Name", "Manager", "Tooltip"],
        //  first layer node
        [{"v":"Instruments Universe", "f":`<div style='text-align: center; color: #364073'>Funds<div style="font-size:13px; color: #808080; font-style:italic">${snapshotData["Total Number of Funds"]}</div></div>`}, "", "Snapshot of Funds in Buylist"],

        // second layer nodes
        [{"v": "Allfunds Funds", "f":`<div style='text-align: center; color: #364073'><strong>Allfunds ( Active )</strong><br><span style="font-size:13px; color: #808080; font-style:italic">${snapshotData["Total Number of Allfunds Enabled Funds"]}</span></div>`}, "Instruments Universe", "These are instruments that only permissioned customers can trade."],
        [{"v": "Direct Funds", "f":`<div style='text-align: center; color: #364073'><strong>Direct</strong><br><span style="font-size:13px; color: #808080; font-style:italic">${snapshotData["Total Number of Funds"] - snapshotData["Total Number of Allfunds Enabled Funds"]}</span></div>`}, "Instruments Universe", "These are instruments that any eligible customer can trade."],


        // third layer nodes
        [{"v": "Ringfenced Funds Allfunds", "f":`<div style='text-align: center; color: #364073'><strong>Ringfenced List Funds</strong><br><span style="font-size:13px; color: #808080; font-style:italic">${snapshotData["Ringfenced Funds Allfunds Enabled"]}</span></div>`}, "Allfunds Funds", "These are instruments that only permissioned customers can trade."],
        [{"v": "Standard Funds Allfunds", "f":`<div style='text-align: center; color: #364073'><strong>Standard List Funds</strong><br><span style="font-size:13px; color: #808080; font-style:italic">${snapshotData["Standard Funds Allfunds Enabled"]}</span></div>`}, "Allfunds Funds", "These are instruments that any eligible customer can trade."],

        [{"v": "Ringfenced Funds", "f":`<div style='text-align: center; color: #364073'><strong>Ringfenced List Funds</strong><br><span style="font-size:13px; color: #808080; font-style:italic">${snapshotData["Ringfenced Funds"] - snapshotData["Ringfenced Funds Allfunds Enabled"]}</span></div>`}, "Direct Funds", "These are instruments that only permissioned customers can trade."],
        [{"v": "Standard Funds", "f":`<div style='text-align: center; color: #364073'><strong>Standard List Funds</strong><br><span style="font-size:13px; color: #808080; font-style:italic">${snapshotData["Standard Funds"] - snapshotData["Standard Funds Allfunds Enabled"]}</span></div>`}, "Direct Funds", "These are instruments that any eligible customer can trade."],

        //  forth layer nodes

        //  ringfenced
        
        // forth layer nodes 

        // ringfenced - other

        [`<div style='text-align: center; color: #364073; width: 95px;'><strong>Direct ( FM In Agent Codes )</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Funds"] - snapshotData["Ringfenced Funds no Allfunds Enabled no Direct FM"] - snapshotData["Ringfenced Funds Allfunds Enabled"]}</span></div>`, "Ringfenced Funds", "These Ringfenced Funds are traded via a direct relationship with the Fund Manager."],

        [{"v":"Ringfenced Not in Agent Codes", "f":`<div style='text-align: center; color: #364073; width: 125px;'><strong>Unkown ( FM Not in Agent Codes )</strong><br><span style='font-size: 13px; color: red; font-style:italic'>Under investigation</span><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Funds no Allfunds Enabled no Direct FM"]}</span></div>`}, "Ringfenced Funds", "These Ringfenced Funds are via different methods other than Direct or Allfunds, such as Calastone."],

        // standard - other
        [`<div style='text-align: center; color: #364073; width: 95px;'><strong>Direct ( FM In Agent Codes )</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Standard Funds"] - snapshotData["Standard Funds no Allfunds Enabled no Direct FM"] - snapshotData["Standard Funds Allfunds Enabled"]}</span></div>`, "Standard Funds", "These Standard Funds are via a direct relationship with the Fund Manager."],

        [{"v": "Standard Not in Agent Codes", "f":`<div style='text-align: center; color: #364073; width: 125px'><strong>Unkown ( FM Not in Agent Codes )</strong><br><span style='font-size: 13px; color: red; font-style:italic'>Under investigation</span><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Standard Funds no Allfunds Enabled no Direct FM"]}</span></div>`}, "Standard Funds", "These Standard Funds are via different methods other than Direct or Allfunds, such as Calastone."],

        
    ]

    const orgChartDataOtherInstr = [
        ["Name", "Manager", "Tooltip"],
        //  first layer node
        [{"v":"Instruments Universe", "f":`<div style='text-align: center; color: #364073'>Other Instruments<div style="font-size:13px; color: #808080; font-style:italic">${snapshotData["Total Number of Other Instruments"]}</div></div>`}, "", "Snapshot of Funds in Buylist"],
        // second layer nodes
        [{"v": "Ringfenced Funds", "f":`<div style='text-align: center; color: #364073'><strong>Ringfenced Instruments</strong><br><span style="font-size:13px; color: #808080; font-style:italic">${snapshotData["Ringfenced Other Instruments"]}</span></div>`}, "Instruments Universe", "These are instruments that only permissioned customers can trade."],
        [{"v": "Standard Funds", "f":`<div style='text-align: center; color: #364073'><strong>Standard Instruments</strong><br><span style="font-size:13px; color: #808080; font-style:italic">${snapshotData["Standard Other Instruments"]}</span></div>`}, "Instruments Universe", "These are instruments that any eligible customer can trade."],
        //  third layer nodes

        // ringfenced - other

        // [`<div style='text-align: center; color: #364073; width: 95px;'><strong>Direct ( IM In Agent Codes )</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Other Instruments"] - snapshotData["Ringfenced Other Instruments no Allfunds Enabled no Direct FM"] - snapshotData["Ringfenced Other Instruments Allfunds Enabled"]}</span></div>`, "Ringfenced Funds", "These Ringfenced Funds are traded via a direct relationship with the Fund Manager."],

        // [{"v":"Ringfenced Not in Agent Codes", "f":`<div style='text-align: center; color: #364073; width: 125px;'><strong>Unkown ( IM Not in Agent Codes )</strong><br><span style='font-size: 13px; color: red; font-style:italic'>Under investigation</span><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Other Instruments no Allfunds Enabled no Direct FM"]}</span></div>`}, "Ringfenced Funds", "These Ringfenced Funds are via different methods other than Direct or Allfunds, such as Calastone."],

        // // standard - other
        // [`<div style='text-align: center; color: #364073; width: 95px;'><strong>Direct ( IM In Agent Codes )</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Standard Other Instruments"] - snapshotData["Standard Other Instruments no Allfunds Enabled no Direct FM"] - snapshotData["Standard Other Instruments Allfunds Enabled"]}</span></div>`, "Standard Funds", "These Standard Funds are via a direct relationship with the Fund Manager."],

        // [{"v": "Standard Not in Agent Codes", "f":`<div style='text-align: center; color: #364073; width: 125px'><strong>Unkown ( IM Not in Agent Codes )</strong><br><span style='font-size: 13px; color: red; font-style:italic'>Under investigation</span><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Standard Other Instruments no Allfunds Enabled no Direct FM"]}</span></div>`}, "Standard Funds", "These Standard Funds are via different methods other than Direct or Allfunds, such as Calastone."],

        // fifth layer nodes

        [`<div style='text-align: center; color: #364073'><strong>BOND</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Other Instruments Types Split"].BOND}</span></div>`, "Ringfenced Funds", "These Ringfenced Funds are traded via a direct relationship with the Fund Manager."],
        [`<div style='text-align: center; color: #364073'><strong>DERV</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Other Instruments Types Split"].DERV}</span></div>`, "Ringfenced Funds", "These Ringfenced Funds are traded via a direct relationship with the Fund Manager."],
        [`<div style='text-align: center; color: #364073'><strong>EQTM</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Other Instruments Types Split"].EQTM}</span></div>`, "Ringfenced Funds", "These Ringfenced Funds are traded via a direct relationship with the Fund Manager."],
        [`<div style='text-align: center; color: #364073'><strong>EQTY</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Other Instruments Types Split"].EQTY}</span></div>`, "Ringfenced Funds", "These Ringfenced Funds are traded via a direct relationship with the Fund Manager."],
        [`<div style='text-align: center; color: #364073'><strong>GOVT</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Other Instruments Types Split"].GOVT}</span></div>`, "Ringfenced Funds", "These Ringfenced Funds are traded via a direct relationship with the Fund Manager."],
        [`<div style='text-align: center; color: #364073'><strong>PREF</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Other Instruments Types Split"].PREF}</span></div>`, "Ringfenced Funds", "These Ringfenced Funds are traded via a direct relationship with the Fund Manager."],
        [`<div style='text-align: center; color: #364073'><strong>SPRO</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Other Instruments Types Split"].SPRO}</span></div>`, "Ringfenced Funds", "These Ringfenced Funds are traded via a direct relationship with the Fund Manager."],
        [`<div style='text-align: center; color: #364073'><strong>STRC</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Other Instruments Types Split"].STRC}</span></div>`, "Ringfenced Funds", "These Ringfenced Funds are traded via a direct relationship with the Fund Manager."],
        [`<div style='text-align: center; color: #364073'><strong>DEBT</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Ringfenced Other Instruments Types Split"].DEBT}</span></div>`, "Ringfenced Funds", "These Ringfenced Funds are traded via a direct relationship with the Fund Manager."],

        
        [`<div style='text-align: center; color: #364073'><strong>EQTY</strong><br><span style='font-size: 13px; color: #808080; font-style:italic'>${snapshotData["Standard Other Instruments Types Split"].EQTY}</span></div>`, "Standard Funds", "These Ringfenced Funds are traded via a direct relationship with the Fund Manager."]
    ]


    return (
        reportSummaryData ? 
            <>
                <div>
                    <TopContainer >
                        <ExecutiveSummary>
                            <ExecutiveSummaryTitle>Executive Summary</ExecutiveSummaryTitle>
                            <ExecutiveSummaryContent>
                                <ExecutiveSummaryP>
                                    This report presents a detailed analysis of data changes over a specified time period. The objective is to provide key insights into how critical metrics have evolved, allowing management to track progress and make informed decisions.
                                </ExecutiveSummaryP>
                                <ExecutiveSummaryP>
                                    The analysis covers fund managers that are being added or removed along with their respective assets and updated assets information, comparing values across weekly intervals. 
                                </ExecutiveSummaryP>
                                <ExecutiveSummaryP>
                                    While no direct recommendations are included, the findings underscore essential trends and potential areas for deeper analysis. This report is intended to help the management team quickly identify areas of growth, decline, and stability across the evaluated metrics.
                                </ExecutiveSummaryP>
                            </ExecutiveSummaryContent>
                        </ExecutiveSummary>
                        <CircleHolder>
                                <ExecutiveSummaryTitle>Fund Managers</ExecutiveSummaryTitle>
                                <Chart
                                    chartType="PieChart" // Donut is a PieChart with a pieHole
                                    data={fundManagerStats}
                                    options={donutOptions}
                                    width="100%"
                                    height="250px"
                                />
                            </CircleHolder>
                        
                    </TopContainer>
                    <OrgChartHolder ref={containerRef}>
                        <ExecutiveSummaryTitle>Snapshot of Funds in Buylist</ExecutiveSummaryTitle>
                        <div
                            style={{
                            transform: `scale(${scale})`,
                            transformOrigin: "top left",
                            width: `${1200 * scale}px`, // Match the scaled chart width
                            marginBottom: "20px",
                            margin: "auto"
                            }}
                        >
                            <Chart
                                chartType="OrgChart"
                                data={orgChartData}
                                options={orgChartOptions}
                                width="100%"
                                height="min-content"
                                loader={<div>Loading Snapshot of Instruments Universe...</div>}
                            />
                        </div>
                        <ExecutiveSummaryTitle>Snapshot of Other Instruments in Buylist</ExecutiveSummaryTitle>
                        <div
                            style={{
                            transform: `scale(${scale})`,
                            transformOrigin: "top left",
                            width: `${1200 * scale}px`, // Match the scaled chart width
                            margin: "auto"
                            }}
                        >
                            <Chart
                                chartType="OrgChart"
                                data={orgChartDataOtherInstr}
                                options={orgChartOptions}
                                width="100%"
                                height="min-content"
                                loader={<div>Loading Snapshot of Instruments Universe...</div>}
                            />
                        </div>
                    </OrgChartHolder>
                    <ChartHolder>
                        <ExecutiveSummaryContent>
                            <ExecutiveSummaryTitle>Buylist Updates By Category</ExecutiveSummaryTitle>
                            {tableData.map((group, index)=>(
                                        index !== 0 &&
                                        typeof group[0] === "string" && typeof group[1] === "number" &&
                                            <GroupDiv key={index}>
                                                <Field>{group[0]}: </Field>
                                                <Value>{group[1]}</Value>
                                            </GroupDiv>
                                        
                            ))}
                        </ExecutiveSummaryContent>
                        <Chart chartType="BarChart" width="100%" height="400px"  data={tableData} options={options}/>
                    </ChartHolder>
                </div>
                <NotVisibleDiv ref={reportRef}>
                    <LogoHolder>
                        <HubwiseLogo iconURL={hubwiseLogo} />
                        <T360Logo iconURL={t360LogoPurple} />
                    </LogoHolder>
                    <TopContainer>
                        <ExecutiveSummary>
                            <ExecutiveSummaryTitle>Executive Summary</ExecutiveSummaryTitle>
                            <ExecutiveSummaryContent>
                                <ExecutiveSummaryP>
                                    This report presents a detailed analysis of data changes over a specified time period. The objective is to provide key insights into how critical metrics have evolved, allowing management to track progress and make informed decisions.
                                </ExecutiveSummaryP>
                                <ExecutiveSummaryP>
                                    The analysis covers fund managers that are being added or removed along with their respective assets and updated assets information, comparing values across weekly intervals. 
                                </ExecutiveSummaryP>
                                <ExecutiveSummaryP>
                                    While no direct recommendations are included, the findings underscore essential trends and potential areas for deeper analysis. This report is intended to help the management team quickly identify areas of growth, decline, and stability across the evaluated metrics.
                                </ExecutiveSummaryP>
                                
                            </ExecutiveSummaryContent>
                        </ExecutiveSummary>
                        <CircleHolder>
                                <ExecutiveSummaryTitle>Fund Managers</ExecutiveSummaryTitle>
                                <Chart
                                    chartType="PieChart" // Donut is a PieChart with a pieHole
                                    data={fundManagerStats}
                                    options={donutOptions}
                                    width="100%"
                                    height="250px"
                                />
                            </CircleHolder>
                        
                    </TopContainer>
                    <ChartHolder>
                        <ExecutiveSummaryContent>
                            <ExecutiveSummaryTitle>Buylist Updates By Category</ExecutiveSummaryTitle>
                            {tableData.map((group, index)=>(
                                        index !== 0 &&
                                        typeof group[0] === "string" && typeof group[1] === "number" &&
                                            <GroupDiv key={index}>
                                                <Field>{group[0]}: </Field>
                                                <Value>{group[1]}</Value>
                                            </GroupDiv>
                                        
                            ))}
                        </ExecutiveSummaryContent>
                        <Chart chartType="BarChart" width="100%" height="400px"  data={tableData} options={options}/>
                    </ChartHolder>
                </NotVisibleDiv>
            </>
           
        : <NoDataContent />
            
    );
}
  
export default ReportSummary;
  