import NoDataContent from "components/noData/NoDataContent";
import { FC } from "react";
import {
  TileContainer,
  TilePersonIcon,
  TilePersonField,
  TilePersonValue,
  TilePersonIconContainer,
  TilePersonDetailsContainer,
  TileRow
} from "styles/party/keyPeople/TilePerson.styled";
import { AgentCodesContactInfo } from "types/funds";

const FundManagerCard: FC<{ data? : AgentCodesContactInfo }> = ({ data }) => {

  return (
    <TileContainer>        
        <TilePersonIconContainer>
            <TilePersonIcon personFlag={data?.["Person Flag"] !== "N" ? true : false}/>
        </TilePersonIconContainer>
      <TilePersonDetailsContainer>
        {data ? (
            <>
                {data?.Name && (
                  <TileRow>
                    <TilePersonField > Name: </TilePersonField>
                    <TilePersonValue> {data?.Name} </TilePersonValue>
                  </TileRow>
                )}
                {Object.entries(data).map(([key, value]) =>            
                    key !== "Name" && key !== "Person Flag" && key !== "Fax" && (
                      value !== null && (
                        <TileRow key={key}>
                          <TilePersonField > {key}: </TilePersonField>
                          <TilePersonValue> {value} </TilePersonValue>
                        </TileRow>
                      )
                      
                    )
                )}
                {data.Fax && 
                  <TileRow>
                    <TilePersonField>Fax</TilePersonField>
                    <TilePersonValue> {data.Fax} </TilePersonValue>
                  </TileRow>
                }
            </>
        ) : (
          <NoDataContent />
        )}
      </TilePersonDetailsContainer>
    </TileContainer>
  );
};

export default FundManagerCard;
