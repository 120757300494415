import React, { FunctionComponent, useState } from "react";
import { Accordion } from "components/party/accordion/Accordion";
import { 
    TemporaryMasterInstrumentsFundManagerType,
    TemporaryFundsType,
    TemporaryFigiMapType
 } from "types/funds";

 import { 
    FormRow,
    AccordionHolder,
    FundManagerInfoHolder,
    SubmitBtnHolder, 
    ListPValue,
    ListPLabel,
    ListHolder
} from "styles/funds/MasterBuylist.styled";
import { FormLabel, InputWrapper, Input } from "styles/fundDetailsConfirmation/FundDetailsConfirmation.styled";
import SelectedFmManagedFundsTable from "./SelectedFmManagedFundsTable";
import AliceModalTooltip from "components/AliceModalTooltip";

const SelectedFmInfo : FunctionComponent<{
    dataToDisplay: TemporaryMasterInstrumentsFundManagerType[];
    selectedfm: {index: number; obj: TemporaryMasterInstrumentsFundManagerType } | undefined;
    setSelectedFund: React.Dispatch<React.SetStateAction<{index: number; obj: TemporaryFundsType} | undefined>>;
    setSelectedFigi: React.Dispatch<React.SetStateAction<{index: number; obj: TemporaryFigiMapType} | undefined>>;
    setFmAccordionOpen: React.Dispatch<React.SetStateAction<boolean>>;
    fmAccordionOpen: boolean;
    setSelectedfm: React.Dispatch<React.SetStateAction<{
        index: number;
        obj: TemporaryMasterInstrumentsFundManagerType;
    } | undefined>>;
    addressDetails: boolean;
    editFM: boolean;
    fmView: boolean;
    setData: React.Dispatch<React.SetStateAction<TemporaryMasterInstrumentsFundManagerType[] | undefined>>;
    targetRef: React.RefObject<HTMLDivElement>
}> =  ({
    dataToDisplay,
    selectedfm,
    setSelectedFund,
    setSelectedFigi,
    setFmAccordionOpen,
    fmAccordionOpen,
    setSelectedfm,
    addressDetails,
    editFM,
    fmView,
    setData,
    targetRef
}) => {  
    const [editEnabled, setEditEnabled]= useState<boolean>(false);
    const handleFmChange = (label: string, value: string | {
            "Line 1": string | null;
            "Line 2": string | null;
            "Line 3": string | null;
            "Line 4": string | null;
            "Line 5": string | null;
            "Post Code": string | null;
            [key: string]: string | null
        } | {
            "Telephone Number": string | null;
            "Email": string | null;
            [key: string]: string | null;
        }) => {
        const newfm= selectedfm?.obj;
        if(newfm && selectedfm) {
            newfm[label]= value;
            setSelectedfm({ index: selectedfm.index, obj: newfm });
        }
    };

    const handleAddressChange= (label: string, value: string) => {
        const newAddress= selectedfm?.obj.Address;
        if (newAddress && selectedfm) {
            newAddress[label]= value;
            handleFmChange("Address", newAddress);
        }
    }

    const handleContactDetailsChange= (label: string, value: string) => {
        const newContacts= selectedfm?.obj["Contact Information"];
        if (newContacts && selectedfm) {
            newContacts[label]= value;
            handleFmChange("Contact Information", newContacts);
        }
    }

    const handleSubmit = () => {
        if(selectedfm) {
            setData(prevState => {
                const updatedState= prevState?.map((fm, fmI) => {
                    if(fmI === selectedfm?.index) {
                        return { ...selectedfm.obj};
                    } else {
                        return fm;
                    }
                })
                return updatedState
            })

        }
        setEditEnabled(false);
    }

    return (
        <AccordionHolder>
            <Accordion key={"instrument-dashboard-accordion"} title={"Fund Manager"} noTopBorder opened={!!fmAccordionOpen} borderBottom>
                <>
                    <Accordion key={"instrument-dashboard-accordion"} title={"General Details"} light noTopBorder opened borderBottom>
                        <ListHolder>
                            <ListPLabel>Provider Name</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedfm?.obj["Provider Name"] ? selectedfm?.obj["Provider Name"] : "-"}
                                selectedKey={"Provider Name"}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedfm?.obj["Provider Name"] ? selectedfm?.obj["Provider Name"] : "-"}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>Provider Code</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedfm?.obj["Provider Code"] ? selectedfm?.obj["Provider Code"] : "-"}
                                selectedKey={"Provider Code"}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedfm?.obj["Provider Code"] ? selectedfm?.obj["Provider Code"] : "-"}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>Status</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedfm?.obj["Status"] ? selectedfm?.obj["Status"] : "-"}
                                selectedKey={"Status"}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedfm?.obj["Status"] ? selectedfm?.obj["Status"] : "-"}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>Morningstar Code</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedfm?.obj["Morningstar Code"] ? selectedfm?.obj["Morningstar Code"] : "-"}
                                selectedKey={"Morningstar Code"}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedfm?.obj["Morningstar Code"] ? selectedfm?.obj["Morningstar Code"] : "-"}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>Provider Type</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedfm?.obj["Provider Type"] ? selectedfm?.obj["Provider Type"] : "-"}
                                selectedKey={"Provider Type"}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedfm?.obj["Provider Type"] ? selectedfm?.obj["Provider Type"] : "-"}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>External Provider ID</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedfm?.obj["External Provider ID"] ? selectedfm?.obj["External Provider ID"] : "-"}
                                selectedKey={"External Provider ID"}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedfm?.obj["External Provider ID"] ? selectedfm?.obj["External Provider ID"] : "-"}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>EMX Product ID</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedfm?.obj["EMX Product ID"] ? selectedfm?.obj["EMX Product ID"] : "-"}
                                selectedKey={"EMX Product ID"}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedfm?.obj["EMX Product ID"] ? selectedfm?.obj["EMX Product ID"] : "-"}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>EMX Provider ID</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedfm?.obj["EMX Provider ID"] ? selectedfm?.obj["EMX Provider ID"] : "-"}
                                selectedKey={"EMX Provider ID"}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedfm?.obj["EMX Provider ID"] ? selectedfm?.obj["EMX Provider ID"] : "-"}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>Routing Code 1</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedfm?.obj["Routing Code 1"] ? selectedfm?.obj["Routing Code 1"] : "-"}
                                selectedKey={"Routing Code 1"}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedfm?.obj["Routing Code 1"] ? selectedfm?.obj["Routing Code 1"] : "-"}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>Routing Code 2</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedfm?.obj["Routing Code 2"] ? selectedfm?.obj["Routing Code 2"] : "-"}
                                selectedKey={"Routing Code 2"}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedfm?.obj["Routing Code 2"] ? selectedfm?.obj["Routing Code 2"] : "-"}</ListPValue>
                            </AliceModalTooltip>

                            {addressDetails &&
                                <>
                                    <ListPLabel>Address Line 1</ListPLabel>
                                    <AliceModalTooltip
                                        selectedField={selectedfm?.obj.Address["Line 1"] ? selectedfm?.obj.Address["Line 1"] : "-"}
                                        selectedKey={"Address Line 1"}
                                        isSmall
                                        isCard={false}
                                    >
                                        <ListPValue>{selectedfm?.obj.Address["Line 1"] ? selectedfm?.obj.Address["Line 1"] : "-"}</ListPValue>
                                    </AliceModalTooltip>
                                    <ListPLabel>Address Line 2</ListPLabel>
                                    <AliceModalTooltip
                                        selectedField={selectedfm?.obj.Address["Address Line 2"] ? selectedfm?.obj.Address["Address Line 2"] : "-"}
                                        selectedKey={"Address Line 2"}
                                        isSmall
                                        isCard={false}
                                    >
                                        <ListPValue>{selectedfm?.obj.Address["Address Line 2"] ? selectedfm?.obj.Address["Address Line 2"] : "-"}</ListPValue>
                                    </AliceModalTooltip>
                                    <ListPLabel>Email</ListPLabel>
                                    <AliceModalTooltip
                                        selectedField={selectedfm?.obj["Contact Information"]["Email"] ? selectedfm?.obj["Contact Information"]["Email"] : "-"}
                                        selectedKey={"Status"}
                                        isSmall
                                        isCard={false}
                                    >
                                        <ListPValue>{selectedfm?.obj["Contact Information"]["Email"] ? selectedfm?.obj["Contact Information"]["Email"] : "-"}</ListPValue>
                                    </AliceModalTooltip>
                                    <ListPLabel>Telephone Number</ListPLabel>
                                    <AliceModalTooltip
                                        selectedField={selectedfm?.obj["Contact Information"]["Telephone Number"] ? selectedfm?.obj["Contact Information"]["Telephone Number"] : "-"}
                                        selectedKey={"Status"}
                                        isSmall
                                        isCard={false}
                                    >
                                        <ListPValue>{selectedfm?.obj["Contact Information"]["Telephone Number"] ? selectedfm?.obj["Contact Information"]["Telephone Number"] : "-"}</ListPValue>
                                    </AliceModalTooltip>
                                </>
                            }
                            
                            
                        </ListHolder>
                    </Accordion>
                    <SelectedFmManagedFundsTable
                            dataToDisplay={dataToDisplay}
                            selectedfm={selectedfm}
                            setSelectedFund={setSelectedFund}
                            setSelectedFigi={setSelectedFigi}
                            fmView={fmView}
                            targetRef={targetRef}
                        />
                </>
            </Accordion>
        </AccordionHolder>
    )
}

export default SelectedFmInfo;