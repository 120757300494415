import NoDataContent from "components/noData/NoDataContent";
import { FC } from "react";
import { List, Text, FundsListContainer, ListHolder, ListPValue, ListPLabel } from "styles/party/funds/index.styled";
import AliceModalTooltip from "components/AliceModalTooltip";

const ListData: FC<{ data: { [key: string]: string } | null; small?: boolean; title?: string; fundId?: string; twoColumns?: boolean }> = ({
  data,
  fundId,
  small,
  title,
  twoColumns
}) => {
  return (
    <FundsListContainer>
      <div className="title-cover">
        {title ? (
          <Text light data-testid="title">
            {title}
          </Text>
        ) : null}
      </div>
      {data ? 
        <ListHolder twoColumns={twoColumns}>
          {Object.entries(data).map(([key, value]) => (
            <List key={key} small={small} data-testid="tags">
              <ListPLabel>{`${key}:`}</ListPLabel>
              <AliceModalTooltip
                selectedField={value}
                selectedKey={key}
                selectedFund={fundId}
                isSmall
              >
                  <ListPValue>{value}</ListPValue>
              </AliceModalTooltip>
            </List>
        ))}
        </ListHolder>
        
       : (
        <NoDataContent />
      )}
    </FundsListContainer>
  );
};

export default ListData;
