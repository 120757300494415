import { FunctionComponent, PropsWithChildren, useEffect } from "react";
import { toggleScroll } from "utils";
import { ModalController } from "../../hooks/useModal";
import { Container, Modal } from "../../styles/Modal.styled";

const ModalComponent: FunctionComponent<
  PropsWithChildren<{
    controller: ModalController;
    width?: string;
    padding?: string;
    medium?: boolean;
  }>
> = ({ children, controller, width, padding, medium }) => {
  useEffect(() => {
    if(controller) {
      toggleScroll(controller.isOpen);
    }
  }, [controller]);

  if (controller && !controller.isOpen) return null;
  return (
    <Container>
      <Modal data-testid="modal" width={width!} padding={padding!} medium={medium}>
        {children}
      </Modal>
    </Container>
  );
};

export default ModalComponent;
