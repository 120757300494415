import { FunctionComponent, useState } from "react";
import { 
    ProgressBar,
    BarChartContainer,
    BarChartTitle,
    BarChartHolder,
    ProgressBarTileSpan
} from "styles/funds/Onboarding.styled";
import LinearProgressComponent from "../linearProgress/LinearProgress";
import { ClientRequestTemplateType } from "types/party";
import NoData from "components/noData/NoData";
import { colorPick } from "utils/onboardingColorPick";

const AverageOnboardingTime: FunctionComponent<{
    data: ClientRequestTemplateType[] | undefined;
    showTitle: boolean;
    boxShadow?: boolean;
}> =  ({
    data,
    showTitle,
    boxShadow
}) => {
    return data ? (
        <BarChartContainer boxShadow= {boxShadow}>
            {showTitle && <BarChartTitle>Average Onboarding Completion Time</BarChartTitle>}
            <ProgressBar>
                <ProgressBarTileSpan></ProgressBarTileSpan>
                <ProgressBarTileSpan>0 months</ProgressBarTileSpan>
                <ProgressBarTileSpan>1 month</ProgressBarTileSpan>
                <ProgressBarTileSpan>2 months</ProgressBarTileSpan>
            </ProgressBar>
            <BarChartHolder>
                {data.length > 0 && data.map((ccid,index) => (
                    <LinearProgressComponent
                        key={index} 
                        label={ccid["Party Legal Name"] && ccid["Party Legal Name"]} 
                        value={parseInt(ccid.Stats["Completion percentage"])} 
                        barBackgroundColor="#bcbcbc" 
                        barColor={colorPick(index)[0]}
                        height={12}
                        days={"N/A"}
                    />
                ))}
            </BarChartHolder>
        </BarChartContainer>   
    ) : <NoData />
};

export default AverageOnboardingTime;