// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { Accordion } from "components/party/accordion/Accordion";
import { 
    Container, 
    Title,
    ContentItem,
    QHolder,
    Section,
    QAccordionHolder,
    QRow,
    FormLabel,
    InputWrapper,
    Input,
    SubmitBtnHolder 
} from "styles/sippAdmin/sippAdmin.styled";
import { useState } from "react";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";

function SIPPClientDetailsComponent() {

    const formObj = {
        "Title": "",
        "Name": "",
        "Date of Birth": "",
        "Address Line 1": "",
        "Address Line 2": "",
        "Address Line 3": "",
        "Country": "",
        "City": "",
        "Post Code": "",
        "Gender": "",
        "Nationality": "",
        "NI Number": "",
        "Status (Married/ Single/ etc.)": "",
        "Income": "",
        "Phone Number": "",
        "Email": "",
        "Retirement Age": "",
        "Bank Account Number": "",
        "Bank Sort Code": "",
        "Bank Name": "",
        "IBAN": "",
        "BIC": ""
    }
    const [formValues, setFormValues] = useState<{[key: string]: any}>(formObj); 

    const handleInputChange = (label: string, value: string) => {
        setFormValues(prevState => ({ ...prevState, [label]: value }));
    };

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        //TODO: Send formValues to backend
        console.log("Form submitted:", formValues);
    };

  return (
    <Container>
        <Title>
            Client Details
        </Title>
        <Accordion title="Screen" noTopBorder>
            <div>
                <QHolder onSubmit={handleSubmit}>
                    <Section>
                        <QAccordionHolder>  
                            {Object.entries(formValues).map(([label, value]) => (
                                <QRow key={label}>
                                    <FormLabel htmlFor={label}>{label}</FormLabel>
                                    <InputWrapper>
                                        <Input
                                            type="text"
                                            id={label}
                                            value={value}
                                            onChange={(e) => handleInputChange(label, e.target.value)}
                                        />
                                    </InputWrapper>
                                </QRow>
                            ))}
                        </QAccordionHolder>
                    </Section>
                </QHolder>
                <SubmitBtnHolder>
                    <SubmitButton
                        onClick={handleSubmit}
                        text={"Submit"}
                        type={"submit"}
                        disabled={false}
                    />
                </SubmitBtnHolder>
            </div>
        </Accordion>
        <Accordion title="Section Content" opened>
            <div>
                <ContentItem>Title</ContentItem>
                <ContentItem>Name</ContentItem>
                <ContentItem>Date of Birth</ContentItem>
                <ContentItem>Address</ContentItem>
                <ContentItem>Gender</ContentItem>
                <ContentItem>Nationality</ContentItem>
                <ContentItem>NI Number</ContentItem>
                <ContentItem>Status – Married/ Single/ etc.</ContentItem>
                <ContentItem>Income</ContentItem>
                <ContentItem>Phone Number</ContentItem>
                <ContentItem>Email Address</ContentItem>
                <ContentItem>Retirement Age (mandatory for SIPP)</ContentItem>
                <ContentItem>Bank details – for contributions and withdrawals</ContentItem>
            </div>
        </Accordion>
    </Container>
  );
}

export default SIPPClientDetailsComponent;
