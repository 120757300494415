import { useEffect, useState, ChangeEvent } from "react";
import { useContext } from "react";
import { AppContext } from "contexts/App";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import { CREATE_NEW_ALICE_TICKET } from "config/googleCloudFunctionsConfig";
import { format } from "date-fns";
import { getAccessToken } from "../../../services/auth0/auth0";
import { 
  FormHolder, 
  Input, 
  InputWrapper, 
  FormLabel, 
  FormValue,
  ErrorDiv,
  SucessMessageP,
  SucessMessageHolder,
  SuccessIcon 
} from "styles/crownAlert/FirstStep.styled";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import { useAppData } from "hooks/useAppData";
import { PARTY, PATH, USER_ROLES } from "constants/index";
import { MY_VIEW_TAB } from "constants/hubwiseDashboard";
import { CheckCircleOutlineSharp } from "@material-ui/icons";

function FirstStep({
  selectedField,
  selectedKey,
  alertIsActive,
  menuToggle,
  selectedFund,
  } : {
  setStep: React.Dispatch<React.SetStateAction<string>>,
  selectedField?: string,
  selectedKey?: string,
  selectedFund?: string,
  alertIsActive: boolean,
  menuToggle: () => void
}) {
  const { selectedParty } = useContext(AppContext);
  const accessToken = getAccessToken();
  const currentDateString = format(new Date(), "dd MMM yyy");
  const currentTimeString = format(new Date(), "HH:mm");
  const [showGeneralTicketLayout, setShowGeneralTicketLayout] = useState<boolean>(false);
  const [showDataSuggestionTicketLayout, setShowDataSuggestionTicketLayout]= useState<boolean>(false);
  // suggestion handle
  const [_value, setValue] = useState("");
  const [errorSuggestion, setErrorSuggestion] = useState<boolean>(false);
  const [errSuggestionContent, setErrSuggestionContent]= useState<string>("");
  const [suggestionInteraction, setSuggestionInteraction] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAliceUser , setIsAliceUser] = useState<boolean>(false);

  // comment handle
  const [_comment, setComment] = useState("");
  const [errorComment, setErrorComment] = useState<boolean>(false);
  const [errCommentContent, setErrCommentContent]= useState<string>("");
  const [commentInteraction, setCommentInteraction] = useState<boolean>(false);

  const [enableSubmit, setEnableSubmit] = useState(false);
  const [confirmedValue, setConfirmedValue] = useState<string>("");
  const [internalValue, setInternalValue] = useState<string>("");
  const [ticketSubmitted, setTicketSubmitted]= useState(false);
  // redirection to alice tickets after a submitted new ticket
  const { user, isPartOfRoles } = useAppData();


  const cleanBeforeClose = () => {
    setSuggestionInteraction(false);
    setErrorSuggestion(false);
    setCommentInteraction(false);
    setErrorComment(false);
    setErrCommentContent("");
    setErrSuggestionContent("");
    setValue("");
    setComment("");
    setIsLoading(false);
    menuToggle();
  }

  const createTicket = () => {
    // roomName must be up to 50 characters.
    const params = {
      "action": "create_stream",
      "roomName": selectedParty && selectedKey && selectedField
                ? `${selectedParty?.["Legal Name"]} : ${selectedField}`.slice(0, 50)
                : `General ticket : ${currentDateString} - ${currentTimeString}`.slice(0, 50),
      "crosswalk": selectedFund && selectedKey ? `${selectedFund}/${selectedKey}` : selectedKey ? selectedKey : "",
      "pid": selectedParty?.id ? selectedParty?.id : "",
      "ticketType": selectedField ? "data_change_suggestion" : "general_ticket",
      "targetValue": selectedField ? selectedField: "",
      "suggestedValue": selectedField ? _value: "",
      "confirmedValue": confirmedValue ? confirmedValue : "",
      "internalValue" : internalValue ? setInternalValue : "",
      "externalValue": internalValue ? setInternalValue : "",
      "commentValue" : _comment ? _comment : "",
      "userDescription" : _comment ? `${_comment.slice(0,150)}...` : ""
    } 
    
    if(accessToken) {
      callGCFunctionWIthAccessToken(accessToken, CREATE_NEW_ALICE_TICKET, params, "test", "test")
      setTicketSubmitted(true);
    }
  }

  useEffect(()=>{
    if(user) {
      setIsAliceUser(isPartOfRoles([USER_ROLES.ALICE_TICKET_MANAGER]) || isPartOfRoles([USER_ROLES.ALICE_TICKET_USER]))
    }
  },[user])

  //checks for enabeling submit button 
  useEffect(()=>{
    if (suggestionInteraction) {
      if(_value.trim() === "") {
        setErrorSuggestion(true);
        setErrSuggestionContent("This field can NOT be empty. Please provide a value!")
        setEnableSubmit(false);
      } else {
        setErrorSuggestion(false);
      }
    } else {
      setEnableSubmit(false);
    }
    
    if(commentInteraction) {
      if(_comment.trim() === "") {
        setErrorComment(true);
        setErrCommentContent("This field can NOT be empty. Please provide a value!");
        setEnableSubmit(false);
      }
      else {
        setErrorComment(false);
      }
    } else {
      setEnableSubmit(false);
    }

    if (showDataSuggestionTicketLayout) {
      if(!errorComment && !errorSuggestion && commentInteraction && suggestionInteraction) {
        setEnableSubmit(true);
      } else if (!commentInteraction && !suggestionInteraction) {
        setEnableSubmit(false);
      }
    } else if (showGeneralTicketLayout) {
      if(!errorComment && commentInteraction ) {
        setEnableSubmit(true);
      } else if (!commentInteraction) {
        setEnableSubmit(false);
      }
    }
  }, [_value, _comment, errorComment, errorSuggestion])

  // checks if popup is open, which layer to display, cleanup at close w/o submitting
  useEffect(()=>{
    if (alertIsActive) {
      if(selectedKey && selectedField) {
        setShowDataSuggestionTicketLayout(true);
      } else {
        setShowGeneralTicketLayout(true);
        setShowDataSuggestionTicketLayout(false);
      }
    } else {
      setSuggestionInteraction(false);
      setErrorSuggestion(false);
      setCommentInteraction(false);
      setErrorComment(false);
      setErrCommentContent("");
      setErrSuggestionContent("");
      setValue("");
      setComment("");
      setTicketSubmitted(false);
    }
  },[alertIsActive, accessToken])

  // ensures only one checkbox can be selected at a time
  const handleCheckboxChange = (e: HTMLInputElement) => {
    const checkboxes = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"][name="source"]');  
    checkboxes.forEach(checkbox => {
      if (checkbox !== e) {
        checkbox.checked = false;
      }
    });
    setInternalValue(e.value);
  }

  // handle suggestion input change
  const handleCurrentValue = (event: ChangeEvent<HTMLInputElement>) => {
    if(!suggestionInteraction) {
      setSuggestionInteraction(true);
    }
    setValue(event.target.value);
  };
  // handle comment input change
  const handleComment = (event: ChangeEvent<HTMLInputElement>) => {
    if(!commentInteraction) {
      setCommentInteraction(true);
    } 
    setComment(event.target.value);
  };



  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmedValue(event.target.value);
  };

  return (
    isLoading ? <LoadingSpinner /> 
    : !ticketSubmitted ? (
      <>
        <FormLabel>Selected Party: {selectedParty?.["Legal Name"]}</FormLabel>
        {showDataSuggestionTicketLayout && 
          <FormHolder>
            <FormLabel>Specific value path: </FormLabel>
            <FormValue>{selectedKey}</FormValue>
            <FormLabel>Current value: </FormLabel>
            <FormValue>{selectedField}</FormValue>
            <FormLabel>Suggested value: </FormLabel>
            <InputWrapper>
              <Input
                value={_value}
                onChange={handleCurrentValue}
                aria-label="input"
                border={errorSuggestion}
                placeholder={"Please insert here the suggested value"}
              />
            </InputWrapper>
            { errorSuggestion && <ErrorDiv>{errSuggestionContent}</ErrorDiv> }
            <FormLabel>Has it been confirmed by you?(test purpose)</FormLabel>
            <FormValue>
              <input type="radio" name="confirmed-option" value={"Yes"} onChange={handleOptionChange}/> Yes
            </FormValue>
            <FormValue>
              <input type="radio" name="confirmed-option" value={"No"} onChange={handleOptionChange}/> No
            </FormValue>
            <FormLabel>Taken from an internal source?</FormLabel>
            <FormValue>
              <input type="checkbox" name="source" value={"Internal"} onChange={(e) => handleCheckboxChange(e.target)}/> Internal
            </FormValue>
            <FormValue>
              <input type="checkbox" name="source" value={"External"} onChange={(e) => handleCheckboxChange(e.target)}/> External
            </FormValue>
            <FormLabel>Send a comment</FormLabel>
            <InputWrapper>
              <Input placeholder={"Add your comment here"} 
                value={_comment}
                onChange={handleComment}
                aria-label="input"
                border={errorComment}
              />
            </InputWrapper>
            { errorComment && <ErrorDiv>{errCommentContent}</ErrorDiv> }
            <SubmitButton
              onClick={createTicket}
              text={"Submit"}
              type={"submit"}
              disabled={!(isAliceUser && enableSubmit)}
            />
          </FormHolder>
        }
        {showGeneralTicketLayout && 
          <FormHolder>
            <FormLabel>Send a general enquiry: </FormLabel>
            <InputWrapper>
              <Input placeholder={"Add your comment here"} 
                value={_comment}
                onChange={handleComment}
                aria-label="input"
                border={errorComment}
              />
            </InputWrapper>
            { errorComment && <ErrorDiv>{errCommentContent}</ErrorDiv> }
            <SubmitButton
              onClick={createTicket}
              text={"Submit"}
              type={"submit"}
              disabled={!(isAliceUser && enableSubmit)}
            />
          </FormHolder>
        }
      </>
    )
    : 
      <SucessMessageHolder>
        <SuccessIcon />
        <SucessMessageP>Submitted succesfully! Data is being verified and processed!</SucessMessageP>
      </SucessMessageHolder>
  )
};

export default FirstStep;
