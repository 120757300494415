import { useContext } from "react";
import { AppContext } from "contexts/App";

export function useToast() {
  const { toastInfo, setToastInfo } = useContext(AppContext);

  function toast(message: string) {
    console.log(message, "mesage");
  }

  return { toast, toastInfo, setToastInfo };
}
