import styled from "styled-components";

export const DetailsIcon = styled.img``;

export const DetailsIconWrapper = styled.div`
  width: 19px;
  height: 19px;
  padding-left: 10px;
  display: block;
  cursor: pointer;
  opacity: 0;
`;

export const Container = styled.div<{ isCard: boolean }>`
  padding: 0px 0px 0px 0px;
  color: ${({ theme }) => theme.colors.GREY[500]};
  font-size: 16px;
  font-family: Roboto Condensed;
  display: flex;
  align-items: ${({ isCard }) => (isCard ? "flex-start" : "center")};
  &:hover > ${DetailsIconWrapper} {
    opacity: 1;
  }
`;
