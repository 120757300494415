import { FunctionComponent } from "react";
import { 
    MyViewContainer,
    Title
} from "styles/funds/MyView.styled";
import UserDesktopViewComponent from "./UserDesktopView";

const HubwiseMyViewComponent: FunctionComponent =  () => {
      
    return (
      <MyViewContainer>
          <Title>User Desktop View</Title>
          <UserDesktopViewComponent />
      </MyViewContainer>
    )
};

export default HubwiseMyViewComponent;