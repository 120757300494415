// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { Accordion } from "components/party/accordion/Accordion";
import { 
    Container, 
    Title,
    ContentItem 
} from "styles/sippAdmin/sippAdmin.styled";

function SIPPAssetsComponent() {

  return (
    <Container>
        <Title>
            Assets
        </Title>
        <Accordion title="Screen" noTopBorder>
            <div></div>
        </Accordion>
        <Accordion title="Section Content" opened>
            <div>
                <ContentItem>List of funds held</ContentItem>
                <ContentItem>Fees</ContentItem>
            </div>
        </Accordion>
    </Container>
  );
}

export default SIPPAssetsComponent;
