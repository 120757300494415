import React, {useEffect} from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import {Outlet} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";


export const Auth0Routes: React.FC = () => {

    const { isAuthenticated, isLoading, loginWithRedirect, getIdTokenClaims } = useAuth0();

    useEffect(() => {

        if (!isAuthenticated) {
            if (!isLoading) {
                loginWithRedirect();
                getIdTokenClaims().then((data) => {
                    console.log("id token claims: ", data);
                })
            }
        }
    }, [isAuthenticated, isLoading]);

    if (!isAuthenticated || isLoading) {
        getIdTokenClaims().then((data) => {
            console.log("id token claims: ", data);
        })
        
        return <LoadingSpinner />;
    }

    return <Outlet />;
};