import DATA from "data/SSI.json";
import { FC } from "react";
import { TableContent } from "components/tableContent/TableContent";
import { NoDataToDisplay } from "components/noData/ComingSoon";
import COUNTRIES_DATA from "data/ssiCountries.json";
import SSIImage from "./SSIImage";

const SSI: FC<{ data: typeof DATA | null }> = ({ data }) => {
  // country initials
  const Cinitials = data?.[1]?.map((c) => c?.["BENEFICIARY (58A)"]?.substring(4, 6)) || [];

  if (!data) return <NoDataToDisplay />;
  return (
    <div>
      <SSIImage Cinitials={Array.from(new Set(Cinitials))} countriesData={COUNTRIES_DATA} />

      <TableContent data={data?.[1]} />
    </div>
  );
};

export default SSI;
