import { 
    TemporaryMasterInstrumentsFundManagerType,
    TemporaryFundsType,
    TemporaryFigiMapType
} from "types/funds";

export const filterFundsHubwiseData = (data: TemporaryMasterInstrumentsFundManagerType[], value: string): Promise<{fmIndex: number; fundIndex: number; figiIndex: number; obj: TemporaryMasterInstrumentsFundManagerType }[]> => {
    return new Promise((resolve) => {
        setTimeout(()=>{
            const result: {fmIndex: number; fundIndex: number; figiIndex: number; obj: TemporaryMasterInstrumentsFundManagerType }[]= [];                
            let matchCount: number= 0;
            const maxMatches= 10;
            let fundIndexL: number, figiIndexL: number;
            data.forEach((fm: TemporaryMasterInstrumentsFundManagerType, fmIndexL) => {
                
                const filteredFunds: TemporaryFundsType[]= [];

                fm.funds.forEach((fund: TemporaryFundsType, fundIndex) => {
                    if(matchCount >= maxMatches) return;
                    const filteredFigis: TemporaryFigiMapType[]= [];
                    
                    if (fund.ISIN && fund.ISIN.toLowerCase().includes(value) ||
                    fund.Sedol && fund.Sedol.toLowerCase().includes(value) ||
                    fund["Instrument Name"] && fund["Instrument Name"].toLowerCase().includes(value)
                        ) {
                        filteredFunds.push(fund);
                        fundIndexL= fundIndex;
                        matchCount++;
                        return;
                    }

                    fund.figi_map.forEach((figi: TemporaryFigiMapType, figiIndex) => {
                        if(matchCount >= maxMatches) return;
                        if (figi.figi && figi.figi.toLowerCase().includes(value) ||
                        figi.shareClassFIGI && figi.shareClassFIGI.toLowerCase().includes(value) ||
                        figi.compositeFIGI && figi.compositeFIGI.toLowerCase().includes(value) ||
                        figi.name && figi.name.toLowerCase().includes(value)) {
                            filteredFigis.push(figi);
                            figiIndexL= figiIndex;
                            matchCount++;
                            return;
                        }
                    })

                    if (filteredFigis.length > 0) {
                        const filteredFundFigis= {...fund, figi_map: filteredFigis};
                        filteredFunds.push(filteredFundFigis);
                        fundIndexL= fundIndex;
                    }
                });

                if(filteredFunds.length > 0) {
                    const filteredFM = {...fm, funds: filteredFunds};
                    result.push({fmIndex: fmIndexL, fundIndex: fundIndexL, figiIndex: figiIndexL, obj: filteredFM});
                }

                if(matchCount >= maxMatches) return;

            })
            resolve(result);
        }, 800);
    });
}

export const filterFundManagersHubwiseData = (data: TemporaryMasterInstrumentsFundManagerType[], value: string): Promise<{fmIndex: number; obj: TemporaryMasterInstrumentsFundManagerType }[]> => {
    return new Promise((resolve) => {
        setTimeout(()=>{
            const result: {fmIndex: number; obj: TemporaryMasterInstrumentsFundManagerType }[]= [];                
            let matchCount: number= 0;
            const maxMatches= 10;
            data.forEach((fm: TemporaryMasterInstrumentsFundManagerType, fmIndexL) => {
                if(fm["Provider Name"].toLowerCase().includes(value) || 
                    fm["Provider Code"].toLowerCase().includes(value) 
                ){
                    matchCount++;
                    result.push({fmIndex: fmIndexL, obj: fm});
                    return;
                }

            if(matchCount >= maxMatches) return;

            })
            resolve(result);
        }, 800);
    });
}