// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the Party Profile section called History
 */

import { NoDataToDisplay } from "components/noData/ComingSoon";
import AliceModalTooltip from "components/AliceModalTooltip";
import { TableContent } from "../tableContent/TableContent";
import { dataFilter } from "../dataFilter/DataFilter";
import { FieldValueFilter } from "../FieldValueFilter";
import {
  CustomFieldsGroupContainer,
  SingleRowContainer,
  SingleRowInternalContainer,
  SingleRowTwoColumns,
  FieldName,
  FieldValue,
  HistoryContainer,
  FieldNameAlt,
  SingleRowAlt,
} from "../../styles/SectionSharedItems.styled";
import { HistoryArray, HistoryData } from "../../types";

export const FieldsGroup = ({ data }: { data: HistoryData }) => {
  return (
    <CustomFieldsGroupContainer>
      {data === undefined ? (
        <NoDataToDisplay />
      ) : (
        <>          
          <SingleRowTwoColumns key={"general-descr"}>
            <FieldName>General Description</FieldName>
            <FieldValue>
              <FieldValueFilter fieldToFilter={ data["General Description"] ? data["General Description"] : []} />
            </FieldValue>
          </SingleRowTwoColumns>

          <SingleRowContainer key={"date-of-incorp"} data-testid="row-cont">
            <SingleRowInternalContainer>
              <SingleRowAlt>
                <FieldNameAlt>Date Of Incorporation</FieldNameAlt>
                <AliceModalTooltip selectedField={data["Date of Incorporation"]} selectedKey={"Date of Incorporation"}>
                  {data["Date of Incorporation"]}
                </AliceModalTooltip>
              </SingleRowAlt>
            </SingleRowInternalContainer>
          </SingleRowContainer>
        </>
      )}
    </CustomFieldsGroupContainer>
  );
};

// main function
export function History({ data }: { data: HistoryData }) {
  return (
    <HistoryContainer>
      <FieldsGroup data={ data } />
    </HistoryContainer>
  );
}
