import styled from "styled-components";

export const SettingImg = `${process.env.PUBLIC_URL}/assets/comingsoon.svg`;

export const SettingSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const SettingSubSectionA = styled.div`
  margin: auto;
  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
  & > h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.PRIMARY[400]};
    margin: 0;
    @media (max-width: 768px) {
      font-weight: 600;
      font-size: 22px;
    }
  }
  & > span {
    font-weight: 700;
    font-size: 50px;
    line-height: 65px;
    text-align: center;
    display: flex;
    justify-content: center;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.DARK[400]};
    margin: 0;
    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 35px;
    }
  }
`;

export const SettingSubSectionB = styled.div`
  margin: auto;
`;
