import { FunctionComponent, useEffect } from "react";
import { useState } from "react";
import { 
    BuylistUpdatesContainer,
    BuylistUpdatesTitle,
    NavContainer,
    NavItem,
    BottomContainer
} from "styles/funds/HubwiseFundsUpdateArea.styled";
import { 
    BUYLIST_UPDATES_TABS,
    BUYLIST_UPDATES_TAB 
 } from "constants/hubwiseDashboard";
 import WeeklyBuylistUpdatesComponent from "./WeeklyBuylistUpdatesArea";
 import MonthlyBuylistUpdatesComponent from "./MonthlyBuylistUpdatesArea";
 import weeklyBuylistUpdatesDummyData from "../../../../data/weeklyBuylistUpdateDataDummy.json";
 import monthlyBuylistUpdatesDummyData from "../../../../data/montlyBuylistUpdateDataDummy.json";
 import { TemporaryMasterInstrumentsFundManagerType } from "types/funds";

const HuwiseInstrumentManagerFundsUpdateAreaComponent: FunctionComponent<{
    data?: TemporaryMasterInstrumentsFundManagerType[];
    buylistsInfo: {"Buylist Code": string, "Buylist Description": string}[];
    showTitle?: boolean;
    boxShadow: boolean;
}> =  ({
    data,
    showTitle,
    boxShadow,
    buylistsInfo
}) => {

    interface Stats {
        "Total Number of Buylists": number;
        "Total Number of Ringfenced Buylists": number;
        "Total Number of Standard Buylists": number;
        "Total Number of Fund Managers": number;
        "Total Number of Instruments": number;
        "Total Number of Standard Instruments": number;
        "Total Number of Ringfenced Instruments": number;
        [key: string]: number
    }
    const [tab, setTab] = useState<BUYLIST_UPDATES_TAB>(BUYLIST_UPDATES_TAB.WEEKLY);
    const [stats, setStats]= useState<Stats>();

    const getStats = () => {
        const newStats: Stats= {
            "Total Number of Buylists": 0,
            "Total Number of Ringfenced Buylists": 0,
            "Total Number of Standard Buylists": 0,
            "Total Number of Fund Managers": 0,
            "Total Number of Instruments": 0,
            "Total Number of Standard Instruments": 0,
            "Total Number of Ringfenced Instruments": 0
        };
        if(data && buylistsInfo) {
            data.map((fm) => {
                newStats["Total Number of Fund Managers"] += 1;
                newStats["Total Number of Instruments"] += fm.funds.length;
                fm.funds.map((fund) => {
                    const isStandard= fund.lists.filter(list => list["Buylist Code"] === "BLST01" && list["Buylist Description"] === "Standard Buylist")
                    isStandard.length > 0 ? newStats["Total Number of Standard Instruments"] +=1 : newStats["Total Number of Ringfenced Instruments"] +=1 ;
                })
            })
            newStats["Total Number of Buylists"]= buylistsInfo ? buylistsInfo.length : 0;
            newStats["Total Number of Ringfenced Buylists"]= buylistsInfo.length - 1;
            newStats["Total Number of Standard Buylists"]= 1;
        }
        if (newStats) {
            setStats(newStats);
        }
    }

    useEffect(()=>{
        getStats();
    },[data, buylistsInfo])

    return (
        <BuylistUpdatesContainer boxShadow= {boxShadow}>
            <WeeklyBuylistUpdatesComponent data={data} stats={stats}/>
            {/* <NavContainer>
                {BUYLIST_UPDATES_TABS.map((tabl)=>(
                    <NavItem 
                        key={tabl} 
                        isActive={tab === tabl} 
                        onClick={()=>setTab(tabl)}
                    > 
                        {tabl}
                    </NavItem>
                ))}
            </NavContainer> */}
            {/* <BottomContainer> */}
                {/* {tab === BUYLIST_UPDATES_TAB.WEEKLY && <WeeklyBuylistUpdatesComponent data={data} stats={stats}/>}  
                {tab === BUYLIST_UPDATES_TAB.MONTHLY && <MonthlyBuylistUpdatesComponent data={data} stats={stats}/>}    */}
            {/* </BottomContainer> */}
        </BuylistUpdatesContainer>
    )
};

export default HuwiseInstrumentManagerFundsUpdateAreaComponent;