import { FC, useState } from "react";
import { Container, ToolTip, Progress, ProgressFilled } from "styles/ProgressBar.styled";
import { useAppData } from "../../hooks/useAppData";

const ProgressBar: FC<{ color?: string }> = ({ color }) => {
  const [mouseDown, setMouseDown] = useState(false);
  const { opacity, setOpacity } = useAppData();

  const scrub = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const scrubTime = e.nativeEvent.offsetX / e.currentTarget.offsetWidth;
    setOpacity(Math.round(scrubTime * 100));
  };

  return (
    <Container>
      <ToolTip progress={opacity}>{opacity}%</ToolTip>
      <Progress
        onClick={scrub}
        onMouseMove={(e) => mouseDown && scrub(e)}
        onMouseDown={() => setMouseDown(true)}
        onMouseUp={() => setMouseDown(false)}
      >
        <ProgressFilled color={color} style={{ width: `${opacity}%` }} />
      </Progress>
    </Container>
  );
};

export default ProgressBar;
