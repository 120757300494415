import styled from "styled-components";

export const Tab = styled.div`
  margin-top: 23px;
  margin-bottom: 23px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const TabsWrapper = styled.div`
  background: ${({ theme }) => theme.colors.PRIMARY[500]};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 3px 4px;
`;
export const TabItem = styled.div<{ active?: boolean; status: string }>`
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  padding: 2px 10px;
  background: ${({ active, theme }) => (active ? "#364073" : theme.colors.WHITE)};
  color: ${({ active, theme, status }) =>
    active || status === "dark" ? "#fff" : theme.colors.PRIMARY[400]};
  border-radius: 16px;
  line-height: 20px;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  @media (max-width: 768px) {
    font-size: 13px;
    padding: 2px 07px;
  }
`;
