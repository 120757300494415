import React, { FunctionComponent, useEffect, useState } from "react";
import { DataPropsS } from "types/index";
import { ModalController } from "hooks/useModal";
import { format } from "date-fns";
import IframeCard from "components/ecp/EcpIframeCard";
import {
  Container,
  Td,
  Status,
  Dot,
  PaginationContainer,
  PaginationButton,
  PaginationPosition,
  InnerTd,
  InnerContent1,
  SpanWrapper,
  EmptySpan,
  TableDate,
  TableTime,
  NoResult,
  Extra,
  ExtraButton,
  TableBodyCell,
  RespTableRow,
  RespTableBody,
  TableHeaderCell,
  RespTableHeader,
  RespTable,
  WrapTable
} from "../../../styles/admin/AdminTable.styled";
import { useAppData } from "hooks/useAppData";
import { RedirectAliceData, NewAliceRedirectDataType } from "types/index";

const AdminTable: FunctionComponent<{
  data: DataPropsS[];
  isParty?: boolean;
  redirectData?: RedirectAliceData;
  setRedirectData?: React.Dispatch<React.SetStateAction<NewAliceRedirectDataType | undefined>>;
}> = ({ 
  data, 
  isParty = true, 
  redirectData, 
  setRedirectData 
}) => {
  const [dataToDisplay, setDataToDisplay] = useState<DataPropsS[]>(data);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [streamId, setStreamId] = useState("");
  const [chatName, setChatName] = useState("");

  const { isT360Email } = useAppData();
  const singleData = data.find((el) => el.id === selectedIndex);
  
  useEffect(()=>{
    if(redirectData){
      setSelectedIndex(redirectData.roomSystemInfo?.id ?? "");
      setStreamId(redirectData.roomSystemInfo?.id ?? "");
      setChatName(`test-${redirectData.roomSystemInfo?.id ?? ""}`);
    }
  },[redirectData])

  const close = () => {
    setSelectedIndex("");
    setStreamId("");
    setChatName("");
    if(setRedirectData) {
      setRedirectData(undefined);
    }
  };

  const toggleVisibility = (index: string) => {
    if (selectedIndex === index) {
      close();
    } else {
      setSelectedIndex(index);
      setStreamId(index);
      setChatName(`test-${index}`);
    }
  };

  useEffect(() => {
    if (selectedIndex) {
      const filteredData = dataToDisplay.filter((data) => data.id === selectedIndex);
      setDataToDisplay(filteredData);
    } else {
      setDataToDisplay(data);
    }
  }, [selectedIndex, data]);

  return (
    <Container>
      {!selectedIndex ? (
        <RespTable data-testid="table">
          <RespTableHeader>
            <RespTableRow>
              <TableHeaderCell>Created</TableHeaderCell> 
              <TableHeaderCell>Chat Name</TableHeaderCell> 
              <TableHeaderCell>Description</TableHeaderCell> 
              <TableHeaderCell>PID</TableHeaderCell> 
              <TableHeaderCell>Path</TableHeaderCell> 
              <TableHeaderCell>Status</TableHeaderCell> 
            </RespTableRow>
          </RespTableHeader>
          {!(dataToDisplay?.length === 0 || !dataToDisplay) && 
            <RespTableBody>
              {dataToDisplay.map((item, index) => (
                <React.Fragment key={index}>
                  <RespTableRow
                    onClick={() => toggleVisibility(item.id)}
                    data-testid="row"
                  >
                    <TableBodyCell>{item.attributes?.roomAttributes?.keywords?.find(pair => pair.key === "creationDatetime")?.value.substring(1,11)}</TableBodyCell> 

                    <TableBodyCell> {item.roomAttributes?.name ? item.roomAttributes?.name : 
                    item.attributes?.roomAttributes?.name ? item.attributes?.roomAttributes?.name
                    : "--"}</TableBodyCell>
                    <TableBodyCell> {item.attributes?.roomAttributes?.description} </TableBodyCell> 
                    <TableBodyCell>{item.attributes?.roomAttributes?.keywords?.find(pair => pair.key === "pid")?.value}</TableBodyCell> 
                    <TableBodyCell>{item.attributes?.roomAttributes?.keywords?.find(pair => pair.key === "crosswalk_path")?.value}</TableBodyCell> 

                    <TableBodyCell>
                      <Status status={item.attributes?.roomAttributes?.keywords?.find(pair => pair.key === "status")?.value === "open" ? "ACTIVE" : "CLOSED"}>
                        {item.attributes?.roomAttributes?.keywords?.find(pair => pair.key === "status")?.value === "open" && <Dot />}
                        {item.attributes?.roomAttributes?.keywords?.find(pair => pair.key === "status")?.value}
                      </Status>
                    </TableBodyCell> 
                  </RespTableRow>
                </React.Fragment>
              ))}
            </RespTableBody>
          }
        </RespTable>
      ) : null}
      {selectedIndex === (singleData?.id || redirectData?.roomSystemInfo?.id) && (
        <Extra data-testid="extra-data">
          <div>
            <InnerContent1>
              <div>
              {singleData &&  <InnerTd key={singleData.roomAttributes?.name}>{ `${singleData.roomAttributes?.name} >`}</InnerTd>}
              {redirectData &&  <InnerTd key={redirectData.roomAttributes?.name}>{ `${redirectData?.roomAttributes?.name}`}</InnerTd>}
              </div>
              <ExtraButton onClick={close} data-testid="close-ed">Close</ExtraButton>
            </InnerContent1>
          </div>
        </Extra>
      )}
      {streamId !== "" ? <IframeCard streamId={streamId} chatName={chatName} /> : null}
      <NoResult show={(dataToDisplay?.length === 0 || !dataToDisplay ) && !redirectData }>
        No record found to display
      </NoResult>
    </Container>
  );
};

export default AdminTable;
