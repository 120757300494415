import { generateRandomString } from "utils/randomStringGenerator";
import { SelectBoxDetails, SelectBoxOption } from "types";

const clientHardcodedPID = "DEFEB13216";
const randomString: string = generateRandomString(6);

export const ALERT_METADATA = (userEmail: string) => ({
  pid: clientHardcodedPID, // here we hardcoded Deutsche Bank's PID
  user_id: userEmail,
  event_id: `${clientHardcodedPID}_ev_${randomString}`,
  transaction_id: `${clientHardcodedPID}_tr_${randomString}`,
  date_time: new Date().toISOString(),
});

export const selectDocumentOptions: SelectBoxOption[] = [
  { optionValue: "passport", optionContent: "passport" },
  { optionValue: "driving_licence", optionContent: "driving_licence" },
  { optionValue: "utility_bill", optionContent: "utility_bill" },
];

export const selectDocument: SelectBoxDetails = {
  selectBoxName: "documentType",
  selectListOfOptions: selectDocumentOptions,
};

export const initialTypeOfDocument = selectDocumentOptions[0].optionValue;

export const autogeneratedDataEditable = "Automatically generated, but can be edited by user";
export const autogeneratedDataNotEditable = "Automatically generated, and CANNOT be edited by user";

export const alertString: { [key: string]: string } = {
  date_time: autogeneratedDataEditable,
  transaction_id: autogeneratedDataEditable,
  event_id: autogeneratedDataNotEditable,
  seconds: autogeneratedDataEditable,
  milliseconds: autogeneratedDataEditable,
  default: "Empty!",
};

export const dropdownItems = [
  { name: "Passport", value: "passport" },
  { name: "Driving Licence", value: "driving_licence" },
  { name: "Utility Bill", value: "utility_bill" },
];

export const allowedFileTypes = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "image/gif",
  "application/pdf",
];

export const allowedClientRequestTemplateFileTypes = [
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
]
