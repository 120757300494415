import styled from "styled-components";

export const DivIcon = `${process.env.PUBLIC_URL}/assets/DivIcon.png`;

export const Container = styled.div`
  flex: 1;
  padding: 0 32px;
`;

export const MatchArea = styled.div`
  display: grid;
  grid-template-columns: repeat(5,1fr);
  gap: 10px;
`;

export const Title = styled.p<{ h1?: boolean }>`
    color: ${({ theme }) => theme.colors.PRIMARY[400]};
    font-weight: 700;
    font-size: 36px;
    line-height: 38px;
    color: #364073;
`;

export const CardDiv = styled.div`
  background: ${({ theme }) => theme.colors.PRIMARY[500]};
  border-radius: 4px;
  height: 870px;
  position: relative;
  margin: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const IconDivA = styled.div`
  margin: 0;
  padding: 0;
  width: 0;
  position: absolute;
  right: -5px;
  & > img {
    position: absolute;
    left: -28px;
    top: 13px;
    width: 50px;
    @media (max-width: 768px) {
      position: absolute;
      top: -15px;
      transform: rotate(90deg);
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const CardDivHeading = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  & > h2 {
    margin: 14px 10px 10px 10px;
  }
`;

export const CardDivContent = styled.div`
  padding: 20px;
  & > div > h2 {
    font-size: 24px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.DARK[400]};
    margin: 0;
  }
  & > div > p {
    margin: 8px 0 0 0;
    color: ${({ theme }) => theme.colors.DARK[400]};
    font-weight: 500;
  }
  & .contentdiv {
    color: ${({ theme }) => theme.colors.DARK[500]};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: 14px;
    & p:nth-child(2) {
      color: ${({ theme }) => theme.colors.PRIMARY[400]};
      font-weight: 700;
    }
  }
`;