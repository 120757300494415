// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { 
    Container,
    TeamBioDiv,
    TeamBioCard,
    TeamBioCardName,
    TeamBioCardRole,
    TeamBioCardBio,
    Title,
    LinkedInLink,
    TeamBioCardImg
} from "styles/sippAdmin/sippAdmin.styled";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";

function SIPPBiosComponent() {

  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(false);
    }, 1500);

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);

  const team = [
    { 
      name: "Andrew Edwards", 
      role: "Technology Specialist - Trading Systems ", 
      bio:[  
        "Electronic Trading specialist with an impressive track record in the development and deployment of mission critical Electronic Trading systems and Wealth Platforms.", "Andy has held senior CIO and CTO management positions in software companies providing trading systems solutions, including Patsystems (Futures and Options), Velsys (FX) London Capital Group (CFDs and FX) and Web Financial Group (multi asset trading). More recently Andy was CTO of Hubwise for 7 years taking the platform from a start up to its successful acquisition by SS&C.", "Before joining Hubwise Andy has also worked closely with Diana Downing in the delivery of data services through Lacuna (BankCheck) for the financial market place."
      ], 
      linkedin: "https://www.linkedin.com/in/andrew-edwards-6a652733/",
      img: `${process.env.PUBLIC_URL}/assets/Andy-grey.JPG`
    },
    { 
      name: "Denis McHugh", 
      role: "Pension and ISA SME", 
      bio: [
        "Denis McHugh is a highly experienced pensions professional with nearly 30 years in the industry. Specializing in SIPP/SSAS and Defined Benefit schemes, Denis provides expert guidance on pension establishment, restructuring, and ongoing management.", "His senior leadership roles include serving as Chief Executive of a SIPP business, a Trustee of a number of occupational and stakeholder schemes. Denis developed and implemented pension offerings for the likes of Interactive Investor, Hubwise, RL360 and most recently Halo Invest."
      ], 
      linkedin: "https://www.linkedin.com/in/denis-m-ab519433/",
      img: `${process.env.PUBLIC_URL}/assets/Denis-grey.JPG` 
    },
    { 
      name: "Diana Downing", 
      role: "Founder/ CEO", 
      bio: [
        "Problem solver with a strong varied skill set, responsible for setting business strategy and implementing structured change to re-orientate existing service propositions to rapidly changing markets and client need.",
        "Diana directs Strategy & Operations. 20+ years working in senior executive banking positions. Diana brings In depth knowledge and experience across all due diligence and governance processes. Diana is a subject matter expert who understands the business drivers of today's financial institutions and corporations."
      ], 
      linkedin: "https://www.linkedin.com/in/diana-downing-08313a5/",
      img: `${process.env.PUBLIC_URL}/assets/Diana-grey.png`
    }
  ];

  return (
    <Container>
      <Title>Meet the Team</Title>
      <TeamBioDiv>
        {team.map((member, index) => (
          <TeamBioCard key={index}>
              <LinkedInLink href={member.linkedin} target="_blank" rel="noopener noreferrer" className="linkedin-icon">
                <FontAwesomeIcon icon={faLinkedin} fontSize={25} beat= {animate}/>
              </LinkedInLink>
            <TeamBioCardImg src={member.img} alt={`${member.name}`} />
            <TeamBioCardName>{member.name}</TeamBioCardName>
            <TeamBioCardRole>{member.role}</TeamBioCardRole>
            {member.bio.map((bio, indexL) => (
              <TeamBioCardBio key={indexL}>{bio}</TeamBioCardBio>
            ))}
            
          </TeamBioCard>
        ))}
      </TeamBioDiv>
    </Container>
  );
}

export default SIPPBiosComponent;
