import { DATE_RANGE, STATUS, HUBWISE_CATEGORY, ALLFUNDS_REQUEST_DATE, FILTER_ALLFUNDS_REQUEST_STATUS } from "constants/filter";
import { isAfter, isBefore, isToday, sub } from "date-fns";
import { useEffect, useState } from "react";
import { Filter } from "types";
import { FundType } from "types/funds";
import { search } from "utils/search";
import { ASSET_ONBOARDING_ACTION_ITEM, FUND_STATUS } from "constants/hubwiseDashboard";

const useFilters = <T extends {
    attributes?: any; 
    createdAt?: string; 
    active?: boolean; 
    name?: string; 
    roomAttributes?: {name?: string}
    customerCode?: string; 
    buylistCode?: string;
    buylistName?: string;
    buyListGenerated?: string;
    exchange?: string;
    "Legal Name [PID]"?: string;
    "Fund Manager Name"? : string;
    PID?: string | undefined;
    funds?: FundType[],
    "Hubwise Category"?: string;
    "Direct"?: string;
    "Party Legal Name"?: string;
    "ISIN"?: string;
    "originalFileName"?: string;
    "Request ID"?: string | number;
    "Request Date"?: string;
    "Request Status"?: string;
 }, T2 extends {"Direct"? : string; "Status"?: string; "ISIN"?: string; "Instrument Type"?: string;}>(data: T[], secondSetOfData?: T2[]) => {
  const [filter, setFilter] = useState({ search: "", secondSearch: "", date: "", modified: "", status: "", category: "", fundStatus: "", allfundsRequestDate: "", allfundsRequestStstus: ""});

  const [filteredData, setFilteredData] = useState<T[]>([]);

  const [secondSetOfFilteredData, setSecondSetOfFilteredData]= useState<T2[]>([]);

  const setFilterBy = (filterBy: Partial<Filter>) => {
    setFilter((f) => ({ ...f, ...filterBy }));
  };

  const onFilterChange = () => {
    let newData: T[] = [...data];
    let newData2: T2[]= [];

    if(secondSetOfData) {
      newData2= [...secondSetOfData];
    }

    // search data
    if (filter.search) {
      newData = search(newData, filter.search);      
    } else {
      setFilteredData(data);      
    }

    if(filter.secondSearch) {
      newData2 = search(newData2, filter.secondSearch);
    } else {
      if(secondSetOfData) {
        setSecondSetOfFilteredData(secondSetOfData);
      }
    }

    // filter by status
    if (filter.status === STATUS.ACTIVE) {
      newData = newData.filter(
        (datum) => datum.attributes?.roomAttributes?.keywords.find((pair: { key: string; }) => pair.key === "status")?.value === "open",
      );
    } else if (filter.status === STATUS.CLOSED) {
      newData = newData.filter(
        (datum) => datum.attributes?.roomAttributes?.keywords.find((pair: { key: string; }) => pair.key === "status")?.value === "closed",
      );
    }; 

    // filter by allfunds request status
    if (filter.allfundsRequestStstus === FILTER_ALLFUNDS_REQUEST_STATUS.IN_PROGRESS) {
      newData = newData.filter(
        (datum) => datum["Request Status"] === "IN PROGRESS",
      );
    } else if (filter.allfundsRequestStstus === FILTER_ALLFUNDS_REQUEST_STATUS.CANCELLED) {
      newData = newData.filter(
        (datum) => datum["Request Status"] === "CANCELLED",
      );
    } 
    else if (filter.allfundsRequestStstus === FILTER_ALLFUNDS_REQUEST_STATUS.COMPLETED) {
      newData = newData.filter(
        (datum) => datum["Request Status"] === "COMPLETED - FUND ACTIVE",
      );
    };

    // filter by fundStatus
    if(filter.fundStatus === ASSET_ONBOARDING_ACTION_ITEM.TO_BE_REQUESTED) {
      newData2= newData2.filter(
        (datum) => datum["Status"] === FUND_STATUS.TO_BE_REQUESTED
      )
    } else if (filter.fundStatus === ASSET_ONBOARDING_ACTION_ITEM.REQUESTED) {
      newData2= newData2.filter(
        (datum) => datum["Status"] === FUND_STATUS.AWAITING
      )
    } else if (filter.fundStatus === ASSET_ONBOARDING_ACTION_ITEM.AWAITING) {
      newData2= newData2.filter(
        (datum) => datum["Status"] === FUND_STATUS.REQUESTED_BY_INSTRUMENT_M || datum.Status === FUND_STATUS.REQUESTED_FROM_FM
      )
    } else if (filter.fundStatus === ASSET_ONBOARDING_ACTION_ITEM.CONFIRMED) {
      newData2= newData2.filter(
        (datum) => datum["Status"] === FUND_STATUS.CONFIRMED
      )
    } else if (filter.fundStatus === ASSET_ONBOARDING_ACTION_ITEM.AWAITING_SETUP) {
      newData2= newData2.filter(
        (datum) => datum["Status"] === FUND_STATUS.AWAITING_SETUP
      )
    } else if (filter.fundStatus === ASSET_ONBOARDING_ACTION_ITEM.SETUP_COMPLETED) {
      newData2= newData2.filter(
        (datum) => datum["Status"] === FUND_STATUS.SETUP_COMPLETED
      )
    }

    // filter by category
    if (filter.category === HUBWISE_CATEGORY.ALL_FUNDS) {
      newData2 = newData2.filter(
        (datum) => datum["Direct"] === "false",
      );
    } else if (filter.category === HUBWISE_CATEGORY.DIRECT) {
      newData2 = newData2.filter(
        (datum) => datum["Direct"] === "true",
      );
    }
    else if (filter.category === HUBWISE_CATEGORY.NON_FUNDS) {
      newData2 = newData2.filter(
        (datum) => datum["Instrument Type"] !== "Fund",
      );
    };

    // filter by date
    if (filter.date === DATE_RANGE.TODAY) {
      newData = newData.filter((datum) => isToday(new Date(datum?.attributes?.roomAttributes?.keywords.find((pair: { key: string; }) => pair.key === "creationDatetime")?.value??"")));
    } else if (filter.date === DATE_RANGE.SEVEN_DAYS) {
      newData = newData.filter((datum) =>
        isAfter(new Date(datum?.attributes?.roomAttributes?.keywords.find((pair: { key: string; }) => pair.key === "creationDatetime")?.value??""), sub(new Date(), { days: 8 })),
      );
    } else if (filter.date === DATE_RANGE.THIRTY_DAYS) {
      newData = newData.filter((datum) =>
        isAfter(new Date(datum?.attributes?.roomAttributes?.keywords.find((pair: { key: string; }) => pair.key === "creationDatetime")?.value??""), sub(new Date(), { days: 31 })),
      );
    }

    // filter by request date
    if (filter.allfundsRequestDate === ALLFUNDS_REQUEST_DATE.TODAY) {
      newData = newData.filter((datum) => isToday(new Date(datum["Request Date"] ? datum["Request Date"] : "")));
    } else if (filter.allfundsRequestDate === ALLFUNDS_REQUEST_DATE.SEVEN_DAYS) {
      newData = newData.filter((datum) =>
        isAfter(new Date(datum["Request Date"] ? datum["Request Date"] : ""), sub(new Date(), { days: 8 })),
      );
    }
    else if (filter.allfundsRequestDate === ALLFUNDS_REQUEST_DATE.THIRTY_DAYS) {
      newData = newData.filter((datum) =>
        isAfter(new Date(datum["Request Date"] ? datum["Request Date"] : ""), sub(new Date(), { days: 31 })),
      );

    } else if (filter.allfundsRequestDate === ALLFUNDS_REQUEST_DATE.SIX_MONTHS) {
      newData = newData.filter((datum) =>
        isAfter(new Date(datum["Request Date"] ? datum["Request Date"] : ""), sub(new Date(), { days: 180 })),
      );
    }
    else if (filter.allfundsRequestDate === ALLFUNDS_REQUEST_DATE.MORE_THAN_SIX_MONTHS) {
      newData = newData.filter((datum) =>
        isBefore(new Date(datum["Request Date"] ? datum["Request Date"] : ""), sub(new Date(), { days: 180 })),
      );
    }

    // filter by modified date
    if (filter.modified === DATE_RANGE.TODAY) {
      newData = newData.filter((datum) => isToday(new Date(datum?.attributes?.roomAttributes?.keywords.find((pair: { key: string; }) => pair.key === "lastModifiedAt")?.value??"")));
    } else if (filter.modified === DATE_RANGE.SEVEN_DAYS) {
      newData = newData.filter((datum) =>
        isAfter(new Date(datum?.attributes?.roomAttributes?.keywords.find((pair: { key: string; }) => pair.key === "lastModifiedAt")?.value??""), sub(new Date(), { days: 8 })),
      );
    } else if (filter.modified === DATE_RANGE.THIRTY_DAYS) {
      newData = newData.filter((datum) =>
        isAfter(new Date(datum?.attributes?.roomAttributes?.keywords.find((pair: { key: string; }) => pair.key === "lastModifiedAt")?.value??""), sub(new Date(), { days: 31 })),
      );
    }


    setFilteredData(newData);
    setSecondSetOfFilteredData(newData2);
  };

  useEffect(() => {
    onFilterChange();
  }, [filter]);

  // set data as filteredData on mount i.e , make data the initialValue of filteredData
  useEffect(() => {
    if(data.length > 0) {
      setFilteredData(data);
    }
   
    if(secondSetOfData) {
      setSecondSetOfFilteredData(secondSetOfData);
    }
  }, [data, secondSetOfData]);

  return { filteredData, secondSetOfFilteredData, setFilter: setFilterBy };
};

export default useFilters;
