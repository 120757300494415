// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for displaying a Cytoscape.
 * It requires as parameters:
 * - dataForCytoscape, the CyData object containing all the data already processed and ready for display
 * - graphType, a string about SHO or SUB
 */

import styled from "styled-components";
import cytoscape, { NodeCollection } from "cytoscape";
import dagre from "cytoscape-dagre";
import { useEffect } from "react";
import { GRAPH_TYPE, PATH } from "constants/index";
import nodeHtmlLabel from "cytoscape-node-html-label";
import { CytoscapeBox } from "styles/party/cytoscape/Cytoscape.styled";
import { CytoscapeWithNodeHtmlLabel } from "types";
import { CyData, CyNode } from "../../../types/cytoscape";

cytoscape.use(dagre);
cytoscape.use(nodeHtmlLabel);

export function DisplayCytoscape({
  dataForCytoscape,
  graphType,
}: {
  dataForCytoscape: CyData;
  graphType: string;
}) {
  useEffect(() => {
    const cy = cytoscape({
      // container to render in
      container: document.getElementById(
        graphType === GRAPH_TYPE.SHO ? GRAPH_TYPE.CY_SHO : GRAPH_TYPE.CY_CUB,
      ),
      maxZoom: 3,
      minZoom: 0.3,
      elements: dataForCytoscape,

      style: [
        {
          selector: "nodes",
          style: {
            shape: "round-rectangle",
            "background-color": "#fff",
            "border-width": 1,
            "border-color": "#fff",
            width: (nodes: NodeCollection) => {
              return nodes.data("name").length * 10;
            },
            "font-size": "10px",
            "padding-left": "5px",
            "padding-right": "5px",
            "padding-top": "5px",
            "padding-bottom": "20px",
            label: "data(htmlLabel)",
            "text-valign": "center",
            color: "#2E1A61",
          },
        },

        {
          selector: "edges",
          style: {
            "curve-style": "taxi",
            "taxi-turn": "2000px",
            "taxi-direction": "horizontal",
            "target-arrow-shape": "triangle",
            width: 2,
            "line-color": "#2E1A61",
            "target-arrow-color": "#2E1A61",
          },
        },
      ],

      layout: {
        name: "dagre",
      },
    }) as CytoscapeWithNodeHtmlLabel;
    cy.resize();
    cy.nodeHtmlLabel([
      {
        query: "node",
        valign: "center",
        halign: "center",
        halignBox: "center",
        valignBox: "center",
        tpl(data: CyNode["data"]) {
          const nameLength = data.name?.length || 0;

          return `<div style="display:flex; flex-direction:column; align-items: center; cursor:grab;">
              <div style="width:${
                nameLength * 10
              }px; background-color:rgba(46, 26, 97, 0.05); color:#2E1A61; border: solid 1.5px #2E1A61; padding:15px 10px ;border-radius: 8px; margin-top: 10px; margin-bottom: 0; ; text-align: center">
              ${data.name}</div>
                <div>
      ${
        data.directFO !== ""
          ? `<div
            style="width:${
              nameLength * 10
            }px; background-color: #2E1A61; color: white; border-radius: 4px; padding: 5px 10px; margin-top: 2px; text-align: center"
          >
           ${
             data.directFO === undefined
               ? `Total: ${data.totalFO}%`
               : data.totalFO === undefined || data.directFO !== undefined
               ? `Direct: ${data.directFO}%`
               : `Direct: ${data.directFO} % - Total: ${data.totalFO} %`
           }
          </div>`
          : ""
      }
      </div>
                </div>`;
        },
        cssClass: "node-html-label",
        cssStyle: {
          "background-color": "#fff",
          "border-width": "1px",
          "border-color": "#2E1A61",
          width: (node: CyNode["data"]) => {
            return node.name ? node.name.length * 7 : 0;
          },
          "font-size": "10px",
          padding: "5px",
        },
      },
    ]);
    // clicking on a Cytoscape node, open a new Party Profile page for the node's id
    cy.on("tap", "node", (clickEventOnCyNode) => {
      window.open(`${PATH.PARTY}/${clickEventOnCyNode.target.id()}`, "_blank");
    });
  }, []);

  return <CytoscapeBox id={graphType === GRAPH_TYPE.SHO ? GRAPH_TYPE.CY_SHO : GRAPH_TYPE.CY_CUB} />;
}
