import {
  ChartCover,
  Container,
  Title,
  Top,
  DashboardWrapper,
  Header,
  Bottom,
  TopBox,
  Box,
  Boxes,
  Button,
  Image,
  Icon,
  ImageCover,
  Text,
  IconCover,
} from "styles/admin/Dashboard.styled";
import { Chart } from "react-google-charts";
import DASHBOARD_DATA from "data/dashboard.json";
import { DASHBOARD_BAR_TITLE, CHART_TYPE } from "constants/index";
import { useAppData } from "../../hooks/useAppData";
import Bar from "./Bar";
import DATA from "../../data/dashboardCountryCodeData.json";

function Dashboard() {
  const { areas } = DASHBOARD_DATA;
  const { colors, status } = useAppData();

  return (
    <Container>
      <DashboardWrapper>
        <Top>
          <Header>
            <Title>Coverage</Title>
            <Button type="button">Real-time report</Button>
          </Header>
          <ChartCover>
            <Chart
              chartType={CHART_TYPE.GEO}
              width="90%"
              height="400px"
              data={DATA}
              options={{
                legend: false,
                colors: [
                  colors.PRIMARY[300],
                  colors.ACCENT[500],
                  colors.PRIMARY[400],
                  colors.GREYED[700],
                ],
                backgroundColor: status === "dark" ? "#222425" : "#fff",
              }}
            />
            <TopBox>
              <Title h1>Australia</Title>
              <div>
                <Bar percent={DASHBOARD_DATA.stats.banks} title={DASHBOARD_BAR_TITLE.BANKS} />
                <Bar
                  percent={DASHBOARD_DATA.stats.financialInstitution}
                  title={DASHBOARD_BAR_TITLE.FINANCIAL_INST}
                />
                <Bar
                  percent={DASHBOARD_DATA.stats.governmentRelatedInstitution}
                  title={DASHBOARD_BAR_TITLE.GOV_RELATED_INST}
                />
              </div>
            </TopBox>
          </ChartCover>
        </Top>

        <Bottom>
          <Title>Data Coverage by Geographical Areas</Title>
          <Boxes>
            {areas.map((area) => (
              <Box key={area.continent} data-testid={area.continent}>
                <IconCover>
                  <Icon
                    src={`${process.env.PUBLIC_URL}/assets/${area.continent
                      .split(" ")
                      .join("-")
                      .toLowerCase()}${status === "dark" ? "-white.svg" : ".svg"}`}
                  />
                  <Text>{area.continent}</Text>
                </IconCover>
                <Bar percent={area.stats.banks} title={DASHBOARD_BAR_TITLE.BANKS} />
                <Bar
                  percent={area.stats.financialInstitution}
                  title={DASHBOARD_BAR_TITLE.FINANCIAL_INST}
                />
                <Bar
                  percent={area.stats.governmentRelatedInstitution}
                  title={DASHBOARD_BAR_TITLE.GOV_RELATED_INST}
                />
              </Box>
            ))}
          </Boxes>
        </Bottom>
        <ImageCover>
          <Image
            src={
              status === "dark"
                ? "/assets/dashboard-footer-white.svg"
                : "/assets/dashboard-footer.svg"
            }
            alt="dashboard"
          />
        </ImageCover>
      </DashboardWrapper>
    </Container>
  );
}

export default Dashboard;
