import { FunctionComponent, MouseEvent, useEffect, useState } from "react";
import { 
    WidgetTitle,
    WidgetHeaderArea,
    OptionsHolder,
    ContextMenu
} from "styles/funds/UserDesktopView.styled";
import { 
    ElipsisHolder
} from "styles/funds/Onboarding.styled";
import { getItem, setItem } from "utils/localStorage";
import { useAppData } from "hooks/useAppData";
import { FundType } from "types/funds";

const WidgetHeaderAreaComponent: FunctionComponent<{
    isOption: boolean;
    setIsOption: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    id: number;
    activeId?: number;
    setActiveId: React.Dispatch<React.SetStateAction<number | undefined>>;
    userPreferences: string[];
    name: string;
    setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
    monitoredItem?: FundType;
    itemsToMonitor?: FundType[];
}> =  ({
    isOption,
    setIsOption,
    title,
    id,
    activeId,
    setActiveId,
    userPreferences,
    name,
    setUpdated,
    monitoredItem,
    itemsToMonitor
}) => {

    const { user }= useAppData();

    const handleEllipsisClick = (id: number) => {

        if(isOption) {
            if(id === activeId) {
                setIsOption(false);
                setActiveId(undefined);
            } else {
                setActiveId(id);
            }
        } else {
            setIsOption(true);
            setActiveId(id);
        }
    }

    const handleContextMenuClick = (name: string) => {
        const globalPreferences= getItem("userPreferences", []);
        console.log("monitored clicked: ", monitoredItem);
        if(monitoredItem) {
            if (itemsToMonitor && itemsToMonitor.length > 0) {
                if(itemsToMonitor.length === 1) {
                    if(globalPreferences.length > 0) {
                        const newPref = userPreferences.filter((acc)=> acc !== "Monitor Item");
                        globalPreferences.map((item: {email: string, preferences: string[], itemsToMonitor: FundType[]}) => {
                            if(item.email === user?.email) {
                                item.preferences= newPref;
                                item.itemsToMonitor= [];
                            }
                        })
                    }
                    setItem("userPreferences", globalPreferences);
                } else {
                    const newItemsArray= itemsToMonitor.filter((fund, index) => fund["Record Id"] !== monitoredItem["Record Id"]);
                    console.log("new items array: ", newItemsArray);
                    if(globalPreferences.length > 0) {
                        globalPreferences.map((item: {email: string, preferences: string[], itemsToMonitor: FundType[]}) => {
                            if(item.email === user?.email) {
                                item.itemsToMonitor= newItemsArray;
                            }
                        })
                    }
                    setItem("userPreferences", globalPreferences);
                }
            }
        } else {
            const newPref = userPreferences.filter((acc)=> acc !== name);
            if(globalPreferences.length > 0) {
                globalPreferences.map((item: {email: string, preferences: string[]}) => {
                    if(item.email === user?.email) {
                        item.preferences= newPref;
                    }
                })
            }
            setItem("userPreferences", globalPreferences);
        }
        setIsOption(false);
        setUpdated(true);
    }
    
    return (
        <WidgetHeaderArea>
            <WidgetTitle>{title}</WidgetTitle>
            <OptionsHolder>
                <ContextMenu id="context-menu" display={isOption.toString()} active={id === activeId} onClick={()=>{handleContextMenuClick(name)}}>
                    <span>Remove from My View</span>
                </ContextMenu>
                <ElipsisHolder key={title} className="ellipsis-btn" onClick={()=>handleEllipsisClick(id)} />
            </OptionsHolder>
        </WidgetHeaderArea>
    )
};

export default WidgetHeaderAreaComponent