import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import {
  Title,
  NewEntitySection,
  NewEntityContent,
  SuppliedEntity,
  SuppliedEntityHeading,
  SuppliedEntityContent,
  IconDiv,
  MatchedEntity,
  MatchedEntityHeading,
  MatchedEntityContent,
  ARFDiv,
  DivIcon,
  AcceptIcon,
  RejectIcon,
  FurtherIcon,
  LinkIcon,
} from "../../styles/admin/EntityLink.styled";
import DummyData from "../../data/newEntityLink.json";

const EntityLink = () => {
  // Dummy DATA Start
  const supplied = DummyData.supplied;
  const matched = DummyData.matched;
  // Dummy DATA End
  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.ENTITY_LINK);
  });
  return (
    <NewEntitySection>
      <Title>New Entity LINK</Title>
      <NewEntityContent>
        <SuppliedEntity>
          <IconDiv>
            <img src={DivIcon} alt="divicon" />
          </IconDiv>
          <SuppliedEntityHeading>
            <h2>Supplied Entity</h2>
          </SuppliedEntityHeading>
          <hr style={{ border: "solid 1px rgba(128, 128, 128, 0.3)" }} />
          <SuppliedEntityContent>
            <div>
              <h2>BIGBANK AS FILIALAS</h2>
              <p>Lithuania</p>
            </div>
            <div style={{ marginTop: "30px" }}>
              <div className="contentdiv">
                <p>Client ID</p>
                <p>{supplied.clientId}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Name</p>
                <p>{supplied.legalName}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Address</p>
                <p>{supplied.legalAddress}</p>
              </div>
              <div className="contentdiv">
                <p>Registration Country</p>
                <p>{supplied.registrationCountry}</p>
              </div>
              <div className="contentdiv">
                <p>SWIFT/BIC</p>
                <p>{supplied.swift}</p>
              </div>
              <div className="contentdiv">
                <p>Status</p>
                <p>{supplied.status}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Form</p>
                <p>{supplied.legalForm}</p>
              </div>
            </div>
          </SuppliedEntityContent>
        </SuppliedEntity>
        <MatchedEntity>
          <MatchedEntityHeading>
            <h2>Matched Entity</h2>
            <div>
              <img src={LinkIcon} style={{ marginRight: "7px" }} width="10px" alt="link icon" />
              <p>VIEW IN ClientScope</p>
            </div>
          </MatchedEntityHeading>
          <hr style={{ border: "solid 1px rgba(128, 128, 128, 0.3)" }} />
          <MatchedEntityContent>
            <div>
              <h2>BIGBANK AS FILIALAS</h2>
              <p>Lithuania</p>
            </div>
            <div style={{ marginTop: "30px" }}>
              <div className="contentdiv">
                <p>T360 PID</p>
                <p>{matched.pid}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Name</p>
                <p>{matched.legalName}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Address</p>
                <p>{matched.legalAddress}</p>
              </div>
              <div className="contentdiv">
                <p>Registration Country</p>
                <p>{matched.registrationCountry}</p>
              </div>
              <div className="contentdiv">
                <p>SWIFT/BIC</p>
                <p>{matched.swift}</p>
              </div>
              <div className="contentdiv">
                <p>Status</p>
                <p>{matched.status}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Form</p>
                <p>{matched.legalForm}</p>
              </div>
              <div className="contentdiv">
                <p>Incorporation Date</p>
                <p>{matched.incorporationDate}</p>
              </div>
              <div className="contentdiv">
                <p>Source Info Provider</p>
                <p>{matched.sourceInfoProvider}</p>
              </div>
              <div className="contentdiv">
                <p>Alternative Names</p>
                <p>{matched.alternativeName}</p>
              </div>
              <div className="contentdiv">
                <p>Entity Name</p>
                <p>{matched.entityType}</p>
              </div>
            </div>
          </MatchedEntityContent>
        </MatchedEntity>
      </NewEntityContent>
      <ARFDiv>
        <div className="ARFIcon">
          <img src={AcceptIcon} alt="accept icon" />
          <span style={{ color: "#4CBB17" }}>Accept Match</span>
        </div>
        <div className="ARFIcon">
          <img src={RejectIcon} alt="reject icon" />
          <span style={{ color: "#E60000" }}>Reject Match</span>
        </div>
        <div className="ARFIcon">
          <img src={FurtherIcon} alt="further enquiry icon" />
          <span style={{ color: "#FF7800" }}>FURTHER ENQUIRY</span>
        </div>
      </ARFDiv>
    </NewEntitySection>
  );
};

export default EntityLink;
