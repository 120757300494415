import { FUNDS_DATA_REPOSITORY_BUCKET_NAME } from "config/googleCloudConfig";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";

export const getSSI = async (accessToken: string, id: string) => {

  if (accessToken) {
    const ssiFileName = `${id}.json`;
    const payload = {
      "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
      "fileName": ssiFileName,
      "filePath": "SSI"
    }

    callGCFunctionWIthAccessToken(accessToken, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
    .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
    .then((data) => {
      return data
    })
  }
  return null;
};
