import { Tag, permissionIcon } from "styles/userPersonalDetailsReader/UserPersonalDetails.styled";
import dummyData from "../../data/dummyUserPersonalDetails.json";

function UserPersonalDetails() {
  const data = dummyData;

  return (
    <>
      {data.TagNames.map((item, i) => {
        let color;
        let backgroundColor;
        let backgroundImage;
        let padding;
        switch (item.name) {
          case "Design":
            color = "#6941C6";
            backgroundColor = "#F9F5FF";
            padding = "2px 8px";
            break;
          case "Product":
            color = "#175CD3";
            backgroundColor = "#EFF8FF";
            padding = "2px 8px";
            break;
          case "Marketing":
            color = "#3538CD";
            backgroundColor = "#EEF4FF";
            padding = "2px 8px";
            break;
          case "Management":
            color = "#C11574";
            backgroundColor = "#FDF2FA";
            padding = "2px 8px";
            break;
          case "":
            backgroundImage = permissionIcon;
            backgroundColor = "#F2F4F7";
            break;
          default:
            break;
        }
        return (
          <Tag
            key={i}
            color={color}
            backgroundColor={backgroundColor}
            iconURL={backgroundImage}
            padding={padding}
          >
            {item.name}
          </Tag>
        );
      })}
    </>
  );
}

export default UserPersonalDetails;
