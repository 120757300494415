import { AdminMenuItem } from "../types";

const dashboardIcon = `${process.env.PUBLIC_URL}/assets/dashboard-icon.svg`;
const workbenchIcon = `${process.env.PUBLIC_URL}/assets/workbench.svg`;
const transfersIcon = `${process.env.PUBLIC_URL}/assets/TransactionIcon.svg`
const withdrawlsIcon = `${process.env.PUBLIC_URL}/assets/arrow-down.svg`
const contributionsIcon = `${process.env.PUBLIC_URL}/assets/interactive-icon.svg`
const clientDetailsIcon = `${process.env.PUBLIC_URL}/assets/client-details.svg`
const financialAdvisorIcon = `${process.env.PUBLIC_URL}/assets/financial-advisor.svg`
const wishesIcon = `${process.env.PUBLIC_URL}/assets/whishes.svg`
const historyIcon = `${process.env.PUBLIC_URL}/assets/docImage.svg`;
const biosIcon= `${process.env.PUBLIC_URL}/assets/bios.svg`;
const goodLooksIcon= `${process.env.PUBLIC_URL}/assets/good-looks.svg`;
const marketPlayersIcon= `${process.env.PUBLIC_URL}/assets/market-player.svg`;

export const SIPP_ADMIN_MENU_ITEMS: AdminMenuItem[] = [
  {
    text: "Bios",
    icon: biosIcon,
    link: "bios",
    isUserAllowed: true
  },
  {
    text: "What Good Looks Like",
    icon: goodLooksIcon,
    link: "good-looks",
    isUserAllowed: true
  },
  {
    text: "Market Players",
    icon: marketPlayersIcon,
    link: "market-players",
    isUserAllowed: true
  },
  {
    text: "Client",
    icon: clientDetailsIcon,
    link: "client-profile",
    isUserAllowed: true
  }
];
