import { FunctionComponent } from "react";
import { useState } from "react";
import NoData from "components/noData/NoData";
import { 
    TopContainer,
    DashboardContainer 
} from "styles/funds/HubwiseInstrumentManagerDashboard.styled";
import HuwiseInstrumentManagerFundsUpdateAreaComponent from "./DashboardFundsUpdateArea";
import { TemporaryMasterInstrumentsFundManagerType } from "types/funds";
import { Accordion } from "components/party/accordion/Accordion";

const HuwiseInstrumentManagerDashboard: FunctionComponent<{
    data?: TemporaryMasterInstrumentsFundManagerType[];
    buylistsInfo: {"Buylist Code": string, "Buylist Description": string}[];
}> =  ({
    data,
    buylistsInfo
}) => {
    return (
        <Accordion key={"instrument-dashboard-accordion"} title={"Dashboard"} noTopBorder>
            <DashboardContainer>
                <HuwiseInstrumentManagerFundsUpdateAreaComponent data={data} buylistsInfo={buylistsInfo} boxShadow showTitle/>
            </DashboardContainer>
        </Accordion>
    )
};

export default HuwiseInstrumentManagerDashboard;