import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import HubwiseOnboardingComponent from "components/hubwise-dashboard/onboarding";

const Onboarding = () => {
    const { track } = useTrackActivity();

    useEffectOnce(()=>{
        track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_DASHBOARD_ONBOARDING);
    });
    
    return <HubwiseOnboardingComponent />
}

export default Onboarding;