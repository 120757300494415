import styled from "styled-components";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link } from "react-router-dom";

export const Container = styled.div``;

export const Banner = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ArrowBack = styled(KeyboardBackspaceIcon)`
  color: ${({ theme }) => theme.colors.DARK[500]};
  margin-bottom: 5px;
`;

export const ArrowLink = styled(Link)``;

export const LeftContainer = styled.div``;

export const LeftWrapper = styled.div`
  width: 30%;
  background-color: ${({ theme }) => theme.colors.GREY[400]};
  padding: 40px 20px;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 768px) {
    order: 2;
    width: 100%;
    overflow-x: hidden;
  }
`;

export const RightWrapper = styled.div`
  width: 70%;
  position: relative;
  @media (max-width: 768px) {
    order: 1;
    width: 100%;
  }
`;

export const GeneralCover = styled.div`
  background-color: ${({ theme }) => theme.colors.PRIMARY[150]};
  padding: 40px 100px 40px 30px;
  @media (max-width: 768px) {
    padding: 40px 10px 40px 30px;
  }
`;

export const ContactCover = styled.div`
  padding: 40px 100px 40px 30px;
  @media (max-width: 768px) {
    padding: 40px 10px 40px 30px;
  }
`;

export const GroupCover = styled.div``;
