/**
 * This is the dedicated component for the Search Page Results of Party Manager.
 * It uses a GCF to get the results.
 * Before displaying the Search Results:
 * a) runs some checks:
 * - a1. to retrieve useful data from the GCF results, and
 * - a2. to avoid displaying empty or duplicate items
 * b) places as first items the Ultimate Owner and the target entity (the entity selected by user in Search Suggestions)
 * c) sorts all the other Search Results by Total Assets in descending order
 */

// TODO: add the ErrorDisplay, avoid to do many calls inside the component, move out some of the code, refactoring in order to simplify code, remove duplicates in search results

import { useState } from "react";
import { useParams } from "react-router-dom";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import LoadingSpinner from "../../components/LoadingSpinner";
import { SearchResultsItem } from "../../components/searchResults/searchResultsItem/SearchResultsItem";
import useEffectOnce from "../../hooks/useEffectOnce";
import { SearchResultsContainer } from "../../styles/searchResult/SearchResult.styled";
import {useAppData} from "../../hooks/useAppData";
import { getAccessToken } from "services/auth0/auth0";
import {callGCFunctionWIthAccessToken} from "services/callGCFunction";
import { SEARCH_RESULTS } from "../../config/googleCloudFunctionsConfig";
import { SearchSuggestionInput } from "../../types";

export const FundsSearchResults = () => {
  const { track } = useTrackActivity();

  const { targetEntityId } = useParams<{ targetEntityId: string }>();
  const { isUserAuthenticated } = useAppData();
  const token = getAccessToken();
  const [resultList, setResultList] = useState<SearchSuggestionInput[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const searchResults: SearchSuggestionInput[] = [];
  const [loadTime, setLoadTime] = useState(0);
  const [guo, setGuo] = useState< SearchSuggestionInput[]>([]);
  const [errorMessage, setErrorMessage] = useState(false);

  // main entry-point function
  const loadSearchResults = (accessToken: string) => {
    if (targetEntityId) {
      const timer = Date.now();
      
      const payloadForGUO = {
        isSearchResults: "True",
        targetPid: targetEntityId,
      }

      callGCFunctionWIthAccessToken(accessToken, SEARCH_RESULTS, payloadForGUO, "test", "test")
        .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((response) => {
          if (response.results.guo && response.results.guo.length > 0) {
            setGuo([...guo, response.results.guo[0]]);
          }
          if(response.results.target && response.results.target.length > 0) {
            const idMap = new Map();
            response.results.target.forEach((obj: SearchSuggestionInput) => {
              if(obj.pid_s !== response.results.guo[0].pid_s) {
                idMap.set(obj.pid_s, obj);
              }              
            });
            if(idMap.size > 0){
              Array.from(idMap.values()).map((item: SearchSuggestionInput) => searchResults.push(item));  
            }
          }
          if(response.results.result && response.results.result.length > 0) {
            const idMap = new Map();
            response.results.result.forEach((obj: SearchSuggestionInput) => {
              idMap.set(obj.pid_s, obj);
            });
            Array.from(idMap.values()).map((item: SearchSuggestionInput) => item.legalName_ss && item.kfTotalAssets_ss &&  searchResults.push(item)); 
          }

          setResultList(searchResults.sort((a, b) => {
            let maxA= 0;
            let maxB= 0;
              if(Array.isArray(a.kfTotalAssets_ss) && a.kfTotalAssets_ss.length > 1) {
                const tmpNumArr = a.kfTotalAssets_ss.map(Number);
                maxA= Math.max(...tmpNumArr);
              } else {
                maxA= parseInt(a.kfTotalAssets_ss?.[0] ? a.kfTotalAssets_ss[0] : "0");
              }
              if(Array.isArray(b.kfTotalAssets_ss) && b.kfTotalAssets_ss.length > 1) {
                const tmpNumArr = b.kfTotalAssets_ss.map(Number);
                maxB= Math.max(...tmpNumArr);
              } else {
                maxB= parseInt(b.kfTotalAssets_ss?.[0] ? b.kfTotalAssets_ss[0] : "0");
              }
              if (maxA > maxB){
                return 1;
              } else if(maxA < maxB) {
                return -1;
              } 
              return 0;
            }             
          ));
          setIsLoading(false);
          setLoadTime((Date.now() - timer) / 1000);
        })
        .catch((err: Error) => {
          setErrorMessage(true);
          console.log("ERROR in getting GUO")
        });
    }
  };

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, "Funds Search Results");
      if (token) loadSearchResults(token);
  });

  if (!isUserAuthenticated) return <LoadingSpinner />;

  if (isLoading) return <LoadingSpinner />; // TODO: add here a proper 'Searching Data' spinner

  return (
    <SearchResultsContainer className="container">
      <p>
        About {resultList.length} results ({loadTime.toFixed(2)} second{loadTime > 1 ? "s" : ""})
      </p>
      {resultList.length === 0 ? (
        <div>Sorry, no data available for the selected entity</div> // TODO: add here a proper no-data screen
      ) : (
        resultList.map((searchResultsItem, index) => (
          <SearchResultsItem data={ searchResultsItem } key={index} />
        ))
      )}
    </SearchResultsContainer>
  );
};
