import styled from "styled-components";
import MenuIcon from "@material-ui/icons//Menu";

export const searchIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-search-blue.svg`;
export const askAliceIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-ask-alice-blue.svg`;
export const alertIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-alert-blue.svg`;
export const notificationIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-notifications-blue.svg`;
export const adminRoomsIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-admin-rooms-blue.svg`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledMenuIcon = styled(MenuIcon)`
  margin-right: 10px;
  font-size: 30px !important ;
  @media (min-width: 768px) {
    display: none !important;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLogo = styled.div<{ iconURL: string }>`
  width: 28px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  background-image: ${(props) => `url(${props.iconURL})`};
  margin: 0 14px 0 0;
`;

export const IconsContainer = styled.div<{ iconUrl: string }>`
  width: 24px;
  height: 24px;
  background-image: ${(props) => `url(${props.iconUrl})`};
  cursor: pointer;
`;

export const IconsSeparator = styled.div`
  margin-right: 24px;
`;
