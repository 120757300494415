import { FunctionComponent } from "react";
import { 
    Title
} from "styles/funds/MyView.styled";
import { 
    UserGuidesContainer, 
    UserGuidesContent, 
    DocsHolder,
    Doc,
    DocIcon,
    DocButton,
    Note 
} from "styles/funds/UserGuides.styled";

const HubwiseUserGuidesComponent: FunctionComponent =  () => {

    const startupGuide =`${process.env.PUBLIC_URL}/assets/startup-guide-icon.png`; 
    const trainingGuide =`${process.env.PUBLIC_URL}/assets/initial-training-icon.png`; 
      
    return (
      <UserGuidesContainer>
          <Title>User Guides</Title>
          <UserGuidesContent>
            <DocsHolder>
                <Doc>
                    <DocIcon background={startupGuide} />
                    <DocButton disabled>Download</DocButton>
                    <Note>*Subject for Live-Testing Period </Note>
                </Doc>
                <Doc>
                    <DocIcon background={trainingGuide} />
                    <DocButton disabled>Download</DocButton>
                    <Note>*Subject for Live-Testing Period </Note>
                </Doc>
            </DocsHolder>
          </UserGuidesContent>
          
      </UserGuidesContainer>
    )
};

export default HubwiseUserGuidesComponent;