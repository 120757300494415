// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { Accordion } from "components/party/accordion/Accordion";
import { 
    Container, 
    Title,
    ContentItem 
} from "styles/sippAdmin/sippAdmin.styled";

function SIPPTransfersComponent() {

  return (
    <Container>
        <Title>
            Transfers
        </Title>
        <Accordion title="Screen" noTopBorder>
            <div></div>
        </Accordion>
        <Accordion title="Section Content" opened>
            <div>
                <ContentItem>Ceding Scheme</ContentItem>
                <ContentItem>Value</ContentItem>
                <ContentItem>Transfer type – Cash or In Specie</ContentItem>
                <ContentItem>Pension type – various types – HMRC return dictate types</ContentItem>
                <ContentItem>Date of transfer</ContentItem>
                <ContentItem>Full/Partial</ContentItem>
                <ContentItem>Uncrystallised value</ContentItem>
                <ContentItem>Crystallised value</ContentItem>
                <ContentItem>Protection in place – lump sum or fund value</ContentItem>
                <ContentItem>Previously taken benefits</ContentItem>
            </div>
        </Accordion>
    </Container>
  );
}

export default SIPPTransfersComponent;
