import { useState, FunctionComponent } from "react";
import { ActivityType, trackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import { ModalController } from "../../hooks/useModal";
import ModalComponent from "../modal";
import {
  SoModalHouse,
  SoCancelButton,
  SoModalDepictionImage,
  SoModalTitle,
  SoModalMessage,
  SoActionButtonsContainer,
  SoActionButton,
} from "../../styles/SignOut.styled";
import LoadingSpinner from "../LoadingSpinner";
import {useAuth0} from "@auth0/auth0-react";

const SignOut: FunctionComponent<{ controller: ModalController }> = ({ controller }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { logout } = useAuth0();

  const closeModal = () => {
    setLoading(false);
    if(controller) {
      controller.close();
    }
  };

  const signOutAction = () => {
    setLoading(true);
    logout();
    setLoading(false);
    controller.close();
    trackActivity(ActivityType.SIGNOUT, TRACKER.SIGNOUT_PAGE);
  };

  return (
    <ModalComponent controller={controller} data-testid="modal-holder">
      <SoModalHouse>
        <SoCancelButton onClick={closeModal} />
        <SoModalDepictionImage />
        <SoModalTitle>Sign out from T360</SoModalTitle>
        <SoModalMessage>Are you sure you want to sign out from your T360 account?</SoModalMessage>
        <SoActionButtonsContainer>
          <SoActionButton onClick={controller && controller.close}>
            <span>Cancel</span>
          </SoActionButton>
          <SoActionButton onClick={signOutAction}>
            {loading ? <LoadingSpinner /> : <span>Sign Out</span>}
          </SoActionButton>
        </SoActionButtonsContainer>
      </SoModalHouse>
    </ModalComponent>
  );
};

export default SignOut;
