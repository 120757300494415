import { useEffect, useState } from "react";
import LoadingSpinner from "../LoadingSpinner";

function TemporaryLoading() {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return loading ? <LoadingSpinner /> : null;
}

export default TemporaryLoading;
