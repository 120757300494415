import styled from "styled-components";

export const Container = styled.div``;

export const Banner = styled.div`
  background-color: ${({ theme }) =>
    theme.colors.WHITE === "#FFFFFF" ? theme.colors.PRIMARY[25] : theme.colors.PRIMARY[150]};
  padding-bottom: 70px;
`;

export const Cover = styled.div`
  padding: 0 40px;
`;
