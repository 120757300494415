import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import { HubwiseDashboardContainer } from "styles/funds/HubwiseDashboard.styled";
import SIPPDashboardMenu from "components/sippAdmin/SippDashboard";

function SIPPAdminDashboard() {
    const { track } = useTrackActivity();
  
    useEffectOnce(() => {
      track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_DASHBOARD);
    });
  
    return (
      <HubwiseDashboardContainer>
        <SIPPDashboardMenu />
      </HubwiseDashboardContainer>
    );
  }
  export default SIPPAdminDashboard;