import {
  Container,
  MainIcon,
  Title,
  Text,
  CloseButton,
  rabbitIcon,
} from "../../../styles/crownAlert/ThirdStep.styled";

function ThirdStep({ menuToggle }: { menuToggle: () => void }) {
  return (
    <Container>
      <MainIcon iconURL={rabbitIcon} />
      <Title>
        <b>Thanks for asking ALICE</b>
      </Title>
      <Text>We will respond you within 24 hours.</Text>
      <CloseButton onClick={menuToggle}>Close</CloseButton>
    </Container>
  );
}
export default ThirdStep;
