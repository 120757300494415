import { FunctionComponent } from "react";
import { LinearProgressHolder,
    Label,
    ProgressBarContainer,
    ProgressBar,
    DaysHolder
} from "styles/linearProgress/LinearProgress.styled";

const LinearProgressComponent: FunctionComponent<{
    label?:string,
    value: number,
    barColor: string,
    barBackgroundColor:string
    height: number,
    days?: number | string
}> =  ({
    label,
    value,
    barColor,
    barBackgroundColor,
    height,
    days
}) => {
    return (
        <LinearProgressHolder>
            {label && <Label data-testid={"label"}>{label}</Label>}
            <ProgressBarContainer data-testid={"barContainer"} color={barBackgroundColor} height={height}>
                <ProgressBar data-testid={"progressBar"} color={barColor} height={height} value={value}/>
            </ProgressBarContainer>
            {days ? <DaysHolder>{days} days </DaysHolder>
            : <DaysHolder>days</DaysHolder>}
        </LinearProgressHolder>
    )
};

export default LinearProgressComponent;