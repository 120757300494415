export const search = <T extends object>(data: T[], text: string) => {
  const filteredData: T[] = [];

  const copiedData = [...data];

  copiedData.forEach((item: T, i: number) => {
    let values = Object.values(item);
    const isReference = values.some((v) => typeof v === "object");
    let localStore: T[] = [];

    // this index is used to prevent duplicate, check below for usage
    let index = i;

    // checking if there is an object in any of the item,
    // if so, loop through that again and get the value
    if (isReference) {
      values.forEach((v: T) => {
        if (typeof v === "object") {
          const localValues: T[] = Object.values(v);
          const isReference2 = localValues.some((v) => typeof v === "object");
          const mergedArray = localStore.concat(localValues);
          localStore = Array.from(new Set(mergedArray));

          if(isReference2) {
            localValues.forEach((v: T) => {
              if (typeof v === "object") {
                const localValues: T[] = Object.values(v);
                const mergedArray = localStore.concat(localValues);
                localStore = Array.from(new Set(mergedArray));
              }
              else {
                localStore.push(v);
              }
            })
          }
        } else {
          localStore.push(v);
        }
      });
      values = localStore;
      localStore = [];
    }

    values.forEach((value) => {
      const isPassed =
        value && index === i && value.toString().toLowerCase().includes(text.trim().toLowerCase());

      if (isPassed) {
        filteredData.push(item);
        index++;
      }

      //   if item at this index is already, we prevent additional push by doing *index === i* above
    });
  });

  return filteredData;
};
