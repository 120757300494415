import styled from "styled-components";

export const TopContainer = styled.div`
  width: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

export const DashboardContainer = styled.div`
  width: 92%;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 769px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;