import styled, { keyframes } from "styled-components";

const cancelIcon = `${process.env.PUBLIC_URL}/assets/close_gray_no_bg.svg`;
const signOutIcon = `${process.env.PUBLIC_URL}/assets/bg-arrow-right.svg`;

const animatedLoadingBar = keyframes`
     0% {
     left:0;
     right:100%;
     width:0;
   }
   10% {
     left:0;
     right:75%;
     width:25%;
   }
   90% {
     right:0;
     left:75%;
     width:25%;
   }
   100% {
     left:100%;
     right:0;
     width:0;
   }
 `;

export const Loader = styled.div`
  width: auto;
  margin: 0 auto;
  border-radius: 10px;
  border: 4px solid transparent;
  position: relative;
  padding: 1px;

  &:before {
    content: "";
    border: 1px solid #fff;
    border-radius: 10px;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }
`;

export const LoaderBar = styled.div`
  position: absolute;
  border-radius: 10px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: #2e1a61;
  width: 0;
  animation: ${animatedLoadingBar} 2s linear infinite;
`;

export const SoModalHouse = styled.div`
  display: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  isolation: isolate;

  position: relative;
  width: 400px;
  height: 236px;
  background: ${({ theme }) => theme.colors.WHITE};
  box-shadow:
    0px 20px 24px -4px ${({ theme }) => `${theme.colors.SHADOW[100]}21`},
    0px 8px 8px -4px ${({ theme }) => `${theme.colors.SHADOW[100]}8`};
  border-radius: 12px;
`;

export const SoCancelButton = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 44px;
  height: 44px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  background-image: url(${cancelIcon});
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center;
  border-radius: 8px;
  cursor: pointer;
`;

export const SoModalDepictionImage = styled.div`
  width: 48px;
  height: 48px;

  background-color: ${({ theme }) => theme.colors.SILVERY[500]};
  background-image: url(${signOutIcon});
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: center;
  border: 8px solid ${({ theme }) => theme.colors.SILVERY[600]};
  border-radius: 28px;
`;

export const SoModalTitle = styled.div`
  width: 352px;
  height: 28px;

  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;

  text-align: center;

  color: ${({ theme }) => theme.colors.DARK[400]};
  margin-top: 8px;
`;

export const SoModalMessage = styled.div`
  width: 352px;
  height: 20px;

  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  margin-top: 8px;
  color: ${({ theme }) => theme.colors.GREYED[600]};
`;

export const SoActionButtonsContainer = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;

  width: 352px;
  height: 44px;
  margin-top: 32px;
`;

export const SoActionButton = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;

  width: 170px;
  height: 44px;

  box-shadow: 0px 1px 2px ${({ theme }) => `${theme.colors.SHADOW[100]}13`};
  border-radius: 8px;

  cursor: pointer;

  &:first-child {
    margin-right: 6px;
    background: ${({ theme }) => theme.colors.WHITE};
    border: 1px solid ${({ theme }) => theme.colors.GREYED[300]};
    & > span {
      color: ${({ theme }) => theme.colors.GREYED[700]};
    }
  }
  &:last-child {
    margin-left: 6px;
    background: ${({ theme }) => theme.colors.SILVERY[400]};
    border: 1px solid ${({ theme }) => theme.colors.SILVERY[400]};
    & > span {
      color: ${({ theme }) => theme.colors.WHITE};
    }
  }

  & > span {
    width: fit-content;
    height: 24px;

    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;
