import styled from "styled-components";

export const backIcon = `${process.env.PUBLIC_URL}/assets/crown-alert-back-icon.svg`;

export const Container = styled.div`
  margin: 24px 20px 112px 20px;
  width: 503px;
  height: 369px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  background-color: rgba(74, 130, 166, 0.1);
`;

export const BackIcon = styled.div<{ iconURL: string }>`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 32px;
  height: 32px;
  background-image: ${(props) => `url(${props.iconURL})`};
  background-size: 32px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const Text = styled.div`
  margin-top: 16px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.DARK[400]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SwitchContainer = styled.div`
  margin: 0 0 36px 24px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  color: #000;
  font-size: 18px;
`;

export const Label = styled.label`
  margin: 0 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Switch = styled.div`
  position: relative;
  width: 40px;
  height: 20px;
  background: #4a82a6;
  border-radius: 22px;
  padding: 2px;
  transition: 300ms all;
  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 25px;
    top: 50%;
    left: 5px;
    background: white;
    transform: translate(0, -50%);
  }
`;

export const Input = styled.input`
  position: absolute;
  &:checked + ${Switch} {
    &:before {
      transform: translate(19px, -50%);
    }
  }
`;

export const TextArea = styled.textarea`
  margin: 0 24px;
  font-size: 16px;
  resize: none;
  width: 455px;
  height: 180px;
  border: none;
`;

export const UploadButton = styled.button`
  margin: 24px 0 12px 24px;
  cursor: pointer;
  width: 127px;
  height: 48px;
  align-items: center;
  background-color: #4a82a6;
  border: solid 1px #4a82a6;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
`;

export const SendButton = styled.button`
  position: absolute;
  bottom: 40px;
  right: 20px;
  cursor: pointer;
  width: 113px;
  height: 48px;
  align-items: center;
  background-color: #4a82a6;
  border: solid 1px #4a82a6;
  border-radius: 6px;
  color: #fff;
  font-size: 18px;
`;
