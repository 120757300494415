import {useContext} from "react";
import { COLOR_MODE, PARTY_MAP } from "constants/index";
import { AppContext } from "../contexts/App";
import { isT360Email } from "../utils/index";
import { IColors } from "../types/styled";
import { styledComponentTheme } from "../constants/styled";
import { useAuth0 } from "@auth0/auth0-react";
import {isUserPartOfAuth0Group} from "../services/auth0/auth0";

const { DARK_MODE_COLORS, LIGHT_MODE_COLORS } = styledComponentTheme;

/**
 *
 * @returns globally shared data
 */
export const useAppData = () => {
  const {
    user,
    setUser,
    status,
    setStatus,
    opacity,
    setOpacity,
    selectedParty,
    setSelectedParty,
    selectedFunds,
    setSelectedFunds,
  } = useContext(AppContext);

  const { isAuthenticated, getIdTokenClaims, user: auth0User} = useAuth0();
  const getClaims = async () => {
    return await getIdTokenClaims();
  }

  const claims = getClaims() as any;

  const party = claims?.Organisation?.[0] as keyof typeof PARTY_MAP;

  const colors: IColors = status === COLOR_MODE.DARK ? DARK_MODE_COLORS : LIGHT_MODE_COLORS;

  return {
    user,
    setUser,
    status,
    setStatus,
    colors,
    opacity,
    setOpacity,
    isT360Email: isT360Email(user?.email || ""),
    party,
    pid: PARTY_MAP[party],
    isT360: isUserPartOfAuth0Group(auth0User,"Organisation", "t360_party"),
    isNetworkManagerRole: isUserPartOfAuth0Group(auth0User,"Role", "network_manager_role"),
    isTechRole: isUserPartOfAuth0Group(auth0User,"Role", "tech_role"),
    isDidoDemo: isUserPartOfAuth0Group(auth0User,"Access_Permissions", "dido_demo_access"),
    isHubWise: isUserPartOfAuth0Group(auth0User,"Organisation", "hubwise_party"),
    isFund: isUserPartOfAuth0Group(auth0User,"Access_Permissions", "funds_inspect"),
    isPartOfRoles: (roles: string[]) =>
      roles.every((role) => isUserPartOfAuth0Group(auth0User,"Role", role)),
    isUserPermission: (permissions: string[]) =>
      permissions.every((permission) =>
          isUserPartOfAuth0Group(auth0User,"Access_Permissions", permission),
      ),
    isUserPartOfOrganization: (organisations: string[]) =>
      organisations.every((organisation) =>
          isUserPartOfAuth0Group(auth0User,"Organisation", organisation),
      ),
    isUserAuthenticated: isAuthenticated,
    claims,
    selectedParty,
    setSelectedParty,
    selectedFunds,
    setSelectedFunds,
  };
};
