import React, { FunctionComponent, useState, useRef } from "react";
import { 
    TemporaryMasterInstrumentsFundManagerType,
    TemporaryFundsType,
    TemporaryFigiMapType
 } from "types/funds";
import { 
    SelectedFundHolder,
    CloseIcon,
    CloseIconHolder,
    FormHolder,
    FormRow,
    SubmitBtnHolder
} from "styles/funds/MasterBuylist.styled";
import { 
    closeIcon
} from "styles/funds/FundManagerT360.styled";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";
import SelectedFmInfo from "./selectedFM/SelectedFmInfo";
import SearchInstrumentSuggestions from "./SearchInstrumentSuggestions";
import SelectedFundForm from "./selectedFund/SelectedFundForm";

const HubwiseMasterBuylistSearchInstrumentComponent: FunctionComponent<{
    buylistsInfo: {"Buylist Code": string, "Buylist Description": string}[];
    dataToDisplay: TemporaryMasterInstrumentsFundManagerType[];
    setDataToDisplay: React.Dispatch<React.SetStateAction<TemporaryMasterInstrumentsFundManagerType[]>>;
}> =  ({
    buylistsInfo,
    dataToDisplay,
    setDataToDisplay
}) => {  
    
    const [singleItemSelected, setSingleItemSelected]= useState<boolean>(false);
    const [selectedfm, setSelectedfm]= useState<{index: number; obj: TemporaryMasterInstrumentsFundManagerType }>();
    const [selectedFund, setSelectedFund]= useState<{index: number; obj: TemporaryFundsType}>();
    const [selectedFigi, setSelectedFigi]= useState<{index: number; obj: TemporaryFigiMapType}>();
    const [editEnabled, setEditEnabled]= useState<boolean>(false);
    const [fmAccordionOpen, setFmAccordionOpen]= useState<boolean>(false);
    const targetRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        setSingleItemSelected(false);
        setSelectedfm(undefined);
        setSelectedFund(undefined);
        setSelectedFigi(undefined);
    }

    const handleSubmit = () => {
        //TODO: Send formValues to backend
        if(selectedfm) {
            setDataToDisplay(prevState => {
                const updatedState= prevState.map((fm, fmI) => {
                    if(fmI === selectedfm?.index) {
                        return {
                            ...selectedfm.obj,
                            funds: fm.funds.map((fund, fundI) => {
                                if(fundI === selectedFund?.index) {
                                    return {
                                        ...selectedFund.obj,
                                        figi_map: selectedFigi?.obj ? [selectedFigi.obj] : fund.figi_map
                                    }
                                }
                                return fund
                            })
                        }
                    }
                    return fm
                })
                return updatedState
            })

        }
        setEditEnabled(false);
    };

    return (
        singleItemSelected ? 
            <SelectedFundHolder>
                <CloseIconHolder>
                    <CloseIcon iconURL={closeIcon} onClick={handleClose} />
                </CloseIconHolder>

                <SelectedFmInfo
                    dataToDisplay={dataToDisplay}
                    selectedfm={selectedfm}
                    setSelectedFund={setSelectedFund}
                    setSelectedFigi={setSelectedFigi}
                    setFmAccordionOpen={setFmAccordionOpen}
                    fmAccordionOpen={fmAccordionOpen}
                    setSelectedfm={setSelectedfm}
                    addressDetails={false}
                    editFM={false}
                    setData={()=>null}
                    fmView={false}
                    targetRef={targetRef}
                />
                
                <FormHolder>
                    <SelectedFundForm 
                        selectedFund={selectedFund}
                        selectedFigi={selectedFigi}
                        targetRef={targetRef}
                    />
                    {/* <SubmitBtnHolder>
                        <SubmitButton
                            onClick={()=>setEditEnabled(!editEnabled)}
                            text={"Edit"}
                            type={"submit"}
                            disabled={editEnabled}
                        />
                        <SubmitButton
                            onClick={handleSubmit}
                            text={"Submit"}
                            type={"submit"}
                            disabled={!editEnabled}
                        />
                    </SubmitBtnHolder> */}
                </FormHolder>            
            </SelectedFundHolder>
        :
        <SearchInstrumentSuggestions
            dataToDisplay={dataToDisplay}
            setSelectedFund={setSelectedFund}
            setSelectedFigi={setSelectedFigi}
            setSelectedfm={setSelectedfm}
            setSingleItemSelected={setSingleItemSelected}
        />
    )
};

export default HubwiseMasterBuylistSearchInstrumentComponent;