// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { Accordion } from "components/party/accordion/Accordion";
import { 
    Container, 
    Title,
    ContentItem 
} from "styles/sippAdmin/sippAdmin.styled";

function SIPPAccountHistoryComponent() {

  return (
    <Container>
        <Title>
            Bank Account/ Transaction History
        </Title>
        <Accordion title="Screen" noTopBorder>
            <div></div>
        </Accordion>
        <Accordion title="Section Content" opened>
            <div>
                <ContentItem>Opening balance</ContentItem>
                <ContentItem>Transaction date</ContentItem>
                <ContentItem>Transaction type</ContentItem>
                <ContentItem>Transaction Amount</ContentItem>
                <ContentItem>Note</ContentItem>
                <ContentItem>Rolling Balance</ContentItem>
                <ContentItem>Closing Balance</ContentItem>
            </div>
        </Accordion>
    </Container>
  );
}

export default SIPPAccountHistoryComponent;
