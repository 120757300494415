import {
    TableBodyCell,
    RespTableRow,
    RespTableBody,
    TableHeaderCell,
    RespTableHeader,
    RespTable,
    WrapTable,
} from "styles/client/Report.styled";
import React, { FunctionComponent } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ReportFmType } from "types/funds";

const ReportTable : FunctionComponent<{
    reportDetailsData: ReportFmType[] | undefined
}> =  ({
    reportDetailsData
}) => {
   
    return (
        <WrapTable> 
            <RespTable>
                <RespTableHeader >
                    <RespTableRow>
                        <TableHeaderCell>Fund Provider Code</TableHeaderCell> 
                        <TableHeaderCell>Asset Name</TableHeaderCell> 
                    </RespTableRow>
                </RespTableHeader>
                <RespTableBody>
                    {reportDetailsData?.map((item, index) => (
                        item.funds.map((fund, fundIndex)=> (
                            <React.Fragment key={`${fund.ISIN}_${fund.Sedol}`}>
                                <RespTableRow>
                                    <TableBodyCell>{item["Provider Code"]}</TableBodyCell>
                                    <TableBodyCell>{typeof fund["Instrument Name"] === "string" ? fund["Instrument Name"] : fund["Instrument Name"] && fund["Instrument Name"].new ? fund["Instrument Name"].new : fund["Instrument Name"] && fund["Instrument Name"].old}</TableBodyCell>
                                </RespTableRow>
                            </React.Fragment>
                        ))
                    ))}
                </RespTableBody>
            </RespTable>
        </WrapTable>
    );
}
  
export default ReportTable;
  