import { Container, Title, Info, Icon, Num } from "styles/client/InfoCard.styled";
import { InfoCardProps } from "types";

function InfoCard({ icon, num, name, testId }: InfoCardProps & { testId: string }) {
  return (
    <Container data-testid={testId}>
      <Title>{name}</Title>
      <Info>
        <Icon src={icon} />
        <Num>{num}</Num>
      </Info>
    </Container>
  );
}

export default InfoCard;
