import styled from "styled-components";

export const NoData = styled.div`
  background-image: url(${`${process.env.PUBLIC_URL}/assets/nodata.png`});
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  width: 400px;
  height: 404px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    width: 350px;
    height: 354px;
  }
`;
export const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
  width: max-content;

  p {
    margin-top: -40px;
    color: ${({ theme }) => theme.colors.DARK[500]};
  }
  h3 {
    margin-top: 0;
    color: ${({ theme }) => theme.colors.PRIMARY[400]};
  }
`;
