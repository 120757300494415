export const isT360Email = (email: string | undefined) => {
  return email?.includes("@t360degrees.com");
};

// check if the condition is true then allow scroll on the page, but if the condition is false then hide scroll
export const toggleScroll = (hide: boolean) => {
  if (hide) document.body.style.overflow = "hidden";
  if (!hide) document.body.style.overflow = "auto";
};

export const groupByBic = (data: any[], key: string): { [key: string]: any[] } => {
  if (!data.length) return {};

  const obj: { [key: string]: any[] } = {};

  data.forEach((d) => {
    if (d[key] in obj) {
      obj[d[key]].push(d);
    } else {
      obj[d[key]] = [d];
    }
  });

  return obj;
};

export function clipText(text: string, maxLength: number) {
  return text.length > maxLength ? `${text.toString().substring(0, maxLength)}...` : text;
}
