import styled from "styled-components";

export const DetailsIconWrapper = styled.div`
  width: 19px;
  height: 19px;
  padding-left: 10px;
  display: none;
  cursor: pointer;
`;

export const DetailsIcon = styled.img``;

export const SingleRow = styled.div`
  display: flex;
  margin-bottom: 27px;
  align-items: center;
  gap: 72px;
`;

export const FieldName = styled.div`
  flex-basis: 242px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-size: 16px;
  line-height: 21.09px;
  text-transform: capitalize;
  font-family: Roboto Condensed;
`;

export const Span = styled.div`
  @media (min-width: 1024px) {
    max-width: 600px;
    word-wrap: break-word;
  }
`;

export const FieldValue = styled.div`
  color: ${({ theme }) => theme.colors.DARK[400]};
  font-size: 16px;
  line-height: 18.75px;
  flex: 1;
  font-family: Roboto Condensed;
  display: flex;
  &:hover > ${DetailsIconWrapper} {
    display: block;
  }
`;
