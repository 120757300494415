import {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StatusProps } from "constants/index";
import { GeneralDetailsType } from "types/party";
import { GeneralDetailsType as FundsGeneralDetailsType } from "types/funds";
import Toast from "components/Toast";
import { ColorMode } from "../types";
import { COLOR_MODE } from "../constants";
import {useAuth0} from "@auth0/auth0-react";
import { User } from "@auth0/auth0-react";

const TOAST_INFO_INITIAL_DATA = {
  isOpen: false,
  message: "",
  status: StatusProps.ERROR,
};

export const CONTEXT_DATA = {
  user: undefined,
  setUser: () => null,
  status: COLOR_MODE.LIGHT,
  setStatus: () => "",
  opacity: 1,
  setOpacity: () => null,
  selectedParty: null,
  selectedFunds: null,
  setSelectedFunds: () => {},
  setSelectedParty: () => {},
  toastInfo: TOAST_INFO_INITIAL_DATA,
  setToastInfo: () => {}
};

// create AppContext
export const AppContext = createContext<{
  user: User | undefined;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  status: ColorMode;
  setStatus: Dispatch<SetStateAction<ColorMode>>;
  opacity: number;
  setOpacity: Dispatch<SetStateAction<number>>;
  selectedParty: GeneralDetailsType | null;
  setSelectedParty: Dispatch<SetStateAction<GeneralDetailsType | null>>;
  selectedFunds: FundsGeneralDetailsType | null;
  setSelectedFunds: Dispatch<SetStateAction<FundsGeneralDetailsType | null>>;
  toastInfo: typeof TOAST_INFO_INITIAL_DATA;
  setToastInfo: Dispatch<SetStateAction<typeof TOAST_INFO_INITIAL_DATA>>;
}>(CONTEXT_DATA);

function getInitialState() {
  const status = localStorage.getItem("status");
  return status ? JSON.parse(status) : "light";
}

// create AppProvider component with initial data set
export const AppProvider: FunctionComponent<PropsWithChildren<object>> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [toastInfo, setToastInfo] = useState(TOAST_INFO_INITIAL_DATA);
  const { isAuthenticated, isLoading, user: auth0User } = useAuth0();

    // used to manage the global variable that indicates the theme color
  const [status, setStatus] = useState<ColorMode>(getInitialState);

  // used to manage the brightness of the app
  const [opacity, setOpacity] = useState<number>(100);

  // used to get selected party in the alert page
  const [selectedParty, setSelectedParty] = useState<GeneralDetailsType | null>(null);

  // used to get selected funds in the alert page
  const [selectedFunds, setSelectedFunds] = useState<FundsGeneralDetailsType | null>(null);


  useEffect(() => {
    // change the brightness of the entire app (style applied to html tag)
    document.documentElement.style.filter = `brightness(${opacity / 2 + 50}%)`;

    // change the color theme of the html tag
    document.documentElement.style.backgroundColor =
      status === COLOR_MODE.LIGHT ? "#fff" : "#222425";

    // use getUserDataAuth0 to transform user information from Auth0 type (User) into the standard type we use (UserDetailsAuth0)
    if(isAuthenticated && !isLoading) {
        setUser(auth0User);
    }
    return () => {};
  }, [isAuthenticated, opacity, status, user]);

  const valueProps = useMemo(
    () => ({
      user,
      setUser,
      status,
      setStatus,
      opacity,
      setOpacity,
      selectedParty,
      setSelectedParty,
      selectedFunds,
      setSelectedFunds,
      toastInfo,
      setToastInfo
    }),
    [user, status, opacity, selectedParty, selectedFunds, toastInfo],
  );

  return (
    <AppContext.Provider value={valueProps} >
      {children}
      <Toast />
    </AppContext.Provider>
  );
};
