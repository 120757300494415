import { FunctionComponent, useState, useEffect } from "react";
import { 
    OnboardingMainContainer,
} from "styles/funds/Onboarding.styled";
import OnboardingBox from "./OnboardingBox";
import NavBox from "./NavBox";
import { ClientRequestTemplateType } from "types/party";
import { getAccessToken } from "services/auth0/auth0";
import {
    FUNDS_DATA_REPOSITORY_BUCKET_NAME,
    CRTS_CCIDS_BUYLISTS_PATH,
    FUNDS_REQUESTS_UPLOADED_PATH
  } from "config/googleCloudConfig";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import LoadingSpinner from "components/LoadingSpinner";
import { FreshDeskTicketIndexType } from "types/party";

const HubwiseOnboardingComponent: FunctionComponent =  () => {

    const [crtData, setCrtData]= useState<ClientRequestTemplateType[]>([]);
    const [requestsData, setRequestsData]= useState<FreshDeskTicketIndexType[]>([]);
    const [loading, setLoading]= useState<boolean>(false);
    const token = getAccessToken();
    const [uploaded, setUploaded]= useState<boolean>(false);

    useEffect(()=>{    
        if(token) {
            setLoading(true);
            const indexFile = "index.json";
            const payload = {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName": indexFile,
                "filePath": `${CRTS_CCIDS_BUYLISTS_PATH}`
            };

            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: ClientRequestTemplateType[])=> {
                setCrtData(data);
            });

            const requestsPayload= {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName": indexFile,
                "filePath": `${FUNDS_REQUESTS_UPLOADED_PATH}`
            }

            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, requestsPayload, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: FreshDeskTicketIndexType[])=> {
                setRequestsData(data);
                setLoading(false);
                setUploaded(false);
            });
        }
    },[token, uploaded])

    return loading ? <LoadingSpinner /> : (
        <OnboardingMainContainer>
            <OnboardingBox data={crtData}/>
            <NavBox crtData={crtData} requestsData={requestsData} setUploaded={setUploaded}/>
        </OnboardingMainContainer>
    )
};

export default HubwiseOnboardingComponent;