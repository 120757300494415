import { useEffect, FC, PropsWithChildren } from "react";

const FavIconDark = `${process.env.PUBLIC_URL}/t360darkmode.png`;
const FavIcon = `${process.env.PUBLIC_URL}/t360-logo.png`;
const DynamicFavIcon: FC<PropsWithChildren<object>> = ({ children }) => {
  useEffect(() => {
    // add favicon to link tag dynamically
    const favIcon = () => {
      let link = document.querySelector("link[rel~='icon']") as Partial<HTMLAnchorElement>;

      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link as unknown as Node);
      }

      const event = matchMedia("(prefers-color-scheme: dark)");
      const colorScheme = event.matches ? "dark" : "light";

      if (colorScheme === "dark") {
        link.href = FavIconDark;
      } else {
        link.href = FavIcon;
      }
    };

    // add favicon on loading page
    window.addEventListener("load", () => {
      favIcon();
    });

    // add favIcon on changing theme color mode
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
      favIcon();
    });

    return () => {
      window.removeEventListener("load", () => {
        favIcon();
      });
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", () => {
        favIcon();
      });
    };
  }, []);
  return <div>{children}</div>;
};

export default DynamicFavIcon;
