// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { 
    Container
} from "styles/sippAdmin/sippAdmin.styled";

function SIPPMarketPlayersComponent() {

  return (
    <Container>Market players coming soon ...</Container>
  );
}

export default SIPPMarketPlayersComponent;
