import { FunctionComponent } from "react";
import { 
    Widget,
    WidgetBodyArea
} from "styles/funds/UserDesktopView.styled";
 import WidgetHeaderAreaComponent from "./WidgetHeaderArea";
import { FundType } from "types/funds";

const WidgetComponent: FunctionComponent <{
    element: JSX.Element;
    isOption: boolean;
    setIsOption: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    display: string;
    id: number;
    activeId?: number;
    setActiveId: React.Dispatch<React.SetStateAction<number | undefined>>;
    userPreferences: string[];
    name: string
    setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
    monitoredItem?: FundType;
    itemsToMonitor?: FundType[];
}> =  ({
    element,
    isOption,
    setIsOption,
    title,
    display,
    id,
    activeId,
    setActiveId,
    userPreferences,
    name,
    setUpdated,
    monitoredItem,
    itemsToMonitor
}) => {    
    return (
        <Widget display={ display }>
            <WidgetHeaderAreaComponent
                isOption={ isOption }
                setIsOption={ setIsOption }
                title={ title }
                id= {id}
                activeId= {activeId}
                setActiveId = {setActiveId}
                userPreferences= {userPreferences}
                name= {name}
                setUpdated= {setUpdated}
                monitoredItem= {monitoredItem}
                itemsToMonitor={itemsToMonitor}
            />
            <WidgetBodyArea>
                { element }
            </WidgetBodyArea>
        </Widget>
    )
};

export default WidgetComponent