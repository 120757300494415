/**
 * This is the dedicated component for every search result item that is showed for every Search Results page in Party Manager.
 */

import { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { Link, useLocation } from "react-router-dom";
import { SearchResultsAccordion } from "../searchResultsAccordion/SearchResultsAccordion";
import {
  SearchResultItemContainer,
  SearchResultItemArea,
  SearchResultItemLeftArea,
  SearchResultItemRightArea,
  SearchResultsItemName,
  SearchResultsItemCountry,
  AccordionActionButton,
  OpenPartyIcon,
  CloseAccordionIcon,
  OpenAccordionIcon,
  GuoIconContainer,
  GuoImage,
  RightIcons,
} from "../../../styles/searchResult/SearchResultsItem.styled";
import { SearchSuggestionInput } from "../../../types";

export function SearchResultsItem({
  data,
  isGuo
}:{
  data: SearchSuggestionInput;
  isGuo?: boolean;
}) {
  const [showItem, setShowItem] = useState(false);

  const returnIdentifiers = () => {
    const identifiers = [];
    if(data.nationalIdNumber_ss && data.nationalIdLabel_ss) {
      for (let i = 0; i < data.nationalIdNumber_ss.length; i++) {
        identifiers.push({label: data.nationalIdLabel_ss[i], value:data.nationalIdNumber_ss[i]})
      }
    }
    return identifiers;
  }

  // get current page url and check if it is the funds search result page
  const location = useLocation();
  const [path] = useState(location.pathname);
  const IS_FUND_SEARCH_RESULT = path.includes("/hubwise") || path.includes("funds/search-results") || path.includes("/crt");

  return (
    <div>
      <SearchResultItemContainer show={showItem}>
        <SearchResultItemArea onClickCapture={() => setShowItem(true)}>
          <SearchResultItemLeftArea>
            {isGuo && (
              <GuoIconContainer>
                <GuoImage src={`${process.env.PUBLIC_URL}/assets/guoIcon.svg`} />
              </GuoIconContainer>
            )}
            {data.legalName_ss ? <SearchResultsItemName>{data.legalName_ss[0]}</SearchResultsItemName> : null}
            <SearchResultsItemCountry>
              {data.countryIsoCode_ss && (
                <div>
                  <ReactCountryFlag
                    countryCode={data.countryIsoCode_ss[0] }
                    svg
                    style={{ width: "1.5em", height: "1.5em" }}
                    title={data.countryIsoCode_ss[0] }
                  />
                </div>
              )}
              {data.countryIsoCode_s && (
                <div>
                  <ReactCountryFlag
                    countryCode={data.countryIsoCode_s }
                    svg
                    style={{ width: "1.5em", height: "1.5em" }}
                    title={data.countryIsoCode_s}
                  />
                </div>
              )}

              {data.mainDomesticCountry_s && <div>{data.mainDomesticCountry_s}</div>}
            </SearchResultsItemCountry>
            {data.typeOfEntity_ss && <div>{data.typeOfEntity_ss[0]}</div>}

            <RightIcons>
              <AccordionActionButton>
                {/* direct to funds page if link is clicked from the search-result page, else direct to party profile page  */}
                <Link to={IS_FUND_SEARCH_RESULT ? `/funds/${data.pid_s}` : `/party/${data.pid_s}`} target="_blank" rel="noopener noreferrer">
                  <OpenPartyIcon
                    title={IS_FUND_SEARCH_RESULT ? "Open Funds Profile" : "Open Party Profile"}
                  />
                </Link>
              </AccordionActionButton>
              <AccordionActionButton onClick={() => setShowItem(!showItem)}>
                {showItem === true ? (
                  <CloseAccordionIcon title="Close this result" />
                ) : (
                  <OpenAccordionIcon title="Open this result" />
                )}
              </AccordionActionButton>
            </RightIcons>
          </SearchResultItemLeftArea>
          <SearchResultItemRightArea onClick={!showItem ? () => setShowItem(true) : undefined}>
            {showItem && (
              <SearchResultsAccordion
                idForNeo4j={data.pid_s}
                mainActivity={data.mainActivity_s ? data.mainActivity_s as string : ""}
                category={data.categoryOfTheCompany_ss ? data.categoryOfTheCompany_ss[0] as string : ""}
                description={data.generalDescription_s}
                identifiers={returnIdentifiers()}
              />
            )}
          </SearchResultItemRightArea>
        </SearchResultItemArea>
      </SearchResultItemContainer>
    </div>
  );
}
