import styled from "styled-components";

export const TileAndModal = styled.div`
  flex-wrap: wrap;
  position: relative;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
`;

export const TileContainer = styled.div`
  max-width: 370px;
  display: grid;
  grid-template-columns: 10% 90% 1fr;
  align-items: center;
  padding: 31px 61px 30px 20px;
  margin-bottom: 20px;
  background-image:
    linear-gradient(to bottom, rgba(250, 250, 250, 0.4), rgba(246, 244, 249, 0.4)),
    radial-gradient(at 50% 50%, rgba(246, 244, 249, 0.3), rgba(250, 250, 250, 0.4));
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

export const TilePersonIconContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TilePersonDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1,1fr);
  margin-left: 20px;
  gap: 15px;

  & div {
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const TilePersonIcon = styled.span<{personFlag?: boolean}>`
  display: block;
  width: 40px;
  height: 40px;
  background-image: ${(props) => `url(${props.personFlag === true ? `${process.env.PUBLIC_URL}/assets/avatar.svg` : `${process.env.PUBLIC_URL}/assets/building-avatar.png`})`};
  background-repeat: no-repeat;
`;

export const TileRow = styled.div`
  display: grid;
  grid-template-columns: 35% 65% 1fr;
`;

export const TilePersonField = styled.div`
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-size: 18px;
  font-family: Roboto Condensed;
  white-space: break-spaces;
`;

export const TilePersonValue = styled.div`
  color: ${({ theme }) => theme.colors.GREY[500]};
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  font-family: Roboto Condensed;
  word-wrap: break-word;
  max-width: 400px;
  text-align: left;
`;

export const NameDiv = styled.div`
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 700;
  font-size: 18px;
  font-family: Roboto Condensed;
`;

export const FundManagerCardsHolder = styled.div`
  display: grid;
  gap: 10px;

  @media (max-width: 769px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 1338px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 769px) and (max-width: 1338px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
