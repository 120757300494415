import { TRACKER } from "constants/tracker";
import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import {
  SettingSection,
  SettingSubSectionA,
  SettingSubSectionB,
  SettingImg,
} from "../../styles/admin/Setting.styled";

function Settings() {
  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.ALICE_ADMIN_SETTINGS);
  });

  return (
    <SettingSection>
      <SettingSubSectionA>
        <h2>Coming soon!!!</h2>
        <span>
          All Good Things <br /> Come to Those who <br />
          Wait...
        </span>
      </SettingSubSectionA>
      <SettingSubSectionB>
        <img src={SettingImg} width="400" className="csImg" alt="coming soon" />
      </SettingSubSectionB>
    </SettingSection>
  );
}

export default Settings;
