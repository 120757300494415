import { FunctionComponent, useState } from "react";
import { docTypes } from "data/docTypes";
import { CNS_INITIAL_VALUES } from "config/payload";
import { ModalController } from "../../hooks/useModal";
import ModalComponent from "../modal";
import { CsuStyle } from "../../styles/ConfirmSubmitUpload.styled";

const ConfirmSubmitUpload: FunctionComponent<{
  controller: ModalController;
  editButtonAction: (editParameter: string) => void;
  nextButtonAction: () => void;
}> = ({ controller, editButtonAction, nextButtonAction }) => {
  const [confirmedAndSubmitted, setConfirmedAndSubmitted] = useState(CNS_INITIAL_VALUES);

  const confirmAndContinueClick = (docIndex: number) => {
    const cNSStateChange = [...confirmedAndSubmitted];
    if (cNSStateChange[docIndex] === CNS_INITIAL_VALUES[0]) {
      cNSStateChange[docIndex] = "Submitted";
    } else {
      cNSStateChange[docIndex] = CNS_INITIAL_VALUES[0];
    }
    setConfirmedAndSubmitted(cNSStateChange);
  };

  return (
    <ModalComponent controller={controller}>
      <CsuStyle.ModalLayout data-testid="confirm-container">
        <CsuStyle.ModalPartition>
          <CsuStyle.ModalHouse>
            <CsuStyle.ModalTitle>
              Please check the text fields for each document before submitting
            </CsuStyle.ModalTitle>
            {docTypes.map((docType) => {
              return (
                <CsuStyle.DocumentTypeContainer data-testid="doc-type-container" key={docType.index}>
                  <CsuStyle.DocumentTitle>{docType.DocTypeName}</CsuStyle.DocumentTitle>
                  {docType.DocDetails.map((docDetail, i) => {
                    return (
                      <CsuStyle.DocumentDetailsContainer key={i}>
                        <CsuStyle.EditDetailLabel>{docDetail.label}</CsuStyle.EditDetailLabel>
                        <CsuStyle.EditInput placeholder="pre-filled information from doc" />
                        <CsuStyle.EditButton data-testid="edit-btn" onClick={() => editButtonAction(docDetail.label)} />
                      </CsuStyle.DocumentDetailsContainer>
                    );
                  })}
                  <CsuStyle.ConfirmNSubmitButton
                    data-testid="submit-btn"
                    onClick={() => {
                      confirmAndContinueClick(docType.index);
                    }}
                  >
                    {confirmedAndSubmitted[docType.index]}
                  </CsuStyle.ConfirmNSubmitButton>
                </CsuStyle.DocumentTypeContainer>
              );
            })}
            <CsuStyle.NextButtonContainer>
              <CsuStyle.NextButton data-testid="next-btn" onClick={nextButtonAction}>Next</CsuStyle.NextButton>
            </CsuStyle.NextButtonContainer>
          </CsuStyle.ModalHouse>
        </CsuStyle.ModalPartition>
      </CsuStyle.ModalLayout>
    </ModalComponent>
  );
};

export default ConfirmSubmitUpload;
