// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { 
    Container,
    Title,
    SubTitle,
    CardsContainerDiv
} from "../../../styles/sippAdmin/goodLooksLike.styled";
import CardItemComponent from "./CardItem";

function SIPPGoodLooksLikeComponent() {

  const centralizedAdminDashboardIcon= `${process.env.PUBLIC_URL}/assets/icons/analyze1.png`;
  const dataSecurityIcon= `${process.env.PUBLIC_URL}/assets/icons/broken-link1.png`;
  const regulatoryComplianceIcon= `${process.env.PUBLIC_URL}/assets/icons/combo-chart1.png`;
  const automatedWorkflowsIcon= `${process.env.PUBLIC_URL}/assets/icons/globe1.png`;
  const investmentManagementIcon= `${process.env.PUBLIC_URL}/assets/icons/icons8-goal1.png`;
  const clientSelfServiceIcon= `${process.env.PUBLIC_URL}/assets/icons/mind-map1.png`;

  const centralizedAdminDashboardDescItems= [
    "Intuitive interface providing a single view of all client SIPPs",
    "Quick access to client records, transaction history, and account balances",
    "Real-time updates on investment performance and cash flow"
  ];
  const dataSecurityDescItems= [
    "Advanced encryption to protect sensitive client and financial data",
    "Regular system backups and failover mechanisms for uninterrupted service",
    "Single source of truth for client funds",
    "GDPR-compliant data handling"
  ];
  const regulatoryComplianceDescItems= [
    "Built-in checks to comply with FCA, HMRC, and TPR regulations",
    "Automated tax calculations, annual return filings, and contribution limits monitoring",
    "Audit trails to track all changes and activities for regulatory reporting"
  ];
  const automatedWorkflowsDescItems= [
    "Automated processing for contributions, withdrawals, and transfers",
    "Systematic reminders for key tasks such as annual reviews or benefit crystallisation events",
    "Document generation for client reports, benefit statements, and tax filings"
  ];
  const investmentManagementDescItems= [
    "Seamless integration with leading investment platforms and custodians",
    "Support for a wide range of assets including stocks, bonds and alternative investments",
    "Real-time valuation updates and portfolio tracking"
  ] ;
  const clientSelfServiceDescItems= [
    "Secure client access to view account details, investment performance, and make contributions",
    "Online submission of transfer requests, withdrawals, and documentation uploads",
    "Enhanced client experience through 24/7 accessibility and transparency"
  ] 

  return (
    <Container>
        <Title> Self-Invested Personal Pension Management Platform</Title>
        <SubTitle>
            Lessened operational staff costs and improved efficiencies throughout the business.
        </SubTitle>
        <CardsContainerDiv>
          <CardItemComponent url={centralizedAdminDashboardIcon} title="Centralised Administration Dashboard" descrItems={centralizedAdminDashboardDescItems} />
          <CardItemComponent url={dataSecurityIcon} title="Data Security and Integrity" descrItems={dataSecurityDescItems} />
          <CardItemComponent url={regulatoryComplianceIcon} title="Regulatory Compliance" descrItems={regulatoryComplianceDescItems} />
          <CardItemComponent url={automatedWorkflowsIcon} title="Automated Workflows" descrItems={automatedWorkflowsDescItems} />
          <CardItemComponent url={investmentManagementIcon} title="Investment Management Integration" descrItems={investmentManagementDescItems} />
          <CardItemComponent url={clientSelfServiceIcon} title="Client Self-Service Portal" descrItems={clientSelfServiceDescItems} />
        </CardsContainerDiv>
    </Container>
  );
}

export default SIPPGoodLooksLikeComponent;
