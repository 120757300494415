import { FunctionComponent } from "react";
import {
    BottomContentContainer,
} from "styles/funds/Onboarding.styled";
import { 
    UpdatedContainer,
    UpdateItem,
    UpdateItemValue,
    UpdateItemContainer
} from "styles/funds/HubwiseFundsUpdateArea.styled";
import NoDataContent from "components/noData/NoDataContent";
import { TemporaryMasterInstrumentsFundManagerType } from "types/funds";

interface Stats {
    "Total Number of Buylists": number;
    "Total Number of Ringfenced Buylists": number;
    "Total Number of Standard Buylists": number;
    "Total Number of Fund Managers": number;
    "Total Number of Instruments": number;
    "Total Number of Standard Instruments": number;
    "Total Number of Ringfenced Instruments": number;
    [key: string]: number
}

const WeeklyBuylistUpdatesComponent: FunctionComponent<{
    data?: TemporaryMasterInstrumentsFundManagerType[];
    stats?: Stats;
}> =  ({
    data,
    stats
}) => {
    
    return stats ? (
        <BottomContentContainer>
            <UpdatedContainer>
                {Object.keys(stats).map((field, index) => (
                    <UpdateItemContainer key={index}>
                        <UpdateItem>{field}: </UpdateItem>
                        <UpdateItemValue>{stats[field]}</UpdateItemValue>
                    </UpdateItemContainer>
                ))}
            </UpdatedContainer>
        </BottomContentContainer>
    ) : <NoDataContent />
};

export default WeeklyBuylistUpdatesComponent;