// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { Accordion } from "components/party/accordion/Accordion";
import { 
    Container, 
    Title,
    ContentItem 
} from "styles/sippAdmin/sippAdmin.styled";

function SIPPContributionsComponent() {

  return (
    <Container>
        <Title>
            Contributions
        </Title>
        <Accordion title="Screen" noTopBorder>
            <div></div>
        </Accordion>
        <Accordion title="Section Content" opened>
            <div>
                <ContentItem>Amount</ContentItem>
                <ContentItem>Frequency</ContentItem>
                <ContentItem>Income</ContentItem>
                <ContentItem>Contributor</ContentItem>
                <ContentItem>Tax relief received</ContentItem>
                <ContentItem>Date of initial contribution</ContentItem>
                <ContentItem>Contributions made this tax year</ContentItem>
                <ContentItem>Carry forward from previous years</ContentItem>
                <ContentItem>Previously taken benefits/ benefits in payment</ContentItem>
                <ContentItem>Employment Status</ContentItem>
                <ContentItem>Source of Wealth</ContentItem>
            </div>
        </Accordion>
    </Container>
  );
}

export default SIPPContributionsComponent;
