import React, { FunctionComponent, useRef, useState } from "react";
import { FundsTableHolder } from "styles/funds/MasterBuylist.styled";
import { Accordion } from "components/party/accordion/Accordion";
import { 
    TemporaryMasterInstrumentsFundManagerType,
    TemporaryFundsType,
    TemporaryFigiMapType
 } from "types/funds";

 import {  
    TableBodyCell,
    RespTableRow,
    RespTableBody,
    TableHeaderCell,
    RespTableHeader,
    RespTable,
    WrapTable
} from "styles/funds/FundManagerT360.styled";
import NoDataContent from "components/noData/NoDataContent";

const SelectedFmManagedFundsTable : FunctionComponent<{
    dataToDisplay: TemporaryMasterInstrumentsFundManagerType[];
    selectedfm: {index: number; obj: TemporaryMasterInstrumentsFundManagerType } | undefined;
    setSelectedFund: React.Dispatch<React.SetStateAction<{index: number; obj: TemporaryFundsType} | undefined>>;
    setSelectedFigi: React.Dispatch<React.SetStateAction<{index: number; obj: TemporaryFigiMapType} | undefined>>;
    fmView: boolean;
    targetRef: React.RefObject<HTMLDivElement>
}> =  ({
    dataToDisplay,
    selectedfm,
    setSelectedFund,
    setSelectedFigi,
    fmView,
    targetRef
}) => { 
    const [clickedRow, setClickedRow] = useState<number | null>(null);

    const handleTableClick = (fmIndex:number, fundIndex: number) => {
        setSelectedFund({index: fundIndex, obj: dataToDisplay[fmIndex].funds[fundIndex]});
        if(dataToDisplay[fmIndex].funds[fundIndex] && dataToDisplay[fmIndex].funds[fundIndex].figi_map.length > 0) {
            setSelectedFigi({index: 0, obj: dataToDisplay[fmIndex].funds[fundIndex].figi_map[0]});
            setClickedRow(fundIndex); 
            setTimeout(()=> {
                targetRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
                setClickedRow(null)
            }, 200);
        } else {
            setSelectedFigi(undefined)
        }
    }

    return (
        <FundsTableHolder>
            <Accordion key={"fund-table-accordion"} title={fmView ? "Managed Funds": `${selectedfm?.obj["Provider Name"]} - Onboarded Funds List`} light small noTopBorder>
                <WrapTable>    
                    <RespTable>
                        <RespTableHeader >
                            <RespTableRow>
                                <TableHeaderCell>Fund</TableHeaderCell> 
                                <TableHeaderCell>ISIN</TableHeaderCell> 
                                <TableHeaderCell>SEDOL</TableHeaderCell> 
                                <TableHeaderCell>FIGI</TableHeaderCell> 
                                <TableHeaderCell>Ringfenced*</TableHeaderCell>                                
                                { fmView && <TableHeaderCell>Permissioned*</TableHeaderCell> }
                            </RespTableRow>
                        </RespTableHeader>   
                        {
                            (dataToDisplay && selectedfm) ?  
                                <RespTableBody>
                                    {dataToDisplay[selectedfm?.index].funds.map((fund, index) => (
                                        <React.Fragment key={index}>
                                            <RespTableRow  onClick={()=>handleTableClick(selectedfm?.index, index)} clicked={clickedRow === index ? true : false}>
                                                {fund.figi_map.length > 0 ? <TableBodyCell>{fund.figi_map[0].name}</TableBodyCell> : 
                                                <TableBodyCell>{fund["Instrument Name"]}</TableBodyCell>}
                                                <TableBodyCell> {fund.ISIN}</TableBodyCell>
                                                <TableBodyCell> {fund.Sedol} </TableBodyCell>
                                                {fund.figi_map.length > 0 ?  (
                                                    <TableBodyCell>{fund.figi_map[0].figi}</TableBodyCell> 
                                                ) : <TableBodyCell>{"-"}</TableBodyCell> }
                                                <TableBodyCell>{fund.lists.some(item => item["Buylist Code"] === "BLST01" && item["Buylist Description"] === "Standard Buylist") ? "No" : "Yes"}</TableBodyCell> 
                                                {fmView && <TableBodyCell>{fund.lists.length === 0 ? "No" : "Yes"}</TableBodyCell>}
                                            </RespTableRow>

                                        </React.Fragment>
                                    ))}
                                </RespTableBody>
                            : <NoDataContent />
                        }
                    </RespTable>
                </WrapTable>
            </Accordion>
        </FundsTableHolder>
    )
}

export default SelectedFmManagedFundsTable;