import { FunctionComponent } from "react";
import {
    BottomContentContainer,
    FiltersContainer,
    SearchWrapper,
    TableBodyCell,
    RespTableRow,
    RespTableBody,
    TableHeaderCell,
    RespTableHeader,
    RespTable,
    WrapTable,
    TableHeaderRow
} from "styles/funds/Onboarding.styled";
import useFilters from "hooks/useFilter";
import { Search } from "../../filter/Search";
import { format } from "date-fns";
import { FreshDeskTicketIndexType } from "types/party";

const RequestsInVerificationTable: FunctionComponent<{data: FreshDeskTicketIndexType[]}> =  ({data}) => {
    const { filteredData, setFilter } = useFilters(data);
    const setData = (str: string) => {
        setFilter({ search: str });
    };

    return (
        <BottomContentContainer>
            <FiltersContainer>
                <SearchWrapper>
                    <Search setData={setData} />
                </SearchWrapper>
            </FiltersContainer>
            <WrapTable>
                <RespTable>
                    <RespTableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell>Request Name</TableHeaderCell>
                            <TableHeaderCell>Upload Date</TableHeaderCell>
                            <TableHeaderCell>Last Modified Date</TableHeaderCell>
                            <TableHeaderCell>Status</TableHeaderCell>
                        </TableHeaderRow>
                    </RespTableHeader>
                    {filteredData && 
                        <RespTableBody>
                            {filteredData.map((request,index) => (
                                <RespTableRow key={index} data-testid="row">
                                    <TableBodyCell>{request.originalFileName}</TableBodyCell>
                                    <TableBodyCell>
                                        {format(new Date(request.uploadTime ? request.uploadTime : "Jan 3 2024 12:46:55 GMT+0200 (Eastern European Standard Time)"), "dd MMM yyy")} {"  "}
                                                    
                                        {format(new Date(new Date(request.uploadTime ? request.uploadTime : "Jan 3 2024 12:46:55 GMT+0200 (Eastern European Standard Time)")), "HH:mm")} 
                                    </TableBodyCell>
                                    <TableBodyCell>
                                        {format(new Date(request.lastModifiedDate ? request.lastModifiedDate : "Jan 3 2024 12:46:55 GMT+0200 (Eastern European Standard Time)"), "dd MMM yyy")} {"  "}
                                                    
                                        {format(new Date(new Date(request.lastModifiedDate ? request.lastModifiedDate : "Jan 3 2024 12:46:55 GMT+0200 (Eastern European Standard Time)")), "HH:mm")} 
                                    </TableBodyCell>
                                    <TableBodyCell>
                                        {request.status}  
                                    </TableBodyCell>
                                </RespTableRow>
                            ))}
                        </RespTableBody>
                    }
                </RespTable>
            </WrapTable>
        </BottomContentContainer>
    )
}

export default RequestsInVerificationTable;