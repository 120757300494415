import {
  Container,
  Header,
  HeaderInfo,
  Title,
  Content,
  CardContainer,
  ReportTracker,
  LeftSection,
  RightSection,
  CardInfoContainer,
  Analytics,
  ClosingRate,
  AnalyticsHeader,
  RateHeaderText,
  RateSmallText,
  TimeCardContainer,
  BottomTimeCards,
  TopTimeCards,
  Status,
  StatusBox,
  StatusItem,
  StatusImage,
  StatusInfo,
  StatusWrapper,
  StatusItemTitle,
  DocsSection,
  DocItem,
  DocTitle,
  DocImage,
  DocInfo,
  DocNumber,
  DownloadBtn,
  DownloadIcon,
} from "styles/client/Report.styled";
import data from "data/report.json";
import { Chart } from "react-google-charts";
import {
  alertStatusCardsData,
  bottomTimeCardsData,
  cardsData,
  infoCardData,
  topTimeCardsData,
  transactionStatusCardsData,
} from "constants/reports";
import { DATA, DOUGHNUT_DATA } from "constants/clientMenu";
import { REPORT_XLSX_DATA } from "constants/index";
import Cards from "./cards/Cards";
import InfoCard from "./cards/InfoCard";
import TimeCards from "./cards/TimeCards";
import { useAppData } from "../../hooks/useAppData";

const scannedDocsIcon = `${process.env.PUBLIC_URL}/assets/ScannedDocIcon.svg`;
const transactionIcon = `${process.env.PUBLIC_URL}/assets/TransactionIcon.svg`;
const downloadIcon = `${process.env.PUBLIC_URL}/assets/downloadIcon.svg`;

type Props = {
  cardsData: typeof cardsData;
  infoCardData: typeof infoCardData;
  transactionStatusCardsData: typeof transactionStatusCardsData;
  alertStatusCardsData: typeof alertStatusCardsData;
};

function Reports({
  cardsData,
  infoCardData,
  transactionStatusCardsData,
  alertStatusCardsData,
}: Props) {
  const { colors } = useAppData();

  const doughnutOptions = {
    pieHole: 0.4,
    is3D: false,
    colors: [colors.PRIMARY[700], colors.PRIMARY[10]],
  };

  const options = {
    title: "",
    legend: { position: "top", maxLines: 2 },
    colors: [colors.PRIMARY[400], colors.PRIMARY[600], colors.SECONDARY[100], colors.YELLOW[400]],
    interpolateNulls: false,
  };

  const exportData = () => {
    const link = document.createElement("a");
    link.href = REPORT_XLSX_DATA;
    link.download = "report.xlsx";
    link.click();
  };

  return (
    <Container>
      <Header>
        <Title>Reports</Title>
        <HeaderInfo>Keep track of requests and their activities</HeaderInfo>
      </Header>
      <Content>
        <CardContainer>
          {cardsData.map((data, index) => (
            <Cards key={index} num={data.num} data={data.data as object} testId="card-data" />
          ))}
        </CardContainer>
        <ReportTracker>
          {/* left section */}
          <LeftSection>
            <Analytics>
              <AnalyticsHeader>Analytics Report</AnalyticsHeader>
              <Chart chartType="Histogram" width="100%" data={DATA} options={options} />
            </Analytics>
            <CardInfoContainer>
              {infoCardData.map((data, index) => (
                <InfoCard
                  key={index}
                  icon={data.icon}
                  num={data.num}
                  name={data.name}
                  testId="info-card"
                />
              ))}
            </CardInfoContainer>
          </LeftSection>
          {/* right section */}
          <RightSection>
            <ClosingRate>
              <RateHeaderText>Closing Rate</RateHeaderText>
              <RateSmallText>Check your score</RateSmallText>
              <Chart
                chartType="PieChart"
                data={DOUGHNUT_DATA}
                options={doughnutOptions}
                width="100%"
              />
            </ClosingRate>
            <TimeCardContainer>
              <TopTimeCards>
                {topTimeCardsData.map((item, index) => (
                  <TimeCards key={index} title={item.title} icon={item.icon} time={item.time} />
                ))}
              </TopTimeCards>
              <BottomTimeCards>
                {bottomTimeCardsData.map((item, index) => (
                  <TimeCards key={index} title={item.title} icon={item.icon} time={item.time} />
                ))}
              </BottomTimeCards>
            </TimeCardContainer>
          </RightSection>
        </ReportTracker>
        <Status>
          <StatusWrapper>
            <StatusItemTitle>Transaction Status</StatusItemTitle>
            <StatusBox>
              {transactionStatusCardsData.map((item, index) => (
                <StatusItem key={index} data-testid="transaction-status">
                  <StatusImage src={item.img} />
                  <StatusInfo color={item.color}>{item.data}</StatusInfo>
                </StatusItem>
              ))}
            </StatusBox>
          </StatusWrapper>
          <StatusWrapper>
            <StatusItemTitle>Alert Status</StatusItemTitle>
            <StatusBox>
              {alertStatusCardsData.map((item, index) => (
                <StatusItem key={index} data-testid="alert-status">
                  <StatusImage src={item.img} />
                  <StatusInfo color={item.color}>{item.data}</StatusInfo>
                </StatusItem>
              ))}
            </StatusBox>
          </StatusWrapper>
        </Status>
        <DocsSection>
          <DocItem>
            <DocTitle>
              Scanned
              <br /> Documents
            </DocTitle>
            <DocInfo>
              <DocImage src={scannedDocsIcon} />
              <DocNumber>{data.scannedDocuments}</DocNumber>
            </DocInfo>
          </DocItem>
          <DocItem>
            <DocTitle>
              Scanning <br /> Transactions
            </DocTitle>
            <DocInfo>
              <DocImage src={transactionIcon} />
              <DocNumber>{data.scanningTransactions}</DocNumber>
            </DocInfo>
          </DocItem>
        </DocsSection>
        <DownloadBtn onClick={exportData}>
          <DownloadIcon src={downloadIcon} />
          Download
        </DownloadBtn>
      </Content>
    </Container>
  );
}

export default Reports;
