import styled from "styled-components";
import { SEARCH_CLASS_TYPE } from "constants/filter";

export const Container = styled.div`
    max-width: 100%;
    margin-bottom: 20px;
    padding: 12px;
`;

export const Title = styled.div`
    font-size: 30px;
    font-weight: 700;
    width: 100%;
    margin-top: 40px;
    text-align: center;
`;

export const QHolder = styled.form`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    margin-top: 40px;
`;

export const QRow = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #EAECF0;
    justify-content: space-around;
    align-items: center;
`;

export const SubmitBtnHolder = styled.div`
  width: 20%;
  margin: 30px auto 30px;
`;

export const FormLabel = styled.label`
  color: #364073;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  width: 100%;
  padding: 10px;
  text-wrap: wrap;
`;

export const InputWrapper = styled.div<{ classType?: SEARCH_CLASS_TYPE }>`
  display: flex;
  align-items: center;
  background-color: ${({ classType }) =>
    classType === SEARCH_CLASS_TYPE.PRIMARY && "rgba(54, 64, 115, 0.1)"};
  border: ${({ classType }) => classType === SEARCH_CLASS_TYPE.DEFAULT && "1px solid #D0D5DD"};
  padding: 13px 10px;
  border-radius: 6px;
  flex-basis: 100%;
  column-gap: 10px;
  width: 100%;
`;

export const Input = styled.input<{ classType?: SEARCH_CLASS_TYPE, border?: boolean}>`
  font-weight: 400;
  font-size: ${({ classType }) => (classType === SEARCH_CLASS_TYPE.DEFAULT ? "16px" : "12px")};
  outline: none;
  border: ${({ border }) => (border ? "1px solid red" : "none")};
  height: 56px;
  color: #000000;
  background: #F8F8F8;
  width: 100%;
  font-size: 16px;
  flex: 1;
  padding: 3px 40px 0 15px;
  &:focus {
    outline: 1px solid #4A82A6;
  }
`;

export const SectionTitle = styled.div`
  font-size: 28px;
  color: white;
  background: linear-gradient(180deg,#4A82A6 90%,#5ECCD9 116.67%);
  padding: 6px;
`;
export const SectionSubTitle = styled.div`
  width: 20%;
`;

export const SubSectionHolder = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

export const QuestionsHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;