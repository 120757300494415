import {
  barclaysIcon,
  defaultIcon,
  hsbcIcon,
  swiftIcon,
} from "styles/notificationTab/NotificationReader.styled";

// object literal that returns an icon based on the name of the notification object
export const avatarSelection: {
  HSBC: string;
  Barclays: string;
  Swift: string;
  T360: string;
} = {
  HSBC: hsbcIcon,
  Barclays: barclaysIcon,
  Swift: swiftIcon,
  T360: defaultIcon,
};
