import { useState, useEffect, RefObject, Dispatch, SetStateAction } from "react";

/**
 * Custom hook that monitors the click on an item or outside the item.
 * This function is useful especially when the item is a dropdown menu.
 * @param {RefObject<HTMLDivElement>} referencedObj the item that you want to enable/disable
 * @param {boolean} initialState the state of the item (active/not-active)
 */
export const useDetectOutsideClick = (
  referencedObj: RefObject<HTMLDivElement>,
  initialState: boolean,
) => {
  const [isActive, setIsActive] = useState<boolean>(initialState);

  // used the following article as inspiration:
  // https://letsbuildui.dev/articles/building-a-dropdown-menu-component-with-react-hooks
  useEffect(() => {
    const pageClickEvent = (e: Event) => {
      // If the active element exists and is clicked outside of
      if (
        referencedObj.current !== null &&
        !referencedObj.current.contains(e.target as HTMLInputElement)
      ) {
        setIsActive(!isActive);
      }
    };

    // if the item is active, then listen for clicks
    if (isActive) {
      window.addEventListener("click", pageClickEvent);
    }

    // do a cleanup: unset the event listener once the dropdown is closed
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [isActive, referencedObj]);

  const returnValue: [boolean, Dispatch<SetStateAction<boolean>>] = [isActive, setIsActive];
  return returnValue;
};
