import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  padding: 32px;
`;

export const Title= styled.div`
  color: rgb(54, 64, 115);
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
`;

export const FundsContainer= styled.div`
  margin-top: 50px;
`;