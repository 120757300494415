/* Google Cloud buckets names */
export const ASPECT_FILES_PATH = "PID-data";
export const ASPECT_FILES_SOURCE_BUCKET_NAME = "data-repository-ssnc";
export const FINANCIAL_GROUP_CACHE_DESTINATION_BUCKET_NAME = "preview-cache-ssnc";
export const MAIN_CACHE_BUCKET_NAME = "main-cache-ssnc";
export const VALET_PARKING_RULES_BUCKET_NAME = "valet-rules-ssnc";
export const FUNDS_DATA_REPOSITORY_BUCKET_NAME = "transactional-data-repository-ssnc";

/* Google Cloud Workload */
export const WORKLOAD_READ_SERVICE_ACCOUNT =
  "workload-read-only@ssnc-407816.iam.gserviceaccount.com";

/* Google Cloud file path names */
export const VALET_PARKING_RULES_PATH = "5PID-rules.json";
export const FUND_MANAGERS_LIST_PATH = "funds-PID/v7";
export const CRTS_CCIDS_BUYLISTS_PATH= "CRTs-CCIDs-Buylists/crts";
export const FUNDS_REQUESTS_UPLOADED_PATH= "uploaded_requests_from_hubwise"
export const HUBWISE_FUNDS_DETAILS_PATH= "funds/v2";
export const FUNDS_PATH = "funds-PID/v7";
export const SUB_FUNDS_PATH = "funds-PID/v1/sub-funds";
export const FUNDS_DOCUMENTS_PATH = "funds-PID/v1/funds-documents";
export const AGENT_CODES_FILE_PATH= "agent-codes";
export const DELTAS_FILE_PATH= "CRTs-CCIDs-Buylists/buylists/deltas";
export const ARCHIVE_FILE_PATH= "CRTs-CCIDs-Buylists/buylists/archive";
