import { FunctionComponent } from "react";
import ModalComponent from "components/modal";
import useModal from "hooks/useModal";
import {
  AliceRabbitImage,
  CancelButton,
  CloseButton,
  Icons,
  ModalContent,
  TextOne,
  TextTwo,
} from "styles/admin/AliceRequest.styled";
import { FilterByDate, FilterByStatus, FilterByModified } from "components/filter/Index";
import useFilters from "hooks/useFilter";
import { DataPropsS } from "types";
import { Search } from "../filter/Search";
import AdminTable from "./table";
import {
  Container,
  Wrapper,
  Header,
  HeaderTextBig,
  HeaderActions,
  HeaderTextSmall,
  HeaderText,
  Content,
  TableContentContainer,
  SearchWrapper,
  closeIcon,
  rabbitImage,
} from "../../styles/admin/AliceRequest.styled";
import { RedirectAliceData, NewAliceRedirectDataType } from "types";

const AliceRequest: FunctionComponent<{ 
  data: DataPropsS[]; 
  isParty?: boolean; 
  redirectData? : RedirectAliceData;
  setRedirectData?: React.Dispatch<React.SetStateAction<NewAliceRedirectDataType | undefined>>;
}> = ({
  data,
  isParty,
  redirectData,
  setRedirectData
}) => {
  const modal = useModal();
  const { filteredData, setFilter } = useFilters(data);
  const setData = (str: string) => {
    setFilter({ search: str });
  };
  return (
    <Container data-testid="container">
      <Wrapper>
        <Header>
          <HeaderText>
            <HeaderTextBig>ALICE Requests</HeaderTextBig>
            <HeaderTextSmall>Keep track of requests and their activities</HeaderTextSmall>
          </HeaderText>

          <HeaderActions>
            <FilterByDate setFilter={setFilter} />
            <FilterByModified setFilter={setFilter} />
            <FilterByStatus setFilter={setFilter} />
          </HeaderActions>
        </Header>
        <Content>
          <SearchWrapper>
            <Search setData={setData} />
          </SearchWrapper>
          <TableContentContainer>
            {data?.length > 0 && (
              <AdminTable data={filteredData} isParty={isParty} redirectData = {redirectData} setRedirectData = {setRedirectData}/>
            )}
          </TableContentContainer>
        </Content>
        <ModalComponent controller={modal}>
          <ModalContent>
            <CancelButton>
              <Icons onClick={modal.close} src={closeIcon} />
            </CancelButton>
            <AliceRabbitImage src={rabbitImage} />
            <TextOne>Thanks for asking ALICE</TextOne>
            <TextTwo>We will respond to you within 24 hours.</TextTwo>
            <CloseButton onClick={modal.close}>Close</CloseButton>
          </ModalContent>
        </ModalComponent>
      </Wrapper>
    </Container>
  );
};

export default AliceRequest;
