// --- [START] Styled Components ---
import { BaseSyntheticEvent, MouseEvent, useRef, useState } from "react";
import {
  CancelButton,
  Container,
  WarningActionRow,
  DeleteButton,
  Description,
  DocumentsTitle,
  DragDropDocuments,
  DropContainer,
  Dropdown,
  DropdownItem,
  FileCard,
  FileDetails,
  FileDetailsContainer,
  FileGrid,
  FileName,
  FileSize,
  UploadImg,
  UploadTitle,
  SaveButton,
  SelectContainer,
  SelectedValue,
  Subtitle,
  SupportedFiles,
  BrowseContainer,
  BrowseLink,
  WarningContainer,
  WarningDetails,
  WarningMessage,
  WarningTitle,
  HiddenFileInput,
  RetryButton,
  OrText,
  dropdownIcon,
  closeIcon,
  fileIcon,
  uploadIcon,
  warningIcon,
  UploadError,
} from "styles/DocumentUpload.styled";
import { allowedFileTypes, dropdownItems } from "constants/alert";
import Title from "../Title";
import {
  AlertFeedbackType,
  DocumentType,
  OcrDocResponseItem,
  SelectedDocument,
} from "../../../types";
import SuccessfulUpload from "../SucessfulUpload";
import ConfirmSubmitUpload from "../ConfirmSubmitUpload";
import EditConfirmSubmitUpload from "../EditConfirmSubmitUpload";
import useModal from "../../../hooks/useModal";
import AlertFeedback from "../AlertFeedback";

function DocumentsUpload({
  onSubmit,
  ocrResponse,
}: {
  onSubmit: (array: SelectedDocument[]) => void;
  ocrResponse?: OcrDocResponseItem[];
}) {
  const fileUploadLimit = 20000000; // 20 MB file upload limit for Nanonets
  const [showDropdown, setShowDropdown] = useState(false);
  const uploadSuccessModal = useModal();
  const alertFeedbackModal = useModal<AlertFeedbackType>();
  const alertStatusModal = useModal();
  const confirmUploadModal = useModal();
  const [editParameter, setEditParameter] = useState("");
  const editConfirmUploadModal = useModal();

  const [documentType, setDocumentType] = useState(dropdownItems[0]);
  const dropdownRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedDocuments, setSelectedDocuments] = useState<SelectedDocument[]>([]);
  const [showWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSetDocumentType = (doc: DocumentType) => {
    setDocumentType(doc);
    setShowDropdown(false);
  };

  const hasInvalidFormat = (files: FileList) => {
    return (
      files.length !==
      [...files]
        .filter((file) => allowedFileTypes.includes(file.type))
        .map((file) => ({ type: documentType.value, file })).length
    );
  };

  const hasInvalidSize = (files: FileList) => {
    return files.length !== [...files].filter((file) => file.size <= fileUploadLimit).length;
  };

  const validateAndFilterFiles = (newFiles: FileList) => {
    if (hasInvalidFormat(newFiles)) {
      setErrorMessage("Supported file types: JPEG, JPG, PNG, PDF");
    } else if (hasInvalidSize(newFiles)) {
      setErrorMessage("Max file size: 20 MB");
    } else {
      setSelectedDocuments([
        ...selectedDocuments,
        ...[...newFiles].map((file) => ({ type: documentType.value, file })),
      ]);
    }
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const dt = event.dataTransfer;
    validateAndFilterFiles(dt.files);
  };
  const handleDragOver = (event: BaseSyntheticEvent) => {
    event.preventDefault();
  };
  const handleDragLeave = (event: BaseSyntheticEvent) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: BaseSyntheticEvent) => {
    event.preventDefault();
  };

  const handleDeleteFile = (file: File) => {
    setSelectedDocuments(selectedDocuments.filter((f) => f.file !== file));
  };

  const handleSave = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (selectedDocuments.length === 0) {
      setErrorMessage("Please upload a document before submitting the form.");
    } else {
      uploadSuccessModal.open();
    }
  };

  const handleBrowseClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
    setErrorMessage("");
  };

  const handleInputFileChange = (event: BaseSyntheticEvent) => {
    if (event.target.files) {
      validateAndFilterFiles(event.target.files);
    }
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const convertSize = (size: number) => {
    if (size > 1000000) {
      return `${(size / 1000000).toFixed(2)}MB`;
    }
    if (size > 1000) {
      return `${(size / 1000).toFixed(2)}KB`;
    }
    return `${size.toFixed(2)}B`;
  };

  const openEditConfirmUpload = (currentParameter: string) => {
    setEditParameter(currentParameter);
    confirmUploadModal.close();
    editConfirmUploadModal.open();
  };

  const saveEditConfirmUpload = () => {
    editConfirmUploadModal.close();
    confirmUploadModal.open();
  };

  const openAlert = () => {
    uploadSuccessModal.close();
    alertStatusModal.open();
  };

  return (
    <Container>
      <ConfirmSubmitUpload
        controller={confirmUploadModal}
        editButtonAction={openEditConfirmUpload}
        nextButtonAction={openAlert}
      />
      <EditConfirmSubmitUpload
        controller={editConfirmUploadModal}
        currentParameter={editParameter}
        saveButtonAction={saveEditConfirmUpload}
      />

      <SuccessfulUpload controller={uploadSuccessModal} alertController={alertFeedbackModal} />
      <AlertFeedback controller={alertFeedbackModal} />

      <Title text="Onboarding" />
      <Subtitle>Provide your business documents</Subtitle>
      <Description>
        Please upload your business document and provide additional information related to your
        business
      </Description>
      <DocumentsTitle>Document to Upload</DocumentsTitle>
      <SelectContainer ref={dropdownRef} onClick={() => setShowDropdown(!showDropdown)}>
        <SelectedValue>{documentType.name || "Select Document"}</SelectedValue>
        <img src={dropdownIcon} alt="Dropdown icon" />
      </SelectContainer>
      {showDropdown && (
        <Dropdown width={dropdownRef.current ? dropdownRef.current.offsetWidth : 0}>
          {dropdownItems.map((item) => (
            <DropdownItem onClick={() => handleSetDocumentType(item)} key={item.value}>
              {item.name}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
      <UploadTitle>Uploaded Documents</UploadTitle>
      {selectedDocuments && (
        <FileGrid>
          {selectedDocuments.map(({ file }, index) => (
            <FileCard key={`file_${index}`}>
              <DeleteButton>
                <img
                  src={closeIcon}
                  onClick={() => handleDeleteFile(file)}
                  alt="Delete document icon"
                />
              </DeleteButton>
              <FileDetailsContainer>
                <img src={fileIcon} alt="Uploaded file icon" />
                <FileDetails>
                  <FileName>{file.name}</FileName>
                  <FileSize>{convertSize(file.size)}</FileSize>
                </FileDetails>
              </FileDetailsContainer>
            </FileCard>
          ))}
        </FileGrid>
      )}
      <HiddenFileInput ref={inputRef} type="file" onChange={handleInputFileChange} />
      {!showWarning && (
        <DropContainer
          onDrop={handleFileDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDragEnter={handleDragEnter}
        >
          <UploadImg src={uploadIcon} alt="Upload document icon" />
          <DragDropDocuments>Drag and drop documents</DragDropDocuments>
          <SupportedFiles>
            Supported file types: JPEG, JPG, PNG, PDF. Max file size: 20 MB
          </SupportedFiles>
          <BrowseContainer>
            <OrText>Or</OrText>
            <BrowseLink onClick={handleBrowseClick}>Browse</BrowseLink>
          </BrowseContainer>
        </DropContainer>
      )}
      {showWarning && (
        <WarningContainer>
          <img src={warningIcon} alt="Warning icon related to upload documents issues" />
          <WarningDetails>
            <WarningTitle>We are so sorry!</WarningTitle>
            <WarningMessage>
              There was an error and the file could not be uploaded. Would you like to try again?
            </WarningMessage>
            <WarningActionRow>
              <CancelButton>Cancel</CancelButton>
              <RetryButton>Retry</RetryButton>
            </WarningActionRow>
          </WarningDetails>
        </WarningContainer>
      )}
      <UploadError>{errorMessage}</UploadError>
      <SaveButton onClick={(event) => handleSave(event)}>Save and Continue</SaveButton>
    </Container>
  );
}

export default DocumentsUpload;
