import styled from "styled-components";

export const BuylistUpdatesContainer = styled.div<{boxShadow?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 12px;
`;

export const BuylistUpdatesTitle = styled.div`
  width: 100%;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
`;

export const NavContainer = styled.div`
  width: 60%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  border: 1px solid #808080;
  border-radius: 8px;
  padding: 10px;
`;

export const NavItem = styled.div<{ isActive?: boolean}>`
  width: 100%;
  @media (max-width: 769px) {
    width: 100%;
  }
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: ${(props) => props.isActive ? "#4A82A6" : "#808080"};
  border-bottom: ${(props) => props.isActive ? "3px solid #4A82A6" : "none"};
  border-bottom-radius: 5px;
  cursor: pointer;
  text-wrap: wrap;
`;

export const BottomContainer = styled.div`
    width: 96%;
    margin: 0;
    padding: 12px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    max-height: 250px; 
    overflow-y: auto;
`;

export const UpdatedContainer= styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
`;

export const UpdateItem= styled.div`
    font-size: 17px;
    text-align: left;
    color: #364073;
    margin-bottom: 5px;
    text-align: justify;
    font-weight: 550;
`;
export const UpdateItemValue = styled.div`
    font-size: 16px;
    text-align: left;
    color: #808080;
`;
export const UpdateItemContainer= styled.div<{backgroundRed?: boolean}>`
    display: grid;
    grid-template-columns: 60% 40% 1fr;
    border-radius: 12px;
    padding: 10px;
    gap: 20px;
`;