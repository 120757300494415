import React, { FunctionComponent, useEffect, useState } from "react";
import { 
    TableContentContainer,
    Container,
    Title,
    TableBodyCell,
    RespTableRow,
    RespTableBody,
    TableHeaderCell,
    RespTableHeader,
    RespTable,
    WrapTable,
    Status,
    HeaderActions,
    SearchWrapper
 } from "styles/funds/AllfundsRequests.styled";
import {callGCFunctionWIthAccessToken} from "services/callGCFunction";
import {
  FUNDS_DATA_REPOSITORY_BUCKET_NAME,
  HUBWISE_FUNDS_DETAILS_PATH
} from "config/googleCloudConfig";
import { getAccessToken } from "services/auth0/auth0";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";
import LoadingSpinner from "components/LoadingSpinner";
import NoDataContent from "components/noData/NoDataContent";
import { AllfundsRequestsType } from "types/funds";
import { FilterByAllfundsRequestStatus, FilterByRequestDate } from "components/filter/Index";
import useFilters from "hooks/useFilter";
import { Search } from "../filter/Search"

const HubwiseAllfundsRequestsComponent: FunctionComponent =  () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [allfundsRequests, setAllfundsRequests] = useState<AllfundsRequestsType[]>([]);
    const token = getAccessToken();
    const { filteredData, setFilter } = useFilters(allfundsRequests);

    useEffect(()=>{
        if(token) {
            setLoading(true);
            const fileName= "all_allfunds_requests.json";
            const payload = {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName": fileName,
                "filePath": `${HUBWISE_FUNDS_DETAILS_PATH}`
            }
            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: AllfundsRequestsType[]) => {
                setAllfundsRequests(data);
                setLoading(false);
            })
        }   
    },[token])

    const setData = (str: string) => {
        setFilter({ search: str });
    };

    return loading ? <LoadingSpinnerDiv><LoadingSpinner /></LoadingSpinnerDiv> :
        allfundsRequests ?
            <Container>  
                <Title>Allfunds Requests</Title>   
                <HeaderActions>
                    <FilterByRequestDate setFilter={setFilter} />
                    <FilterByAllfundsRequestStatus setFilter={setFilter} />
                </HeaderActions> 
                <SearchWrapper>
                    <Search setData={setData} />
                </SearchWrapper>
                <TableContentContainer>
                    <WrapTable>
                        <RespTable>
                            <RespTableHeader >
                                <RespTableRow>
                                    <TableHeaderCell>Request ID</TableHeaderCell> 
                                    <TableHeaderCell>Request Date</TableHeaderCell>
                                    <TableHeaderCell>ISIN</TableHeaderCell> 
                                    <TableHeaderCell>Fund Name</TableHeaderCell>
                                    <TableHeaderCell>Fund House Name</TableHeaderCell>
                                    <TableHeaderCell>Fund Activation date</TableHeaderCell>
                                    <TableHeaderCell>Request Status</TableHeaderCell>
                                    <TableHeaderCell>Request Substatus</TableHeaderCell>
                                    <TableHeaderCell>Distributor Code</TableHeaderCell>
                                    <TableHeaderCell>Distributor Name</TableHeaderCell>
                                    <TableHeaderCell>Fund set up in Allfunds</TableHeaderCell>
                                    <TableHeaderCell>Investment Amount</TableHeaderCell>
                                    <TableHeaderCell>Minimum Investment Amount</TableHeaderCell>
                                    <TableHeaderCell>Product</TableHeaderCell>
                                    <TableHeaderCell>Settlement Period (Redemptions)</TableHeaderCell>
                                    <TableHeaderCell>Settlement Period (Subscription)</TableHeaderCell>
                                    <TableHeaderCell>Transfer Amount</TableHeaderCell>
                                    <TableHeaderCell>Fund economic status</TableHeaderCell>
                                    <TableHeaderCell>Fund operational restrictions</TableHeaderCell>
                                    <TableHeaderCell>Fund distribution restrictions</TableHeaderCell>
                                    <TableHeaderCell>Depositary</TableHeaderCell>
                                    <TableHeaderCell>Cut-Off Time</TableHeaderCell>
                                    <TableHeaderCell>Comments</TableHeaderCell>
                                    <TableHeaderCell>Account status</TableHeaderCell> 
                                    <TableHeaderCell>Client authorised</TableHeaderCell> 
                                </RespTableRow>
                            </RespTableHeader>
                            {!(filteredData?.length === 0 || !filteredData) ? 
                                <RespTableBody>
                                    {filteredData.map((request: AllfundsRequestsType, requestIndex: number) => 
                                    (
                                        <React.Fragment key={requestIndex}>                                    
                                            <RespTableRow>
                                                <TableBodyCell>{request["Request ID"]}</TableBodyCell>
                                                <TableBodyCell>{request["Request Date"]}</TableBodyCell>
                                                <TableBodyCell>{request["ISIN Code"]}</TableBodyCell>
                                                <TableBodyCell>{request["Fund Name"]}</TableBodyCell>
                                                <TableBodyCell>{request["Fund House Name"]}</TableBodyCell>
                                                <TableBodyCell>{request["Fund Activation date"]}</TableBodyCell>
                                                <TableBodyCell>
                                                    <Status status={request["Request Status"] === "IN PROGRESS" ? "INPROGRESS" : request["Request Status"] === "COMPLETED - FUND ACTIVE" ? "COMPLETED" : request["Request Status"] === "CANCELLED" ? "CANCELLED" : ""}>
                                                    {request["Request Status"] && request["Request Status"].toLowerCase()}
                                                    </Status>
                                                    
                                                </TableBodyCell>
                                                <TableBodyCell>{request["Request Substatus"]}</TableBodyCell>
                                                <TableBodyCell>{request["Distributor Code"]}</TableBodyCell>
                                                <TableBodyCell>{request["Distributor Name"]}</TableBodyCell>
                                                <TableBodyCell>{request["Fund set up in Allfunds"]}</TableBodyCell>
                                                <TableBodyCell>{request["Investment Amount"]}</TableBodyCell>
                                                <TableBodyCell>{request["Minimum Investment Amount"]}</TableBodyCell>
                                                <TableBodyCell>{request.Product}</TableBodyCell>
                                                <TableBodyCell>{request["Settlement Period (Redemptions)"]}</TableBodyCell>
                                                <TableBodyCell>{request["Settlement Period (Subscription)"]}</TableBodyCell>
                                                <TableBodyCell>{request["Transfer Amount"]}</TableBodyCell>
                                                <TableBodyCell>{request["Fund economic status"]}</TableBodyCell>
                                                <TableBodyCell>{request["Fund operational restrictions"]}</TableBodyCell>
                                                <TableBodyCell>{request["Fund distribution restrictions"]}</TableBodyCell>
                                                <TableBodyCell>{request.Depositary}</TableBodyCell>
                                                <TableBodyCell>{request["Cut-Off Time"]}</TableBodyCell>
                                                <TableBodyCell>{request.Comments}</TableBodyCell>
                                                <TableBodyCell>{request["Account status"]}</TableBodyCell>
                                                <TableBodyCell>{request["Client authorised"]}</TableBodyCell>
                                            </RespTableRow>                                    
                                        </React.Fragment>
                                        
                                    ))}
                                </RespTableBody>
                                :
                                <NoDataContent />
                            }
                        </RespTable>
                    </WrapTable>
                </TableContentContainer>  
            </Container>
        : 
            <NoDataContent />
    
};

export default HubwiseAllfundsRequestsComponent;