import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import LandingPageMenu from "../../components/landingPageMenu/LandingPageMenu";

function Home() {
  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.HOME_PAGE);
  });

  return (
    <div className="Home">
      <LandingPageMenu />
    </div>
  );
}
export default Home;
