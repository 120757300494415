// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for displaying (or not) a table in the Party Profile's Hierarchy section
 */
import { useState, useEffect, useCallback } from "react";
import {
  TableContentContainer,
  RespTable,
  RespTableHeader,
  TableHeaderCell,
  RespTableBody,
  RespTableRow,
  TableBodyCell,
} from "styles/tableContent/TableContent.styled";
import { PATH } from "constants/index";
import AliceModalTooltip from "components/AliceModalTooltip";
import {
  CyData,
  CyEdge,
  HierarchyTableRow,
  HierarchyTableRowEntity,
} from "../../../types/cytoscape";
import { FilterTable } from "../../filter/FilterTable";
import { WrapTable } from "../../../styles/FilterableTable.styled";
import { search } from "../../../utils/search";

function DisplayHierarchyRow({ rowData }: { rowData: HierarchyTableRow }) {
  let displayRow = false;

  // check if there's at least one column of useful data. If yes, show the row. [0] is the name, [1] is the field.
  if (
    Object.entries(rowData).some(
      (rowNameFieldCouple: [string, string | number | HierarchyTableRowEntity]) =>
        rowNameFieldCouple[1] && rowNameFieldCouple[1] !== "",
    )
  ) {
    displayRow = true;
  }

  return displayRow ? (
    <RespTableRow>
      {Object.entries(rowData).map(([name, field]: [string, string | any], j) =>
        name === "shareholder" || name === "subsidiary" ? (
          <TableBodyCell key={j}>
            <AliceModalTooltip selectedField={field.name} selectedKey={name} isSmall>
              <a href={`${PATH.PARTY}/${field.id}`} target="_blank" rel="noreferrer">
                {field.name}
              </a>
            </AliceModalTooltip>
          </TableBodyCell>
        ) : (
          <TableBodyCell key={j}>
            {field ? (
              <AliceModalTooltip selectedField={field} selectedKey={name} isSmall>
                {field}
              </AliceModalTooltip>
            ) : (
              "-"
            )}
          </TableBodyCell>
        ),
      )}
    </RespTableRow>
  ) : null;
}

export function TableHierarchyContent({ data }: { data: CyData }) {
  const [filter, setFilter] = useState("");
  const [displayTable, setDisplayableData] = useState(false);

  const [rowsToDisplay, setRowsToDisplay] = useState<HierarchyTableRow[]>([]);

  const [filteredData, setFilteredData] = useState<HierarchyTableRow[]>([]);

  const tableHead: HierarchyTableRow = rowsToDisplay[0];

  const handleSearchChange = useCallback(() => {
    const searchedData = search<HierarchyTableRow>(rowsToDisplay, filter);

    setFilteredData(searchedData);
  }, [filter, rowsToDisplay]);

  useEffect(() => {
    handleSearchChange();
  }, [handleSearchChange]);

  useEffect(() => {
    // function to get nodes name, by comparing node id to edge source/target
    const name = (source: string) =>
      data.nodes.find((item) => item.data.id === source)?.data?.name || "";

    // restructure data for table display
    const restructuredData = data.edges.map((item: CyEdge) => {
      const shareHolderName = name(item.data.source);
      const subsidiaryName = name(item.data.target);
      return {
        shareholder: {
          id: item.data.source,
          name: shareHolderName || "",
        },
        subsidiary: {
          id: item.data.target,
          name: subsidiaryName || "",
        },
        direct: item.data.direct,
        directFO: item.data.directFO || "" || undefined,
        total: item.data.total,
        totalFO: item.data.totalFO || "" || undefined,
      };
    });

    // this is the main data, this data will not be affected so we can always fallback to it
    setRowsToDisplay(restructuredData);

    // this data will be affected on search change
    setFilteredData(restructuredData);

    // check if there's at least one table cell of useful data. If yes, show the table.
    data.edges.forEach((item) => {
      if (
        Object.entries(item).some(
          (nameFieldCouple: [string, string | any]) =>
            nameFieldCouple[1] && nameFieldCouple[1] !== "",
        )
      ) {
        setDisplayableData(true);
      }
    });
  }, [data.edges, data.nodes]);

  return displayTable ? (
    <TableContentContainer>
      <FilterTable setData={setFilter} />
      <WrapTable>
        <RespTable>
          <RespTableHeader>
            <RespTableRow>
              {tableHead &&
                Object.entries(tableHead).map(([rowName]: [string, string | any], j) => {
                  switch (rowName) {
                    case "shareholder":
                      return <TableHeaderCell key={j}>Shareholder</TableHeaderCell>;
                    case "subsidiary":
                      return <TableHeaderCell key={j}>Subsidiary</TableHeaderCell>;
                    case "direct":
                      return <TableHeaderCell key={j}>Direct</TableHeaderCell>;
                    case "directFO":
                      return <TableHeaderCell key={j}>D. Figure Only</TableHeaderCell>;
                    case "total":
                      return <TableHeaderCell key={j}>Total</TableHeaderCell>;
                    case "totalFO":
                      return <TableHeaderCell key={j}>T. Figure Only</TableHeaderCell>;
                    default:
                      return null;
                  }
                })}
            </RespTableRow>
          </RespTableHeader>
          <RespTableBody>
            {filteredData.map((item: HierarchyTableRow, i: number) => (
              <DisplayHierarchyRow rowData={item} key={i} />
            ))}
          </RespTableBody>
        </RespTable>
      </WrapTable>
    </TableContentContainer>
  ) : null;
}
