import styled from "styled-components";
import CloseIcon from "@material-ui/icons//Close";

export const searchIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-search-blue.svg`;
export const askAliceIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-ask-alice-blue.svg`;
export const alertIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-alert-blue.svg`;
export const notificationIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-notifications-blue.svg`;
export const adminRoomsIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-admin-rooms-blue.svg`;

export const Container = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ show }) => (show ? 0 : "-100%")};
  height: 100%;
  width: 40%;
  background: ${({ theme }) => theme.colors.WHITE};
  transition: all 0.6s ease-in-out;
  padding: 10px;
  box-shadow: ${({ theme }) => `1px 1px 1px ${theme.colors.GRAY[50]}`};
`;

export const Content = styled.div`
  height: 100%;
  color: ${({ theme }) => theme.colors.DARK[500]};
  a {
    text-decoration: none;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  margin-right: 10px;
  margin-bottom: 20px;
  font-size: 2rem !important;
  color: ${({ theme }) => theme.colors.DARK[500]};
  @media (min-width: 768px) {
    display: none !important;
  }
`;

export const Text = styled.p`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.DARK[500]};
`;

export const IconsSeparator = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const IconsContainer = styled.div<{ iconUrl: string }>`
  width: 24px;
  height: 24px;
  background-image: ${(props) => `url(${props.iconUrl})`};
  cursor: pointer;
  background-size: cover;
`;
