import { SEARCH_CLASS_TYPE } from "constants/filter";
import type { FunctionComponent } from "react";
import { FilterContainer } from "styles/filter/FilterTable.styled";
import { FilterTableProps } from "types/filterableTable";
import { Search } from "./Search";

export const FilterTable: FunctionComponent<FilterTableProps> = ({ setData }) => {
  return (
    <FilterContainer>
      <Search setData={setData} classType={SEARCH_CLASS_TYPE.PRIMARY} />
    </FilterContainer>
  );
};
