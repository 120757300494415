import styled from "styled-components";

export const MonitoredItemContainer = styled.div`
    width: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`;

export const MonitordItemPairHolder = styled.div`
    display: grid;
    grid-template-columns: 50% 50% 1fr;
    align-items: center;
`;

export const MonitoredItemLabel = styled.p`
    color: #364073;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    width: 50%;
    padding: 10px;
    text-wrap: wrap;
    @media (max-width: 769px) {
        width: 95%;
    }
`;

export const MonitoredItemValue= styled.p`
    font-weight: 400;
    font-size: 16px;
    outline: none;
    color: #000000;
`;