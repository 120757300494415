import { useState, useEffect } from "react";
import { FunctionComponent } from "react";
import {
    Container,
    Title,
    QHolder,
    QRow,
    SubmitBtnHolder,
    FormLabel,
    InputWrapper,
    Input,
    SectionTitle,
    SectionSubTitle,
    SubSectionHolder,
    QuestionsHolder
} from "../../styles/esg/EsgQuestionnaire.styled";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";
import NoData from "components/noData/NoData";
import { Accordion } from "components/party/accordion/Accordion";

type IndexedType<T> = {
    [Key in keyof T]: T[Key]
};

const EsgQuestionnaireComponent: FunctionComponent<{data?: any}> =  ({data}) => {

    const [formValues, setFormValues] = useState<IndexedType<typeof data>>({});

    useEffect(() => {
        setFormValues(data);
    }, [data]);

    const handleInputChange = (label: string, value: string) => {
        setFormValues(prevState => ({ ...prevState, [label]: value }));
    };

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        //TODO: Send formValues to backend
        console.log("Form submitted:", formValues);
    };

    return (
        <Container>
            <Title>ESG Questionnaire</Title>
            {formValues && Object.entries(formValues).length > 0 ? 
                <>
                    <QHolder onSubmit={handleSubmit}>
                        {Object.entries(formValues).map(([section]) => (
                            <Accordion title={section} opened noTopBorder borderBottom>
                                <>
                                    {Object.entries(formValues[`${section}`]).map(([subsection]) => (
                                        <>
                                            <SectionTitle>{subsection}</SectionTitle>
                                            {Object.entries(formValues[`${section}`][`${subsection}`]).map(([subSubSection]) => (
                                                <SubSectionHolder>
                                                    <SectionSubTitle>{subSubSection}</SectionSubTitle>
                                                    <QuestionsHolder>
                                                        {Object.entries(formValues[`${section}`][`${subsection}`][`${subSubSection}`]).map(([label, value]) => (
                                                            <>
                                                                <FormLabel htmlFor={label}>{label}</FormLabel>
                                                                <InputWrapper>
                                                                    <Input
                                                                        type="text"
                                                                        id={label}
                                                                        value={value as string}
                                                                        onChange={(e) => handleInputChange(label, e.target.value)}
                                                                    />
                                                                </InputWrapper>
                                                            </>                                                         
                                                        ))}
                                                    </QuestionsHolder>                                                    
                                                </SubSectionHolder>
                                            ))}
                                        </>
                                    ))}
                                </>
                            </Accordion>
                        ))}
                    </QHolder>
                    <SubmitBtnHolder>
                        <SubmitButton
                            onClick={handleSubmit}
                            text={"Submit"}
                            type={"submit"}
                            disabled={false}
                        />
                    </SubmitBtnHolder>
                </> 
                : <NoData /> 
            }
        </Container>
    );
};

export default EsgQuestionnaireComponent;