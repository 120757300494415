import { FunctionComponent, useState, MouseEvent, useRef, BaseSyntheticEvent } from "react";
import { 
    BottomContainer,
    UploadRequestsContainer,
    SaveButton
} from "styles/funds/Onboarding.styled";
import ClientRequestsOnboardingTable from "./ClientRequestsOnboardingTable";
import FundDetailsConfirmationComponent from "components/detailsConfirmationForm/DetailsConfirmationForm";
import fundDataRequestMockTemplate from "../../../data/fundDataRequestMockTemplate.json";
import { ClientRequestTemplateType } from "types/party";
import { Accordion } from "components/party/accordion/Accordion";
import {
    DeleteButton,
    DragDropDocuments,
    DropContainer,
    FileCard,
    FileDetails,
    FileDetailsContainer,
    FileGrid,
    FileName,
    FileSize,
    UploadImg,
    SupportedFiles,
    BrowseContainer,
    BrowseLink,
    HiddenFileInput,
    OrText,
    closeIcon,
    fileIcon,
    uploadIcon,
    UploadError,
  } from "styles/DocumentUpload.styled";
import { AlertFeedbackType } from "types";
import SuccessfulFundRequestsUpload from "components/alert/SucessfulFundRequestsUpload";
import ConfirmSubmitUpload from "components/alert/ConfirmSubmitUpload";
import useModal from "../../../hooks/useModal";
import { allowedClientRequestTemplateFileTypes } from "constants/alert";
import { UPLOAD_FILES } from "config/googleCloudFunctionsConfig";
import { callGCFunctionWIthAccessTokenForFiles } from "services/callGCFunction";
import { getAccessToken } from "services/auth0/auth0";
import RequestsInVerificationTable from "./RequestsInVerificationTable";
import { FreshDeskTicketIndexType } from "types/party";

const NavBox: FunctionComponent<{
    crtData: ClientRequestTemplateType[];
    requestsData: FreshDeskTicketIndexType[];
    setUploaded: React.Dispatch<React.SetStateAction<boolean>>
}> =  ({
    crtData,
    requestsData,
    setUploaded
}) => {
    const fileUploadLimit = 20000000; 
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [showWarning] = useState(false);
    const confirmUploadModal = useModal();
    const alertFeedbackModal = useModal<AlertFeedbackType>();
    const alertStatusModal = useModal();
    const uploadSuccessModal = useModal();
    const inputRef = useRef<HTMLInputElement>(null);
    const token = getAccessToken();

    const hasInvalidFormat = (files: FileList) => {
        return (
          files.length !==
          [...files]
            .filter((file) => allowedClientRequestTemplateFileTypes.includes(file.type))
            .map(file => file ).length
        );
    };

    const hasInvalidSize = (files: FileList) => {
        return files.length !== [...files].filter((file) => file.size <= fileUploadLimit).length;
    };

    const validateAndFilterFiles = (newFiles: FileList) => {
        if (hasInvalidFormat(newFiles)) {
          setErrorMessage("Supported file types: text/csv, .xls");
        } else if (hasInvalidSize(newFiles)) {
          setErrorMessage("Max file size: 20 MB");
        } else {
            setSelectedFiles([
                ...selectedFiles,
                ...[...newFiles].filter((file)=> {
                    if(selectedFiles.length > 0) {
                        return !selectedFiles.some(selectedFile => selectedFile.name === file.name && selectedFile.size === file.size )
                    } else {
                        return file
                    }
                })
            ]);
            setErrorMessage("")
        }
    };

    const handleInputFileChange = (event: BaseSyntheticEvent) => {
        if (event.target.files) {
            validateAndFilterFiles(event.target.files);
          }
          if (inputRef.current) {
            inputRef.current.value = "";
        }
    }

    const convertSize = (size: number) => {
        if (size > 1000000) {
            return `${(size / 1000000).toFixed(2)}MB`;
        }
        if (size > 1000) {
            return `${(size / 1000).toFixed(2)}KB`;
        }
        return `${size.toFixed(2)}B`;
    };

    const handleDeleteFile = (file: File) => {
        setSelectedFiles(selectedFiles.filter((f: File) => f !== file));
        setErrorMessage("")
    };

    const openAlert = () => {
        uploadSuccessModal.close();
        alertStatusModal.open();
    };

    const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const dt = event.dataTransfer;
        validateAndFilterFiles(dt.files);
    };
    const handleDragOver = (event: BaseSyntheticEvent) => {
        event.preventDefault();
    };
    const handleDragLeave = (event: BaseSyntheticEvent) => {
        event.preventDefault();
    };
    const handleDragEnter = (event: BaseSyntheticEvent) => {
        event.preventDefault();
    };

    const handleBrowseClick = () => {
        if (inputRef.current) {
          inputRef.current.click();
        }
        setErrorMessage("");
    };

    const handleSave = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (selectedFiles.length === 0) {
          setErrorMessage("Please upload a document before submitting the form.");
        } else {
            const formData= new FormData();
            selectedFiles.forEach(file => formData.append("files", file))
            callUploadFunction(formData)
            uploadSuccessModal.open();
            setSelectedFiles([]);
            setErrorMessage("")
        }
    };

    const callUploadFunction = (formData: FormData) => {
        if (token) {
            callGCFunctionWIthAccessTokenForFiles(token, UPLOAD_FILES, formData, "test", "test")
            .then(() => setUploaded(true))
            
        }
    }
    
    return (            
        <BottomContainer>
            <Accordion title="Upload Fund Request">
                <div>  
                    <ConfirmSubmitUpload
                        controller={confirmUploadModal}
                        editButtonAction={()=>null}
                        nextButtonAction={openAlert}
                    />
                    <SuccessfulFundRequestsUpload controller={uploadSuccessModal} alertController={alertFeedbackModal} />
                    <UploadRequestsContainer>
                        <HiddenFileInput ref={inputRef} type="file" accept=".csv, .xlsx" multiple onChange={handleInputFileChange} />
                        {!showWarning && (
                            <DropContainer
                                onDrop={handleFileDrop}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDragEnter={handleDragEnter}
                            >
                            <UploadImg src={uploadIcon} alt="Upload document icon" />
                            <DragDropDocuments>Drag and drop documents</DragDropDocuments>
                            <SupportedFiles>
                                Supported file types: CSV or XLSX/ XLS. Max file size: 20 MB
                            </SupportedFiles>
                            <BrowseContainer>
                                <OrText>Or</OrText>
                                <BrowseLink onClick={handleBrowseClick}>Browse</BrowseLink>
                            </BrowseContainer>
                            </DropContainer>
                        )}
                        {selectedFiles && (
                            <FileGrid>
                                {selectedFiles.map(( file, index) => (
                                    <FileCard key={`file_${index}`}>
                                    <DeleteButton>
                                        <img
                                        src={closeIcon}
                                        onClick={() => handleDeleteFile(file)}
                                        alt="Delete document icon"
                                        />
                                    </DeleteButton>
                                    <FileDetailsContainer>
                                        <img src={fileIcon} alt="Uploaded file icon" />
                                        <FileDetails>
                                        <FileName>{file.name}</FileName>
                                        <FileSize>{convertSize(file.size)}</FileSize>
                                        </FileDetails>
                                    </FileDetailsContainer>
                                    </FileCard>
                                ))}
                            </FileGrid>
                        )}
                        <UploadError>{errorMessage}</UploadError>
                        <SaveButton onClick={(event) => handleSave(event)}>Save and Continue</SaveButton>
                    </UploadRequestsContainer>
                </div>
            </Accordion>
            <Accordion title="Uploaded Files Status" opened>
                <RequestsInVerificationTable data={requestsData}/>
            </Accordion>
            <Accordion title="Fund Request" opened>
                <ClientRequestsOnboardingTable data={crtData}/>
            </Accordion>

            {/* <BottomCompContainer>
                {tab === ONBOARDING_TAB.FUND_REQUEST && <ClientRequestsOnboardingTable data={data}/>}  
                {tab === ONBOARDING_TAB.FUND_LINK && <FundDetailsConfirmationComponent data={fundDataRequestMockTemplate[0]} allFundsData={[]} title="Fund Data Request" hideProgress hideSubmit hideTitleSection hideNavSection/>}   
            </BottomCompContainer> */}
        </BottomContainer>
    )
};

export default NavBox;