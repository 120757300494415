import { Container, SignOutHolder } from "../../styles/noAccess/NoAccess.styled";
import SignOut from "components/alert/SignOut";
import useModal from "hooks/useModal";
import {
    MenuItem,
    MenuItemIcon,
    MenuItemText,   
    signoutIcon,
  } from "../../styles/admin/MobileMenu.styled";
import { PATH } from "constants/index";

function NoAccessComponent() {

    const signOutModal = useModal();
    const openSignOutModal = () => {
        close();
        signOutModal.open();
    };

return (
    <>
        <SignOut controller={signOutModal} />
        <SignOutHolder>
            <MenuItem onClick={openSignOutModal}>
                <MenuItemIcon iconURL={signoutIcon} path={PATH.SIGNOUT} />
                <MenuItemText>Sign Out</MenuItemText>
            </MenuItem>
        </SignOutHolder>
        <Container>ERROR: You do not have access to this page. Please sign out and contact the administrator.</Container>
    </>
    );
}

export default NoAccessComponent;
  