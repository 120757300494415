import { useEffect, useState } from "react";
import DropDownContent from "components/admin/table/DropDownContent";
import {
  DropDownWrapper,
  DropDown,
  DropDownName,
  InnerCover,
  Left,
  Right,
  Icon,
  ArrowDownIcon,
  calendarIcon,
  arrowDown,
  statusIcon,
} from "styles/FilterSearch.styled";
import { 
  DATE_RANGE, 
  DATE_RANGES, 
  FILTER_STATUS, 
  FILTER_TEAM, 
  STATUS, 
  HUBWISE_CATEGORY, 
  FILTER_CATEGORY, 
  ALLFUNDS_REQUEST_DATE, 
  ALLFUNDS_REQUEST_RANGES,
  FILTER_ALLFUNDS_REQUEST_STATUS,
  FILTER_ALLFUNDS_REQUEST_STATUSES 
} from "constants/filter";
import { FilterProps } from "types/filterableTable";

export function FilterByDate({ setFilter }: FilterProps) {
  const [dateOpen, setDateOpen] = useState<boolean>(false);
  const [dateRangeSelected, setDateRangeSelected] = useState<string>(DATE_RANGE.DEFAULT);

  useEffect(() => {
    setFilter({ date: dateRangeSelected });
  }, [dateRangeSelected]);

  return (
    <DropDownWrapper>
      <DropDown onClick={() => setDateOpen(!dateOpen)} role="button">
        <InnerCover>
          <Left>
            <Icon src={calendarIcon} />
            <DropDownName>
              {dateRangeSelected === DATE_RANGE.DEFAULT ? "Date created" : dateRangeSelected}
            </DropDownName>
          </Left>
          <Right>
            <ArrowDownIcon src={arrowDown} />
          </Right>
        </InnerCover>
      </DropDown>
      {dateOpen && (
        <DropDownContent
          onSelect={setDateRangeSelected}
          setOpen={setDateOpen}
          items={DATE_RANGES}
        />
      )}
    </DropDownWrapper>
  );
}

export function FilterByRequestDate({ setFilter }: FilterProps) {
  const [dateOpen, setDateOpen] = useState<boolean>(false);
  const [dateRangeSelected, setDateRangeSelected] = useState<string>(ALLFUNDS_REQUEST_DATE.DEFAULT);

  useEffect(() => {
    setFilter({ allfundsRequestDate: dateRangeSelected });
  }, [dateRangeSelected]);

  return (
    <DropDownWrapper>
      <DropDown onClick={() => setDateOpen(!dateOpen)} role="button">
        <InnerCover>
          <Left>
            <Icon src={calendarIcon} />
            <DropDownName>
              {dateRangeSelected === ALLFUNDS_REQUEST_DATE.DEFAULT ? "Date requested" : dateRangeSelected}
            </DropDownName>
          </Left>
          <Right>
            <ArrowDownIcon src={arrowDown} />
          </Right>
        </InnerCover>
      </DropDown>
      {dateOpen && (
        <DropDownContent
          onSelect={setDateRangeSelected}
          setOpen={setDateOpen}
          items={ALLFUNDS_REQUEST_RANGES}
        />
      )}
    </DropDownWrapper>
  );
}

export function FilterByModified({ setFilter }: FilterProps) {

  const [dateOpen, setDateOpen] = useState<boolean>(false);
  const [dateRangeSelected, setDateRangeSelected] = useState<string>(DATE_RANGE.DEFAULT);

  useEffect(() => {
    setFilter({ modified: dateRangeSelected });
  }, [dateRangeSelected]);

  return (
    <DropDownWrapper>
      <DropDown onClick={() => setDateOpen(!dateOpen)} role="button">
        <InnerCover>
          <Left>
            <Icon src={calendarIcon} />
            <DropDownName>
              {dateRangeSelected === DATE_RANGE.DEFAULT ? "Date modified" : dateRangeSelected}
            </DropDownName>
          </Left>
          <Right>
            <ArrowDownIcon src={arrowDown} />
          </Right>
        </InnerCover>
      </DropDown>
      {dateOpen && (
        <DropDownContent
          onSelect={setDateRangeSelected}
          setOpen={setDateOpen}
          items={DATE_RANGES}
        />
      )}
    </DropDownWrapper>
  );
}

export function FilterByStatus({ setFilter }: FilterProps) {
  const [statusSelected, setStatusSelected] = useState<string>(STATUS.DEFAULT);
  const [statusOpen, setStatusOpen] = useState<boolean>(false);

  useEffect(() => {
    setFilter({ status: statusSelected });
  }, [statusSelected]);

  return (
    <DropDownWrapper>
      <DropDown onClick={() => setStatusOpen(!statusOpen)} role="button">
        <InnerCover>
          <Left>
            <Icon src={statusIcon} />
            <DropDownName>
              {statusSelected === STATUS.DEFAULT ? "Status" : statusSelected}
            </DropDownName>
          </Left>
          <Right>
            <ArrowDownIcon src={arrowDown} />
          </Right>
        </InnerCover>
      </DropDown>
      {statusOpen && (
        <DropDownContent
          onSelect={setStatusSelected}
          setOpen={setStatusOpen}
          items={FILTER_STATUS}
        />
      )}
    </DropDownWrapper>
  );
}

export function FilterByAllfundsRequestStatus({ setFilter }: FilterProps) {
  const [statusSelected, setStatusSelected] = useState<string>(FILTER_ALLFUNDS_REQUEST_STATUS.DEFAULT);
  const [statusOpen, setStatusOpen] = useState<boolean>(false);

  useEffect(() => {
    setFilter({ allfundsRequestStstus: statusSelected });
  }, [statusSelected]);

  return (
    <DropDownWrapper>
      <DropDown onClick={() => setStatusOpen(!statusOpen)} role="button">
        <InnerCover>
          <Left>
            <Icon src={statusIcon} />
            <DropDownName>
              {statusSelected === FILTER_ALLFUNDS_REQUEST_STATUS.DEFAULT ? "Status" : statusSelected}
            </DropDownName>
          </Left>
          <Right>
            <ArrowDownIcon src={arrowDown} />
          </Right>
        </InnerCover>
      </DropDown>
      {statusOpen && (
        <DropDownContent
          onSelect={setStatusSelected}
          setOpen={setStatusOpen}
          items={FILTER_ALLFUNDS_REQUEST_STATUSES}
        />
      )}
    </DropDownWrapper>
  );
}

export function FilterByTeam({ setFilter }: FilterProps) {
  const [teamSelected, setTeamSelected] = useState<string>(STATUS.DEFAULT);
  const [statusOpen, setStatusOpen] = useState<boolean>(false);

  useEffect(() => {
    setFilter({ team: teamSelected });
  }, [teamSelected]);

  return (
    <DropDownWrapper>
      <DropDown onClick={() => setStatusOpen(!statusOpen)} role="button">
        <InnerCover>
          <Left>
            <Icon src={statusIcon} />
            <DropDownName>
              {teamSelected === STATUS.DEFAULT ? "Team" : teamSelected}
            </DropDownName>
          </Left>
          <Right>
            <ArrowDownIcon src={arrowDown} />
          </Right>
        </InnerCover>
      </DropDown>
      {statusOpen && (
        <DropDownContent
          onSelect={setTeamSelected}
          setOpen={setStatusOpen}
          items={FILTER_TEAM}
        />
      )}
    </DropDownWrapper>
  );
}

export function FilterByCategory({ setFilter }: FilterProps) {
  const [categorySelected, setCategorySelected] = useState<string>(HUBWISE_CATEGORY.DEFAULT);
  const [statusOpen, setStatusOpen] = useState<boolean>(false);

  useEffect(() => {
    setFilter({ category: categorySelected });
  }, [categorySelected]);

  return (
    <DropDownWrapper>
      <DropDown onClick={() => setStatusOpen(!statusOpen)} role="button">
        <InnerCover>
          <Left>
            <Icon src={statusIcon} />
            <DropDownName>
              {categorySelected === HUBWISE_CATEGORY.DEFAULT ? "All Requests" : categorySelected}
            </DropDownName>
          </Left>
          <Right>
            <ArrowDownIcon src={arrowDown} />
          </Right>
        </InnerCover>
      </DropDown>
      {statusOpen && (
        <DropDownContent
          onSelect={setCategorySelected}
          setOpen={setStatusOpen}
          items={FILTER_CATEGORY}
        />
      )}
    </DropDownWrapper>
  );
}
