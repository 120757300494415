import {
    ExecutiveSummaryTitle,
    ReportDetailsContainer,
    DetailSection
} from "styles/client/Report.styled";
import { FunctionComponent } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ReportDetailedType } from "types/funds";
import NoDataContent from "components/noData/NoDataContent";
import ReportTable from "./ReportTable";

const ReportDetails : FunctionComponent<{
    reportDetailsData: ReportDetailedType | undefined
}> =  ({
    reportDetailsData
}) => {
   
    return (
        <ReportDetailsContainer>
            <DetailSection>
                <ExecutiveSummaryTitle>Additions</ExecutiveSummaryTitle>
                {reportDetailsData?.fm_additions.length === 0 ? <NoDataContent />:
                    <ReportTable reportDetailsData={reportDetailsData?.fm_additions}/>
                }
            </DetailSection>
            <DetailSection>
                <ExecutiveSummaryTitle>Removals</ExecutiveSummaryTitle>
                {reportDetailsData?.fm_removals.length === 0 ? <NoDataContent />:
                    <ReportTable reportDetailsData={reportDetailsData?.fm_removals}/>
                }
            </DetailSection>
            <DetailSection>
                <ExecutiveSummaryTitle>Updates</ExecutiveSummaryTitle>
                {reportDetailsData?.modifications.length === 0 ? <NoDataContent />:
                    <ReportTable reportDetailsData={reportDetailsData?.modifications}/>
                }
            </DetailSection>
        </ReportDetailsContainer>
    );
}
  
export default ReportDetails;
  