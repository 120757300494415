// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

import { CustomSubmitButton } from "styles/alertAirtableTesting/SubmitButton.styled";
import { ButtonType } from "../../types";

/**
 * Component dedicated for submit buttons.
 * @param text: button text content that has to be displayed
 * @param onClick: function that handles the default onClick
 * @param type: each button has a type with the following possible values: "button", "submit", "reset" or undefined
 * @param disabled: boolean value that indicates if the button should be disabled (a click would have no effect)
 */
function SubmitButton({
  text,
  onClick,
  type,
  disabled,
}: {
  text: string;
  onClick: (event: any) => void;
  type: ButtonType;
  disabled: boolean;
}) {
  return (
    <CustomSubmitButton onClick={onClick} type={type} disabled={disabled}>
      {text}
    </CustomSubmitButton>
  );
}

export default SubmitButton;
