import styled from "styled-components";

export const hsbcIcon = `${process.env.PUBLIC_URL}/assets/notification-hsbc-icon.svg`;
export const barclaysIcon = `${process.env.PUBLIC_URL}/assets/notification-barclays-icon.svg`;
export const swiftIcon = `${process.env.PUBLIC_URL}/assets/notification-swift-icon.svg`;
export const defaultIcon = `${process.env.PUBLIC_URL}/assets/notification-default-icon.svg`;

export const Container = styled.div`
  padding: 10px 20px;
  color: #333;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const AvatarIcon = styled.div<{ iconURL: string }>`
  margin: 0 10px 0 0;
  width: 40px;
  height: 40px;
  background-image: ${(props) => `url(${props.iconURL})`};
  background-size: 40px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const MessageContainer = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  color: ${({ theme }) => theme.colors.DARK[400]};
`;

export const TimeStamp = styled.div`
  position: absolute;
  right: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  color: ${({ theme }) => theme.colors.DARK[400]};
`;

export const DailyContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
`;

export const DayContainer = styled.div`
  margin: 20px 0 20px 20px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.DARK[400]};
`;
