import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  white-space: nowrap;
  display: block;
  overflow-x: auto;
`;
export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
export const Thead = styled.thead`
  margin-bottom: 13px;
  background-color: ${({ theme }) => theme.colors.PRIMARY[15]};
`;
export const Tbody = styled.tbody<{ hide?: boolean }>`
  display: ${({ hide }) => hide && "none"};
`;
export const Th = styled.th`
  font-size: 13px;
  line-height: 18px;
  font-family: Roboto Condensed;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 700;
  padding: 24px;
`;
export const Td = styled.td<{ align?: string }>`
  font-size: 13px;
  font-family: Roboto Condensed;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  line-height: 20px;
  padding: 0 24px;
`;
export const SpecialTd = styled.td`
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto Condensed";
  height: 142px;
  text-align: left;
  vertical-align: top;
  padding: 0 20px;
  padding-top: 16px;
  max-width: 100% !important;
  width: 100% !important;
`;

export const InnerTd = styled.div`
  display: inline-block;
  margin-right: 5px;
`;
export const SpecialTr = styled.tr<{ infoOpen: boolean }>`
  display: ${(props) => (props.infoOpen ? "content" : "none")};
`;
export const Tr = styled.tr<{ body?: boolean; noBorderBottom?: boolean }>`
  text-align: left;
  height: ${(props) => (props.body === true ? "72px" : "")};
  cursor: ${(props) => (props.body === true ? "pointer" : "")};
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
`;
export const PaginationButton = styled.button`
  border: ${({ theme }) => `1px solid ${theme.colors.GREYED[300]}`};
  border-radius: 8px;
  font-size: 14px;
  height: 36px;
  width: 78px;
  background-color: white;
  color: ${({ theme }) => theme.colors.GREYED[300]};
  line-height: 20px;
  font-weight: 700;
  font-family: "Roboto Condensed";
`;
export const PaginationPosition = styled.div``;
export const ModalContainer = styled.div``;
export const EmptySpan = styled.span`
  display: flex;
  align-items: center;
`;
export const SpanWrapper = styled.span``;
export const TableTime = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.GREYED[600]};
`;
export const TableDate = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #101828;
`;
export const Input = styled.input`
  margin-top: 5px;
  padding: 7px 0;
  width: 100%;
  border: none;
  box-shadow: none;
  outline: 0;
  color: ${({ theme }) => theme.colors.DARK[500]};
`;

export const NoResult = styled.div<{ show?: boolean }>`
  height: 100px;
  display: ${({ show }) => (show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: Roboto Condensed;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  color: #295880;
`;

export const SubTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  color: rgba(0,119,200,1.0);
`;

export const SubHeader = styled.div``;

export const SubContent = styled.div<{ maxHeight?: string }>`
  padding: 20px 10px;
  max-height: ${({ maxHeight }) => maxHeight || "65vh"};
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SubHeaderNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

export const Button = styled.button`
  padding: 5px 15px;
  cursor: pointer;
`;

const animatedLoadingBar = keyframes`
    0% {
    left:0;
    right:100%;
    width:0;
  }
  10% {
    left:0;
    right:75%;
    width:25%;
  }
  90% {
    right:0;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0;
    width:0;
  }
`;
export const LoaderBar = styled.div`
  position: absolute;
  border-radius: 10px;
  height: 2px;
  top: 95%;
  right: 100%;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => `${theme.colors.PRIMARY[400]}`};
  width: 0;
  animation: ${animatedLoadingBar} 2s linear infinite;
`;

export const FormValue = styled.p`
  color: #808080;
  font-size: 16px;
  margin-top: 0;
`;

export const AddButton = styled.button<{ disabled?: boolean}>`
  border: 1px solid ${({ theme }) => theme.colors.GRAY[300]};
  border-radius: 8px;
  font-size: 14px;
  height: 36px;
  background-color: ${(props) => (props.disabled === true ? "#EDEFEF" : "#fff" )};
  cursor: ${(props) => (props.disabled === true ? "" : "pointer" )};
  color: ${({ theme }) => theme.colors.GRAY[700]};
  line-height: 20px;
  font-weight: 700;
  font-family: "Roboto Condensed";
  margin-left: 20px;
  @media (max-width: 769px) {
    height: 46px;
  }
`;
