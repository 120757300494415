import {
  Container,
  Item,
  Value,
  Field,
  FieldItem,
  FieldListItem,
  List,
} from "styles/party/Afme.styled";
import data from "data/afme.json";
import { PARTY_MAP } from "constants/index";
import { useParams } from "react-router-dom";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { PartyDetails } from "types";
import { ContactInformation } from "types/party";
import { TRACKER } from "constants/tracker";

function Afme({ partyId, partyData }: { partyId: string; partyData: PartyDetails }) {
  const { id } = useParams();

  const isDeutscheBank = id === PARTY_MAP.deutsche_bank_party;

  // show N/A if party is not deutsche bank
  // NOTE: this is because we are currently using deutsche bank dummy data for this page
  const DATA: typeof data = isDeutscheBank
    ? data
    : data.map((datum) => ({ name: datum.name, fields: "N/A" }));

  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PARTY_PROFILE, TRACKER.PARTY_PROFILE_AFME, {
      party_name: (partyData["Contact Information"] as ContactInformation).Name,
      party_id: partyId,
    });
  });

  return (
    <Container>
      {DATA.map((item, index) => (
        <Item key={index}>
          <Value>{item.name}</Value>
          <Field
            legal={item.name === "Respondent information"}
            isArray={Array.isArray(item.fields)}
          >
            {Array.isArray(item.fields) ? (
              item.fields.map((field, index) => (
                <FieldListItem key={index}>
                  {typeof field === "string"
                    ? field
                    : Object.entries(field).map(([title]) => (
                        <List>
                          <div>{title}</div>
                        </List>
                      ))}
                </FieldListItem>
              ))
            ) : (
              <FieldItem>{item.fields}</FieldItem>
            )}
          </Field>
        </Item>
      ))}
    </Container>
  );
}

export default Afme;
