import styled from "styled-components";

export const CustomLoadingSpinner = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingSpinnerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  margin: auto;
`;
