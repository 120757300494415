// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the Funds Under Management Accordion content for Party Profile
 */

import React, { FunctionComponent, useEffect, useState, Dispatch, SetStateAction } from "react";
import { getAccessToken } from "services/auth0/auth0";
import { FundType, FundDocumentsType } from "types/funds";
import { NoDataToDisplay } from "components/noData/ComingSoon";
import { FUNDS_DATA_REPOSITORY_BUCKET_NAME, FUNDS_PATH, FUNDS_DOCUMENTS_PATH } from "config/googleCloudConfig";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import LoadingSpinner from "components/LoadingSpinner";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";
import { 
    CloseIcon,
    closeIcon,
    FundDetailsContainer,
    FundDetailsCloseIcon,
    CloseIconHolder,
    ManagedFundsTableHolder, 
    TableBodyCell,
    RespTableRow,
    RespTableBody,
    TableHeaderCell,
    RespTableHeader,
    RespTable,
    WrapTable
} from "styles/funds/FundManagerT360.styled";
import GroupedDetails from "./GroupedDetails";
import { FormValue } from "styles/party/funds/ManageFunds.styled";
import { Check } from "@material-ui/icons";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import NoDataContent from "components/noData/NoDataContent";
import { useFilters } from "react-table";
import { CrtFundsTableHolder } from "styles/funds/FundManagerList.styled";
import { FUND_STATUS } from "constants/hubwiseDashboard";
import { format } from "date-fns";

const ManagedFundsTable: FunctionComponent<{ 
  partyId: string;
  onboarding?: boolean;
  fundsToAdd?: {[key: string] : {[key: string]: {"Record Id": string; "ISIN": string, "SEDOL": string, selected?: boolean}[]}[]},
  setFundsToAdd?: Dispatch<SetStateAction<{[key: string] : {[key: string]: {"Record Id": string; "ISIN": string, "SEDOL": string, selected?:boolean}[]}[]}>>;
  clientId?: string | undefined;
  setAnyFundSelected?: Dispatch<SetStateAction<boolean>>;
  secondSetOfFilteredData?: FundType[];
  allSelected?: boolean;
  anyFundSelected?: boolean;
  fm?: boolean;
}> = ({
  partyId,
  onboarding,
  fundsToAdd,
  setFundsToAdd,
  clientId,
  setAnyFundSelected,
  secondSetOfFilteredData,
  allSelected,
  anyFundSelected,
  fm
}) => {
  const [loading, setLoading]= useState<boolean>(false);
  const [singleSelectedFund, setSingleSelectedFund]= useState<boolean>(false);
  const [selectedFundId, setSelectedFundId]= useState<string>();    
  const [fundDocuments, setFundDocuments] = useState<FundDocumentsType | undefined>();
  const [fundDataLoading, setFundDataLoading]= useState<boolean>(false);
  const [selectedFundData, setSelectedFundData]= useState<FundType>();
  const token  = getAccessToken();
  const [fundsData, setFundsData]= useState<FundType []>([]);

  const handleFundClick = (recordId: string) => {
    setSingleSelectedFund(true);
    setFundDataLoading(true);

    const fileName= `${recordId}.json`;
    const payload = {
    "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
    "fileName": fileName,
    "filePath": `${FUNDS_PATH}/${partyId}`
    }

    if(token) {
        callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
        .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((data) => {
            setSelectedFundData(data);
            setFundDataLoading(false);
        }).catch(()=>setFundDataLoading(false))   

        //TODO: funds documents are not yet properly retrieved, to be fixed: retrieve pdf files instead of json
        const fileNameForDocs = `${recordId}.json`;
        const payloadForFundsDocs = {
        "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
        "fileName": fileNameForDocs,
        "filePath": `${FUNDS_DOCUMENTS_PATH}/${recordId}`
        }

        if(token) {
            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payloadForFundsDocs, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data) => {
                setFundDocuments(data);
            }).catch((err)=>console.log("error: ", err))
        }
    }
  }

  useEffect(() => {    
    setLoading(true);
    if (token && partyId) {
        const indexFile = "index.json";
        const payload = {
            "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
            "fileName": indexFile,
            "filePath": `${FUNDS_PATH}/${partyId}`
        }

        callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
        .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((data) => {
            setFundsData(data);   
            setLoading(false);
        }).catch(()=>setLoading(false))
    }
  }, [token, partyId]);

  const handleClose = () => {
    setSingleSelectedFund(false);
    setSelectedFundData(undefined);
  }

  const handleCheckboxChange = (fund: FundType, PID: string, filteredData: boolean) => {
    if(filteredData === true) {
      setFundsData(()=>{
        if(secondSetOfFilteredData) {
          const newState= [...secondSetOfFilteredData];
          newState.map((fundL)=>{
            if(fundL["Record Id"] === fund["Record Id"]) {
              if(fundL.selected === true) {
                fundL.selected= false;
              } else if (fundL.selected === false) {
                fundL.selected= true;
              } else {
                fundL.selected= true;
              }
            }
          })
          return newState;
        }
        return []
      })
    } else {
      setFundsData((prevState)=>{
        const newState= [...prevState];
        newState.map((fundL)=>{
          if(fundL["Record Id"] === fund["Record Id"]) {
            if(fundL.selected === true) {
              fundL.selected= false;
            } else if (fundL.selected === false) {
              fundL.selected= true;
            } else {
              fundL.selected= true;
            }
          }
        })
        return newState;
      })
    }

    if(setFundsToAdd) {
      setFundsToAdd((prevState)=>{
        let updatedState= { ...prevState };
  
        if (clientId) {
          if(updatedState.hasOwnProperty(clientId)) {
            let itemFound= false;
            
            const newStateArray: {[key: string]: {"Record Id": string; "ISIN": string, "SEDOL": string, selected?: boolean}[]}[] = prevState[clientId].reduce((acc,item)=>{
              if (item[PID]) {
                itemFound= true;
                const updatedFundsList = item[PID].some((item) => item["Record Id"] === fund["Record Id"])
                  ? item[PID].filter((s) => s["Record Id"] !== fund["Record Id"])
                  : [...item[PID], {"Record Id": fund["Record Id"], ISIN: fund.ISIN, "SEDOL": fund.SEDOL, selected: true}];
  
                if (updatedFundsList.length > 0) {
                  acc.push({[PID]: updatedFundsList})
                }
              } else {
                acc.push(item);
              }
              return acc;
            }, [] as {[key: string]: {"Record Id": string; "ISIN": string, "SEDOL": string, selected?: boolean}[]}[])
  
            updatedState= { ...prevState,
              [clientId]: newStateArray
            }
  
            if(!itemFound) {
              updatedState= { ...prevState,
                [clientId]: [ ...prevState[clientId], { [PID]: [{"Record Id": fund["Record Id"], "ISIN": fund.ISIN, "SEDOL": fund.SEDOL}]}]
              }     
            }
          } else {
            updatedState= { ...prevState,
              [clientId]: [{ [PID]: [{"Record Id": fund["Record Id"], "ISIN": fund.ISIN, "SEDOL": fund.SEDOL}]}]
            };
          }
        }
        return updatedState;
      })
    }

   

    if(clientId && fundsToAdd && setAnyFundSelected){
      if(fundsToAdd[clientId] && fundsToAdd[clientId].length === 0){
        setAnyFundSelected(false);
      } else {
        setAnyFundSelected(true);
      }
    }
  }

  useEffect(()=>{
    if(anyFundSelected === false) {
      setFundsData((prevState)=>{
        const newState= [...prevState];
        newState.map((fundL)=>fundL.selected= false);
        return newState;
      })
    }
  },[anyFundSelected])

  useEffect(()=>{
    if(allSelected === true) {
      setFundsData((prevState)=>{
        const newState= [...prevState];
        newState.forEach((fund)=>fund.selected= true);
        return newState;
      })
    } else if (allSelected === false) {
      setFundsData((prevState)=>{
        const newState= [...prevState];
        newState.forEach((fund)=>fund.selected= false);
        return newState;
      })
    }
  },[allSelected])

  return (
    <CrtFundsTableHolder>
        {
        loading ? 
          <LoadingSpinnerDiv><LoadingSpinner /></LoadingSpinnerDiv> 
        : fundsData ? 
          !singleSelectedFund ? 
            <WrapTable>            
              <RespTable>
                  <RespTableHeader >
                      <RespTableRow>
                          {onboarding && <TableHeaderCell></TableHeaderCell>}
                          <TableHeaderCell>Asset</TableHeaderCell> 
                          <TableHeaderCell>ISIN</TableHeaderCell> 
                          <TableHeaderCell>SEDOL</TableHeaderCell>
                          <TableHeaderCell>FIGI</TableHeaderCell> 
                          {/* these fields are specific to client request template instruments table */}
                          {!fm && onboarding && <TableHeaderCell>Status</TableHeaderCell> }
                          {!fm && onboarding && <TableHeaderCell>Ring-fenced</TableHeaderCell> }
                          {!fm && onboarding && <TableHeaderCell>Type</TableHeaderCell> }
                          {!fm && onboarding && <TableHeaderCell>Direct/Allfunds</TableHeaderCell>}
                          {!fm && onboarding && <TableHeaderCell>Set Up Initials</TableHeaderCell>}
                          {!fm && onboarding && <TableHeaderCell>QC Initials</TableHeaderCell>}
                          {!fm && onboarding && <TableHeaderCell>Date</TableHeaderCell>}
                          {/* these fields are specific to fund manager profile instruments table */}
                          {!fm && !onboarding && <TableHeaderCell>Onboarded</TableHeaderCell>}
                          {!fm && !onboarding && <TableHeaderCell>Active</TableHeaderCell>}
                          {!fm && !onboarding && <TableHeaderCell>SMFKB Available</TableHeaderCell>}
                          {!fm && !onboarding && <TableHeaderCell>External</TableHeaderCell>}
                      </RespTableRow>
                  </RespTableHeader>     
                <RespTableBody>
                  {
                  secondSetOfFilteredData ? 
                    secondSetOfFilteredData.map((fund: FundType, index) => (
                      <React.Fragment key={index}>
                          <RespTableRow 
                              onClick={
                                handleFundClick ?
                                ()=>handleFundClick(fund["Record Id"]) : () => null}
                          >
                            {/* first cell is for the checkbox in onboarding */}
                            { onboarding && 
                              <TableBodyCell marginRight={secondSetOfFilteredData.length === 1 ? true : false}>
                                {fund.Status === "3" ? <Check /> :
                                  <FormControlLabel key={`${index}-${fund["Record Id"]}`} name="add" value={"Add to Fund Link"}  control={<Checkbox  color="primary" checked={fund.selected ? fund.selected : false} onClick={(e) => e.stopPropagation()} onChange={() => handleCheckboxChange(fund, partyId, true)}/> } label=""></FormControlLabel>
                                  }
                              </TableBodyCell>
                            }
                            <TableBodyCell >{fund["Fund Full Name"]}</TableBodyCell>
                            <TableBodyCell> {fund.ISIN}</TableBodyCell>
                            <TableBodyCell> {fund.SEDOL} </TableBodyCell>
                            {fund.FIGI ? 
                              <TableBodyCell>{fund.FIGI}</TableBodyCell> 
                              : 
                              <TableBodyCell>{"-"}</TableBodyCell> 
                            }
                            {!fm && onboarding && <TableBodyCell>
                              {
                                fund.Status === FUND_STATUS.TO_BE_REQUESTED ? "To Be Requested" : 
                                fund.Status === FUND_STATUS.AWAITING ? "Request Sent to FM" : 
                                fund.Status === FUND_STATUS.CONFIRMED ? "Confirmed Available by FM" :
                                fund.Status === FUND_STATUS.REQUESTED_BY_INSTRUMENT_M || fund.Status === FUND_STATUS.REQUESTED_FROM_FM ? "Information Requested by FM" :
                                "-"
                              }
                            </TableBodyCell>}
                            {!fm && onboarding && <TableBodyCell>{fund["Ring-fenced"] === "true" ? "Yes" : "No"}</TableBodyCell>}
                            {!fm && onboarding && <TableBodyCell>{fund["Instrument Type"] ? fund["Instrument Type"] : "-"}</TableBodyCell>}
                            {!fm && onboarding && <TableBodyCell>{fund.Direct === "true" ? "Direct" : "Allfunds"}</TableBodyCell>}
                            {!fm && onboarding && <TableBodyCell>{"-"}</TableBodyCell>}
                            {!fm && onboarding && <TableBodyCell>{"-"}</TableBodyCell>}
                            {!fm && onboarding && 
                              <TableBodyCell>
                                {format(new Date(fund["Last Update Datetime"] ? fund["Last Update Datetime"] : "Jan 3 2024 12:46:55 GMT+0200 (Eastern European Standard Time)"), "dd MMM yyy")} {"  "}
                              </TableBodyCell>
                            }
                            {!fm && !onboarding && <TableBodyCell>{"TBC"}</TableBodyCell>}
                            {!fm && !onboarding && <TableBodyCell>{"TBC"}</TableBodyCell>}
                            {!fm && !onboarding && <TableBodyCell>{"TBC"}</TableBodyCell>}
                            {!fm && !onboarding && <TableBodyCell>{"TBC"}</TableBodyCell>}
                          </RespTableRow>
                      </React.Fragment>
                    ))
                  : fundsData.length > 0 ? 
                    fundsData.map((fund: FundType, index) => (
                        <React.Fragment key={index}>
                            <RespTableRow 
                              onClick={
                                handleFundClick ? 
                                ()=>handleFundClick(fund["Record Id"]) : () => null}
                            >
                              { onboarding && 
                                <TableBodyCell marginRight={fundsData.length === 1 ? true : false}>
                                  <FormValue>
                                    <FormControlLabel key={`${index}-${fund["Record Id"]}`} name="add" value={"Add to Fund Link"}  control={<Checkbox color="primary"  checked={fund.selected ? fund.selected : false} onClick={(e) => e.stopPropagation()} onChange={() => handleCheckboxChange(fund, partyId, false)}/> } label=""></FormControlLabel>
                                  </FormValue>  
                                </TableBodyCell>
                              }
                              <TableBodyCell >{fund["Fund Full Name"]}</TableBodyCell>
                              <TableBodyCell> {fund.ISIN}</TableBodyCell>
                              <TableBodyCell> {fund.SEDOL} </TableBodyCell> 
                              {fund.FIGI ? 
                                <TableBodyCell>{fund.FIGI}</TableBodyCell> 
                                : 
                                <TableBodyCell>{"-"}</TableBodyCell> 
                              }
                              { !fm && onboarding && 
                                <TableBodyCell>
                                  {
                                    fund.Status === FUND_STATUS.TO_BE_REQUESTED ? "To Be Requested" : 
                                    fund.Status === FUND_STATUS.AWAITING ? "Request Sent to FM" : 
                                    fund.Status === FUND_STATUS.CONFIRMED ? "Confirmed Available by FM" :
                                    fund.Status === FUND_STATUS.REQUESTED_BY_INSTRUMENT_M || fund.Status === FUND_STATUS.REQUESTED_FROM_FM ? "Information Requested by FM" :
                                    "-"
                                  }
                                </TableBodyCell>
                              }
                              {!fm && onboarding && <TableBodyCell>{fund["Ring-fenced"] === "true" ? "Yes" : "No"}</TableBodyCell>}
                              {!fm && onboarding && <TableBodyCell>{fund["Instrument Type"] ? fund["Instrument Type"] : "-"}</TableBodyCell>}
                              {!fm && onboarding && <TableBodyCell>{fund.Direct === "true" ? "Direct" : "Allfunds"}</TableBodyCell>}
                              {!fm && onboarding && <TableBodyCell>{"-"}</TableBodyCell>}
                              {!fm && onboarding && <TableBodyCell>{"-"}</TableBodyCell>}
                              {!fm && onboarding && 
                                <TableBodyCell>
                                  {format(new Date(fund["Last Update Datetime"] ? fund["Last Update Datetime"] : "Jan 3 2024 12:46:55 GMT+0200 (Eastern European Standard Time)"), "dd MMM yyy")} {"  "}
                                </TableBodyCell>
                              }
                              {!fm && !onboarding && <TableBodyCell>{"TBC"}</TableBodyCell>}
                              {!fm && !onboarding && <TableBodyCell>{"TBC"}</TableBodyCell>}
                              {!fm && !onboarding && <TableBodyCell>{"TBC"}</TableBodyCell>}
                              {!fm && !onboarding && <TableBodyCell>{"TBC"}</TableBodyCell>}
                            </RespTableRow>

                        </React.Fragment>
                      )) 
                  : <NoDataContent />
                  }
                  </RespTableBody> 
              </RespTable>
            </WrapTable> 
          : fundDataLoading ? 
            <LoadingSpinnerDiv><LoadingSpinner /></LoadingSpinnerDiv> 
            : selectedFundData ? 
              <FundDetailsContainer> 
                <CloseIconHolder>
                    <FundDetailsCloseIcon iconURL={closeIcon} onClick={handleClose} />
                </CloseIconHolder>
                <GroupedDetails 
                    data={selectedFundData} 
                    fundDocuments={fundDocuments} 
                    fundId= {selectedFundId}
                />
              </FundDetailsContainer> 
            : <NoDataToDisplay />
          : <NoDataToDisplay />
        }
    </CrtFundsTableHolder>
  );
};

export default ManagedFundsTable;
