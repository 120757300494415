import ReportComponent from "components/client/Report";
import {
  alertStatusCardsData,
  cardsData,
  infoCardData,
  transactionStatusCardsData,
} from "constants/reports";
import { TRACKER } from "constants/tracker";
import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";

const Report = () => {
  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.CLIENT_REPORTS);
  });
  return (
    <ReportComponent
      cardsData={cardsData}
      infoCardData={infoCardData}
      transactionStatusCardsData={transactionStatusCardsData}
      alertStatusCardsData={alertStatusCardsData}
    />
  );
};

export default Report;
