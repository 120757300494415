import styled from "styled-components";

export const CustomForm = styled.div`
  margin: 0 0 20px 0;
`;
export const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 136px auto;
  grid-gap: 24px;
  margin: 8px 0;
  label {
    font-weight: 700;
    font-size: 16px;
    color: #333333;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }

  input {
    padding: 16px 12px;
    background: #ffffff;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    color: #808080;
    box-shadow: unset;
    border: unset;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: #070c80;
  margin-bottom: 24px;
  text-transform: capitalize;
`;

export const Container = styled.div`
  padding: 40px;
  background: #f2f2f2;
  border-radius: 12px;
  margin-bottom: 32px;
`;

export const ConfirmButton = styled.div`
  background: #2e1a61;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: white;
  padding: 16px;
  width: 130px;
  position: relative;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;

export const ButtonRow = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
