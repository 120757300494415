import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  padding: 32px;
`;

export const Title= styled.div`
  color: rgb(54, 64, 115);
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
`;

export const RequestsContainer = styled.div`
    width: 98%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 50px;
    gap: 50px;
`;

export const Request = styled.div`
    width: 100%;
    padding: 12px;
    font-size: 18px;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
`;

export const RequestHeader= styled.div``;
export const RequestDetails= styled.div`
    margin-top: 15px;
`;

export const WrapTable = styled.div`
  white-space: nowrap;
  display: block;
  overflow: auto;
  max-height: 450px;
  margin-top: 40px;
`;


export const RespTable = styled.div`
  width: 100%;
  display: table;
  border-collapse: collapse;
  // word-break: break-word;
`;

export const RespTableHeader = styled.div`
  display: table-header-group;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  word-break: break-word; // this is a temporary fixd for table width
`;

export const TableHeaderRow = styled.tr`
  background: rgba(224, 226, 236);
  position: sticky;
  top: 0;
`;

export const TableHeaderCell = styled.div`
  display: table-cell;
  padding: 18px;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
`;

export const RespTableBody = styled.div`
  display: table-row-group;
  padding: 0 40px;
`;

export const RespTableRow = styled.div<{ hasBorder?: boolean }>`
  display: table-row;
  border-bottom: ${(props) => (props.hasBorder ? "1px solid rgba(74, 130, 166, 0.5)" : "none")};
  font-size: 16px;
  cursor: pointer;
`;

export const TableBodyCell = styled.div`
  display: table-cell;
  padding: 18px;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 16px;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
  color: ${({ theme }) => (theme.colors.WHITE !== "#FFFFFF" ? "#fff" : theme.colors.DARK[400])};
  & a {
    color: inherit;
    &:visited {
      color: inherit;
    }
    &:hover {
      color: #364073;
      opacity: 0.9;
    }
  }
`;

export const TableContentContainer = styled.div<{
    noShadow?: boolean;
    noMarginTop?: boolean;
  }>`
    display: block;
    column-span: all;
    overflow-x: auto;
    margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    margin-bottom: 60px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border-radius: 8px;
    padding: 12px 0;
    padding-bottom: 37px;
    white-space: nowrap;
    &:last-child {
      margin-bottom: 0px;
    }
    &:first-child {
      margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    }
  `;