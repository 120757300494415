import { Container, Icon, TitleText, Time } from "styles/client/TimeCards.styled";

function TimeCards({ icon, time, title }: { icon: string; time: number | string; title: string }) {
  return (
    <Container>
      <Icon src={icon} />
      <TitleText>{title}</TitleText>
      <Time>{time}</Time>
    </Container>
  );
}

export default TimeCards;
