import styled from "styled-components";

export const UserGuidesContainer = styled.div`
  height: 100%;
  padding: 32px;
`;

export const UserGuidesContent = styled.div<{
    noShadow?: boolean;
    noMarginTop?: boolean;
  }>`
    margin-top: 0;
    display: block;
    column-span: all;
    overflow-x: auto;
    margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    margin-bottom: 60px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border-radius: 8px;
    padding: 12px 0;
    padding-bottom: 37px;
    white-space: nowrap;
    overflow: hidden;
    &:last-child {
      margin-bottom: 0px;
    }
    &:first-child {
      margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    }
    margin-left: 22px;
  `;

export const DocsHolder= styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;

export const Doc= styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DocIcon= styled.div<{background: string}>`
  width: 200px;
  height: 200px;
  background-image: ${(props) => props.background ? `url(${props.background})` : ""};;
  background-size: contain; 
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #808080;
`;

export const DocButton= styled.button`
  width: 81px;
  height: 29px;
  background: #bcbcbc;
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: white;
  gap: 7px;
  border: none;
  outline: none;
  cursor: not-allowed;
  justify-content: center;
  float: right;
  margin-top: 22px;
`;

export const Note= styled.p`
  font-size: 16px;
`;