import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import AliceRequestComponent from "../../components/admin/AliceRequest";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import { useEffect, useState } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import { getAccessToken } from "../../services/auth0/auth0";
import { GET_ALICE_TICKETS, GET_ROOM_ATTRIBUTES } from "config/googleCloudFunctionsConfig";
import { useLocation } from "react-router-dom";
import { NewAliceRedirectDataType } from "types";
import { isAliceRedirectState } from "utils/redirectDataForNewAlice";
import { DataPropsS } from "types";

function AliceRequests() {
  const { track } = useTrackActivity();
  const [streamData, setStreamData] = useState<DataPropsS[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const accessToken = getAccessToken();
  const [redirectData, setRedirectData] = useState<NewAliceRedirectDataType | undefined>();

  const location = useLocation();

  useEffect(()=>{
    if(isAliceRedirectState(location.state)) {
      setRedirectData(location.state);
    }
  },[location.state])

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.ALICE_REQUEST);
  });

  useEffect(()=>{
    setIsLoading(true);

    const params = {
        "limit": "0",
        "skip": "",
        "action": "stream_list"
    }

    if (accessToken){
      callGCFunctionWIthAccessToken(accessToken, GET_ALICE_TICKETS, params, "test", "test")
      .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
      .then((data)=> {
        let last= false;
        data.map((chat: DataPropsS, index: number) => {
          const chatParams = {
            "streamIdList": [chat.id],
            "action": "get_room_attributes"
          }

          if (accessToken){
            callGCFunctionWIthAccessToken(accessToken, GET_ROOM_ATTRIBUTES, chatParams, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data)=> {
              const newObj: DataPropsS= chat;
              newObj.attributes= data[0];  
              const newArray= streamData;
              newArray.push(newObj);
              setStreamData([...streamData, newObj]); 
            });
            if(index === data.length - 1) {
              last= true;
            }
          }
        })
        if(last) {
          setTimeout(()=>{
            setIsLoading(false);
          },2000)
          
        }
      });
    }
  },[accessToken, redirectData])

  return (
      <>
        {isLoading ? <LoadingSpinner /> :
        streamData.length > 0 &&  <AliceRequestComponent data={streamData} redirectData = { redirectData?.from.data } setRedirectData = {setRedirectData} />}
      </>
  )

}

export default AliceRequests;
