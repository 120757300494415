/**
 * Convert timestamp of the notification object into the time passed compared the actual time.
 * @return Returns [1] a string that represent the time passed compared the actual time
 * or [2] hours and minutes retrived from the timestamp.
 */
export const timeSince = (date: Date): string => {
  const seconds = Math.floor((new Date().getTime() - date.getTime() * 1000) / 1000);
  let interval = seconds / 3600;
  // if the notification time is more than 2 hours ago compared to the current time, returns hours and minutes
  if (interval >= 2) {
    return `${date.getHours()}:${date.getMinutes()}`;
  }
  if (interval >= 1) {
    return `${Math.floor(interval)} h ago`;
  }
  interval = seconds / 60;
  return `${Math.floor(interval)} min`;
};
