import { useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import DummyData from "../../data/getSearch.json";
import { useAppData } from "../../hooks/useAppData";
import {
  Title,
  GetSearchSection,
  GetSearchContent,
  Hive,
  HiveHeading,
  HiveContent,
  IconDivA,
  SCRAPING,
  SCRAPINGHeading,
  SCRAPINGContent,
  IconDiv,
  AliceImported,
  AliceImportedHeading,
  AliceImportedContent,
  DropDownContainer,
  DropDownHeader,
  DropDownListContainer,
  DropDownList,
  ListItem,
  IconDivB,
  DivIcon,
} from "../../styles/admin/GetSearch.styled";

function GetSearch() {
  // Dummy DATA Start
  const { hive } = DummyData;
  const { scraping } = DummyData;
  const scrapinglinks = scraping.links;
  const importeddata = DummyData.imported.data;
  const importeddoc = DummyData.imported.doc;
  const { tags } = DummyData;
  // Dummy DATA End

  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.GET_SEARCH);
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [dataView, setDataView] = useState(false);

  const dataViewChange = () => {
    setDataView((current) => !current);
  };

  const toggling = () => setIsOpen(!isOpen);
  const onOptionClicked = (value: string) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const { status } = useAppData();
  return (
    <GetSearchSection>
      <Title>Get Search</Title>
      <GetSearchContent>
        <Hive>
          {/* join icon start */}
          <IconDivA>
            <img src={DivIcon} alt="divicon" />
          </IconDivA>
          {/* join icon end */}
          <HiveHeading>
            <h2>Hive</h2>
          </HiveHeading>
          <hr style={{ border: "solid 1px rgba(128, 128, 128, 0.3)" }} />
          <HiveContent>
            <div>
              <h2>{hive.name}</h2>
              <p>{hive.country}</p>
            </div>
            <div style={{ marginTop: "30px" }}>
              <div className="contentdiv">
                <p>Legal Name</p>
                <p> {hive.legalName}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Address</p>
                <p>{hive.legalAddress}</p>
              </div>
              <div className="contentdiv">
                <p>Registration Country</p>
                <p>{hive.registrationCountry}</p>
              </div>
              <div className="contentdiv">
                <p>SWIFT/BIC</p>
                <p>{hive.swift}</p>
              </div>
              <div className="contentdiv">
                <p>Status</p>
                <p>{hive.status}</p>
              </div>
              <div className="contentdiv">
                <p>Legal Form</p>
                <p>{hive.legalForm}</p>
              </div>
              <div className="contentdiv">
                <p>Incorporation Date</p>
                <p>{hive.incorporationDate}</p>
              </div>
              <div className="contentdiv">
                <p>Source Info Provider</p>
                <p>{hive.sourceInfoProvider}</p>
              </div>
              <div className="contentdiv">
                <p>Alternative Names</p>
                <p>{hive.alternativeName}</p>
              </div>
              <div className="contentdiv">
                <p>Entity Type</p>
                <p>{hive.type}</p>
              </div>
            </div>
          </HiveContent>
        </Hive>
        <SCRAPING>
          {/* join icon start */}
          <IconDiv>
            <img src={DivIcon} alt="divicon" />
          </IconDiv>
          {/* join icon end */}
          <SCRAPINGHeading>
            <h2>{scraping.name}</h2>
          </SCRAPINGHeading>
          <hr style={{ border: "solid 1px rgba(128, 128, 128, 0.3)" }} />
          <SCRAPINGContent status={status}>
            <div>
              <h2>HSBC HOLDINGS</h2>
            </div>
            <div style={{ marginTop: "30px" }}>
              <div className="contentdiv">
                {scrapinglinks.map((links, i) => (
                  <p key={i}>{links}</p>
                ))}
              </div>
            </div>
          </SCRAPINGContent>
        </SCRAPING>
        <AliceImported>
          <IconDivB>
            <img src={DivIcon} alt="divicon" />
          </IconDivB>
          <AliceImportedHeading>
            <h2>ALICE IMPORTED</h2>
            <div onClick={dataViewChange}>
              {dataView === true ? <p>Data View</p> : <p>Doc View</p>}
            </div>
          </AliceImportedHeading>
          <hr style={{ border: "solid 1px rgba(128, 128, 128, 0.3)" }} />
          <AliceImportedContent>
            {dataView === false ? (
              <div>
                <div>
                  <h2>{importeddata.name}</h2>
                  <p>{importeddata.country}</p>
                </div>
                <div style={{ marginTop: "30px" }}>
                  <div className="contentdiv">
                    <p>PID</p>
                    <p>{importeddata.pid}</p>
                  </div>
                  <div className="contentdiv">
                    <p>Legal Name</p>
                    <p>{importeddata.legalName}</p>
                  </div>
                  <div className="contentdiv">
                    <p>Legal Address</p>
                    <p>{importeddata.legalAddress}</p>
                  </div>
                  <div className="contentdiv">
                    <p>Registration Country</p>
                    <p>{importeddata.registrationCountry}</p>
                  </div>
                  <div className="contentdiv">
                    <p>SWIFT/BIC</p>
                    <p>{importeddata.swift}</p>
                  </div>
                  <div className="contentdiv">
                    <p>Status</p>
                    <p>{importeddata.status}</p>
                  </div>
                  <div className="contentdiv">
                    <p>Legal Form</p>
                    <p>{importeddata.legalForm}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <embed src={importeddoc} type="application/pdf" width="100%" height="750px" />
              </div>
            )}
          </AliceImportedContent>
        </AliceImported>
        <DropDownContainer>
          <DropDownHeader onClick={toggling}>
            <div>
              <KeyboardArrowDownOutlinedIcon />
            </div>
          </DropDownHeader>
          {isOpen && (
            <DropDownListContainer>
              <DropDownList>
                {tags.map((option) => (
                  <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                    {option}
                  </ListItem>
                ))}
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
      </GetSearchContent>
    </GetSearchSection>
  );
}

export default GetSearch;
