import styled from "styled-components";

export const DivIcon = `${process.env.PUBLIC_URL}/assets/DivIcon.png`;
export const AcceptIcon = `${process.env.PUBLIC_URL}/assets/AcceptIcon.svg`;
export const RejectIcon = `${process.env.PUBLIC_URL}/assets/RejectIcon.svg`;
export const FurtherIcon = `${process.env.PUBLIC_URL}/assets/FurtherIcon.svg`;

export const T360ConnectSection = styled.div`
  h1 {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

export const T360ConnectSectionHeading = styled.div`
  & > p {
    color: ${({ theme }) => theme.colors.PRIMARY[400]};
    margin-bottom: 30px;
    margin-top: 10px;
  }
`;
export const T360ConnectContent = styled.div`
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
  position: relative;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }
`;
export const T360 = styled.div`
  background: rgba(46, 26, 97, 0.05);
  border-radius: 4px;
  width: calc(100% / 2 - 0.5rem);
  position: relative;
  margin: 0;

  & hr {
    border: 1px solid ${({ theme }) => theme.colors.DARK[200]};
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const T360Heading = styled.div`
  display: flex;
  justify-content: right;
  align-item: center;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  padding-right: 20px;
  & > h2 {
    margin: 14px 10px 10px 10px;
  }
  @media (max-width: 768px) {
    justify-content: left;
  }
`;
export const T360Content = styled.div`
  padding: 20px;
  & > div > h2 {
    font-size: 24px;
    font-weight: 600;
    ${({ theme }) => theme.colors.DARK[400]};
    margin: 0;
  }
  & > div > p {
    margin: 8px 0 0 0;
    color: ${({ theme }) => theme.colors.DARK[300]};
    font-weight: 500;
  }
  & .contentdiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: 14px;
    & p:nth-child(2) {
      color: ${({ theme }) => theme.colors.PRIMARY[400]};
      font-weight: 700;
    }
  }
`;
export const IconDiv = styled.div`
  margin: 0;
  padding: 0;
  width: 0;
  position: absolute;
  right: -5px;
  @media (max-width: 768px) {
    position: relative;
  }
  & > img {
    position: absolute;
    left: -28px;
    top: 13px;
    width: 50px;
    @media (max-width: 768px) {
      position: absolute;
      top: -15px;
      left: -15.5rem;
      transform: rotate(90deg);
    }
  }
`;
export const Proprietary = styled.div`
  background: rgba(46, 26, 97, 0.05);
  border-radius: 4px;
  width: calc(100% / 2);
  height: 100%;
  margin: 0;
  & hr {
    border: 1px solid ${({ theme }) => theme.colors.DARK[200]};
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ProprietaryHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  padding-left: 20px;
  & > h2 {
    margin: 14px 10px 10px 10px;
  }
  @media (max-width: 768px) {
    padding-left: 0;
  }
  & > div {
    font-size: 12px;
    margin: 10px 23px 0px 10px;
    padding: 01px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.SECONDARYHOVER[100]};
      border-radius: 05px;
    }
  }
`;
export const ProprietaryContent = styled.div`
  padding: 20px;
  & > div > h2 {
    font-size: 24px;
    font-weight: 600;
    ${({ theme }) => theme.colors.DARK[400]};
    margin: 0;
  }
  & > div > p {
    margin: 8px 0 0 0;
    color: ${({ theme }) => theme.colors.DARK[300]};
    font-weight: 500;
  }
  & .contentdiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: 14px;
    & p:nth-child(2) {
      color: ${({ theme }) => theme.colors.PRIMARY[400]};
      font-weight: 700;
    }
  }
`;
export const ARFDiv = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  & .ARFIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  & .ARFIcon img {
    width: 50px;
    &:hover {
      cursor: pointer;
    }
  }
  & .ARFIcon > span {
    font-size: 18px;
    margin-top: 10px;
  }
  & .ARFIcon > .accept-match {
    color: ${({ theme }) => theme.colors.SUCCESS[500]};
  }
  & .ARFIcon > .reject-match {
    color: ${({ theme }) => theme.colors.DANGER[500]};
  }
  & .ARFIcon > .further-enquire {
    color: ${({ theme }) => theme.colors.ORANGE[500]};
  }
`;
