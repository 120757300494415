import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import HubwiseFundManagerComponent from "components/hubwise-dashboard/HubwiseFundManager";

const FundPartyFundManager = () => {
    const { track } = useTrackActivity();

    useEffectOnce(()=>{
        track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_DASHBOARD_FUND_MANAGER_T360);
    });
    return <HubwiseFundManagerComponent />
}

export default FundPartyFundManager;