import { ErrorMessageContainer, NoData } from "styles/NoDataFound.styled";

function NoDataFound() {
  return (
    <ErrorMessageContainer>
      <NoData />
      <p>Something went wrong while fetching your Data</p>
    </ErrorMessageContainer>
  );
}

export default NoDataFound;
