import { NoDataToDisplay } from "components/noData/ComingSoon";
import { IdentifiersData } from "../../types";
import { FieldName, SingleRow, Span } from "styles/party/FieldsGroup.styled";
import AliceModalTooltip from "components/AliceModalTooltip";

type Obj = { [key: string]: any };

function present(arr: Obj[], c: string) {
  let check = false;

  arr.forEach((item) => {
    if (item?.["National ID Label"] === c) {
      check = true;
    }
  });

  return check;
}

function format(obj: Obj): Obj[] {
  if (!obj) return [];

  const arr: Obj[] = [];

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        if (!present(arr, v?.["National ID Label"])) {
          arr.push({
            "National ID Label": v?.["National ID Label"],
            "National ID Number": v?.["National ID Number"],
          });
        }
      });
    } else {
      arr.push({ "National ID Label": key, "National ID Number": value });
    }
  });

  return arr;
}

function convertToList(arr: Obj[]): Obj[][] {
  if (!arr || !arr.length) return [];
  const mid = Math.ceil(arr.length / 2);

  const arrs = [arr.slice(0, mid > 3 ? mid : 3), arr.slice(mid > 3 ? mid : 3)];

  return arrs as Obj[][];
}


// general layout for Accordion's data (not for specific sections)
export function Identifiers({ data }: { data: IdentifiersData }) {
  // dataFilter is a function that chooses if we should display BanksOnly (bo) or NON-BanksOnly (no_bo)

  return !data ? <NoDataToDisplay /> : 
  <>
    {Object.keys(data).map((field, index) => (
      <SingleRow key={index}>
          <FieldName>{field}</FieldName>
          {Array.isArray(data[field]) ? 
          (
            <AliceModalTooltip selectedField={data[field][0]} selectedKey={field}>
              <Span>{data[field][0]}</Span>
            </AliceModalTooltip>
          ) : 
          (
            <AliceModalTooltip selectedField={data[field] as string} selectedKey={field}>
              <Span>{data[field]}</Span>
            </AliceModalTooltip>
          )}
      </SingleRow>
      ))}
  </>;
}
