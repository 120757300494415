// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { Accordion } from "components/party/accordion/Accordion";
import { 
    Container, 
    Title,
    ContentItem 
} from "styles/sippAdmin/sippAdmin.styled";

function SIPPAccountComponent() {

  return (
    <Container>
        <Title>
            Account
        </Title>
        <Accordion title="Screen" noTopBorder>
            <div></div>
        </Accordion>
        <Accordion title="Section Content" opened>
            <div>
                <ContentItem>Account Type eg. SIPP</ContentItem>
                <ContentItem>Total value</ContentItem>
                <ContentItem>Uncrystallised value</ContentItem>
                <ContentItem>Crystallised value</ContentItem>
                <ContentItem>Tax Free Cash taken</ContentItem>
                <ContentItem>Pension Income</ContentItem>
                <ContentItem>Income longevity prediction</ContentItem>
            </div>
        </Accordion>
    </Container>
  );
}

export default SIPPAccountComponent;
