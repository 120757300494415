import EsgQuestionnaireComponent from "components/esgQuestionnaire/EsgQuestionnaireComponent";
import esgQuestionnaireData from "../../data/esgQuestionnaire.json";

const EsgQuestionnaire = () => {

    return (       
       <EsgQuestionnaireComponent data={esgQuestionnaireData} />
    )
}

export default EsgQuestionnaire;