import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    margin-left: 45px;
    margin-top: 60px;
    font-size: 32px;
    text-align: left;
`;

export const SignOutHolder = styled.div`
    width: 80%;
    margin: 30px 0 0 30px;
`;