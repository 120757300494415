import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 30px;
    display: grid;
    grid-template-columns: 20% repeat(auto-fill, minmax(80%, 1fr));
    align-items: center;
    margin-top: 45px;
    @media (max-width: 769px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const LeftContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: space-evenly;
    @media (max-width: 769px) {
        margin-left: 20px;
    }
`;

export const FiltersContainer= styled.div`
    margin-bottom: 15px;
`;

export const RightContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 769px) {
       margin-top: 40px;
    }
    @media (max-width: 1310px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const LabelValueHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
`;

export const Label = styled.div`
    color: #364073;
    font-weight: 700;
    font-size: 18px;
`;

export const Value = styled.div`
    margin-left: 20px;
    color: #808080;
    font-size: 18px;
`;