import styled from "styled-components";
import { SEARCH_CLASS_TYPE } from "constants/filter";

export const MainIcon = styled.div<{ iconURL: string }>`
  margin: 40px 0 54px 0;
  width: 200px;
  height: 162px;
  background-image: ${(props) => `url(${props.iconURL})`};
  background-size: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const Text = styled.div`
  margin-top: 16px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.DARK[400]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonsContainer = styled.div`
  margin-bottom: 40px;
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  width: 130px;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: #4a82a6;
  border: solid 1px #4a82a6;
  border-radius: 6px;
  color: #fff;
  @media (max-width: 768px) {
    width: 120px;
    height: 50px;
  }
`;

export const FormHolder = styled.div`
  width: 90%;
`;

export const SucessMessageHolder= styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;
`;

export const SucessMessageP= styled.p`
  color: #364073;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-left: 20px;
`;

export const SuccessIcon=styled.div`
  width: 30px;
  height: 30px;
  background-image: url(${`${process.env.PUBLIC_URL}/assets/checkmate-verified.svg`});
  background-size: cover;
`;

export const Input = styled.input<{ classType?: SEARCH_CLASS_TYPE, border?: boolean }>`
  font-weight: 400;
  font-size: ${({ classType }) => (classType === SEARCH_CLASS_TYPE.DEFAULT ? "16px" : "12px")};
  line-height: 21px;
  outline: none;
  border: ${({ border }) => (border ? "1px solid red" : "none")};;
  height: 56px;
  color: #000000;
  background: #F8F8F8;
  width: 100%;
  font-size: 16px;
  flex: 1;
  padding: 3px 40px 0 15px;
  &:focus {
    outline: none;
  }
`;

export const InputWrapper = styled.div<{ classType?: SEARCH_CLASS_TYPE }>`
  display: flex;
  align-items: center;
  background-color: ${({ classType }) =>
    classType === SEARCH_CLASS_TYPE.PRIMARY && "rgba(54, 64, 115, 0.1)"};
  border: ${({ classType }) => classType === SEARCH_CLASS_TYPE.DEFAULT && "1px solid #D0D5DD"};
  padding: 13px 10px;
  border-radius: 6px;
  flex-basis: 392px;
  column-gap: 10px;
`;

export const FormLabel = styled.p`
  color: #364073;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
`;

export const FormValue = styled.p`
  color: #808080;
  font-size: 16px;
`;

export const ErrorDiv = styled.div`
  color: red;
  font-size: 16px;
`
