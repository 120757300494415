import styled from "styled-components";

export const arrowDown = `${process.env.PUBLIC_URL}/assets/arrow-down.svg`;

export const Container= styled.div`
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    align-items: center;
    padding: 5px;
    margin-bottom: 10px;
`;

export const DropDownContainer = styled.div`
  height: min-content;
  margin-top: 10px;
  background: ${({ theme }) => theme.colors.GREYED[800]};
  border: 1px solid ${({ theme }) => theme.colors.GRAY[300]};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding-left: 10px;
`;

export const DropDownCover = styled.div`
  width: 100%;
  max-height: 150px;
  overflow-y: scroll;
  cursor: pointer;
`;
export const DropDownItem = styled.div`
  padding: 10px 0 10px 14px;
  font-size: 12px;
  line-height: 24px;
  font-family: "Roboto Condensed", "sans-serif";
  color: ${({ theme }) => theme.colors.DARK[500]};
`;

export const InputContainer= styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ArrowDownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Input = styled.input`
  margin-top: 5px;
  padding: 7px 0;
  width: 100%;
  border: none;
  box-shadow: none;
  outline: 0;
  color: ${({ theme }) => theme.colors.DARK[500]};
  background-color: ${({ theme }) => theme.colors.GREYED[800]};
`;

export const SelectedOption= styled.div`
    padding: 7px 0;
    margin-top: 10px;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.DARK[500]};
`;