/* eslint-disable @typescript-eslint/quotes */
// Copyright Marco Rapaccini, Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the Search Suggestions.
 */

import { useEffect, useState } from "react";
import { useToast } from "hooks/useToast";
import { SEARCH_RESULTS, GET_GUOS_FOR_ADVANCED_SEARCH } from "../../config/googleCloudFunctionsConfig";
import {
  CallNeo4jExternallyItem,
  SearchSuggestionInput,
  SearchSuggestionResult,
  RelatedEntity,
} from "../../types";
import { SearchSuggestionItem } from "./SearchSuggestionItem";
import {
  TopSearches,
  Loader,
  LoaderBar,
  Suggestions,
  SuggestionNotFound,
} from "../../styles/SearchSuggestion.styled";
import { getAccessToken } from "services/auth0/auth0";
import {callGCFunctionWIthAccessToken} from "services/callGCFunction";
import { SetIsSearch, SetPartyDetails, SetId, SolrResults } from "../../types";
import solrData from "data/fund_managers_list_pid.json";

const searchSuggestionsLimit: number = 100;

export function SearchSuggestions({
  searchString,
  isIdentifierSearch,
  showTopSearches = true,
  noMargin = false,
  isHubwise
}: {
  searchString: string;
  isIdentifierSearch: boolean;
  showTopSearches?: boolean;
  noMargin?: boolean;
  isHubwise?: boolean;
}) {
  const { toast } = useToast();
  const token = getAccessToken();

  const [resultsToDisplay, setResultsToDisplay] = useState<SearchSuggestionResult[]>([]);

  const [luceneReplyLength, setLuceneReplyLength] = useState<number | null>(null);

  const [searchSuggestionsHasFinished, setSearchSuggestionsHasFinished] = useState<boolean>(false);
  const searchSuggestionsFromApi: any[]= [];

  const fetchResults = async () => {
      if (token) {
        const payloadForGUO = {
          params: {
            "q": 
            `(_query_:\"{!complexphrase inOrder=true}legalName_txt:\\\"${searchString}\\\"~20\"`+`_query_:\"{!complexphrase inOrder=true}previousName_txt:\\\"${searchString}\\\"~20\"`+`_query_:\"{!complexphrase inOrder=true}fatcaRegisterName_t:\\\"${searchString}\\\"~20\"`+`_query_:\"{!complexphrase inOrder=true}legalNameLocal_t:\\\"${searchString}\\\"~20\"`+`_query_:\"{!complexphrase inOrder=true}aliasName_txt:\\\"${searchString}\\\"~20\")`+"AND legalStatus_txt: \"active\" AND _query_:\"{!complexphrase inOrder=true}  categoryOfTheCompany_txt:\\\"Very large company~2\\\"~20\"",
            "rows": 200
          }
        }

        callGCFunctionWIthAccessToken(token, SEARCH_RESULTS, payloadForGUO, "test", "test")
          .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
          .then((luceneResults) => {
            const guosArray: RelatedEntity[] = [];
            const nonGuosArray: RelatedEntity[] = [];
            const luceneNonNeo4jArray: RelatedEntity[] = [];
            // using a new map to filter out duplicate pids
            const idMap = new Map();
            luceneResults.results.result.forEach((obj: SearchSuggestionInput) => {
              idMap.set(obj.pid_s, obj);
            });
            Array.from(idMap.values()).map((item) => searchSuggestionsFromApi.push(item));

            const luceneResultsLength = luceneResults.results.result.length;
            setLuceneReplyLength(luceneResultsLength);
            if (luceneResultsLength > 0) {
              const entityIdList = luceneResults.results.result.map((item: SearchSuggestionInput) => {
                return item.pid_s;
              });

              const params = {
                entityIdList: entityIdList,
                queryType: "search_suggestions_list",
                limit: "100"
              };

              callGCFunctionWIthAccessToken(token, GET_GUOS_FOR_ADVANCED_SEARCH, params, "test", "test")
              .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
                .then((response: CallNeo4jExternallyItem[]) => {
                  if (Array.isArray(response)) {
                    response.forEach((item: CallNeo4jExternallyItem, index: number) => {
                      if(searchSuggestionsFromApi[index] !== undefined) {
                        const relatedEntityItem: RelatedEntity = {
                          name:                        
                          searchSuggestionsFromApi[index].legalName_ss[0] 
                            ? searchSuggestionsFromApi[index].legalName_ss[0] 
                            : searchSuggestionsFromApi[index].fatcaRegisterName_t[0] 
                            ? searchSuggestionsFromApi[index].fatcaRegisterName_t[0] 
                            : searchSuggestionsFromApi[index].legalNameLocal_t[0] 
                            ? searchSuggestionsFromApi[index].legalNameLocal_t[0]  
                            : searchSuggestionsFromApi[index].aliasName_txt[0] 
                            ? searchSuggestionsFromApi[index].aliasName_txt[0] 
                            : "",
                          isGuo: item.status === "guo",
                          id: searchSuggestionsFromApi[index].pid_s,
                          iso: searchSuggestionsFromApi[index].countryIsoCode_s,
                          type: searchSuggestionsFromApi[index].typeOfEntity_ss[0],
                          // TODO Fix bug on not setting notInNeo to true when the status is null
                          notInNeo: item.status === "null",
                        };  
                        // this an alternative to a switch or to an if/else/else-if
                        const assignToRightArray: { [key: string]: RelatedEntity[] } = {
                          guo: guosArray,
                          sub: nonGuosArray,
                          null: luceneNonNeo4jArray,
                        };
                        assignToRightArray[item.status].push(relatedEntityItem);
                      }
                      
                    });
                  }
                })
                .finally(() =>
                  setResultsToDisplay(guosArray.concat(nonGuosArray).concat(luceneNonNeo4jArray)),
                )
                .catch((err: Error) => toast(err.toString()));
            }
          })
          .catch((err: Error) => toast(err.toString()));
      }
  };
  const resetValues = () => {
    setResultsToDisplay([]);
    setLuceneReplyLength(null);
    setSearchSuggestionsHasFinished(false);
  };

  useEffect(() => {
    if (searchString.trim() !== "") {
      resetValues();
      if (!isHubwise && searchString.trim() !== "") {
        fetchResults();
      } else {
        const searchData: SolrResults = solrData as SolrResults;
        const results : any = [];
        const newData = searchData.response.docs.filter((el) =>{
          if(el.legalName_ss) {
            if(el.legalName_ss[0].toLowerCase().includes(searchString)) {
              return el;
            };
        }})
        newData.map((el) => {
          if(el.legalName_ss) {
            const tmp = {
              name: el.legalName_ss[0],
              id: el.pid_s
            }
            results.push(tmp);
          }          
        })
        setResultsToDisplay(results);
      }
    }
  }, [searchString, isHubwise, token]);

  function SearchSuggestionItemsList() {
    // sometimes the results are less than the searchSuggestionsLimit, so we need to pick the smallest one between the 2
    const maxSuggestionIndex =
      (resultsToDisplay.length < searchSuggestionsLimit
        ? resultsToDisplay.length
        : searchSuggestionsLimit) - 1;
    return (
       
        resultsToDisplay.length !== 0
        ? resultsToDisplay.map((item: RelatedEntity, index) =>
            index < searchSuggestionsLimit ? (
              <SearchSuggestionItem
                key={index}
                entityToDisplay={item}
                setSearchSuggestionsHasFinished={setSearchSuggestionsHasFinished}
                isLast={index === maxSuggestionIndex}
                isIdentifierSearch={isIdentifierSearch}
              />
            ) : null
      )
      : null
   
  )}

  return (
    <Suggestions noMargin={noMargin}>
      {showTopSearches && (
        <TopSearches data-testid="top-searches">
          <p>Top Searches</p>
        </TopSearches>
      )}
      {!searchSuggestionsHasFinished && luceneReplyLength !== 0 && (
        <Loader data-testid="loader">
          <LoaderBar />
        </Loader>
      )}
      {luceneReplyLength !== 0 ? (
        SearchSuggestionItemsList()
      ) : (
        <SuggestionNotFound data-testid="suggestion-not-found">
          Nothing found, try a different search
        </SuggestionNotFound>
      )}
    </Suggestions>
  );
}
