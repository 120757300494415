import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  @media (max-width: 768px) {
    grid-template-columns: 100% 1fr;
  }
  @media (min-width: 768px) {
    grid-template-columns: 15% 85% 1fr;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-height: 99vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Nav = styled.div`
  display: flex;
  padding: 10px;
  margin-bottom: 45px;
  background-color: #2f2f3f;
  position: sticky;
  top: 0;
  z-index: 10;
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`;

export const OutletCover = styled.div`
  overflow-y: none;
`;
