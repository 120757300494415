// theme.js
// All app colors
const LIGHT_MODE_COLORS = {
  BLACK: {
    200: "#808080",
    300: "#4F4F4F",
    400: "#333333",
    500: "#141414",
    600: "#505050",
  },
  DARK: {
    500: "#000000",
    400: "#333333",
    300: "#666666",
    200: "#CCCCCC",
  },
  WHITE: "#FFFFFF",
  PRIMARY: {
    10: "#D7EAFF",
    15: "rgba(54, 64, 115, 0.1)",
    25: "#F6F4F9",
    50: "#E5D7F6",
    75: "rgba(54, 64, 115, 0.5)",
    100: "#FFF4ED",
    150: "#2e1a611a",
    200: "#B93815",
    300: "#4A82A6",
    400: "#364073",
    500: "rgba(46, 26, 97, 0.05)",
    600: "#8E7FFE",
    700: "#3C21F7",
    800: "rgba(46,26, 97,0.3)",
    900: "#2E1A61",
    1000: "d7d3e0",
  },
  SECONDARY: {
    25: "#F2F8FA",
    50: "#ECFDF3",
    100: "#50E680",
    200: "#12B76A",
    300: "#027A48",
    500: "rgba(128, 128, 128, 0.5)",
    400: "#5ECCD9",
  },
  YELLOW: { 400: "#FFEA5F" },
  TERTIARY: {
    100: "#FDF2FA",
    200: "#C11574",
    300: "99A678",
    400: "#7DA66E",
    500: "#99CC66",
  },
  ACCENT: {
    25: "#EBEEF4",
    700: "#C11574",
    500: "#CC0066",
    400: "#B53D70",
  },
  SUCCESS: { 500: "#4CBB17", 100: "#D1FADF", 50: "#ECFDF3" },
  GRAY: {
    25: "#FCFCFD",
    50: "#F9FAFB",
    100: "#F2F4F7",
    200: "#EAECF0",
    300: "#D0D5DD",
    400: "#98A2B3",
    500: "#667085",
    600: "#475467",
    650: "rgb(104, 118, 146)",
    700: "#344054",
    800: "#1D2939",
    900: "#101828",
  },
  GREY: { 400: "#F2F2F2", 500: "#808080" },
  GREYED: {
    800: "#F8F8F8",
    775: "#F2F2F2",
    700: "#344054",
    600: "#475467",
    500: "#808080",
    480: "rgba(128, 128, 128, 0.8)",
    300: "#D0D5DD",
    100: "rgba(51, 51, 51, 1)",
    80: "rgba(51, 51, 51, 0.8)",
  },
  TITLE: { 500: "#070C80" },
  ACCORDION: { 100: "2px solid #36407380", 200: "2px solid rgba(81, 97, 174, 0.5)" },
  SHADOW: { 100: "#101828", 200: "rgba(128, 128, 128, 0.25)" },
  LINEAR_GRADIENT: {
    775: "linear-gradient(180deg, #FF7800 0%, rgba(228, 144, 69, 0.849198) 83.33%, #ED9D2B 100%);",
    600: "linear-gradient(180deg, #2e1a61 0%, #6142af 100%)",
    500: "linear-gradient(180deg, #E60003 0%, rgba(229, 68, 70, 0.849198) 83.33%, #EC7D7E 100%)",
    400: "linear-gradient(180deg, #3F24F7 -50%, #8E7FFE 182.35%)",
    300: "linear-gradient(180deg, #F28524 0%, #F79F51 100%)",
    200: "linear-gradient(180deg, #048A42 -50%, #50E680 182.35%);",
    150: "linear-gradient(180deg, rgba(46, 26, 97, 0.7) 0%, rgba(99, 18, 175, 0.6) 100%)",
    120: "linear-gradient(180deg, #AD22D4 0%, #6142DF 100%)",
    100: "linear-gradient(180deg, #4D1A61 0%, #3442AF -50%)",
    75: "linear-gradient(180deg, #4A1A61 0%, #6142AF 100%)",
    70: "linear-gradient(180deg, #FF7810 0%, rgba(225, 100, 60, 0.849198) 83.33%, #ED3D2B 100%);",
    60: "linear-gradient(180deg, #6941C6 0%, #B93815 100%)",
    50: "linear-gradient(180deg, #D1FADF 0%, rgba(229, 68, 70, 0.849198) 83.33%, #FDF2FA 100%)",
  },
  SECONDARYHOVER: { 100: "#EAECF0" },
  DANGER: {
    50: "#FEF3F2",
    100: "#FEE4E2",
    500: "#E60000",
    600: "#D92D20",
  },
  ORANGE: { 500: "#FF7800" },
  SILVERY: { 600: "#FEF3F2", 500: "#FEE4E2", 400: "#D92D20" },
  CORAL_PINK: { 500: "#FF6584" },
  PURPLE: { 25: "#F9F5FF", 600: "#6941C6" },
  NEON_BLUE: { 25: "#EFF8FF", 600: "#175CD3" },
  INDIGO: { 25: "#EEF4FF", 600: "#3538CD" },
  PINK: { 25: "#FDF2FA", 600: "#C11574" },
  PEACH: { 25: "#FFF4ED", 600: "#B93815" },
  NEON_GREEN: { 50: "#ECFDF3", 100: "#D1FADF", 500: "#039855" },
  GREEN: { 500: "#4CBB17" },
  BLUEISH: { 4: "rgba(16, 24, 40, 0.05)" },
  BLACK_SHADES: { 20: "rgba(0, 0, 0, 0.2)", 24: "rgba(0, 0, 0, 0.24)" },
};

const DARK_MODE_COLORS = {
  BLACK: {
    200: "#505050",
    300: "#141414",
    400: "#333333",
    500: "#4F4F4F",
    600: "#808080",
  },
  DARK: {
    500: "#CCCCCC",
    400: "rgb(209, 203, 199)",
    300: "#333333",
    200: "#000000",
  },
  WHITE: "#222425",
  PRIMARY: {
    10: "#D7EAFF",
    15: "rgba(54, 64, 115, 0.1)",
    25: "rgb(24, 18, 33)",
    50: "#E5D7F6",
    75: "rgba(54, 64, 115, 0.5)",
    100: "rgb(44, 22, 8)",
    150: "#202123",
    200: "rgb(243, 167, 146)",
    300: "#4A82A6",
    400: "rgb(186, 192, 222)",
    500: "#212224",
    600: "#8E7FFE",
    700: "#3C21F7",
    800: "rgba(46,26, 97,0.3)",
    900: "rgb(191, 175, 233)",
    1000: "#D7D3E0",
  },
  SECONDARY: {
    400: "#F2F8FA",
    500: "#ECFDF3",
    300: "rgb(140, 248, 203)",
    200: "#12B76A",
    100: "#027A48",
    50: "rgb(9, 42, 23)",
    25: "#5ECCD9",
  },
  YELLOW: { 400: "#FFEA5F" },
  TERTIARY: {
    200: "rgb(243, 145, 199)",
    100: "rgb(41, 10, 33)",
    500: "#99CC66",
    400: "#7DA66E",
    300: "99A678",
  },
  ACCENT: {
    25: "#EBEEF4",
    700: "#C11574",
    500: "#CC0066",
    400: "#B53D70",
  },
  SUCCESS: { 500: "#4CBB17", 100: "#D1FADF", 50: "#ECFDF3" },
  GRAY: {
    900: "rgb(182, 197, 226)",
    800: "#F9FAFB",
    700: "#EAECF0",
    650: "rgb(160, 117, 234)",
    600: "#D0D5DD",
    500: "#98A2B3",
    400: "#667085",
    300: "#475467",
    200: "rgb(104, 118, 146)",
    100: "#344054",
    50: "#1D2939",
    25: "#101828",
  },
  GREY: { 400: "#808080", 500: "#F2F2F2" },
  GREYED: {
    80: "#F8F8F8",
    100: "#F2F2F2",
    300: "#344054",
    480: "#475467",
    500: "#808080",
    600: "rgba(128, 128, 128, 0.8)",
    700: "#D0D5DD",
    775: "rgba(51, 51, 51, 1)",
    800: "rgb(24, 26, 27)",
  },
  TITLE: { 500: "#070C80" },
  ACCORDION: { 100: "2px solid rgba(81, 97, 174, 0.5)", 200: "2px solid #36407380" },
  SHADOW: { 100: "#101828", 200: "rgba(16, 24, 40, 0.3)" },
  LINEAR_GRADIENT: {
    775: "linear-gradient(180deg, #F28524 0%, #F79F51 100%)",
    600: "linear-gradient(180deg, #3F24F7 -50%, #8E7FFE 182.35%)",
    500: "linear-gradient(rgb(37, 7, 7) 0%, rgba(43, 10, 11, 0.85) 83.33%, rgb(48, 12, 12) 100%)",
    400: "linear-gradient(rgb(13, 8, 43) -50%, rgb(14, 9, 52) 182.35%)",
    300: "linear-gradient(rgb(42, 24, 8) 0%, rgb(47, 27, 9) 100%)",
    200: "linear-gradient(rgb(5, 25, 14) -50%, rgb(11, 44, 21) 182.35%)",
    150: "linear-gradient(rgba(10, 6, 20, 0.7) 0%, rgba(20, 7, 31, 0.6) 100%)",
    120: "linear-gradient(rgb(31, 9, 37) 0%, rgb(17, 11, 41) 100%)",
    100: "linear-gradient(rgb(16, 6, 20) 0%, rgb(11, 14, 33) -50%)",
    75: "linear-gradient(rgb(15, 6, 20) 0%, rgb(19, 13, 33) 100%)",
    70: "linear-gradient(rgb(42, 23, 7) 0%, rgba(41, 18, 10, 0.85) 83.33%, rgb(42, 12, 9) 100%)",
    60: "linear-gradient(rgb(20, 12, 36) 0%, rgb(33, 13, 8) 100%)",
    50: "linear-gradient(rgb(13, 56, 27) 0%, rgba(43, 10, 11, 0.85) 83.33%, rgb(57, 14, 45) 100%)",
  },
  SECONDARYHOVER: { 100: "#EAECF0" },
  DANGER: {
    50: "#FEF3F2",
    100: "#FEE4E2",
    500: "#E60000",
    600: "#D92D20",
  },
  ORANGE: { 500: "#FF7800" },
  SILVERY: { 600: "#FEF3F2", 500: "#FEE4E2", 400: "#D92D20" },
  CORAL_PINK: { 500: "#FF6584" },
  PURPLE: { 25: "#F9F5FF", 600: "#6941C6" },
  NEON_BLUE: { 25: "#EFF8FF", 600: "#175CD3" },
  INDIGO: { 25: "#EEF4FF", 600: "#3538CD" },
  PINK: { 25: "#FDF2FA", 600: "#C11574" },
  PEACH: { 25: "#FFF4ED", 600: "#B93815" },
  NEON_GREEN: { 50: "#ECFDF3", 100: "#D1FADF", 500: "#039855" },
  GREEN: { 500: "#4CBB17" },
  BLUEISH: { 4: "rgba(16, 24, 40, 0.05)" },
  BLACK_SHADES: { 20: "rgba(0, 0, 0, 0.2)", 24: "rgba(0, 0, 0, 0.24)" },
};

export const styledComponentTheme = {
  DARK_MODE_COLORS,
  LIGHT_MODE_COLORS,
};
