export enum DOMAIN {
    PROD = "auth.t360degrees.com",
    STAGING = "auth.staging.t360degrees.com",
    DEV= "auth.ssnc.development.t360degrees.com"
}

export enum CLIENT_ID {
    PROD = "v19lujJ4VobAPtnQx9HEZEbCuy3PRUB3",
    STAGING = "mVFibIdD1RGipPKfuAwmOHjZueZCiLSj",
    DEV= "simAjx7kIW6slhyKipmiJViEKGcmPnrO"
}

export enum AUDIENCE {
    PROD = "https://google_api_prod",
    // TODO: change staging when api audience is changing. For now we can use dev
    // STAGING = "https://google_api_ssnc",
    DEV= "https://google_api_ssnc"
}