import data from "data/report.json";
import { CardsDataProps, InfoCardDataProps, TransactionProps } from "types";

const docsIcon = `${process.env.PUBLIC_URL}/assets/docs.svg`;
const ssiIcon = `${process.env.PUBLIC_URL}/assets/SSIIcon.svg`;
const financialIcon = `${process.env.PUBLIC_URL}/assets/FinancialsIcon.svg`;
const ddqRequestedIcon = `${process.env.PUBLIC_URL}/assets/DDQsRequestedIcon.svg`;
const ddqSupplied = `${process.env.PUBLIC_URL}/assets/docs.svg`;
const acceptIcon = `${process.env.PUBLIC_URL}/assets/accept.svg`;
const rejectIcon = `${process.env.PUBLIC_URL}/assets/reject.svg`;
const confirmedIcon = `${process.env.PUBLIC_URL}/assets/confirmed.svg`;
const progressIcon = `${process.env.PUBLIC_URL}/assets/progress.svg`;
const ticketIcon = `${process.env.PUBLIC_URL}/assets/ticket.svg`;
const clockIcon = `${process.env.PUBLIC_URL}/assets/clock.svg`;
const progressImage = `${process.env.PUBLIC_URL}/assets/progressFeedback.svg`;
const deniedImage = `${process.env.PUBLIC_URL}/assets/deniedFeedback.svg`;

export const cardsData: CardsDataProps[] = [
  { num: 1, data: data.enquiry },
  { num: 2, data: data.feedback },
  { num: 3, data: data.views },
  { num: 4, data: data.searches },
];

export const infoCardData: InfoCardDataProps[] = [
  {
    icon: docsIcon,
    num: data.downloadedDocuments,
    name: "Downloaded Documents",
  },
  {
    icon: ssiIcon,
    num: data.ssiViewed,
    name: "SSI Viewed",
  },
  {
    icon: financialIcon,
    num: data.financialViewed,
    name: "Financials Viewed",
  },
  {
    icon: ddqRequestedIcon,
    num: data.ddqsRequested,
    name: "DDQs Requested",
  },
  {
    icon: ddqSupplied,
    num: data.ddqsSupplied,
    name: "DDQs Supplied",
  },
];

export const transactionStatusCardsData: TransactionProps[] = [
  {
    img: acceptIcon,
    color: "approved",
    data: data.transactionStatus.approved,
    text: "Approved",
  },
  {
    img: progressIcon,
    color: "progress",
    data: data.transactionStatus.inProgress,
    text: "In Progress",
  },
  {
    img: rejectIcon,
    color: "denied",
    data: data.transactionStatus.denied,
    text: "Denied",
  },
];

export const alertStatusCardsData: TransactionProps[] = [
  {
    img: deniedImage,
    color: "denied",
    data: data.alertStatus.denied,
  },
  {
    img: progressImage,
    color: "progress",
    data: data.alertStatus.inProgress,
  },
  {
    img: confirmedIcon,
    color: "approved",
    data: data.alertStatus.approved,
  },
];
export interface TimeCardsProps {
  title: string;
  icon: string;
  time: number | string;
}
export const topTimeCardsData: TimeCardsProps[] = [
  {
    title: "Average Resolution Time",
    icon: clockIcon,
    time: `${data.averageResolutionTimePerHour}h`,
  },
  {
    title: "Tickets closed in less than 8 hours",
    icon: ticketIcon,
    time: data.closedTickets.lessThan8Hours,
  },
];

export const bottomTimeCardsData: TimeCardsProps[] = [
  {
    title: "Tickets closed in less than 24 hours",
    icon: ticketIcon,
    time: data.closedTickets.lessThan24Hours,
  },
  {
    title: "Tickets closed in less than 48 hours",
    icon: ticketIcon,
    time: data.closedTickets.lessThan48Hours,
  },
];
