export const PARTY_TABS = ["Profile", "FUNDS", "AFME", "CASH", "Payments"] as PARTY_TAB[];

export enum PARTY_TAB {
  DEFAULT = "",
  PROFILE = "Profile",
  FUNDS = "FUNDS",
  AFME = "AFME",
  CASH = "CASH",
  T360_PAYMENTS = "Payments",
}

export const FundDataRequestD= [
  "FIGI",
  "ISIN",
  "SEDOL",
  "Fund Provider Name",
  "Fund Full Name",
  "Currency",
  "Ticker",
  "Exchange",
  "Instrument Type",
  "Estimated inflow (£)",
  "Type of Business"
]

export const FundProfileD = [
  "Fund Name",
  "Fund Legal Structure",
  "Fund Administrator Name",
  "Provider Company Branding Name",
  "Provider Company Type",
  "Country Of Registration",
  "Fund Size",
  "Share Class",
  "Restricted Shareclass",
  "Retail/ Institutional Share Class",
  "CREST Settlement",
  "Investment Strategy",
  "Status",
  "Available via Morningstar",
  "Administrator Portal Available",
  "Priced by Morningstar",
  "Clean Shareclass",
  "CITICODE",
  "TIDM"
]

export const FundIdentifiersAndKeyInfoD = [
  "Legal Entity Identifier",
  "Sector Code",
  "EMX Product Code",
  "EMX Provider Code",
  "ON/ OFFSHORE",
  "Settlement Period (T+)",
  "Settlement Bank Account",
  "Standard Initial Charge %",
  "Hubwise Initial Charge %",
  "OCF %",
  "Annual Management Charge",
]

export const FundKeyInformationD = [
  "ON/ OFFSHORE",
  "Instrument Type",
  "Settlement Period (T+)",
  "Settlement Bank Account"
]

export const FundChargesD = [
  "Standard Initial Charge %",
  "Hubwise Initial Charge %",
  "OCF %",
  "Annual Management Charge",
]

export const FundOtherInformationD = [
  "Asset Class Title",
  "Instrument Subtype",
  "IA Sector",
  "ISA Category",
  "SIPP Eligibility",
  "Trading Frequency",
  "Dividend Frequency",
  "Income or Accumulation",
  "Fund Access Requirements",
  "STP",
  "Tradeable via Telephone",
  "Tradeable via fax",
  "Valuation Point",
  "Trade Cut Off Point",
  "Confirmation to use existing account",
  "Additional notes",
  "Progress updates"
]

export interface TableField<T> {
  name: keyof T;
  displayName?: string;
}

export interface TableData<T> {
  items: T[];
}

export type FieldBuilder<T, D> = (
  field: TableField<T>,
  data: D,
  row: number,
  column: number,
) => React.ReactNode;

export interface TableProps<T, D> {
  fields: TableField<T>[];
  tableData: D[];
  noDataMessage?: string;
  builder: FieldBuilder<T, D>;
  isLoading?: boolean;
  startColumn?: number;
  startRow?: number;
  columns?: number;
  rows?: number;
  before?: React.ReactNode;
  after?: React.ReactNode;
  onClickRow?: (id: string) => void;
  idKey?: string;
}
