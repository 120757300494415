// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { Accordion } from "components/party/accordion/Accordion";
import { 
    Container, 
    Title,
    ContentItem,
    QHolder,
    Section,
    QAccordionHolder,
    QRow,
    FormLabel,
    InputWrapper,
    Input,
    SubmitBtnHolder 
} from "styles/sippAdmin/sippAdmin.styled";
import { useState } from "react";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";

function SIPPFinancialAdvisorComponent() {
        const today = new Date().toISOString().split("T")[0];
        const formObj = {
            "Adviser Name": "",
            "Company": "",
            "Reference": "",
            "Fees": ""            
        }
        const [formValues, setFormValues] = useState<{[key: string]: any}>(formObj); 
    
        const handleInputChange = (label: string, value: string) => {
            setFormValues(prevState => ({ ...prevState, [label]: value }));
        };
    
        const handleSubmit = (event: { preventDefault: () => void; }) => {
            event.preventDefault();
            //TODO: Send formValues to backend
            console.log("Form submitted:", formValues);
        };

  return (
    <Container>
        <Title>
            Financial Advisor
        </Title>
        <Accordion title="Screen" noTopBorder>
            <div>
                <QHolder onSubmit={handleSubmit}>
                    <Section>
                        <QAccordionHolder>  
                            {Object.entries(formValues).map(([label, value]) => (
                                label !== "Date of appointment" && (
                                    <QRow key={label}>
                                        <FormLabel htmlFor={label}>{label}</FormLabel>
                                        <InputWrapper>
                                            <Input
                                                type="text"
                                                id={label}
                                                value={value}
                                                onChange={(e) => handleInputChange(label, e.target.value)}
                                            />
                                        </InputWrapper>
                                    </QRow>
                                )                                
                            ))}
                            <QRow>
                                <FormLabel htmlFor="Date of appointment">Date of Appointment</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="date"
                                        id="Date of appointment"
                                        value={formValues["Date of appointment"] ? formValues["Date of appointment"] : today}
                                        onChange={(e) => handleInputChange("Date of appointment", e.target.value)}
                                    />
                                </InputWrapper>
                            </QRow>
                        </QAccordionHolder>
                    </Section>
                </QHolder>
                <SubmitBtnHolder>
                    <SubmitButton
                        onClick={handleSubmit}
                        text={"Submit"}
                        type={"submit"}
                        disabled={false}
                    />
                </SubmitBtnHolder>
            </div>
        </Accordion>
        <Accordion title="Section Content" opened>
            <div>
                <ContentItem>Adviser Name</ContentItem>
                <ContentItem>Company</ContentItem>
                <ContentItem>Reference</ContentItem>
                <ContentItem>Date of appointment</ContentItem>
                <ContentItem>Fees</ContentItem>
            </div>
        </Accordion>
    </Container>
  );
}

export default SIPPFinancialAdvisorComponent;
