import React, { FunctionComponent, useEffect, useState } from "react";
import { 
    Container,
    RequestsContainer,
    Title,
    Request,
    WrapTable,
    RespTable,
    RespTableHeader,
    TableHeaderCell,
    RespTableBody,
    RespTableRow,
    TableBodyCell,
    TableHeaderRow,
    TableContentContainer
} from "styles/funds/ActiveRequests.styled";
import ActiveRequest from "./ActiveRequest";
import { PATH } from "constants/index";

const ActiveRequestsComponent: FunctionComponent =  () => {

    const [activeRequests, setActiveRequests]= useState<{"PID": string; "Customer": string; "funds": number}[]>([]);

    // TODO: get active requests from backend and adjust structure
    useEffect(()=>{
        setActiveRequests([
            {"PID": "FR13KRJAJQESR", "Customer": "AXA Investment Managers", "funds": 3},
            {"PID": "DEZXLFJTOSPCH", "Customer": "Allianz Global Investors GmbH", "funds": 5},
            {"PID": "GBFEPSEP1XSRJ", "Customer": "Smith & Williamson Fund Administration Limited", "funds": 2}
        ])
    },[])

    const handleRowClick = () => {
        // TODO: navigate to fund details page with an ID
        window.open(`${PATH.FUND_DATA_REQUEST}`, "_blank");
    }
      
    return (
      <Container>
          <Title>Active Requests</Title>
          <WrapTable>
            <RespTable>
                <RespTableHeader >
                    <TableHeaderRow>                        
                        <TableHeaderCell>Customer</TableHeaderCell> 
                        <TableHeaderCell>No. of Funds</TableHeaderCell> 
                    </TableHeaderRow>
                </RespTableHeader> 
                <RespTableBody>
                    {activeRequests.length > 0 && activeRequests.map((item, i) => (
                        <React.Fragment key={i}>
                            <RespTableRow onClick={handleRowClick}>
                                <TableBodyCell>{item.Customer}</TableBodyCell>
                                <TableBodyCell>{item.funds}</TableBodyCell>
                            </RespTableRow>
                        </React.Fragment>
                    )) }
                </RespTableBody>
            </RespTable>
          </WrapTable>
      </Container>
    )
};

export default ActiveRequestsComponent;