import {useAuth0, User} from "@auth0/auth0-react";
import {UserDetailsAuth0} from "types";
import {useEffect, useState} from "react";

/**
 * Retrieving the name, given name, family name and initials info regarding a user from auth0 default user information.
 * @param userData the user information retrieved from auth0 hook (available after login)
 */
export const parseUserDataAuth0 = (userData: User | undefined): UserDetailsAuth0 | null => {
    if(userData) {
        return {
            name: userData.name! && userData.name,
            givenName: userData.given_name,
            familyName: userData.family_name,
            initials:
                userData.given_name && userData.family_name
                ? userData.given_name.charAt(0) + userData.family_name.charAt(0)
                : "OU",
            email: userData.email,
        }
    }
    return null;
}

/**
 * Using the Auth0 custom user claims, check if the authenticated user is part of a group
 * @param user user info provided by Auth0 hook
 * @param {string} userClaimName property key of the custom user claim
 * @param {string} groupName name of the Auth0 users group
 */
export const isUserPartOfAuth0Group = (
    user: User | undefined,
    userClaimName: string,
    groupName: string,
): boolean => {
    const userClaimNameObject =
        user && user[userClaimName] ? Object.entries(user[userClaimName]) : [];
    return userClaimNameObject.some(([, value]: [string, unknown]) => value === groupName);
};

export const getAccessToken = (): string | undefined => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState<string>();

    useEffect(() => {
        if (isAuthenticated) {
            getAccessTokenSilently().then(token => {setAccessToken(token)})
        }
    }, [isAuthenticated]);
    return accessToken;
};