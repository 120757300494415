import {
    Container,
    Header,
    Title,
    ReportHolder,
    DownloadBtn,
    DownloadIcon
} from "styles/client/Report.styled";
import { useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {  HUBWISE_REPORT_TYPE } from "constants/filter";
import LoadingSpinner from "components/LoadingSpinner";
import { ReportSummaryType, ReportDetailedType } from "types/funds";
import NoDataContent from "components/noData/NoDataContent";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";
import ReportFilter from "./ReportFilter";
import ReportSummary from "./ReportSummary";
import ReportDetails from "./ReportDetails";
  
const downloadIcon = `${process.env.PUBLIC_URL}/assets/downloadIcon.svg`;

const HubwiseReportComponent = () => {
    const [typeSelected, setTypeSelected] = useState<string>("");
    const [reportGenerated, setReportGenerated]= useState<boolean>(false);
    const [isLoading, setIsLoading]= useState<boolean>(false);
    const [reportSummaryData, setReportSummaryData]= useState<ReportSummaryType | undefined>();
    const [reportDetailsData, setReportDetailsData]= useState<ReportDetailedType | undefined>();
    const [tableData, setTableData]= useState<(string | { role: string; } | number)[][]>([]);

    const reportRef = useRef<HTMLDivElement>(null);

    const downloadPdf = async () => {
        if (reportRef.current) {
            // Create jsPDF instance with landscape orientation
            const pdf = new jsPDF("l", "pt", "a4"); // "l" for landscape, "pt" for points, "a4" for A4 size
      
            // Capture the report area
            const canvas = await html2canvas(reportRef.current, {
              scale: 2, // Higher scale for better quality
            });
      
            const imgData = canvas.toDataURL("image/png");
            const imgWidth = 800; // Adjust based on your layout
            const pageHeight = pdf.internal.pageSize.height;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
      
            let position = 20;
      
            // Add image to PDF
            pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
      
            // Add additional pages if needed
            while (heightLeft >= 0) {
              position = heightLeft - imgHeight;
              pdf.addPage();
              pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;
            }
      
            // Save the generated PDF
            pdf.save("report.pdf");
          }
      };    

    return (
        <Container>
            <Header>
                <Title>Reports</Title>
            </Header>
            <ReportFilter 
                setReportSummaryData= {setReportSummaryData}
                setReportDetailsData = {setReportDetailsData}
                setIsLoading= {setIsLoading}
                setTableData= {setTableData}
                setReportGenerated= {setReportGenerated}
                setTypeSelected= {setTypeSelected}
                typeSelected={typeSelected}
            />
            {reportGenerated && (
                isLoading ? <LoadingSpinnerDiv><LoadingSpinner /></LoadingSpinnerDiv>  : 
                tableData && 
                    <ReportHolder>
                        <ReportSummary
                            reportRef={reportRef}
                            tableData={tableData}
                            reportSummaryData= {reportSummaryData}
                        />
                        { typeSelected === HUBWISE_REPORT_TYPE.DETAILED && reportDetailsData ? 
                            <ReportDetails 
                                reportDetailsData={reportDetailsData}
                            />
                        : typeSelected === HUBWISE_REPORT_TYPE.DETAILED && !reportDetailsData && <NoDataContent />}
                        <DownloadBtn onClick={downloadPdf}>
                            <DownloadIcon src={downloadIcon} />
                            Download Report
                        </DownloadBtn>
                    </ReportHolder>
                    
            )}
        </Container>
    );
}
  
export default HubwiseReportComponent;
  