import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import agentCodes from "data/agentCodesFile.json";
import { useAppData } from "hooks/useAppData";
import { PARTY } from "constants/index";
import NoData from "components/noData/NoData";
import HubwiseAgentCodesComponent from "components/hubwise-dashboard/HubwiseAgentCodes";

const HubwiseAgentCodes = () => {

    const { isUserPartOfOrganization } = useAppData();
    const { track } = useTrackActivity();

    useEffectOnce(()=>{
        track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_DASHBOARD_MASTER_BUYLIST);
    });
    return (
        isUserPartOfOrganization([PARTY.T360]) ? <NoData /> 
        : isUserPartOfOrganization([PARTY.HUBWISE]) ? <HubwiseAgentCodesComponent data={agentCodes}/> 
        : <NoData />
    )
     
}

export default HubwiseAgentCodes;