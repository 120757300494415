import {
    Container,
    Header,
    Title,
    FContainer,
    StartDatePicker,
    EndDatePicker,
    FilterContainer,
    FilterLabel,
    GenerateReportBtn,
    GenerateReportBtnHolder,
    DropDownName,
    DropDown
} from "styles/client/Report.styled";
import {
    DropDownWrapper,
    InnerCover,
    Left,
    Right,
    Icon,
    ArrowDownIcon,
    arrowDown,
    statusIcon,
  } from "styles/FilterSearch.styled";
import React, {FunctionComponent, useState } from "react";
import { startOfWeek, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import DropDownContent from "components/admin/table/DropDownContent";
import { HUBWISE_REPORT_TYPES } from "constants/filter";
import {
    FUNDS_DATA_REPOSITORY_BUCKET_NAME,
    DELTAS_FILE_PATH,
    ARCHIVE_FILE_PATH
  } from "config/googleCloudConfig";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import { GET_DELTAS } from "config/googleCloudFunctionsConfig";
import { getAccessToken } from "services/auth0/auth0";
import { ReportSummaryType, ReportDetailedType } from "types/funds";

const ReportFilter: FunctionComponent<{
    setReportSummaryData: React.Dispatch<React.SetStateAction<ReportSummaryType | undefined>>;
    setReportDetailsData: React.Dispatch<React.SetStateAction<ReportDetailedType | undefined>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setTableData: React.Dispatch<React.SetStateAction<(string | { role: string; } | number)[][]>>;
    setReportGenerated: React.Dispatch<React.SetStateAction<boolean>>;
    setTypeSelected: React.Dispatch<React.SetStateAction<string>>;
    typeSelected: string
}> =  ({
    setReportSummaryData,
    setReportDetailsData,
    setIsLoading,
    setTableData,
    setReportGenerated,
    setTypeSelected,
    typeSelected
}) => {
    const [selectedStartDate, setSelectedStartDate] = useState< string | null>("");
    const [selectedEndDate, setSelectedEndDate]= useState< string | null>("");
    const [typeOpen, setTypeOpen] = useState<boolean>(false);

    const token = getAccessToken();

    type ApiDataKeys= "total_fm_additions" | "total_fm_removals" | "total_fund_additions" | "total_fund_removals" | "total_funds_subject_to_change"; 

    const customHeaders: Record<ApiDataKeys, string>= {
        total_fm_additions: "Added Fund Managers",
        total_fm_removals: "Removed Fund Managers", 
        total_fund_additions: "Added Funds",
        total_fund_removals: "Removed Funds", 
        total_funds_subject_to_change: "Updated Funds"
    }

    const minDate = new Date("2024-08-26");  // Earliest selectable date
   const maxDate = new Date("2024-10-20");  // Latest selectable date

    const createChartData = (apiData: ReportSummaryType): (string | number | {role: string})[][] => {
        const headers = ["Item", "Value", { role: "style" }];  // X-axis label and Y-axis value
        const colors= ["#bbd097", "#c86767", "#528cbc", "#937a46", "#51e48a"];
        const rows = (Object.keys(apiData) as ApiDataKeys[]).map((key,index) => [customHeaders[key], apiData[key], colors[index]]);
      
        return [headers, ...rows];
    };

    const handleGenerateClick = () => {
        setReportGenerated(true);
        setIsLoading(true);
        
        if(token) {

            const jsonFile1 = `master_buylist_${selectedStartDate}.json`;
            const jsonFile2 = `master_buylist_${selectedEndDate}.json`;
            const deltasPayload = {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName1": jsonFile1,
                "fileName2": jsonFile2,
                "filePath": `${ARCHIVE_FILE_PATH}`
            };
            callGCFunctionWIthAccessToken(token, GET_DELTAS, deltasPayload, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: {summary: ReportSummaryType, deltas: ReportDetailedType})=> {
                if(data) {
                    setTableData(createChartData(data.summary));
                }
                setReportSummaryData(data.summary);
                setReportDetailsData(data.deltas);
                setIsLoading(false);
            });
        }
    }

    return (
        <Container>
            <FContainer>
                <FilterContainer>
                    <FilterLabel htmlFor="start-date-picker" className="datepicker-label">
                        Select Start Date:
                    </FilterLabel>
                    <StartDatePicker 
                        selected={selectedStartDate}
                        onChange={(date: Date) =>  setSelectedStartDate(format(startOfWeek(date, { weekStartsOn: 1 }), "yyyy-MM-dd") )}
                        showWeekPicker
                        showPopperArrow
                        showWeekNumbers
                        dateFormat="yyyy-MM-dd"
                        value= {selectedStartDate ? selectedStartDate : "Select a start date"}
                        minDate={minDate}  
                        maxDate={maxDate} 
                    />
                </FilterContainer>
                <FilterContainer>
                    <FilterLabel htmlFor="end-date-picker" className="datepicker-label">
                        Select End Date:
                    </FilterLabel>
                    <EndDatePicker 
                        selected={selectedEndDate}
                        onChange={(date: Date) =>  setSelectedEndDate(format(startOfWeek(date, { weekStartsOn: 1 }), "yyyy-MM-dd"))}
                        showWeekPicker
                        showWeekNumbers
                        showPopperArrow
                        dateFormat="yyyy-MM-dd"
                        value={selectedEndDate ? selectedEndDate : "Select an end date"}
                        minDate={minDate}  
                        maxDate={maxDate}  
                    />
                </FilterContainer>
                <FilterContainer>
                    <FilterLabel htmlFor="report-type-picker" className="report-picker-label">
                        Select Report Type:
                    </FilterLabel>
                    <DropDownWrapper>
                        <DropDown onClick={() => setTypeOpen(!typeOpen)} role="button">
                            <InnerCover>
                            <Left>
                                <Icon src={statusIcon} />
                                <DropDownName>
                                {typeSelected === "" ? "Report Type" : typeSelected}
                                </DropDownName>
                            </Left>
                            <Right>
                                <ArrowDownIcon src={arrowDown} />
                            </Right>
                            </InnerCover>
                        </DropDown>
                        {typeOpen === true && 
                            <DropDownContent
                                onSelect={setTypeSelected}
                                setOpen={setTypeOpen}
                                items={HUBWISE_REPORT_TYPES}
                            />
                        }
                    </DropDownWrapper>
                </FilterContainer>
                <FilterContainer>
                    <GenerateReportBtnHolder>
                        <GenerateReportBtn disabled={typeSelected === ""} onClick={handleGenerateClick}>Generate Report</GenerateReportBtn>
                    </GenerateReportBtnHolder>
                </FilterContainer>
            </FContainer>
        </Container>
    );
}
  
export default ReportFilter;
  