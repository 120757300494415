// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * Configuration properties for filterable tables.
 */

import { TableConfiguration } from "../types/filterableTable";

export const tableConfigurations: TableConfiguration[] = [
  {
    // table name
    name: "FP",
    // configuration for the headers
    columnDetails: [
      {
        accessor: "Filing Type",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        accessor: "Number of Months",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    // additional table options
    additionalTableOptions: {
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: "Closing Date",
            desc: true,
          },
        ],
      },
    },
    // table data format properties
    dataFormatConfiguration: [
      {
        accessorName: "Closing Date",
        format: "date",
      },
      {
        accessorName: "Exchange Rate from original currency",
        format: "decimal_5",
      },
      {
        accessorName: "Profit Margin",
        format: "decimal_2",
      },
      {
        accessorName: "Operational Revenue",
        format: "comma_figures",
      },
      {
        accessorName: "Total Assets",
        format: "comma_figures",
      },
      {
        accessorName: "Number of Employees",
        format: "comma_figures",
      },
    ],
  },
];
