import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import ActiveRequestsComponent from "components/funds/fundManager/ActiveRequestsComponent";

const ActiveRequests = () => {
    const { track } = useTrackActivity();

    useEffectOnce(()=>{
        track(ActivityType.LOAD_PAGE, TRACKER.FUND_PARTY_MANAGER_FM_ACTIVE_REQUESTS);
    });
    
    return <ActiveRequestsComponent />
}

export default ActiveRequests;