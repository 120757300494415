import { TRACKER } from "constants/tracker";
import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import HubwiseReportComponent from "components/hubwise-dashboard/reports/HubwiseReportComponent";

const ReportsGenerator = () => {
  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.CLIENT_REPORTS);
  });
  return (
    <HubwiseReportComponent />
  );
};

export default ReportsGenerator;
