import { Outlet } from "react-router-dom";
import SideBar from "../../components/client/SideBar";
import { Container, Content, Nav, OutletCover } from "../../styles/admin/index.styled";
import AdminNavbar from "../../components/admin/Navbar";

function AdminRoom() {
  return (
    <Container>
      <SideBar />
      <Content>
        {/* nav */}
        <Nav>
          <AdminNavbar />
        </Nav>

        {/* outlet  */}
        <OutletCover>
          <Outlet />
        </OutletCover>
      </Content>
    </Container>
  );
}

export default AdminRoom;
