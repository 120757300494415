import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import DashboardComponent from "../../components/admin/Dashboard";

function Dashboard() {
  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.ALICE_ADMIN_DASHBOARD);
  });

  return <DashboardComponent />;
}

export default Dashboard;
