// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the main component for Party Profile
 */

import React, { FunctionComponent, useEffect, useState } from "react";
import DATA from "data/SSI.json";
import SEPA_DATA from "data/SEPA.json";
import { Accordion } from "components/party/accordion/Accordion";
import { History } from "components/party/History";
import { Identifiers } from "components/party/Identifiers";
import { Hierarchy } from "components/party/Hierarchy";
import { KeyPeople } from "components/party/keyPeople/KeyPeople";
import { Documents } from "components/party/Documents";
import { PartyDetails } from "types";
import { getSSI } from "services/ssi";
import { getSEPA } from "services/sepa";
import { useParams } from "react-router-dom";
import { useToast } from "hooks/useToast";
import SSI from "./ssi/SSI";
import SEPA from "./SEPA";
import { FieldsGroup } from "./FieldsGroup";
import { MembershipAndListings } from "./MembershipAndListings";
import { getAccessToken } from "services/auth0/auth0";
import ManagedFundsTable from "./funds/ManagedFundsTable";

const GroupedDetails: FunctionComponent<{ 
  data: PartyDetails; 
  partyId: string
}> = ({
  data,
  partyId
}) => {
  const [ssiData, setSSIData] = useState<typeof DATA | null>(null);
  const [sepaData, setSepaData] = useState<typeof SEPA_DATA | null>(null);

  const token  = getAccessToken();

  const { id: pid } = useParams<{ id: string }>();
  const { toast } = useToast();


  useEffect(() => {    
    if (token) {
      if(pid) {
        getSEPA(token, pid)
        .then((data) => {
          setSepaData(data);
        })
        .catch((err: Error) => toast(err.toString()));

        getSSI(token, pid)
          .then((data) => {
            setSSIData(data);
          })
          .catch((err: Error) => toast(err.toString()));
      }
    }
  }, [token, pid, partyId]);

  if (!data) return null;
  return (
    <div>
      <Accordion key={"identifiers-accordion"} title={"Identifiers"} medium noTopBorder>
          { data.Identifiers && <Identifiers data={data.Identifiers} key={"identifiers-comp"} /> }
      </Accordion>
      <Accordion key={"activities-accordion"} title={"Activities"} medium>
          { data.Activities && <FieldsGroup data={data.Activities} key={"activities-comp"} /> }
      </Accordion>
      <Accordion key={"managed-funds-accordion"} title={"Funds Under Management"} medium >
        <ManagedFundsTable partyId={partyId} />
      </Accordion>
      <Accordion key={"history-accordion"} title={"History"} medium>
        { data.History && <History data={data.History} key={"history-com"} /> }
      </Accordion>
      <Accordion key={"hierarchy-accordion"} title={"Hierarchy / Group Info"} medium>
        { data["Hierarchy / Group Info"] && 
          <div>
            <FieldsGroup data={data["Hierarchy / Group Info"]} key={"hierarchy-fields"} />
            <Hierarchy partyId={partyId} key={"hierarchy-comp"} />
          </div> 
        } 
      </Accordion>
      {/* TODO: Key People accordion should be uncommented once data is available
        currently it is throwing an error */}

      {/* <Accordion key={"key-people-accordion"} title={"Key People"} small>
        <KeyPeople data={data["Key People"]} key={"key-people-comp"} />
      </Accordion> */}
      <Accordion key={"documents-accordion"} title={"Documents  / KYC"} medium>
        <Documents partyId={partyId} key={"documents-comp"} />
      </Accordion>
      <Accordion key={"membership-accordion"} title={"Membership & Listing"} medium>
        { data["Membership & Listing"] && <MembershipAndListings data={data["Membership & Listing"]} key={"memberships-listings-comp"} /> }
      </Accordion>
      <Accordion key={"sepa-accordion"} title={"SEPA"} medium>
        <SEPA key={"sepa-comp"} data={sepaData!} />
      </Accordion>
      <Accordion key={"ssi-accordion"} title={"Standing Settlement Instructions (SSI)"} medium>
        <SSI key={"ssi-comp"} data={ssiData} />
      </Accordion>

      {Object.entries(data!)?.map(([key, dataToDisplay]: [string, any], i) => {
        // this part is hardcoded, so has to be changed in the future!
        switch (key) {
          case "Identifiers":
            return null;
          case "Activities":
              return null;
          case "History":
            return null;
          case "Hierarchy / Group Info":
            return null;
          case "Key People":
            return null;
          case "Documents / KYC":
            return null;
          case "SEPA":
            return null;
          case "Standing settlement Instructions (SSI)":
            return null;
          case "Funds":
            return null;
          case "Contact Information":
            return null;
          case "Membership & Listing":
            return null;
          case "Financial Performance":
              return null;
          default:
            // if it's not a specific case, just use the general layout
            return (
              <Accordion
                key={i}
                title={key === "Mergers & Aquisitions" ? "Mergers & Acquisitions" : key}
                medium
              >
                <FieldsGroup data={dataToDisplay} key={i} />
              </Accordion>
            );
        }
      })}
    </div>
  );
};

export default GroupedDetails;
