import DATA from "data/SEPA.json";
import { FC } from "react";
import { TableContent } from "components/tableContent/TableContent";
import { NoDataToDisplay } from "components/noData/ComingSoon";

const SEPA: FC<{ data: typeof DATA | null }> = ({ data }) => {
  if (!data) return <NoDataToDisplay />;
  return <TableContent data={data?.[1]} />;
};

export default SEPA;
