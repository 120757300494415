// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

  import HubwiseDashboardSideBar from "./HubwiseDashboardSideBar";
  import { Content } from "styles/funds/MyView.styled";
  import { Outlet } from "react-router-dom";
  import { Container, OutletCover } from "styles/funds/HubwiseDashboard.styled";
  import { Nav } from "../../styles/admin/index.styled";
  import HubwiseNavbar from "./HubwiseNavbar";

  
  function HubwiseDashboardMenu() {
  
    return (
      <Container>
        <HubwiseDashboardSideBar />
        <Content>
          {/* nav */}
          <Nav>
            <HubwiseNavbar /> 
          </Nav>
          {/* outlet  */}
          <OutletCover>
            <Outlet />
          </OutletCover>
        </Content>        
      </Container>
    );
  }
  
  export default HubwiseDashboardMenu;
  