// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the initial Default Search Page of Party Manager.
 */
import { useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import BasicSearch from "components/searchComponents/BasicSearch";
import IdentifierSearch from "components/searchComponents/IdentifierSearch";
import { SEARCH, OPTIONS, FUND_OPTIONS, DISABLED_OPTIONS } from "constants/search";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { useAppData } from "hooks/useAppData";
import { PARTY } from "constants/index";
import { TRACKER } from "constants/tracker";
import {
  SearchMenu,
  SearchContainer,
  DropDownContainer,
  DropDownHeader,
  DropDownListContainer,
  DropDownList,
  ListItem,
  SearchWrapper,
} from "../../styles/Search.styled";

export function Search() {
  const { track } = useTrackActivity();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Full Search");
  const { isUserPartOfOrganization } = useAppData();

  //  This Function for toggleing drodown menu
  const toggling = () => setIsOpen(!isOpen);

  // This Fuction sets the dropdown menu options based on what organisation the logged in user belongs to
  useEffectOnce(() => {
    if (isUserPartOfOrganization([PARTY.HUBWISE])) {
      setSelectedOption(FUND_OPTIONS[0]);
    } else {
      setSelectedOption(OPTIONS[0]);
    }
    track(ActivityType.LOAD_PAGE, TRACKER.SEARCH_PAGE);
  });

  //  This Function desides what happens when one of the dropdown option is selected
  const onOptionClicked = (value: string) => () => {
    if (DISABLED_OPTIONS.includes(value)) {
      return null;
    }
    setSelectedOption(value);
    setIsOpen(false);
  };

  return (
    <SearchWrapper className="container">
      <SearchMenu>
        <DropDownContainer>
          <DropDownHeader onClick={toggling}>
            {selectedOption || "Select"}
            <div>
              <KeyboardArrowDownOutlinedIcon />
            </div>
          </DropDownHeader>
          {isOpen && (
            <DropDownListContainer>
              <DropDownList>
                {(isUserPartOfOrganization([PARTY.HUBWISE]) ? FUND_OPTIONS : OPTIONS).map(
                  (option) => (
                    <ListItem
                      disabled={DISABLED_OPTIONS.includes(option)}
                      onClick={onOptionClicked(option)}
                      key={Math.random()}
                    >
                      {option}
                    </ListItem>
                  ),
                )}
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
      </SearchMenu>

      <SearchContainer>
        {selectedOption === SEARCH.FULL_SEARCH && <BasicSearch />}
        {selectedOption === SEARCH.IDENTIFIER_SEARCH && <IdentifierSearch />}
      </SearchContainer>
    </SearchWrapper>
  );
}

export default Search;
