import { FunctionComponent } from "react";
import { 
    TopContainer,
    TopBottomContainer,
    AccordionHolder
} from "styles/funds/Onboarding.styled";
import AverageOnboardingTime from "./AverageOnboardingTime";
import CcIdProgress from "./CcIdProgress";
import { ClientRequestTemplateType } from "types/party";
import NoData from "components/noData/NoData";
import { Accordion } from "components/party/accordion/Accordion";

const OnboardingBox: FunctionComponent<{data: ClientRequestTemplateType[]}> =  ({data}) => {

    return data ? (
        <TopContainer>
            <AccordionHolder>
                <Accordion title="Dashboard" noTopBorder opened>
                    <TopBottomContainer>
                        <AverageOnboardingTime data={data} showTitle boxShadow/>
                        <CcIdProgress data={data} showTitle boxShadow/> 
                    </TopBottomContainer>
                </Accordion>
            </AccordionHolder>
        </TopContainer>
    ) : <NoData />
};

export default OnboardingBox;