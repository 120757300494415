import { NewAliceRedirectDataType } from "types";

export const isAliceRedirectState = (value:any): value is NewAliceRedirectDataType => {
    return (
      typeof value === "object" &&
      value !== null &&
      typeof value.from === "object" &&
      value.from !== null &&
      typeof value.from.tab === "string"
    );
}