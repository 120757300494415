import { FunctionComponent } from "react";
import {
    TemporaryFundsType,
    TemporaryFigiMapType
 } from "types/funds";
 import { 
    BuylistArea,
    BuylistsHolder,
    BuylistItem,
    ListPValue,
    ListPLabel,
    ListHolder
} from "styles/funds/MasterBuylist.styled";
import { FormLabel} from "styles/fundDetailsConfirmation/FundDetailsConfirmation.styled";
import BuylistsSelectionForm from "./BuylistsSelectionForm";
import AliceModalTooltip from "components/AliceModalTooltip";
import { Accordion } from "components/party/accordion/Accordion";

const SelectedFundForm : FunctionComponent<{
    selectedFund: {index: number; obj: TemporaryFundsType} | undefined;
    selectedFigi: {index: number; obj: TemporaryFigiMapType} | undefined;
    targetRef: React.RefObject<HTMLDivElement>
}> =  ({
    selectedFund,
    selectedFigi,
    targetRef
}) => {  

    return (
        <Accordion key={"instrument-dashboard-accordion"} title={"Fund Details"} noTopBorder opened>
            <div ref={targetRef}>
                {selectedFigi? (
                    <ListHolder>
                        <ListPLabel>Fund Name</ListPLabel>
                        <AliceModalTooltip
                            selectedField={selectedFigi.obj.name ? selectedFigi.obj.name : "-"}
                            selectedKey={"Instrument Name"}
                            // TODO: replace isin with fund record id
                            selectedFund={selectedFund?.obj.ISIN}
                            isSmall
                            isCard={false}
                        >
                            <ListPValue>{selectedFigi.obj.name}</ListPValue>
                        </AliceModalTooltip>
                        <ListPLabel>FIGI</ListPLabel>
                        <AliceModalTooltip
                            selectedField={selectedFigi.obj.figi}
                            selectedKey={"figi"}
                            // TODO: replace isin with fund record id
                            selectedFund={selectedFund?.obj.ISIN}
                            isSmall
                            isCard={false}
                        >
                            <ListPValue>{selectedFigi.obj.figi}</ListPValue>
                        </AliceModalTooltip>
                    </ListHolder>
                    

                ): (
                    <ListHolder>
                        <ListPLabel>Fund Name</ListPLabel>
                        <AliceModalTooltip
                            selectedField={selectedFund?.obj["Instrument Name"] ? selectedFund?.obj["Instrument Name"]: "-"}
                            selectedKey={"Instrument Name"}
                            // TODO: replace isin with fund record id
                            selectedFund={selectedFund?.obj.ISIN}
                            isSmall
                            isCard={false}
                        >
                            <ListPValue>{selectedFund?.obj["Instrument Name"]}</ListPValue>
                        </AliceModalTooltip>
                        <ListPLabel>FIGI</ListPLabel>
                        <AliceModalTooltip
                            selectedField={"-"}
                            selectedKey={"figi"}
                            // TODO: replace isin with fund record id
                            selectedFund={selectedFund?.obj.ISIN}
                            isSmall
                            isCard={false}
                        >
                            <ListPValue>{"-"}</ListPValue>
                        </AliceModalTooltip>
                        
                    </ListHolder>
                )}
                {selectedFund && (
                    <>
                        <ListHolder>
                            <ListPLabel>ISIN</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedFund?.obj.ISIN}
                                selectedKey={"ISIN"}
                                // TODO: replace isin with fund record id
                                selectedFund={selectedFund?.obj.ISIN}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedFund?.obj.ISIN}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>SEDOL</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedFund?.obj.Sedol}
                                selectedKey={"Sedol"}
                                // TODO: replace isin with fund record id
                                selectedFund={selectedFund?.obj.ISIN}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedFund?.obj.Sedol}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>Instrument Type</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedFund?.obj["Instrument Type"]}
                                selectedKey={"Instrument Type"}
                                // TODO: replace isin with fund record id
                                selectedFund={selectedFund?.obj.ISIN}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedFund?.obj["Instrument Type"]}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>Exchange Code</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedFund?.obj["Exchange Code"] ? selectedFund?.obj["Exchange Code"] : "-"}
                                selectedKey={ "Exchange Code"}
                                // TODO: replace isin with fund record id
                                selectedFund={selectedFund?.obj.ISIN}
                                isSmall
                                isCard={false}
                            >
                                <ListPValue>{selectedFund?.obj["Exchange Code"] ? selectedFund?.obj["Exchange Code"] : "-"}</ListPValue>
                            </AliceModalTooltip>
                            <ListPLabel>Ringfenced*</ListPLabel>
                            <AliceModalTooltip
                                selectedField={selectedFund? selectedFund.obj.lists.some(item => item["Buylist Code"] === "BLST01" && item["Buylist Description"] === "Standard Buylist") ? "N" : "Y" : "-"}
                                selectedKey={"Ring-fenced"}
                                // TODO: replace isin with fund record id
                                selectedFund={selectedFund?.obj.ISIN}
                                isSmall
                                isCard={false}
                            >
                                {/* TODO: update the value to be in the json directly instead of calculating at render time */}
                                <ListPValue>{selectedFund? selectedFund.obj.lists.some(item => item["Buylist Code"] === "BLST01" && item["Buylist Description"] === "Standard Buylist") ? "N" : "Y" : "-"}</ListPValue>
                            </AliceModalTooltip>
                        
                    </ListHolder>
                    <BuylistArea>
                        <FormLabel>Buylists in which this Asset is available: </FormLabel>
                        <BuylistsHolder>
                            {selectedFund.obj.lists.length > 0 && selectedFund.obj.lists.map((list, index) => (
                                <BuylistItem key={index}>
                                    <ListPLabel>{list["Buylist Code"]}</ListPLabel>
                                    <AliceModalTooltip
                                        selectedField={selectedFund.obj.lists[index]["Buylist Code"]}
                                        selectedKey={"Buylist Code"}
                                        // TODO: replace isin with fund record id
                                        selectedFund={selectedFund?.obj.ISIN}
                                        isSmall
                                        isCard={false}
                                    >
                                        <ListPValue>{selectedFund.obj.lists[index]["Buylist Description"]}</ListPValue>
                                    </AliceModalTooltip>
                                </BuylistItem>
                            ))}
                            
                        </BuylistsHolder>
                    {/* {editEnabled && ( 
                        <BuylistsSelectionForm 
                            selectedFund={selectedFund}
                            setSelectedFund={setSelectedFund}
                            buylistsInfo={buylistsInfo}
                        />
                    )} */}
                </BuylistArea>
                    </>
                    
                )}
            </div>
        </Accordion>
        
    )
}

export default SelectedFundForm;