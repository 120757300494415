import styled from "styled-components";

const NoContent = styled.div`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-top: 15px;
  font-family: "Roboto Condensed";
  color: ${({ theme }) => theme.colors.GREY[500]};
`;

function NoDataContent() {
  return <NoContent data-testid="no-data">No record found to display</NoContent>;
}

export default NoDataContent;
