import { FunctionComponent, useState } from "react";
import { 
    ContentContainer,
    BottomContainer,
    MasterBuylistContainer,
    Title,
    NavContainer,
    NavItem
} from "styles/funds/MasterBuylist.styled";
import NoData from "components/noData/NoData";
import HuwiseInstrumentManagerDashboard from "./masterBuylistDashboard/HubwiseInstrumentManagerDashboard";
import HubwiseMasterBuylistSearchInstrumentComponent from "./searchInstrument/HubwiseBuylistSearchInstrument";
import { TemporaryMasterInstrumentsFundManagerType } from "types/funds";
import { MASTER_INSTRUMENT_LIST_TABS, MASTER_INSTRUMENT_LIST_TAB } from "constants/hubwiseDashboard";
import HubwiseMasterBuylistViewComponent from "./buylistView/BuylistView";

const HubwiseMasterBuylistComponent: FunctionComponent<{
    data: TemporaryMasterInstrumentsFundManagerType[];
    buylistsInfo: {"Buylist Code": string, "Buylist Description": string}[];
}> =  ({
    data,
    buylistsInfo
}) => {

    const [tab, setTab] = useState<MASTER_INSTRUMENT_LIST_TAB>(MASTER_INSTRUMENT_LIST_TAB.INSTRUMENTS);
    const [dataToDisplay, setDataToDisplay]= useState<TemporaryMasterInstrumentsFundManagerType[]>(data);
    
    return !data ? <NoData /> : (
        <MasterBuylistContainer>
            <Title>Instrument Manager</Title>
            <ContentContainer>
                <HuwiseInstrumentManagerDashboard data={data} buylistsInfo={buylistsInfo}/>
                
                <NavContainer>
                    {MASTER_INSTRUMENT_LIST_TABS.map((tabl)=>(
                        <NavItem 
                            key={tabl} 
                            isActive={tab === tabl} 
                            onClick={()=>setTab(tabl)}
                        > 
                            {tabl}
                        </NavItem>
                    ))}
                </NavContainer>
                
                <BottomContainer>
                    {tab === MASTER_INSTRUMENT_LIST_TAB.INSTRUMENTS && <HubwiseMasterBuylistSearchInstrumentComponent buylistsInfo={buylistsInfo} dataToDisplay={dataToDisplay} setDataToDisplay={setDataToDisplay}/>}
                    {tab === MASTER_INSTRUMENT_LIST_TAB.BUYLISTS && <HubwiseMasterBuylistViewComponent buylistsInfo={buylistsInfo} dataToDisplay={dataToDisplay} setDataToDisplay={setDataToDisplay}/>}
                </BottomContainer>
            </ContentContainer>
        </MasterBuylistContainer>
    )
};

export default HubwiseMasterBuylistComponent;