import styled from "styled-components";

export const calendarIcon = `${process.env.PUBLIC_URL}/assets/calendar.svg`;
export const arrowDown = `${process.env.PUBLIC_URL}/assets/arrow-down.svg`;
export const statusIcon = `${process.env.PUBLIC_URL}/assets/status.svg`;

export const DropDown = styled.div`
  width: 181px;
  height: min-content;
  background: ${({ theme }) => theme.colors.GREYED[800]};
  border: 1px solid ${({ theme }) => theme.colors.GRAY[300]};
  border-radius: 8px;
  cursor: pointer;
`;
export const DropDownWrapper = styled.div`
  display: block;
  position: relative;
`;
export const InnerCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-top: 6px;
`;
export const Icon = styled.img`
  width: 15px;
  height: 16.67px;
`;
export const ArrowDownIcon = styled.img`
  width: 24px;
  height: 24px;
`;
export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const Right = styled.div``;
export const DropDownName = styled.div`
  color: ${({ theme }) => theme.colors.DARK[500]};
  font-family: "Roboto Condensed", "sans-serif";
  font-size: 14px;
  line-height: 16px;
`;
