import { initialUltimateOwnerState, JsonData, Neo4jResultJson, RelatedEntity } from "../types";
import { GET_LNH_STATS } from "config/googleCloudFunctionsConfig";
import {callGCFunctionWIthAccessToken} from "services/callGCFunction";


export const ultimateOwnerCall = async (
  ultimateOwnerType: string,
  idForNeo4j: string,
  accessToken?: string,
) => {
  // GUO_FOR_SEARCH_SUGGESTION
  let suggestion: RelatedEntity = initialUltimateOwnerState;
  // GUO
  let guo: RelatedEntity = initialUltimateOwnerState;
  // DUO
  let duo: RelatedEntity = initialUltimateOwnerState;

  if (accessToken) {
    const paramsObj = {
      pid: idForNeo4j,
      ultimateOwnerType,
    };

    callGCFunctionWIthAccessToken(accessToken, GET_LNH_STATS, paramsObj, "test", "test")
    .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
    .then((responseJson: Neo4jResultJson) => {
        if (responseJson.results[0].data.length > 0) {
          if (ultimateOwnerType === "GUO_FOR_SEARCH_SUGGESTION") {
            const isGuoCheck =
              responseJson.results[0].data[0].row[0].id ===
              responseJson.results[0].data[0].row[1].id;
            if (isGuoCheck) suggestion = responseJson.results[0].data[0].row[0];
            else suggestion = responseJson.results[0].data[0].row[1];
            suggestion.isGuo = isGuoCheck;
            suggestion.targetName = responseJson.results[0].data[0].row[1].name;
          } else if (ultimateOwnerType === "GUO") {
            guo = responseJson.results[0].data[0].row[0];
            guo.notInNeo = false;
          } else {
            duo = responseJson.results[0].data[0].row[0];
            duo.notInNeo = false;
          }
        }
      })
      .catch((err) => {
        // TODO: re-add ErrorDisplay here
        // ErrorDisplay(err, "API call to Neo4j server for retrieving " + ultimateOwnerType, "ultimateOwner")
      });
  }

  if (ultimateOwnerType === "GUO_FOR_SEARCH_SUGGESTION") return suggestion;
  if (ultimateOwnerType === "GUO") return guo;
  return duo;
};

/**
 * This is the dedicated function for getting related entities (shareholders and subsidiaries) given the following parameters:
 * - relatedEntityType
 * - idForNeo4j
 * - directFOPercentage
 * - totalFOPercentage
 * - limit
 */

// function for getting related entities (shareholders and subsidiaries) info from Neo4j
export const getRelatedEntities = async (
  relatedEntityType: string,
  idForNeo4j: string,
  directFOPercentage: string,
  totalFOPercentage: string,
  limit: string,
  accessToken: string,
) => {
  const shareholdersContainer: RelatedEntity[] = [];
  const subsidiariesContainer: RelatedEntity[] = [];

  const paramsObj = {
    pid: idForNeo4j,
    typeOfStats: relatedEntityType,
    limit,
    directFOPercentage,
    totalFOPercentage,
  };

    callGCFunctionWIthAccessToken(accessToken, GET_LNH_STATS, paramsObj, "test", "test")
    .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
    .then((responseJson: Neo4jResultJson) => {
      if (responseJson.results[0].data.length > 0) {
        responseJson.results[0].data.map((item: JsonData) => {
          if (relatedEntityType === "SHO") {
            return shareholdersContainer.push(item.row[0]);
          }
          return subsidiariesContainer.push(item.row[0]);
        });
      }
    })
    .catch((err) => {
      // TODO: re-add the ErrorDisplay
    });

  if (relatedEntityType === "SHO") return shareholdersContainer;
  return subsidiariesContainer;
};

// function for retrieving the Neo4j results specifically for hierarchy
export const getRelatedEntities4Hierarchy = async (
  relatedEntityType: string,
  idForNeo4j: string,
  directFOPercentage: string,
  totalFOPercentage: string,
  limit: string,
  accessToken: string,
) => {
  const paramsObj = {
    pid: idForNeo4j,
    typeOfStats: relatedEntityType,
    limit,
    directFOPercentage,
    totalFOPercentage,
  };

  return callGCFunctionWIthAccessToken(accessToken, GET_LNH_STATS, paramsObj, "test", "test")
    .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // TODO: re-add ErrorDisplay
    });
};
