import styled, { keyframes } from "styled-components";
import { StatusProps } from "constants/index";

export const slideIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const Container = styled.div<{ status: StatusProps }>`
  position: fixed;
  top: 10%;
  right: 2%;
  background-color: white;
  color: black;
  padding: 17px 10px;
  min-width: 250px;
  z-index: 9999;
  animation: ${slideIn} 0.2s ease-in-out;
  border-radius: 5px;
  border-left: ${({ status }) =>
    status === StatusProps.ERROR ? "8px solid red" : "8px solid green"};
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
export const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const CloseBtn = styled.div``;
export const ToastIcon = styled.img`
  width: 10px;
  cursor: pointer;
`;
export const ToastIcon2 = styled.img`
  color: white;
  width: 13px;
  height: 10px;
`;
export const IconWrapper = styled.div<{ status: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ status }) => (status === "error" ? "red" : "green")};
  height: 10px;
  width: 10px;
  padding: 7px;
  border-radius: 50%;
`;
export const Info = styled.div``;
export const InfoTitle = styled.div`
  font-weight: 800;
  font-size: 17px;
`;
export const Message = styled.div`
  font-size: 14px;
  font-family: "Roboto Condensed";
  font-weight: 600;
  color: grey;
`;
