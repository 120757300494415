import { NoDataToDisplay } from "components/noData/ComingSoon";
import { MembershipsListing } from "types/party";
import { FieldName, SingleRow, Span } from "styles/party/FieldsGroup.styled";
import AliceModalTooltip from "components/AliceModalTooltip";
import { TableContentContainer } from "styles/tableContent/TableContent.styled";
import { Table } from "components/filterableTable";

// general layout for Accordion's data (not for specific sections)
export function MembershipAndListings({ data }: { data: MembershipsListing }) {
  return !data ? <NoDataToDisplay /> : <>
    <SingleRow key={"main-exc"}>
    <FieldName>{data["Main Exchange"]}</FieldName>
    <AliceModalTooltip selectedField={data["Main Exchange"]} selectedKey={"Main Exchange"}>
        <Span>{data["Main Exchange"]}</Span>
    </AliceModalTooltip>
    </SingleRow>
    <TableContentContainer>
      <NoDataToDisplay />
        {/* <Table
            columns={[{Header:"Other exchanges", accessor:"Other exchanges"},
            {Header:"Indexes", accessor:"Indexes"}]} 
            data={data["Other Exchanges"] ? data["Other Exchanges"] : []}
            tableType="FP"
            filter=""
        /> */}
    </TableContentContainer>
  </>;
}
