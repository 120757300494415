import styled from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 6px;
  column-gap: 32px;
  padding: 0px 0px 17px 40px;
`;

export const FilterInputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(54, 64, 115, 0.1);
  padding: 13px 10px;
  border-radius: 6px;
  flex-basis: 392px;
  column-gap: 10px;
`;

export const FilterIcon = styled.div<{ icon: string }>`
  width: 20px;
  height: 20px;
  background-image: url(${process.env.PUBLIC_URL}/assets/${(props) => props.icon}.svg);
  background-size: contain;
`;

export const FilterInput = styled.input`
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  outline: none;
  border: none;
  background: transparent;
  flex: 1;
  &::placeholder {
    color: ${({ theme }) => theme.colors.PRIMARY[400]};
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  &:focus {
    outline: none;
  }
`;

export const FilterButton = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.PRIMARY[400]};
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
`;
