import { FunctionComponent, useEffect, useState } from "react";
import { 
    Label,
    Value, 
    Container,
    LeftContainer,
    RightContainer,
    LabelValueHolder,
    FiltersContainer
} from "styles/funds/AssetOnboardingSummaryCategory.styled";
import { 
    AssetOnboardingSummaryHolder,
    DropDownHolder,
    ResetFilterButton
  } from "styles/funds/ClientTemplateRequest.styled";
import { FilterByCategory } from "components/filter/Index";
import { 
    ASSET_ONBOARDING_SUMMARY_CATEGORY_LABELS,
    ASSET_ONBOARDING_ACTION_ITEMS 
} from "constants/hubwiseDashboard";
import CircularProgress from "../circularProgress/CircularProgress";
import { FundType } from "types/funds";
import { Dispatch, SetStateAction } from "react";
import useFilters from "hooks/useFilter";

const AssetOnboardingSummaryCategory: FunctionComponent<{
    fundManagers?: number,
    funds?: number,
    isMyView?: boolean,
    allFunds?: FundType[];
    setFilteredFunds?: Dispatch<SetStateAction<FundType[] | object[]>>;
}> = ({
    fundManagers,
    funds,
    isMyView,
    allFunds,
    setFilteredFunds
}) => {

    const [percentages, setPercentages]= useState<number[]>([0, 0, 0, 0]);
    const { secondSetOfFilteredData, setFilter } = useFilters(allFunds ? allFunds : [], allFunds);

    useEffect(()=>{
        if(setFilteredFunds) {
            setFilteredFunds(secondSetOfFilteredData);
        }
    },[secondSetOfFilteredData])

    useEffect(()=>{
        let noSent= 0;
        let awaiting= 0;
        let confirmed= 0;
        let requested= 0;
        let awaiting_setup= 0;
        let setup_complete= 0;

        if (allFunds) {
            allFunds.map((fund: FundType)=>{
                if (fund.Status === "1") {
                    noSent += 1;
                } else if(fund.Status === "2") {
                    awaiting += 1;
                } else if(fund.Status === "3") {
                    confirmed += 1;
                } else if(fund.Status === "4") {
                    requested += 1;
                } else if(fund.Status === "6") {
                    awaiting_setup += 1;
                } else if(fund.Status === "7") {
                    setup_complete += 1;
                }
            })
            setPercentages([
                parseInt((noSent/allFunds.length*100).toFixed(0)),
                parseInt((requested/allFunds.length*100).toFixed(0)),
                parseInt((awaiting/allFunds.length*100).toFixed(0)),
                parseInt((confirmed/allFunds.length*100).toFixed(0)),
                parseInt((awaiting_setup/allFunds.length*100).toFixed(0)),
                parseInt((setup_complete/allFunds.length*100).toFixed(0))
            ])
        }
    },[allFunds])

    return (
      <Container>
        {!isMyView && 
             <LeftContainer>
                <FiltersContainer>
                    <DropDownHolder>
                        <FilterByCategory setFilter={setFilter} />
                    </DropDownHolder>
                    <ResetFilterButton onClick={()=>{setFilter({search: "", secondSearch: "", date: "", modified: "", status: "", category: "", fundStatus: "" })}}>Reset Filters</ResetFilterButton>
                </FiltersContainer>
                <LabelValueHolder>
                    <Label>{ASSET_ONBOARDING_SUMMARY_CATEGORY_LABELS.TOTAL_NO_FUND_MANAGERS}: </Label>
                    <Value>{fundManagers}</Value>
                </LabelValueHolder>
                <LabelValueHolder>
                    <Label>{ASSET_ONBOARDING_SUMMARY_CATEGORY_LABELS.TOTAL_NO_FUNDS}: </Label>
                    <Value>{funds}</Value>
                </LabelValueHolder>
            </LeftContainer>
        }
        <RightContainer>
            {ASSET_ONBOARDING_ACTION_ITEMS.map((item: any, index:number) => (
                <CircularProgress
                    key={index}
                    width={100} 
                    height={100} 
                    title={item}
                    progressText={percentages[index]}
                    setFilter= {setFilter}
                />                                                
            ))}
        </RightContainer>
      </Container>
    );
  }
  
  export default AssetOnboardingSummaryCategory;
  