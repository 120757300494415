import { ChangeEvent, useState } from "react";
import {
  BackIcon,
  Text,
  Container,
  SwitchContainer,
  Label,
  Input,
  Switch,
  TextArea,
  UploadButton,
  SendButton,
  backIcon,
} from "styles/crownAlert/SecondStep.styled";
import { useLocation } from "react-router-dom";
import { PATH } from "constants/index";

function SecondStep({
  setStep,
  value,
}: {
  setStep: React.Dispatch<React.SetStateAction<string>>;
  value: string;
}) {
  const [checked, setChecked] = useState(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setChecked(e.target.checked);
  const { pathname } = useLocation();
  const subText = pathname.includes(PATH.PARTY) ? "Data Enquiry" : "General Enquiry";

  return (
    <>
      <BackIcon onClick={() => setStep("")} iconURL={backIcon} />
      <Text>
        <b>{value === "Feedback" ? "Feedback" : subText}</b>
      </Text>
      <Container>
        <SwitchContainer>
          <b style={{ color: checked ? "#000" : "#4A82A6" }}>
            {value === "Feedback" ? "Report an Issue" : "Incorrect Data"}
          </b>
          <Label>
            <Input checked={checked} type="checkbox" onChange={handleChange} />
            <Switch />
          </Label>
          <b style={{ color: !checked ? "#000" : "#4A82A6" }}>
            {value === "Feedback" ? "Additional Feedback" : "Missing Data"}
          </b>
        </SwitchContainer>
        <TextArea />
        <UploadButton>Upload File</UploadButton>
      </Container>
      <SendButton onClick={() => setStep("Close")}>Send</SendButton>
    </>
  );
}
export default SecondStep;
