import { FunctionComponent } from "react";
import {
    CirclesChartContainer,
    CirclesTitle,
    CirclesChartHolder
} from "styles/funds/Onboarding.styled";
import CircularProgress from "../circularProgress/CircularProgress";
import { ClientRequestTemplateType } from "types/party";
import NoData from "components/noData/NoData";
import { colorPick } from "utils/onboardingColorPick";
import { Filter } from "types";

const CcIdProgress: FunctionComponent<{
    data: ClientRequestTemplateType[] | undefined;
    showTitle: boolean;
    boxShadow?: boolean;
}> =  ({
    data,
    showTitle,
    boxShadow
}) => {
    return data ? (
        <CirclesChartContainer boxShadow= {boxShadow}>
            {showTitle && <CirclesTitle>CCID Progress</CirclesTitle>}
            <CirclesChartHolder>
                    {data.length > 0 && data.map((ccid,index) => (
                            <CircularProgress
                                key={index}
                                width={100} 
                                height={100} 
                                title={ccid["Party Legal Name"] && ccid["Party Legal Name"]}
                                progressText={parseInt(ccid.Stats["Completion percentage"])}
                                progressColor={colorPick(index)[0]}
                                setFilter={(filterBy: Partial<Filter>) => null}
                            />                                                
                    ))}
            </CirclesChartHolder>
        </CirclesChartContainer>   
    ) : <NoData />
};

export default CcIdProgress;