import { TRACKER } from "constants/tracker";
import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { Container } from "styles/party/t360Payments/index.styled";
import { PartyDetails } from "types";
import { ContactInformation } from "types/party";

function Funds({ partyId, partyData }: { partyId: string; partyData: PartyDetails }) {
  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PARTY_PROFILE, TRACKER.PARTY_PROFILE_PAYMENT, {
      party_name: (partyData["Contact Information"] as ContactInformation).Name,
      party_id: partyId,
    });
  });

  return (
    <Container>
      <h1>Payments Content Coming Soon</h1>
    </Container>
  );
}

export default Funds;
