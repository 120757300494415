import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import HubwiseAllfundsRequestsComponent from "components/hubwise-dashboard/HubwiseAllfundsRequestsComponent";

const AllfundsRequests = () => {
    const { track } = useTrackActivity();

    useEffectOnce(()=>{
        track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_DASHBOARD_ALLFUNDS_REQUESTS);
    });

    return <HubwiseAllfundsRequestsComponent />
}

export default AllfundsRequests;