// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * Shared types, interfaces and styles that are useful for using our React Table implementation.
 */

import styled from "styled-components";
import SavedIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Cancel";

export const firstPageIcon = `${process.env.PUBLIC_URL}/assets/first-page.svg`;
export const prevPageIcon = `${process.env.PUBLIC_URL}/assets/prev-page.svg`;
export const firstPageIconWhite = `${process.env.PUBLIC_URL}/assets/first-page-white.svg`;
export const prevPageIconWhite = `${process.env.PUBLIC_URL}/assets/prev-page-white.svg`;

export const WrapTable = styled.div`
  white-space: nowrap;
  display: block;
  overflow-x: scroll;
`;
export const TableBasicStyles = styled.table`
  border-collapse: collapse;
  margin-bottom: 24px;
  width: 100%;
`;

export const TableHeaderRow = styled.tr`
  background: rgba(224, 226, 236);
  position: sticky;
  top: 0;
`;

export const TableHeaderCell = styled.th`
  padding: 18px;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
`;

export const TableHeaderContent = styled.div`
  display: flex;
  align-items: center;
`;
export const TableHeaderTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  letter-spacing: 0.2px;
  text-transform: uppercase;
`;

export const TableBodyRow = styled.tr`
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  :hover {
    background-color: rgba(224, 226, 236);
  }
`;

export const TableBodyCell = styled.td`
  padding: 18px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => (theme.colors.WHITE === "#FFFFFF" ? "#333333" : "#fff")};
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }

  div {
    font-size: 13px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-top: 20px;
`;

export const PaginationControl = styled.div`
  cursor: pointer;
  margin-right: 12px;
`;

export const PaginationControlNext = styled.div`
  transform: rotateZ(180deg);
  cursor: pointer;
  margin-left: 12px;
`;

export const PaginationPage = styled.div<{ active: boolean }>`
  background-color: ${({ theme, active }) => `${active ? "#364073" : theme.colors.WHITE}`};
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme, active }) => `${active ? "#FFFFFF" : theme.colors.DARK[400]}`};
  width: 24px;
  height: 24px;
  border: ${({ theme, active }) => (active ? "none" : `1px solid ${theme.colors.DARK[400]}`)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TableInput = styled.input<{ isDisabled?: boolean }>`
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
  color: ${({ theme }) => theme.colors.GREY[500]};
  padding-right: 30px;
  width: 100%;
`;

export const InputCover = styled.div`
  position: relative;
`;

export const SaveIcon = styled(SavedIcon)`
  color: ${({ theme }) => theme.colors.PRIMARY[900]};
`;

export const CancelIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.colors.DANGER[600]};
`;

export const IconCover = styled.div`
  position: absolute;
  right: -35px;
  top: 0;
`;
