// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { 
    Container,
    Title,
    SubTitle,
    CardsContainerDiv,
    CardDiv,
    CardText,
    CardIcon,
    CardTitle,
    CardDescription,
    DescriptionItem,
    DescriptionLargeSpan,
    DescriptionSmallSpan,
    SpanHolder,
    DescriptionCardsHolder
} from "../../../styles/sippAdmin/goodLooksLike.styled";
import { FunctionComponent } from "react";

const CardItemComponent: FunctionComponent<{ 
    title: string;
    url: string; 
    descrItems: string[] 
}> = ({ 
    title,
    url, 
    descrItems 
}) => {

  return (
    <CardDiv>
        <CardText>
            <CardTitle>{title}</CardTitle>
            <DescriptionCardsHolder>
                {descrItems && descrItems.map((item, index) => (
                    <CardDescription key={index}>
                        <SpanHolder>
                            <DescriptionLargeSpan />
                            {index !== descrItems.length - 1 && (
                                <>
                                    <DescriptionSmallSpan />
                                    <DescriptionSmallSpan />
                                </>
                            )}
                        </SpanHolder>
                        <DescriptionItem>{item}</DescriptionItem>
                    </CardDescription>
                ))}
            </DescriptionCardsHolder>
        </CardText>
        <CardIcon src= {url}/>
    </CardDiv>
  );
}

export default CardItemComponent;
