import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: ${({ theme }) => `${theme.colors.DARK[500]}20`};
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 0;
    margin: 0;
  }

  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }
`;

export const Modal = styled.div<{ width: string; padding: string; medium?: boolean }>`
  width: ${({ width }) => width || "auto"};
  min-width: ${({ medium }) => (medium ? "60%" : "auto")};
  min-height: ${({ medium }) => (medium ? "60%" : "auto")};
  height: auto;
  background: ${({ theme }) => theme.colors.WHITE};
  border-radius: 8px;
  padding: ${({ padding }) => padding || "10px"};
  position: relative;
`;
