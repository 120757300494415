import styled from "styled-components";

export const MainContainer = styled.div`
  transition: transform 400ms;
  width: 375px;
  top: 74px;
  right: 0;
  bottom: 0;
  z-index: 9;
  position: fixed;
  border-radius: 0 0 8px 8px;
  background: ${({ theme }) => theme.colors.WHITE};
  font-family: "Roboto Condensed", "sans-serif";
`;

export const DarkLayer = styled.div`
  transition:
    opacity 500ms,
    visibility 500ms;
  opacity: 20%;
  z-index: 7;
  position: fixed;
  top: 74px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.DARK[500]};
`;

export const TopContainer = styled.div`
  padding: 20px 20px 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export const Title = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.DARK[400]};
  cursor: default;
`;

export const CloseIcon = styled.div<{ iconURL: string }>`
  width: 24px;
  height: 24px;
  background-image: ${(props) => `url(${props.iconURL})`};
  background-size: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  filter: ${({ theme }) =>
    theme.colors.WHITE !== "#FFFFFF"
      ? "saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)"
      : "none"};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const closeIcon = `${process.env.PUBLIC_URL}/assets/notification-close-icon.svg`;
