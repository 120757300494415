import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import WorkbenchComponent from "components/admin/Workbench";

function Workbench() {
  const { track } = useTrackActivity();

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.ALICE_ADMIN_WORKBENCH);
  });

  return <WorkbenchComponent />;
}

export default Workbench;
