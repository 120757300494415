import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { TRACKER } from "constants/tracker";
import { DashboardContainer } from "styles/sippAdmin/sippAdmin.styled";
import SIPPExpressionWishesComponent from "components/sippAdmin/ExpressionWishesComponent";

function SIPPExpressionWishes() {
    const { track } = useTrackActivity();
  
    useEffectOnce(() => {
      track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_DASHBOARD);
    });
  
    return (
      <DashboardContainer>
        <SIPPExpressionWishesComponent />
      </DashboardContainer>
    );
  }
  export default SIPPExpressionWishes;