import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  overflow: hidden;
  @media (max-width: 769px) {
    grid-template-columns: 100% 1fr;
  }
  @media (min-width: 769px) {
    grid-template-columns:  270px 1fr;
  }
`;

export const HubwiseDashboardContainer = styled.div`
  height: 100vh;
  overflow: hidden;
`;

export const OutletCover = styled.div`
`;