import { TrackActivityData } from "types";
import timezoneData from "data/Countries.json";
import { useAppData } from "./useAppData";
import { User } from "@auth0/auth0-react";

interface CountryType {
  [key: string]: string;
}

let timezoneInfo;
let regionInfo;
let countryInfo;
const zone: CountryType = timezoneData;

if (Intl) {
  timezoneInfo = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tzArr = timezoneInfo.split("/");
  const [info] = tzArr;
  regionInfo = info;
  countryInfo = zone[timezoneInfo];
}

const timezone = timezoneInfo;
const region = regionInfo;
const country = countryInfo;

export enum ActivityType {
  CLICK = "click",
  BUTTON_CLICK = "button click",
  LOGIN = "login",
  SEARCH = "search",
  SIGNOUT = "signout",
  LOAD_PAGE = "load page",
  ACCORDION_OPEN = "accordion toggled",
  LOAD_PARTY_PROFILE = "load party profile page",
}

const dbName: string = "userActivityDb";
const storeName: string = "userActivityStore";

function DbSetup(): Promise<IDBDatabase> {
  const request: IDBOpenDBRequest = window.indexedDB.open(dbName, 1);

  return new Promise((resolve, reject) => {
    request.onerror = () => reject(new Error("Failed to open database connection"));

    request.onsuccess = () => {
      const db: IDBDatabase = request.result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      if (event.target instanceof IDBRequest) {
        const db: IDBDatabase = event.target.result;
        db.createObjectStore(storeName, { keyPath: "id", autoIncrement: true });
      }
    };
  });
}

export const trackActivity = (
  activity: ActivityType,
  currentPage?: string,
  metadata?: Record<string, string | number>,
  user?: User,
) => {
  DbSetup()
    .then((db: IDBDatabase) => {
      const transaction: IDBTransaction = db.transaction([storeName], "readwrite");
      const store: IDBObjectStore = transaction.objectStore(storeName);
      const currentDate = new Date();
      const utcDateToString = new Date(currentDate).toUTCString();
      const uuid = Math.random().toString(16).slice(2, 9);
      const object: TrackActivityData = {
        id: uuid,
        date_and_time: utcDateToString,
        region,
        country,
        timezone,
        activity,
        currentPage,
        ...metadata,
        user,
      };
      store.add(object);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const useTrackActivity = () => {
  const { user } = useAppData();

  const track = (
    activity: ActivityType,
    currentPage?: string,
    metadata?: Record<string, string | number>,
  ) => {
    return trackActivity(activity, currentPage, metadata!, user!);
  };
  return {
    track,
  };
};
