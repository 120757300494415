import { Accordion } from "components/party/accordion/Accordion";
import {
    Container,
    Title,
    CardDiv,
    IconDivA,
    DivIcon,
    CardDivHeading,
    CardDivContent,
    MatchArea
  } from "styles/admin/Workbench.styled";
  
  function Workbench() {
  
    return (
      <Container>
        <Title>Workbench</Title>
        <Accordion title="Dashboard" noTopBorder>
            <div>Stats coming soon ...</div>
        </Accordion>
        <Accordion title="Match Area" opened>
            <MatchArea>
                <CardDiv>
                    <IconDivA>
                        <img src={DivIcon} alt="divicon" />
                    </IconDivA>
                    <CardDivHeading>
                        <h3>HIVE</h3>
                    </CardDivHeading>
                    <hr style={{ border: "solid 1px rgba(128, 128, 128, 0.3)" }} />
                    <CardDivContent>
                        <div className="contentdiv">
                            <p>Label goes here</p>
                            <p>Value goes here</p>
                        </div>
                    </CardDivContent>
                </CardDiv>
                <CardDiv>
                    <IconDivA>
                        <img src={DivIcon} alt="divicon" />
                    </IconDivA>
                    <CardDivHeading>
                        <h3>Open FIGI</h3>
                    </CardDivHeading>
                    <hr style={{ border: "solid 1px rgba(128, 128, 128, 0.3)" }} />
                    <CardDivContent>
                        
                    </CardDivContent>
                </CardDiv>
                <CardDiv>
                    <IconDivA>
                        <img src={DivIcon} alt="divicon" />
                    </IconDivA>
                    <CardDivHeading>
                        <h3>Morningstar</h3>
                    </CardDivHeading>
                    <hr style={{ border: "solid 1px rgba(128, 128, 128, 0.3)" }} />
                    <CardDivContent>
                        
                    </CardDivContent>
                </CardDiv>
                <CardDiv>
                    <IconDivA>
                        <img src={DivIcon} alt="divicon" />
                    </IconDivA>
                    <CardDivHeading>
                        <h3>Trustnet</h3>
                    </CardDivHeading>
                    <hr style={{ border: "solid 1px rgba(128, 128, 128, 0.3)" }} />
                    <CardDivContent>
                        
                    </CardDivContent>
                </CardDiv>
                <CardDiv>
                    <CardDivHeading>
                        <h3>PID</h3>
                    </CardDivHeading>
                    <hr style={{ border: "solid 1px rgba(128, 128, 128, 0.3)" }} />
                    <CardDivContent>
                        
                    </CardDivContent>
                </CardDiv>
            </MatchArea>
        </Accordion>
        
      </Container>
    );
  }
  
  export default Workbench;
  