// Copyright Ioana Bac and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { 
    Container
} from "styles/sippAdmin/sippAdmin.styled";
import clientData from "../../../data/clientProfileData.json";
import { useState, FunctionComponent } from "react";
import { Accordion } from "components/party/accordion/Accordion";
import { 
    FieldName,
    SingleRow,
    Span,
    AccordionRowsHolder 
} from "styles/sippAdmin/clientProfile.styled";
import AliceModalTooltip from "components/AliceModalTooltip";
import NoDataContent from "components/noData/NoDataContent";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";

const ClientProfileAccordionsComponent: FunctionComponent<{ 
  selectedClient: {
        id: string;
        "Client Details": {
            [key: string]: string;
        },
        "Financial Advisor": {
            [key: string]: string;
        },
        "Expression of Wishes/Beneficiary"?: {
            [key: string]: string;
        }
    } | undefined; 
}> = ({
    selectedClient
}) => {

  return (
    <Container>
        <Accordion title="Client Details" opened noTopBorder> 
            {/* <NoDataContent /> */}
            <AccordionRowsHolder twoColumns>
                {selectedClient && selectedClient["Client Details"] && Object.keys(selectedClient["Client Details"]).map((field, index) => (
                    <SingleRow key={index} >
                        <FieldName>{field}</FieldName>
                        {Array.isArray(selectedClient["Client Details"][field]) ? 
                        (
                            <AliceModalTooltip selectedField={selectedClient["Client Details"][field][0]} selectedKey={field}>
                            <Span>{selectedClient["Client Details"][field][0]}</Span>
                            </AliceModalTooltip>
                        ) : 
                        (
                            <AliceModalTooltip selectedField={selectedClient["Client Details"][field] as string} selectedKey={field}>
                            <Span>{selectedClient["Client Details"][field]}</Span>
                            </AliceModalTooltip>
                        )}
                    </SingleRow>
                ))}
             </AccordionRowsHolder>
        </Accordion>
        <Accordion title="Financial Advisor">
            {/* <NoDataContent /> */}
            <AccordionRowsHolder>
                {selectedClient && selectedClient["Financial Advisor"] && Object.keys(selectedClient["Financial Advisor"]).map((field, index) => (
                    <SingleRow key={index}>
                        <FieldName>{field}</FieldName>
                        {Array.isArray(selectedClient["Financial Advisor"][field]) ? 
                        (
                            <AliceModalTooltip selectedField={selectedClient["Financial Advisor"][field][0]} selectedKey={field}>
                            <Span>{selectedClient["Financial Advisor"][field][0]}</Span>
                            </AliceModalTooltip>
                        ) : 
                        (
                            <AliceModalTooltip selectedField={selectedClient["Financial Advisor"][field] as string} selectedKey={field}>
                            <Span>{selectedClient["Financial Advisor"][field]}</Span>
                            </AliceModalTooltip>
                        )}
                    </SingleRow>
                ))}
             </AccordionRowsHolder>
        </Accordion>
        <Accordion title="Expression of Wishes/Beneficiary">
            {/* <NoDataContent /> */}
            <AccordionRowsHolder twoColumns>
                {selectedClient && selectedClient["Expression of Wishes/Beneficiary"] ? Object.keys(selectedClient["Expression of Wishes/Beneficiary"]).map((field, index) => (
                    <SingleRow key={index} >
                        <FieldName>{field}</FieldName>
                        {selectedClient["Expression of Wishes/Beneficiary"] && selectedClient["Expression of Wishes/Beneficiary"][field] && Array.isArray(selectedClient["Expression of Wishes/Beneficiary"][field]) ? 
                        (
                            <AliceModalTooltip selectedField={selectedClient["Expression of Wishes/Beneficiary"][field][0]} selectedKey={field}>
                            <Span>{selectedClient["Expression of Wishes/Beneficiary"][field][0]}</Span>
                            </AliceModalTooltip>
                        ) : 
                        (
                            selectedClient["Expression of Wishes/Beneficiary"] && selectedClient["Expression of Wishes/Beneficiary"][field] && 
                                <AliceModalTooltip selectedField={selectedClient["Expression of Wishes/Beneficiary"][field] as string} selectedKey={field}>
                                    <Span>{selectedClient["Expression of Wishes/Beneficiary"][field]}</Span>
                                </AliceModalTooltip>
                                                        
                        )}
                    </SingleRow>
                ))
                :
                <>
                    <NoDataContent />
                    <SubmitButton text="Add Beneficiary" onClick={()=>{console.log("print")}} type={"submit"} disabled={false}/>
                </>
                } 
                
             </AccordionRowsHolder>
        </Accordion>
        <Accordion title="Assets"><NoDataContent /></Accordion>
        <Accordion title="Account"><NoDataContent /></Accordion>
        <Accordion title="Transfers"><NoDataContent /></Accordion>
        <Accordion title="Contributions"><NoDataContent /></Accordion>
        <Accordion title="Bank Account/Transaction history"><NoDataContent /></Accordion>
        <Accordion title="Withdrawals/Drawdown"><NoDataContent /></Accordion>
    </Container>
  );
}

export default ClientProfileAccordionsComponent;
