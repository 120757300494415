import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { TRACKER } from "constants/tracker";
import HubwiseMasterBuylistComponent from "components/hubwise-dashboard/masterBuylist/HubwiseMasterBuylist";
import { useAppData } from "hooks/useAppData";
import { PARTY } from "constants/index";
import NoData from "components/noData/NoData";
import { getAccessToken } from "services/auth0/auth0";
import { useEffect, useState } from "react";
import {
    FUNDS_DATA_REPOSITORY_BUCKET_NAME,
    HUBWISE_FUNDS_DETAILS_PATH
  } from "config/googleCloudConfig";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import { MasterInstrumentManagerType } from "types/funds";
import LoadingSpinner from "components/LoadingSpinner";
import { TemporaryMasterInstrumentsFundManagerType } from "types/funds";

const MasterBuylist = () => {
    const [ loading, setLoading ]= useState<boolean>(false);
    const [ masterList, setMasterList ]= useState<TemporaryMasterInstrumentsFundManagerType[]>([]);
    const [buylistsInfo, setBuylistsInfo]= useState<{"Buylist Code": string, "Buylist Description": string}[]>([]);
    const { isUserPartOfOrganization } = useAppData();
    const { track } = useTrackActivity();
    const token = getAccessToken();

    useEffect(()=>{    
        if(token) {
            setLoading(true);
            const masterBuylist = "master_buylist.json";
            const payload = {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName": masterBuylist,
                "filePath": `${HUBWISE_FUNDS_DETAILS_PATH}`
            };

            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: TemporaryMasterInstrumentsFundManagerType[])=> {
                setMasterList(data);
                setLoading(false);
            });

            const buylistFileName= "all_buylists.json"

            const buylistsPayload= {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName": buylistFileName,
                "filePath": `${HUBWISE_FUNDS_DETAILS_PATH}`
            }

            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, buylistsPayload, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: {"Buylist Code": string, "Buylist Description": string}[])=> {
                setBuylistsInfo(data);
            });
        }
    },[token]);

    useEffectOnce(()=>{
        track(ActivityType.LOAD_PAGE, TRACKER.HUBWISE_DASHBOARD_MASTER_BUYLIST);
    });
    return (
        loading ? <LoadingSpinner/> : 
        !masterList ? <NoData /> 
        : <HubwiseMasterBuylistComponent data={masterList} buylistsInfo={buylistsInfo}/> 
    )
     
}

export default MasterBuylist;