// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * Using React Table for having a filterable, sortable and paginated table.
 * To know more about React Table see the official doc here: https://react-table.tanstack.com/docs/overview
 */

import React, { ChangeEvent, useEffect, useState } from "react";
import {
  TableOptions,
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  Row,
  Column,
  HeaderGroup,
  Cell,
} from "react-table";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {
  WrapTable,
  TableBasicStyles,
  TableHeaderCell,
  TableHeaderContent,
  TableHeaderTitle,
  TableHeaderRow,
  TableBodyRow,
  TableBodyCell,
  PaginationContainer,
  PaginationControl,
  PaginationControlNext,
  PaginationPage,
  firstPageIcon,
  prevPageIcon,
  firstPageIconWhite,
  prevPageIconWhite,
  TableInput,
  InputCover,
  SaveIcon,
  CancelIcon,
  IconCover,
} from "styles/FilterableTable.styled";
import {
  formatDataForTable,
  getAdditionalTableOptions,
  getHeadersForTable,
} from "utils/sharedFunctionsForTables";
import AliceModalTooltip from "components/AliceModalTooltip";
import { ColumnDetails } from "types/filterableTable";
import { DefaultColumnFilter } from "./DefaultColumnFilter";
import { useAppData } from "../../hooks/useAppData";

export function Table({
  columns,
  data,
  tableType,
  filter,
  idKey,
  onClickRow,
  isEditable,
}: {
  columns: Column[];
  data: string[] | number[];
  tableType: string;
  filter: string;
  idKey?: string;
  onClickRow?: (arg: string) => void;
  isEditable?: boolean;
}) {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const columnFilter = React.useMemo(() => columns.filter((c) => !c.disableFilters), [columns]);

  const Data = React.useMemo(() => {
    if (filter === "") {
      return data;
    }
    const res: any[] = [];
    data.forEach((d: any) => {
      if (
        columnFilter.some((c) =>
          ((c.accessor && d[c.accessor?.toString()]) || "")
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()),
        )
      ) {
        res.push(d);
      }
    });
    return res;
  }, [data, columnFilter, filter]);

  // here we define the basic TableOptions for React Table
  let tableOptions: TableOptions<object> = {
    columns,
    data: Data,
    disableSortRemove: true, // un-sorted state will not be available to columns once they have been sorted
    defaultColumn, // this is for filtering
  };

  // let's see if we have more additional table options coming from the table configurations
  const additionalTableOptions = getAdditionalTableOptions(tableType);

  if (additionalTableOptions) {
    tableOptions = {
      ...tableOptions,
      ...additionalTableOptions,
    };
  }

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    tableOptions,
    useFilters, // Important! Place the useFilters before useSortBy and usePagination hooks!
    useSortBy,
    usePagination,
  );

  const { status } = useAppData();

  // editing each table cells section start

  const [editingCells, setEditingCells] = useState<
    { rowId: number; colId: number; value: string }[]
  >([]);

  const handleValueChange = (
    cellValue: string,
    e: ChangeEvent<HTMLInputElement>,
    rowId: number,
    colId: number,
  ) => {
    const val = e.target.value;
    const clonedCells = [...editingCells];

    const isDataExisting = !![...clonedCells].find((c) => c.rowId === rowId && c.colId === colId);

    if (!isDataExisting) {
      clonedCells.push({ rowId, colId, value: cellValue });
    }

    const newCellData = [...clonedCells].map((c) =>
      c.rowId === rowId && c.colId === colId ? { rowId: c.rowId, colId: c.colId, value: val } : c,
    );
    setEditingCells(newCellData);
  };

  const getCellData = (rowId: number, colId: number) => {
    return editingCells.find((c) => c.rowId === rowId && c.colId === colId)?.value;
  };

  const isDataDiff = (oldVal: string, rowId: number, colId: number) => {
    const newValue = editingCells.find((c) => c.rowId === rowId && c.colId === colId)?.value || "";
    if (oldVal !== newValue) return true;
    return false;
  };

  const save = (rowId: number, colId: number) => {};

  const cancel = (value: string, rowId: number, colId: number) => {
    const data = [...editingCells].map((c) =>
      c.rowId === rowId && c.colId === colId ? { rowId, colId, value } : c,
    );
    setEditingCells(data);
  };

  useEffect(() => {
    const data: { colId: number; rowId: number; value: string }[] = [];
    page.forEach((row: Row<object>, rowId) => {
      row.cells.forEach((cell: Cell, colId) => {
        data.push({ rowId, colId, value: cell.value });
      });
    });

    setEditingCells(data);
  }, []);

  // editing each table cells section ends

  // Render the UI for your table
  return (
    <>
      <WrapTable>
        <TableBasicStyles {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: HeaderGroup<object>) => (
              <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: HeaderGroup<object>, i: number) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <TableHeaderCell key={i}>
                    <TableHeaderContent {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <TableHeaderTitle>{column.render("Header")}</TableHeaderTitle>
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.canSort && column.isSorted ? (
                          (column.isSortedDesc && (
                            <ArrowDropDownIcon
                              style={{
                                color: status === "dark" ? "rgb(186, 192, 222)" : "#364073",
                              }}
                            />
                          )) ||
                          (!column.isSortedDesc && (
                            <ArrowDropUpIcon
                              style={{
                                color: status === "dark" ? "rgb(186, 192, 222)" : "#364073",
                              }}
                            />
                          ))
                        ) : (
                          <ArrowDropDownIcon
                            style={{ color: status === "dark" ? "rgb(186, 192, 222)" : "#364073" }}
                          />
                        )}
                      </span>
                    </TableHeaderContent>
                  </TableHeaderCell>
                ))}
              </TableHeaderRow>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: Row<object>, rowId) => {
              prepareRow(row);
              return (
                <TableBodyRow {...row.getRowProps()}>
                  {row.cells.map((cell: Cell, i) => {
                    /**
                     * If it's a number and is equal to zero, return a dash.
                     * If it's a number and is not equal to zero, then add the comma for the thousands figures.
                     * If it's not a number, just render the cell.
                     */

                    return (
                      <TableBodyCell
                        {...cell.getCellProps()}
                        onClick={() => !isEditable && onClickRow?.(cell.row.values?.[idKey!])}
                      >
                        {cell.value && (
                          <AliceModalTooltip
                            isDisable
                            selectedField={cell.value}
                            selectedKey={headerGroups?.[0].headers?.[i]?.Header as string}
                            isSmall
                          >
                            {isEditable ? (
                              <InputCover>
                                <TableInput
                                  type="text"
                                  value={getCellData(rowId, i)}
                                  autoFocus
                                  onChange={(e) => handleValueChange(cell.value, e, rowId, i)}
                                />
                                {isDataDiff(cell.value, rowId, i) && (
                                  <IconCover>
                                    <SaveIcon fontSize="small" onClick={() => save(rowId, i)} />
                                    <CancelIcon
                                      fontSize="small"
                                      onClick={() => cancel(cell.value, rowId, i)}
                                    />
                                  </IconCover>
                                )}
                              </InputCover>
                            ) : (
                              <div>{cell.value}</div>
                            )}
                          </AliceModalTooltip>
                        )}
                      </TableBodyCell>
                    );
                  })}
                </TableBodyRow>
              );
            })}
          </tbody>
        </TableBasicStyles>
      </WrapTable>
      {pageCount > 1 && (
        <PaginationContainer>
          <PaginationControl onClick={() => canPreviousPage && gotoPage(0)}>
            <img src={status === "dark" ? firstPageIconWhite : firstPageIcon} alt="first-page" />
          </PaginationControl>
          <PaginationControl onClick={() => canPreviousPage && previousPage()}>
            <img src={status === "dark" ? prevPageIconWhite : prevPageIcon} alt="prev-page" />
          </PaginationControl>
          {Array.from(Array(pageCount).keys()).map((page) => (
            <PaginationPage
              key={`pagination_${page}`}
              active={pageIndex === page}
              onClick={() => gotoPage(page)}
            >
              {page + 1}
            </PaginationPage>
          ))}
          <PaginationControlNext onClick={() => canNextPage && nextPage()}>
            <img src={status === "dark" ? prevPageIconWhite : prevPageIcon} alt="prev-page" />
          </PaginationControlNext>
          <PaginationControlNext onClick={() => canNextPage && gotoPage(pageCount - 1)}>
            <img src={status === "dark" ? firstPageIconWhite : firstPageIcon} alt="first-page" />
          </PaginationControlNext>
        </PaginationContainer>
      )}
    </>
  );
}

// main function
export function FilterableTable({
  data,
  tableType,
  filter,
  onClickRow,
  idKey,
  isEditable,
}: {
  data: ColumnDetails[];
  tableType: string;
  filter: string;
  idKey?: string;
  onClickRow?: (arg: string) => void;
  isEditable?: boolean;
}) {
  // React Table requires columns and data memoisation for avoiding too many table re-rendering.

  // define the columns (and the respective headers) and memoise it (React Table want)
  const columns = React.useMemo(() => getHeadersForTable(data, tableType), []);
  // memoise the data
  const dataForTable = React.useMemo(() => formatDataForTable(data, tableType), []);
  return (
    <Table
      columns={columns}
      data={dataForTable as unknown as string[]}
      tableType={tableType}
      filter={filter}
      onClickRow={onClickRow}
      idKey={idKey}
      isEditable={isEditable}
    />
  );
}
