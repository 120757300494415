import { useLocation, Link } from "react-router-dom";
import useModal from "hooks/useModal";
import { PATH } from "constants/index";
import SignOut from "components/alert/SignOut";
import { 
    Content,
    MenuItemIcon,
    MenuLink,
 } from "styles/admin/Sidebar.styled";
 import { FundPartyManagerSideBarDetails } from "constants/hubwiseDashdoardMenu";
 import { 
    HWMenuItem, 
    MenuContainer, 
    MenuItemText,
    Header,
    HeaderTitle,
    Logo,
    Container 
} from "styles/funds/ClientTemplateRequest.styled";
import { useAppData } from "hooks/useAppData";
import { PARTY } from "constants/index";

 const isActive = (path: string, link: string) => {
    return path.includes(link);
  };

function HubwiseDashboardSideBar () {
    const { pathname } = useLocation();

    const { isUserPartOfOrganization } = useAppData();

    const signOutModal = useModal();

    const openSignOutModal = () => {
        signOutModal.open();
    };

    const t360LogoWhite =`${process.env.PUBLIC_URL}/assets/t360-header-logo.svg`;
    const hubwiseLogo = `${process.env.PUBLIC_URL}/assets/hubwise-logo.svg`;

    return (
        <Container>
            <SignOut data-testid="sign-out-modal" controller={signOutModal} />
            <Content>
                <Header>
                    <Link to={PATH.INDEX}>
                        <Logo iconURL={isUserPartOfOrganization([PARTY.HUBWISE]) ? hubwiseLogo : t360LogoWhite} isHubwise={isUserPartOfOrganization([PARTY.HUBWISE])}/>
                        <HeaderTitle>{isUserPartOfOrganization([PARTY.HUBWISE]) ? "SS&C FUND PARTY MANAGER" : "ClientScope - IM"}</HeaderTitle>
                    </Link>
                </Header>
                <MenuContainer>
                    {FundPartyManagerSideBarDetails({pathname}).map((menu) => (
                        menu.isUserAllowed && 
                        <MenuLink data-testid="menu-item" to={menu.link} key={menu.text}>
                            <HWMenuItem isActive={isActive(pathname, menu.link)}>
                                <MenuItemIcon iconURL={menu.icon} isActive={isActive(pathname, menu.link)} />
                                <MenuItemText>{menu.text}</MenuItemText>
                            </HWMenuItem>
                        </MenuLink>                        
                    ))}
                </MenuContainer>
            </Content>
        </Container>
    )
}

export default HubwiseDashboardSideBar;