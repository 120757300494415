import styled from "styled-components";

export const assetDir = `${process.env.PUBLIC_URL}/assets/`;

export const leftArrow = `${process.env.PUBLIC_URL}/assets/arrow-left-gray.svg`;

const StatusColor: { [key: string]: string } = {
  approved: "#4CBB17",
  "in-progress": "#FF7800",
  denied: "#E60000",
};

export const ViewPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ViewPageImage = styled.img`
  width: 248px;
  height: auto;
  margin-bottom: 24px;
  margin-top: 40px;
`;

export const Title = styled.h1`
  font-size: 32px;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.DARK[400]};
  font-weight: 700;
  margin: 0;
  text-align: center;
`;

export const SubTitle = styled.p`
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.BLACK[600]};
  font-weight: 700;
  margin: 0;
  margin-top: 16px;
`;
export const ViewButton = styled.button`
  background-color: #2e1a61;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 40px;
  padding: 14px 40px;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

export const AlertStatusContainer = styled.div``;
export const AlertStatusList = styled.div`
  margin-top: 24px;
  padding: 0 40px;
  margin-bottom: 63px;
  overflow-y: auto;
  max-height: 418px;
`;

export const AlertStatusItem = styled.div<{ rag: string }>`
  background: ${(props) =>
    props.rag === "RED"
      ? "linear-gradient(180deg, #E60003 0%, rgba(229, 68, 70, 0.849198) 83.33%, #EC7D7E 100%)"
      : "linear-gradient(180deg, #FF7800 0%, rgba(228, 144, 69, 0.849198) 83.33%, #ED9D2B 100%)"};
  border-radius: 24px;
  padding: 24px 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  cursor: pointer;
  align-items: center;
`;
export const AlertStatusText = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
`;
export const AlertStatusIcon = styled.img`
  width: 20px;
  height: 20px;
  padding-left: 10px;
`;

export const GoToTransactionContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 59px;
`;
export const GoToTransactionTitle = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.DARK[400]};
  cursor: pointer;
`;
export const DetailContainer = styled.div`
  margin-top: 8px;
  padding: 32px;
  padding-right: 97px;
  background: rgba(46, 26, 97, 0.05);
  border-radius: 16px;
  overflow-y: auto;
  max-height: 450px;
`;
export const DetailContentItem = styled.div`
  margin-bottom: 40px;
`;
export const DetailItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;
export const DetailTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  flex-basis: 166px;
`;
export const DetailContent = styled.div`
  border: 0.5px solid ${({ theme }) => theme.colors.PRIMARY[400]};
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  padding: 14px 16px;
  flex: 1;
`;
export const CloseIcon = styled.span`
  display: inline-block;
  position: absolute;
  top: 27px;
  right: 27px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${`${process.env.PUBLIC_URL}/assets/close-button.svg`});
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
export const TransactionContainer = styled.div`
  margin-top: 8px;
  padding: 32px;
  background: ${({ theme }) => theme.colors.PRIMARY[500]};
  border-radius: 16px;
  height: 408px;
  padding-top: 77px;
`;
export const TransactionContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -20px;
`;
export const TransactionIcon = styled.div<{ icon: string }>`
  width: 55px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${(props) =>
    `${process.env.PUBLIC_URL}/assets/checkmate-${props.icon}-disabled-icon.svg`});
`;
export const TransactionItem = styled.div<{ type: string }>`
  color: #808080;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${(props) => StatusColor[props.type]};
  }
  &:hover ${TransactionIcon} {
    background-image: url(${(props) =>
      `${process.env.PUBLIC_URL}/assets/checkmate-${props.type}-hover-icon.svg`});
  }
`;
export const TransactionTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
`;

export const GrrenPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const GreenIcon = styled.img`
  width: 128.33px;
  height: 122.5px;
  margin-top: 40px;
`;
export const GreenSubTitle = styled.span`
  margin-top: 33px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #808080;
`;
export const GreenStatus = styled.span`
  margin-top: 61px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
`;
export const GreenTransactionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -20px;
  margin-top: 27px;
`;

export const ArrowBack = styled.div`
  position: absolute;
  top: 36px;
  left: 42px;
  width: 40px;
  height: 40px;
  background-image: url(${leftArrow});
  filter: ${({ theme }) =>
    theme.colors.WHITE !== "#FFFFFF"
      ? "saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)"
      : "none"};
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

export const Details = styled.div``;
