import styled from "styled-components";

export const MyViewContainer = styled.div`
  height: 100%;
  padding: 32px;
`;

export const Title= styled.div`
  font-size: 36px;
  line-height: 38px;
  color: #364073;
  font-weight: 700;
`;

export const Content = styled.div`
  width: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;