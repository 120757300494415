import { FunctionComponent } from "react";
import { Container, StyledBar, Text, Cover } from "styles/admin/Bar.styled";
import { useAppData } from "../../hooks/useAppData";

const Bar: FunctionComponent<{
  percent?: number;
  title: string;
  color?: string;
  isGrey?: boolean;
}> = ({ percent, title, color, isGrey }) => {
  const { status } = useAppData();
  return (
    <Container>
      <Cover>
        <StyledBar
          percent={percent!}
          data-testid={title}
          title={title}
          status={status}
          bgColor={color}
        >
          <Text isGrey white>
            {title}
          </Text>
        </StyledBar>
      </Cover>
      <Text isGrey white>
        {percent}%
      </Text>
    </Container>
  );
};

export default Bar;
