import styled from "styled-components";

export const Container = styled.div`
  height: 159px;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const Icon = styled.img`
  margin-bottom: 18px;
`;
export const TitleText = styled.div`
  text-align: center;
  margin-bottom: 8px;
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  width: 80%;
`;
export const Time = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
`;
