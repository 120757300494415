import styled from "styled-components";

export const Container = styled.div`
  margin-top: 70px;
  text-align: center;
  font-family: "Roboto Condensed";
  h1 {
    color: ${({ theme }) => theme.colors.DARK[400]};
  }
`;
