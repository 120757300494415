import { useContext, useRef } from "react";
import useModal from "hooks/useModal";
import {
  CloserTab,
  BackImage,
  ContentContainer,
  AvatarImageContainer,
  MenuItemIcon,
  TextContainer,
  NameContainer,
  EmailContainer,
  PermissionsContainer,
  SettingsContainer,
  SettingsTab,
  ButtonContainer,
  SignOutButton,
  closerIcon,
  settingsIcon,
  GeneralMenuContainer,
  SubMenuContainer,
} from "styles/header/HeaderMenu.styled";
import UserPersonalDetails from "../userPersonalDetailsReader/UserPersonalDetails";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import SettingsMenu from "./SettingsMenu";
import { AppContext } from "../../contexts/App";
import SignOut from "../alert/SignOut";

function HeaderMenu({ menuToggle }: { menuToggle: any }) {
  const { user } = useContext(AppContext);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const settingsMenuToggle = () => setIsActive(!isActive);

  const signOutModal = useModal();

  const openSignOutModal = () => {
    signOutModal.open();
  };

  return (
    <>
      <SignOut controller={signOutModal} />
      <CloserTab>
        <MenuItemIcon onClick={menuToggle} iconURL={closerIcon} style={{ margin: "0 25px 0 0" }} />
      </CloserTab>
      <GeneralMenuContainer>
        <BackImage />
        <SubMenuContainer>
          <ContentContainer>
            <AvatarImageContainer />
            <TextContainer>
              <NameContainer>{user?.name}</NameContainer>
              <EmailContainer>{user?.email}</EmailContainer>
            </TextContainer>
            <PermissionsContainer>
              <UserPersonalDetails />
            </PermissionsContainer>
          </ContentContainer>

          <SettingsContainer ref={dropdownRef}>
            <SettingsTab onClick={settingsMenuToggle}>
              <MenuItemIcon
                iconURL={settingsIcon}
                style={{
                  height: "36px",
                  width: "36px",
                  backgroundSize: "36px",
                  marginRight: "12px",
                }}
              />
              Settings
            </SettingsTab>
            <SettingsMenu display={isActive ? "flex" : "none"} />
          </SettingsContainer>
          <ButtonContainer>
            <SignOutButton onClick={openSignOutModal}>Sign Out of Transaction360</SignOutButton>
          </ButtonContainer>
        </SubMenuContainer>
      </GeneralMenuContainer>
    </>
  );
}
export default HeaderMenu;
