import React, { Dispatch, SetStateAction } from "react";
import { StatusProps } from "constants/index";
import { Core } from "cytoscape";
import { GeneralDetailsType, PartyData } from "./party";
import { User } from "@auth0/auth0-react";
import { MY_VIEW_TAB } from "constants/hubwiseDashboard";;

export interface LandingPageCardDetails {
  link: string;
  iconURL: string;
  iconURLHover: string;
  text: string;
}

export interface PageCardDetails {
  link: string;
  iconURL: string;
  iconURLHover: string;
  text: string;
  isUserAllowed: boolean;
}

export interface HeaderMenuItem {
  text: string;
  iconURL: string;
  url: string;
  onClick?: () => unknown;
}

export interface UserDetailsAuth0 {
  name: string;
  givenName?: string;
  familyName?: string;
  initials: string;
  email: string | undefined;
}

export interface UserDetailsEmail {
  email: string;
}

export enum HeaderType {
  DEFAULT = "DEFAULT",
  ADMIN = "ADMIN",
  FUNDS = "FUNDS",
  NONE = "NONE",
  T360 = "T360",
  HUBWISE = "HUBWISE"
}

export interface ToastContextType {
  toast: (message: string, status: StatusProps) => void;
  isOpen: boolean;
}

export type HeaderStyle =
  | HeaderType.DEFAULT
  | HeaderType.ADMIN
  | HeaderType.FUNDS
  | HeaderType.NONE;

export type PartyDetails = PartyData;

export interface AspectDetails {
  [name: string]: string;
}

export interface Result {
  id: string;
  pid_s?: string;
  mainActivity?: string;
  category?: string | unknown;
  identifiers?: Array<Array<string>>;
  description?: string;
  history?: string;
  name?: string;
  iso?: string;
  country?: string;
  entityType?: string;
  entityTypeBvD?: string;
  isGuo: boolean;
  isTargetEntity: boolean;
  totalAssets: number;
  entityTypeInternal?: string;
}

export interface SlrSearchResult {
    pid_s: string,
    scope_s: string,
    previousName_ss: string | string[],
    previousName_txt: string | string[],
    bankHistoryWithLink_ss: string | string[],
    bankHistoryWithLink_txt: string | string[],
    historicalRecordSince_ss: string | string[],
    historicalRecordSince_txt: string | string[],
    nameChangeDate_ss: string | string[],
    nameChangeDate_txt: string | string[],
    noRecentFinancialsFlag_ss: string | string[],
    noRecentFinancialsFlag_txt: string | string[],
    statusDate_ss: string | string[],
    statusDate_txt: string | string[],
    typeOfEntity_ss: string | string[],
    typeOfEntity_txt: string | string[],
    informationProvider_ss: string | string[],
    informationProvider_txt: string | string[],
    legalFormStandardized_ss: string | string[],
    legalFormStandardized_txt: string | string[],
    reasonOfFilingExemption_ss: string | string[],
    reasonOfFilingExemption_txt: string | string[],
    listeddelistedunlisted_ss: string | string[],
    listeddelistedunlisted_txt: string | string[],
    delistedDate_ss: string | string[],
    delistedDate_txt: string | string[],
    legalFormNational_ss: string | string[],
    legalFormNational_txt: string | string[],
    legalStatus_ss: string | string[],
    legalStatus_txt: string | string[],
    categoryOfTheCompany_ss: string | string[],
    categoryOfTheCompany_txt: string | string[],
    delistedComment_ss: string | string[],
    delistedComment_txt: string | string[],
    historicalRecordFlag_ss: string | string[],
    historicalRecordFlag_txt: string | string[],
    sameOrSimilarNameInLexisNexisWorldCompliance_ss: string | string[],
    sameOrSimilarNameInLexisNexisWorldCompliance_txt: string | string[],
    aliasName_ss: string | string[],
    aliasName_txt: string | string[],
    dateOfIncorporation_ss: string | string[],
    dateOfIncorporation_txt: string | string[],
    stateOfIncorporationInUs_ss: string | string[],
    stateOfIncorporationInUs_txt: string | string[],
    ipoDate_ss: string | string[],
    ipoDate_txt: string | string[],
    mainExchange_ss: string | string[],
    mainExchange_txt: string | string[],
    id: string | string[],
    _version_: number
}

export interface TotalAssetsWithClosingDate {
  totalAssetsValue: number;
  closingDate: Date;
}

export interface FinancialGroupItem {
  entityId: string;
  previewData: PartyDetails;
}

export interface CreateFinancialGroupCachePayload {
  accessToken: string;
  fromBucketName: string;
  toBucketName: string;
  storagePath: string;
  mod: string;
  toGit: string;
  fromGit: string;
  entityId: string;
  limit?: string;
  filePath: string;
}

export interface RelatedEntity {
  id: string;
  name: string;
  iso?: string;
  isGuo?: boolean;
  type?: string;
  notInNeo?: boolean;
  targetName?: string;
}

export const initialUltimateOwnerState: RelatedEntity = {
  id: "",
  name: "",
  notInNeo: true,
};

export interface DefaultSearchInput {
  entityName: string;
}

export type SetSearchSuggestionsHasFinished = React.Dispatch<React.SetStateAction<boolean>>;

export type SetIsSearch = React.Dispatch<React.SetStateAction<boolean>>;
export type SetId = React.Dispatch<React.SetStateAction<string | undefined>>;
export type SetPartyDetails = React.Dispatch<React.SetStateAction<PartyDetails | undefined>>;
export type SetGeneralDetails= React.Dispatch<React.SetStateAction<GeneralDetailsType | undefined>>;

export interface SolrResponseHeader {
    zkConnected:boolean;
    status:number;
    QTime:number,
    params?:object
}

export interface SolrResponse {
    numFound:number,
    start:number,
    numFoundExact:boolean;
    docs:  SolrSearchResult[];
}
export interface SolrSearchResult {
  pid_s: string;
  legalName_ss?: string;
}
export interface SolrResults {
  responseHeader: SolrResponseHeader;
  response: SolrResponse;
}

export interface CallNeo4jExternallyItem {
  guo_id: string | null;
  sub_id: string | null;
  target_id: string;
  status: string;
}

export interface OcrDocResponseFields {
  [key: string]: string;
}

export type TooltipProps = {
  content: string;
  askAlice?: boolean | undefined;
  propInModal?: boolean | undefined;
};

export interface OcrDocResponseItem {
  doc: OcrDocResponseFields;
  name_saved_sha: string;
  type: string;
}

export interface CSVData {
  data: Array<string>[];
}

export interface SelectedDocument {
  type: string;
  file: File;
}

export type CytoscapeWithNodeHtmlLabel = Core & {
  nodeHtmlLabel: any;
};

export interface DocumentFromOCRProps {
  ocrDocResponseItem: OcrDocResponseItem;
  setConfirmedDocuments: Dispatch<SetStateAction<OcrDocResponseItem[]>>;
  index: number;
  setDocSavedChecks: Dispatch<SetStateAction<boolean[]>>;
}

export interface DocumentType {
  name: string;
  value: string;
}

export interface DocumentsUploadProps {
  onClose: () => void;
  title: string;
  message: string;
  show: boolean;
}
export interface Metadata {
  pid: string;
  user_id: string;
  event_id: string;
  transaction_id: string;
  date_time: string;
}

export interface MetadataProps {
  metadata: Metadata;
  setMetadata: React.Dispatch<React.SetStateAction<Metadata>>;
}

export const initialMetadataValue: Metadata = {
  pid: "",
  user_id: "",
  event_id: "",
  transaction_id: "",
  date_time: "",
};

export interface FeedbackSingleObject {
  client_address: string;
  client_name: string;
  client_pid: string;
  document_address: string | null;
  document_dob: string | null;
  document_name: string | null;
  document_number: string | null;
  document_surname: string | null;
  document_type: string | null;
}

export interface SingleFeedbackResult {
  comment: string;
  data: FeedbackSingleObject[];
  rag: string;
}

export type StructuredData = [string, SingleFeedbackResult];

export interface CounterSingleObject {
  Bank: SingleFeedbackResult | object;
  CarRental: SingleFeedbackResult | object;
  Feedback: SingleFeedbackResult | object;
  PhoneShop: SingleFeedbackResult | object;
}

export interface CounterObject {
  [key: string]: CounterSingleObject;
}

export interface ComparatorSingleObject {
  [key: string]: SingleFeedbackResult;
}

export interface ComparatorObject {
  different_doc_addresses_uploaded_together: ComparatorSingleObject | SingleFeedbackResult | object;
  doc_expired: ComparatorSingleObject | SingleFeedbackResult | object;
  old_doc_used_after_new_used: ComparatorSingleObject | SingleFeedbackResult | object;
  same_details_different_doc_number: ComparatorSingleObject | SingleFeedbackResult | object;
  same_doc_number_different_date_of_birth: ComparatorSingleObject | SingleFeedbackResult | object;
  same_doc_number_different_name: ComparatorSingleObject | SingleFeedbackResult | object;
  utility_bill_address_different_names: ComparatorSingleObject | SingleFeedbackResult | object;
  same_document_number_different_address: ComparatorSingleObject | SingleFeedbackResult | object;
}

export interface AlertFeedbackType {
  rag: string;
  comparator: ComparatorObject;
  counter: CounterObject;
}

export const RAG_COLOURS: { [key: string]: string } = {
  RED: "#FF0000",
  AMBER: "#FFBF00",
  GREEN: "#00FF00",
};

export interface AirTableRecordFields {
  [key: string]: string;
}

export interface AirTableRecord {
  id: string;
  fields: AirTableRecordFields;
  createdTime: string;
}

export interface AirTableJSON {
  records: AirTableRecord[];
}

export interface AirTableData {
  tableName: string;
  airTableJSON: AirTableJSON;
}

export const initialAirTableRecord: AirTableRecord = {
  id: "",
  fields: {},
  createdTime: "",
};

export interface AirTableDocumentData {
  [key: string]: string;
}

export interface AirTableSelectedDocument {
  typeOfDocument: string;
  documentData: AirTableDocumentData;
}

export interface SelectedDocumentsProps {
  selectedDocuments: AirTableSelectedDocument[];
  setSelectedDocuments: React.Dispatch<React.SetStateAction<AirTableSelectedDocument[]>>;
}

export type ButtonType = "button" | "submit" | "reset" | undefined;

export interface SelectBoxOption {
  optionValue: string;
  optionContent: string;
}

export interface SelectBoxDetails {
  selectBoxName: string;
  selectListOfOptions: SelectBoxOption[];
}

export const initialSelectBoxDetails: SelectBoxDetails = {
  selectBoxName: "",
  selectListOfOptions: [],
};

export type StringStateSet = React.Dispatch<React.SetStateAction<string>>;

export interface SearchSuggestionInput {
    pid_s: string,
    scope_s: string,
    previousName_ss?: string | string[],
    previousName_txt?: string | string[],
    bankHistoryWithLink_ss?: string | string[],
    bankHistoryWithLink_txt?: string | string[],
    historicalRecordSince_ss?: string | string[],
    historicalRecordSince_txt?: string | string[],
    nameChangeDate_ss?: string | string[],
    nameChangeDate_txt?: string | string[],
    noRecentFinancialsFlag_ss?: string | string[],
    noRecentFinancialsFlag_txt?: string | string[],
    statusDate_ss?: string | string[],
    statusDate_txt?: string | string[],
    typeOfEntity_ss?: string | string[],
    typeOfEntity_txt?: string | string[],
    informationProvider_ss?: string | string[],
    informationProvider_txt?: string | string[],
    legalFormStandardized_ss?: string | string[],
    legalFormStandardized_txt?: string | string[],
    reasonOfFilingExemption_ss?: string | string[],
    reasonOfFilingExemption_txt?: string | string[],
    listeddelistedunlisted_ss?: string | string[],
    listeddelistedunlisted_txt?: string | string[],
    delistedDate_ss?: string | string[],
    delistedDate_txt?: string | string[],
    legalFormNational_ss?: string | string[],
    legalFormNational_txt?: string | string[],
    legalStatus_ss?: string | string[],
    legalStatus_txt?: string | string[],
    categoryOfTheCompany_ss?: string | string[],
    categoryOfTheCompany_txt?: string | string[],
    delistedComment_ss?: string | string[],
    delistedComment_txt?: string | string[],
    historicalRecordFlag_ss?: string | string[],
    historicalRecordFlag_txt?: string | string[],
    sameOrSimilarNameInLexisNexisWorldCompliance_ss?: string | string[],
    sameOrSimilarNameInLexisNexisWorldCompliance_txt?: string | string[],
    aliasName_ss?: string | string[],
    aliasName_txt?: string | string[],
    dateOfIncorporation_ss?: string | string[],
    dateOfIncorporation_txt?: string | string[],
    stateOfIncorporationInUs_ss?: string | string[],
    stateOfIncorporationInUs_txt?: string | string[],
    ipoDate_ss?: string | string[],
    ipoDate_txt?: string | string[],
    mainExchange_ss?: string | string[],
    mainExchange_txt?: string | string[],
    id?: string,
    globalIntermediaryIdentificationNumbergiin_s?: string,
    globalIntermediaryIdentificationNumbergiin_t?: string,
    giinRole_s?: string,
    giinRole_t?: string,
    igaModel_s?: string,
    igaModel_t?: string,
    fatcaRegisterName_s?: string,
    fatcaRegisterName_t?: string,
    mainDomesticCountry_s?: string,
    mainDomesticCountry_t?: string,
    mainProductsAndServices_s?: string,
    mainProductsAndServices_t?: string,
    historicalNote_s?: string,
    historicalNote_t?: string,
    generalDescription_s?: string,
    generalDescription_t?: string,
    primaryBusinessLine_s?: string,
    primaryBusinessLine_t?: string,
    mainActivity_s?: string,
    mainActivity_t?: string,
    leiAnnualRenewalDate_s?: string,
    leiAnnualRenewalDate_t?: string,
    legalEntityIdentiferlei_ss?: string | string[],
    legalEntityIdentiferlei_txt?: string | string[],
    leiStatus_s?: string,
    leiStatus_t?: string,
    leiFirstAssignmentDate_s?: string,
    leiFirstAssignmentDate_t?: string,
    leiOperatingUnit_s?: string,
    leiOperatingUnit_t?: string,
    legalName_ss?: string | string[],
    legalName_txt?: string | string[],
    telephoneNumber_s?: string,
    telephoneNumber_t?: string,
    website_s?: string,
    website_t?: string,
    cityLocal_s?: string,
    cityLocal_t?: string,
    typeOfRegionInCountry_s?: string,
    typeOfRegionInCountry_t?: string,
    addressLine1Local_s?: string,
    addressLine1Local_t?: string,
    city_s?: string,
    city_t?: string,
    regionInCountry_s?: string,
    regionInCountry_t?: string,
    postcode_s?: string,
    postcode_t?: string,
    countryIsoCode_ss?: string,
    countryIsoCode_s?: string,
    countryIsoCode_t?: string,
    nuts3_s?: string,
    nuts3_t?: string,
    legalNameLocal_s?: string,
    legalNameLocal_t?: string,
    nuts2_s?: string,
    nuts2_t?: string,
    nuts1_s?: string,
    nuts1_t?: string,
    country_s?: string,
    country_t?: string,
    faxNumber_s?: string,
    faxNumber_t?: string,
    addressLine1_s?: string,
    addressLine1_t?: string,
    otherCompanyIdNumber_ss?: string | string[],
    otherCompanyIdNumber_txt?: string | string[],
    vattaxNumber_ss?: string | string[],
    vattaxNumber_txt?: string | string[],
    tickerSymbol_ss?: string | string[],
    tickerSymbol_txt?: string | string[],
    isinNumber_ss?: string | string[],
    isinNumber_txt?: string | string[],
    vatNumber_ss?: string | string[],
    vatNumber_txt?: string | string[],
    ipIdentificationNumber_ss?: string | string[],
    ipIdentificationNumber_txt?: string | string[],
    ipIdentificationLabel_ss?: string | string[],
    ipIdentificationLabel_txt?: string | string[],
    nationalIdNumber_ss?: string | string[],
    nationalIdNumber_txt?: string | string[],
    nationalIdLabel_ss?: string | string[],
    nationalIdLabel_txt?: string | string[],
    kfTotalAssets_ss?: string | string[],
    kfTotalAssets_txt?: string | string[],
    kfClosingDate_ss?: string | string[],
    kfClosingDate_txt?: string | string[],
    _version_?: number
}

export interface SearchSuggestionResult {
  id: string;
  name: string;
  iso?: string;
  isGuo?: boolean;
  type?: string;
  notInNeo?: boolean;
}

export interface SearchInput {
  companyCategory?: string;
  isoCountry?: string;
  entityType?: string;
  legalForm?: string;
  mainActivity?: string;
  address?: string;
  city?: string;
  country?: string;
  county?: string;
  maxResult?: string;
  metropolitanArea?: string;
  stateOrProvince?: string;
  email?: string;
  abbreviatedName?: string;
  entityName?: string;
  personName?: string;
  postcode?: string;
  website?: string;
}

export interface SearchInputField {
  type: string;
  placeholder: string;
  name: string;
  autoComplete: string;
}

export interface HttpHeaders {
  [name: string]: string;
}

export interface ImportVariables {
  inputLocation: string;
  inputBucket: string;
  inputMount: string;
  intermediatePath: string;
  outputLocation: string;
  outputBucket: string;
  outputMount: string;
  tmpdir: string;
  zipPath: string;
  aspectPath: string;
  aspectExtension: string;
  sortedAspects: string;
  fingerPrintFilePath: string;
  fullFingerPrintFile: string;
  progressCount: string;
  entityMod: string;
  entityListFilePath: string;
  entityZipPath: string;
  entityLocalPath: string;
  reverseIdFile: string;
  newReverseIdFile: string;
  partitionToGit: string;
  partitionFromGit: string;
  entityCacheLocation: string;
  entityCacheBucket: string;
  entityCacheMount: string;
  [name: string]: string;
}

export const EmptyImportVariablesObject = {
  inputLocation: "",
  inputBucket: "",
  inputMount: "",
  intermediatePath: "",
  outputLocation: "",
  outputBucket: "",
  outputMount: "",
  tmpdir: "",
  zipPath: "",
  aspectPath: "",
  aspectExtension: "",
  sortedAspects: "",
  fingerPrintFilePath: "",
  fullFingerPrintFile: "",
  progressCount: "",
  entityMod: "",
  entityListFilePath: "",
  entityZipPath: "",
  entityLocalPath: "",
  reverseIdFile: "",
  newReverseIdFile: "",
  partitionToGit: "",
  partitionFromGit: "",
  entityCacheLocation: "",
  entityCacheBucket: "",
  entityCacheMount: "",
};

export interface FileDetails {
  id: string;
  shaOfTruncatedId: string;
  parts: string[];
  name: string;
  intermediateEntitylistPath: string;
  entitylistTarPath: string;
  entitylistTarOnGoogleCloud: string;
  pathInsideTar: string;
  cachedEntityPath: string;
}

export interface FieldData {
  [aspect: string]: ParkingFromAspect;
}

export interface ParkingFromAspect {
  [parking: string]: string;
}

export interface ArrayData {
  [parkingArrayName: string]: ParkingFromAspectArray;
}

export interface ParkingFromAspectArray {
  array: string;
  fields: ParkingFromAspect;
}

export interface ValetParkingRules {
  [valetLocation: string]: ValetParkingRule;
}

export interface ValetParkingRule {
  fields?: FieldData;
  arrays?: ArrayData;
}

export interface NameValue {
  [name: string]: string;
}

export interface ValetLocationData {
  [name: string]: string | NameValue[];
}

export interface ValetLocations {
  [valetLocation: string]: ValetLocationData;
}

export type ValetParking = (
  rules: ValetParkingRules,
  debug?: boolean,
) => (p: ObjectType) => ObjectType;

export interface BufferAndLoadUrlData {
  buffer: ArrayBuffer;
  fileDetails: FileDetails;
  metadataForDebug: string[];
  url: string[];
}

export interface RowNumberAndLines {
  row: string;
  rowName: string;
  lines: string[];
}

export interface NamesAndValues {
  rowName: string;
  names: string[];
  values: string[][];
}

export interface Entity {
  entityListAndLoadUrlData: BufferAndLoadUrlData;
  rawPartyDetails: PartyDetails;
  partyDetails: PartyDetails;
  rowNumberAndLines: RowNumberAndLines[];
  namesAndValues: NamesAndValues[];
}

export interface FingerPrint {
  lines: string[];
  fileNames: string[];
}

export interface ReduceAccumulator {
  currentHeader: number;
  currentLines: string[];
  result: RowNumberAndLines[];
}

export interface AllSameValues<V> {
  [name: string]: V;
}

export interface AspectDetailsTransformDefn {
  aspectKeyFilter: (name: string) => boolean;
  aspectKeyMap: (name: string) => string;
}

export type AspectTransform = (
  defn: AspectDetailsTransformDefn,
) => (p: AspectDetails) => AspectDetails;

export interface PartyDetailsTransformDefn extends AspectDetailsTransformDefn {
  partyKeyFilter: (name: string) => boolean;
  partyKeyMap: (name: string) => string;
}

export interface ArrayType {
  name: string;
  no_bo: any[];
  bo: any[];
}

export type TitleProps = {
  text: string;
  isOpen: boolean;
  hideIcon?: boolean;
  light?: boolean;
  toggle?: () => void;
  small?: boolean;
  medium?: boolean;
};

export interface AccordionProps {
  title: string;
  children: React.ReactChild;
  opened?: boolean;
  dep?: string;
  wrapper?: any;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

// custom interface for basic examples
export interface ColumnDetails {
  [key: string]: string;
}

// interface for the filterable table headers
export interface ColumnHeader {
  Header: string; // it's with a capital H because React Table wants it with capital H
  accessor: string;
  disableSortBy?: boolean;
  disableFilters?: boolean;
}

// interface for each specific header configuration
export interface HeaderConfiguration {
  [key: string]: string | boolean;
}

export interface DataFormatConfiguration {
  accessorName: string;
  format: string;
}

// interface for each table headers configuration
export interface TableConfiguration {
  name: string;
  columnDetails: HeaderConfiguration[];
  additionalTableOptions?: any;
  dataFormatConfiguration?: DataFormatConfiguration[];
}

export interface DiDoMenuItem {
  text: string;
  icon: string;
  link: string;
  isUserAllowed: boolean;
}

export type ClickDiDoMenuItem = React.Dispatch<DiDoMenuItem>;
export type ChangeDiDoMenuTab = React.Dispatch<string>;

export interface AdminMenuItem {
  text: string;
  icon: string;
  link: string;
  isUserAllowed: boolean;
}

export type ClickAdminMenuItem = React.Dispatch<AdminMenuItem>;
export type ChangeAdminMenuTab = React.Dispatch<string>;

export interface HubwiseDashboardMenuItem {
  text: string;
  icon: string;
  link: string;
  isUserAllowed: boolean;
}

export type ClickHubwiseDashboardMenuItem = React.Dispatch<HubwiseDashboardMenuItem>;
export type ChangeHubwiseDashboardMenuTab = React.Dispatch<string>;

export interface InteractiveDataItem {
  name: string;
  iconURL: string;
  prototypeURL: string;
}

export const emptyInteractiveDataItem: InteractiveDataItem = {
  name: "",
  iconURL: "",
  prototypeURL: "",
};

export interface IFrameSize {
  width: string;
  height: string;
}

export const emptyIFrameSize: IFrameSize = {
  width: "",
  height: "",
};

export type ColorMode = "light" | "dark";

export interface NotificationsData {
  Notifications: Notifications[];
}

export interface Notifications {
  name: string;
  messageContent: string;
  timeStamp: string;
}

export interface UserProfileProps {
  headerStyle: HeaderStyle;
  tabIsActive: boolean;
  alertIsActive: boolean;
}

export interface DataProps {
  id: string;
  createdAt: string;
  email: string;
  party?: string;
  status: string;
  fields: string[];
  streamId: string;
  chatName: string;
  chats: any[];
}

export interface KeyValueProps {
  key: string;
  value: string;
}

export interface RoomAtributes {
  copyProtected?: boolean;
  crossPod?: boolean;
  description?: string;
  discoverable?: boolean;
  groupChat?: boolean;
  keywords?: KeyValueProps[];
  membersCanInvite?: boolean;
  multiLateralRoom?: boolean;
  name?: string;
  public?: boolean;
  readOnly?: boolean;
  viewHistory?: boolean;
}

export interface RoomSystemInfo {
  active: boolean;
  createdByUserId: number;
  creationDate: number;
  id: string;
}

export interface RedirectAliceData {
  roomAttributes?: RoomAtributes | undefined;
  roomSystemInfo?: RoomSystemInfo | undefined;
}

export interface NewAliceRedirectDataType {
  from: {
    data: RedirectAliceData;
    tab: MY_VIEW_TAB
  }
}

export interface StreamType {
  type: string;
}

export interface DataPropsS {
  active: boolean;
  crossPod: boolean;
  id: string;
  roomAttributes?: RoomAtributes | undefined;
  attributes?: RedirectAliceData;
  streamType: StreamType;
}

//TODO:  might need updating
export interface CcIdListProps {
  id: string;
  createdAt: string;
  createdBy: string;
  documents: number;
  status: string;
  pid: string;
}

export interface CcIdProgress {
  percentage?: number;
}
// TODO: might need updating
export interface CcIdListType {
  id: string;
  start?: string;
  end?: string;
  name: string;
  progress?: CcIdProgress;
}

export interface InfoCardProps {
  icon: string;
  num: number;
  name: string;
}

export interface CardsDataProps {
  num: number;
  data: string | object;
}

export interface InfoCardDataProps {
  icon: string;
  num: number;
  name: string;
}

export interface TransactionProps {
  img: string;
  color: string;
  data: number;
  text?: string;
}

export interface Filter {
  search: string;
  secondSearch: string;
  modified: string;
  date: string;
  status: string;
  team: string;
  category: string;
  fundStatus: string;
  allfundsRequestDate: string;
  allfundsRequestStstus: string;
}

export type PaginationProps = {
  limit: number;
  total: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

export interface TopHoldingType {
  "Data Date": string;
  "Largest Holdings": string;
  Percentage: string;
  Rank: string;
}

export interface SectorType {
  Rank: number, 
  Sectors: string; 
  "Data Date": string; 
  Percentage: number; 
  "Trustnet Fund Id": string; 
  "Trustnet Category": string; 
  "Trustnet Fund Type": string;
}

export interface AssetClassType {
  Rank: number;
  Asset: string; 
  "Data Date": string; 
  Percentage: number; 
  "Trustnet Fund Id": string;
  "Trustnet Category": string; 
  "Trustnet Fund Type": string;
}

export interface PerformanceType {
  "Annual Performance 12 Months": string;
  "Annual Performance 12 to 24 Months": string;
  "Annual Performance 24 to 36 Months": string;
  "Annual Performance 36 to 48 Months": string;
  "Annual Performance 48 to 60 Months": string;
  "Annual Performance YTD": string;
  "Discrete Performance 0 to 12 Months": string;
  "Discrete Performance 1 Month": string;
  "Discrete Performance 3 Months": string;
  "Discrete Performance 6 Months": string;
  "Discrete Performance 12 Months": string;
  "Discrete Performance 12 to 24 Months": string;
  "Discrete Performance 24 Months": string;
  "Discrete Performance 24 to 36 Months": string;
  "Discrete Performance 36 Months": string;
  "Discrete Performance 36 to 48 Months": string;
  "Discrete Performance 48 to 60 Months": string;
  "Discrete Performance 60 Months": string;
  "Discrete Performance 120 Months": string;
}

export interface HWFundsType {
  "Fund ID": string,
  "Fund Name": string,
  "ISIN": string,
  "Ticker": string,
  "Fund Provider Name":string,
  "Fund Administrator Name": string,
  "Provider Company Branding Name": string,
  "Provider Company Type": string,
  "Country Of Registration": string,
  "AMC":string,
  "Currency": string,
  "Fund size":string,
  "Domicile": string,
  "Exchange": string,
  "Estimated inflow (£)":string,
  "Type of Business": string,
  "SEDOL": string,
  "Additional notes": string,
  "Progress updates": string,
  "Available via Morningstar": string,
  "Trading Frequency": string,
  "STP": string,
  "Settlement Bank Account": string,
  "Share Class": string,
  "Clean Shareclass": string,
  "Dividend Frequency": string,
  "Restricted Shareclass": string,
  "CREST Settlement": string,
  "Status": string,
  "ON/ OFFSHORE": string,
  "Income or Accumulation": string,
  "Standard Initial Charge %": string,
  "Hubwise Initial Charge %": string,
  "OCF %": string,
  "Settlement Period (T+)": string,
  "Fund Access Requirements": string,
  "Tradeable via Telephone": string,
  "Tradeable via fax": string,
  "Calastone": string,
  "EMX Product Code": string,
  "EMX Provider Code": string,
  "Trade Cut Off Point": string,
  "Valuation Point": string,
  "Administrator Portal Available": string,
  "Confirmation to use existing account": string,
  "FIGI": string
}

export type ObjectType = { [key: string]: any };

export interface ItemsPortfolioBreakdown {
  "Advisor Fund Index": string;
  "Alpha Manager": string;
  "Annual Performance 12 Months": string;
  "Annual Performance 12 to 24 Months": string;
  "Annual Performance 24 to 36 Months": string;
  "Annual Performance 36 to 48 Months": string;
  "Annual Performance 48 to 60 Months": string;
  "Annual Performance YTD": string;
  "Asset Class": string;
  "Asset Class BreakDown": string;
  "Asset Class Title": string;
  "Child Fund ID": string;
  Citicode: string;
  "Client Scope Id": string;
  "Crown Rating": string;
  Currency: string;
  "Discrete Performance 0 to 12 Months": string;
  "Discrete Performance 1 Month": string;
  "Discrete Performance 3 Months": string;
  "Discrete Performance 6 Months": string;
  "Discrete Performance 12 Months": string;
  "Discrete Performance 12 to 24 Months": string;
  "Discrete Performance 24 Months": string;
  "Discrete Performance 24 to 36 Months": string;
  "Discrete Performance 36 Months": string;
  "Discrete Performance 36 to 48 Months": string;
  "Discrete Performance 48 to 60 Months": string;
  "Discrete Performance 60 Months": string;
  "Discrete Performance 120 Months": string;
  "Entity Group Name": string;
  "Entity Name": string;
  "Fund ID": string;
  "Fund Level": string;
  "Fund Manager": string;
  "Fund Manager - Fund": string;
  "Fund Manager - URL": string;
  "Fund Manager Details": string;
  "Fund Name": string;
  "Fund Type": string;
  "Fund URL": string;
  "Group Fund ID": string;
  "Group Name": string;
  "Group Url": string;
  ISIN: string;
  "Legal Entity Identifier": string;
  "Morning Star Analyst Rating": string;
  "Parent Fund ID": string;
  "Portfolio Asset Class": string;
  "Portfolio Sector": string;
  Price: string;
  "Ratio Alpha": string;
  "Ratio Beta": string;
  "Ratio Sharpe": string;
  "Ratio Volatility": string;
  "Region Breakdown": string;
  "Risk Score": string;
  SEDOL: string;
  "Sector Breakdown": string;
  TIDM: string;
  "Top Holdings": string;
  Yield: string;
}

export interface PortfolioBreakdownData {
  data: ItemsPortfolioBreakdown;
}

export interface ElementPortfolioBreakdown {
  Asset: string;
  "Data date": string;
  Percentage: string;
  Rank: string;
}

export interface KeyPeopleData {
  DMC: IndividualPersonData[];
  DMC_BO: IndividualPersonData[];
}

export interface IndividualPersonData {
  Address: string;
  Age: string;
  "Age Bracket": string;
  "Also a ShareHolder?": string;
  "Appointment Date": string;
  Biography: string;
  "Board, Committee or Dept": string;
  "Confirmation Date": string;
  Country: string;
  "Country of Nationality": string;
  "Date Info Last Received": string;
  "Date of Birth": string;
  "First Name": string;
  "Full Name": string;
  "Function Label (Debug)": string;
  "Function Code (Debug)": string;
  Gender: string;
  "Individual or Company": string;
  "Information Provider": string;
  "Information Source": string;
  "Last Name": string;
  "Level of responsibility": string;
  "Middle Name": string;
  Position: string;
  "Resignation Date": string;
  Salutation: string;
  Title: string;
  "Type of position": string;
  "UCI (Unique Contact Identifier)": string;
}

export interface ContactInfoLEIIdentifer {
  "LEI (Legal Entity Identifer)": string | undefined;
}

export type ContactInfoArray = [string, ContactInfoLEIIdentifer[]];

export type ContactInfoData = [string, string | ContactInfoArray][];

export interface HistoryName {
  "Name change date": string;
  "Previous Name": string;
  "Previous Name (international language)": string | undefined;
}

export type HistoryArray = [string, HistoryName[]];

export type HistoryData = {
  "Date of Incorporation" : string;
  "General Description": string;
}

export interface IdentifiersIDs {
  "European VAT number": string | undefined;
  "IP Identification Label": string | undefined;
  "IP identification number": string | undefined;
  "ISIN Number": string | undefined;
  "LEI (Legal Entity Identifier)": string | undefined;
  "National ID Label": string | undefined;
  "National ID Number": string | undefined;
  "National ID Type": string | undefined;
  "Other company ID number": string | undefined;
  "Statistical number": string | undefined;
  "Ticker Symbol": string | undefined;
  "Trade register number": string | undefined;
  "VAT/Tax number": string | undefined;
}

export interface IdentifiersData {
  "Global Intermediary Identification Number (GIIN)": string;
  "Legal Entity Identifier (LEI)": string | string[];
  [key: string]: string | string[];
  // IDs?: IdentifiersIDs[];
  // IDs_BO?: IdentifiersIDs[];
}

export type IdentifiersArrays = [string, IdentifiersIDs[]];

export type IdentifiersFilteredData = [string, IdentifiersArrays][];

export interface SingleFund {
  "Record Id": string,
  "Trustnet Fund Id": string,
  "Fund Full Name": string,
  "ISIN": string,
  "Legal Entity Identifier Asset": string,
  "SEDOL": string,
  "FIGI": string,
  "Composite FIGI": string,
  "Share Class FIGI": string,
  "FIGI Group ID": string,
  "Hubwise Category": string
}

export interface TrackActivityData {
  activity: string;
  country: string | undefined;
  currentPage: string | undefined;
  date_and_time: string;
  id: string;
  region: string | undefined;
  timezone: string | undefined;
  user: User | undefined;
}

export interface JsonData {
  meta: { id: number; type: string; deleted: boolean }[];
  row: { iso: string; name: string; id: string }[];
}

export interface Results {
  columns: [string];
  data: JsonData[];
}

export type ResultsArray = [Results];

export interface Neo4jResultJson {
  commit: string;
  errors: any[];
  results: ResultsArray;
  transaction: { expires: string };
}

export interface GeoCoderAddress {
  city: string;
  countryCode?: string;
  countryName?: string;
  county?: string;
  countyCode?: string;
  district?: string;
  houseNumber?: string;
  label?: string;
  postalCode?: string;
  state?: string;
  street?: string;
}

export interface GeoCoderItem {
  access: [{ lat: number; lng: number }];
  address: GeoCoderAddress;
  houseNumberType: string;
  mapView: { west: number; south: number; east: number; north: number };
  position: { lat: number; lng: number };
  resultType: string;
  scoring: {
    fieldScore: {
      city: number;
      country: number;
      houseNumber: number;
      postalCode: number;
      streets: number[];
    };
    queryScore: number;
  };
  title: string;
}

export interface Identifiers {
  "": string;
  "European VAT number": string;
  "IP identification label": string;
  "IP identification number": string;
  "ISIN number": string;
  "LEI (Legal Entity Identifier)": string;
  "National ID label": string;
  "National ID number": string;
  "National ID type": string;
  "Other company ID number": string;
  "Statistical number": string;
  "Ticker symbol": string;
  "Trade register number": string;
  "VAT/Tax number": string;
  "BvD ID number": string;
}

export interface EntityData {
  "Accounting practice": string;
  "Added value": string;
  "Audit status": string;
  "Average cost of employee (th)": string;
  Capital: string;
  "Cash & cash equivalent": string;
  "Cash flow": string;
  "Cash flow / Operating revenue (%)": string;
  "Closing date": string;
  "Collection period (days)": string;
  "Consolidation code": string;
  "Costs of employees": string;
  "Costs of employees / Operating revenue (%)": string;
  "Costs of goods sold": string;
  "Credit period (days)": string;
  Creditors: string;
  "Current assets": string;
  "Current liabilities": string;
  "Current ratio (x)": string;
  Debtors: string;
  "Depreciation & Amortization": string;
  "EBIT margin (%)": string;
  EBITDA: string;
  "EBITDA margin (%)": string;
  "Enterprise value": string;
  "Enterprise value / EBITDA (x)": string;
  "Estimated employees": string;
  "Estimated operating revenue": string;
  "Exchange rate from original currency": string;
  "Export revenue": string;
  "Export revenue / Operating revenue (%)": string;
  "Extr. and other P/L": string;
  "Extr. and other expenses": string;
  "Extr. and other revenue": string;
  "Filing type": string;
  "Financial P/L": string;
  "Financial expenses": string;
  "Financial revenue": string;
  "Fixed assets": string;
  "Gearing (%)": string;
  "Gross margin (%)": string;
  "Gross profit": string;
  "Intangible fixed assets": string;
  "Interest cover (x)": string;
  "Interest paid": string;
  "Liquidity ratio (x)": string;
  Loans: string;
  "Long term debt": string;
  "Market cap / Cash flow from operations (x)": string;
  "Material costs": string;
  "Net assets turnover (x)": string;
  "Net current assets": string;
  "Non-current liabilities": string;
  "Number of employees": string;
  "Number of months": string;
  "Operating P/L [=EBIT]": string;
  "Operating revenue (Turnover)": string;
  "Operating revenue per employee (th)": string;
  "Original currency": string;
  "Original units": string;
  "Other current assets": string;
  "Other current liabilities": string;
  "Other fixed assets": string;
  "Other non-current liabilities": string;
  "Other operating expenses": string;
  "Other shareholders funds": string;
  "P/L after tax": string;
  "P/L before tax": string;
  "P/L for period [=Net income]": string;
  "Profit margin (%)": string;
  "Profit per employee (th)": string;
  Provisions: string;
  "R&D expenses / Operating revenue (%)": string;
  "ROA using Net income (%)": string;
  "ROA using P/L before tax (%)": string;
  "ROCE using Net income (%)": string;
  "ROCE using P/L before tax (%)": string;
  "ROE using Net income (%)": string;
  "ROE using P/L before tax (%)": string;
  "Research & Development expenses": string;
  Sales: string;
  "Shareholders funds": string;
  "Shareholders funds per employee (th)": string;
  "Shareholders liquidity ratio (x)": string;
  "Solvency ratio (Asset based) (%)": string;
  "Solvency ratio (Liability based) (%)": string;
  "Source (for publicly quoted companies)": string;
  Stock: string;
  "Stock turnover (x)": string;
  "Tangible fixed assets": string;
  Taxation: string;
  "Total assets": string;
  "Total assets per employee (th)": string;
  "Total shareh. funds & liab.": string;
  "Working capital": string;
  "Working capital per employee (th)": string;
  "﻿BvD ID number": string;
}

export interface BankDetails {
  "Account Name"?: string,
  "Account Number"?: string,
  "Sort Code"?: string,
  "Bank Name"?: string,
  "BIC"?: string,
  "IBAN"?: string,
  "Notes"?: string
}

export interface ContactDetails {
  Notes?: string,
  Name?: string,
  Telephone?: string,
  Fax?: string,
  Email?: string
}

export interface FundManagerContactDetailsType {
  Name?: string,
  Title?: string,
  Company?: string,
  Telephone?: string | string[],
  Website?: string | string[],
  Email?: string | string[],
  Address?: string | string[]
}

export interface FundManagerDetailsSierra {
  ID?: string,
  "Fund Manager Name"?: string,
  "Calastone OR EMX Account"?: string | string[],
  "Provider Code"?: string | string[],
  "Broker Code"?: string | string[],
  "Agent Code"?: string | string[],
  "Unit Holder Number"?: string | string[],
  "Account Designation"?: string | string[],
  "Account Name"?: string | string[],
  "EMX Provider ID"?: string | string[],
  "EMX Product ID"?: string | string[],
  "Bank Details"?: string | string[],
  "Administrator Name"?: string | string[],
  "Administrator Contact Details"?: string | string[],
  "Administrator Correspondence Address"?: string | string[],
  "Instructions"?: string | string[],
  "Notes"?: string | string[],
  "Hubwise Notes"?: string | string[],
  "MI Reporting"?: string | string[],
  "Fund Manager Relationship Contact"?: string | string[],
  [key: string]: string | string[] | undefined ;
}
