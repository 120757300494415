import styled from "styled-components";

export const AddWidgetsButton = styled.button`
  width: 100%;
  height: 48px;
  background-color: ${(props) => `${props.type === "reset" ? "#CC0066" : "#4A82A6"}`};
  overflow: hidden;
  border-radius: 4px;
  color: #ffffff;
  border: none;
  font-size: 14px;
  font-weight: 700;
  margin: 10px 0;
  opacity: ${(props) => `${props.disabled ? 0.3 : 1}`};

  &:hover {
    cursor: ${(props) => `${props.disabled ? "not-allowed" : "pointer"}`};
    opacity: ${(props) => `${props.disabled ? 0.1 : 0.9}`};
  }
`;

export const BtnHolder = styled.div`
    width: 10%;
    @media (max-width: 769px) {
      width: 50%;
    }
    margin-right: 2%;
`;

export const AddToMyViewBtnHolder= styled.div`
    width: 80%;
    margin-left: 20px;
    margin-top: 20px;
`;

export const HeaderAreaContainer= styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 769px) {
      justify-content: flex-start;
      margin-top: 30px;
    }
`;

export const ModalContainer = styled.div<{ header?: boolean }>`
    min-height: 60%;
    width: 35%;
    border-radius: 8px;
    top: ${({ header }) => (header === true ? "42%" : "50%")};
    left: ${({ header }) => (header === true ? "76%" : "50%")};
    transform: translate(-50%, -50%);
    z-index: 8;
    position: fixed;
    background: ${({ theme }) => theme.colors.WHITE};
    font-family: "Roboto Condensed", "sans-serif";
    z-index: 10;
    @media (max-width: 768px) {
      width: 95vw;
      height: 85vh;
      top: 10%;
      left: 50%;
      transform: translate(-50%, 0);
    }
`;

export const ModalContent= styled.div`
    width: 100%;
`;

export const MainContentContiner= styled.div`
    width: 100%;
    padding: 0 20px;
    margin: auto;
    display: flex;
    flex-direction: row;
`;

export const SideBarNav= styled.div`
    width: 35%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 20px;
`;

export const SideBarNavItem= styled.div<{ isActive?: boolean, borderBottom?: boolean}>`
    font-size: 20px;
    padding: 7px;
    text-align: center;
    color: #295880;
    background: ${({ isActive }) =>
    isActive ? "#F3F3F3" : "#fff"};
    border-bottom-radius: 5px;
    border-bottom: ${({ borderBottom }) => borderBottom ? "1px solid #bcbcbc" : "none"};
    cursor: pointer;
    text-wrap: wrap;
    align-items: center;
`;

export const SubMenuContainer= styled.div`
    width: 75%;
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
`;

export const Title = styled.div`
  margin-top: 63px;
  font-size: 26px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.PRIMARY[300]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavContainer = styled.div`
  width: 75%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 40px;
  border-bottom: 1px solid #444444;
`;

export const NavItem = styled.div<{ isActive?: boolean}>`
  
  @media (max-width: 768px) {
    width: 45%;
  }
  @media (min-width: 768px) {
    width: 50%;
  }
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  color: ${(props) => props.isActive ? "#295880" : "#444444"};
  border-bottom: ${(props) => props.isActive ? "5px solid #295880" : "none"};
  border-bottom-radius: 5px;
  cursor: pointer;
  text-wrap: wrap;
`;