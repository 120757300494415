import { FunctionComponent, useEffect, useState } from "react";
import { FundType } from "types/funds";
import { 
    MonitoredItemContainer,
    MonitordItemPairHolder,
    MonitoredItemLabel,
    MonitoredItemValue 
} from "styles/funds/MonitoredAsset.styled";

const MonitoredAsset: FunctionComponent <{
    monitoredItem?: FundType;
}> =  ({
    monitoredItem
}) => {    

    const [status, setStatus] = useState<string>("No status found");
    useEffect(()=>{
        if(monitoredItem){
            "3 - Enum of instrument status: 1 = no sent, 2 = pending, 3 = completed, 4 = further info required from instrument manager, 5 = further info requested by instrument manager, etc"
            if(monitoredItem.Status === "1"){
                setStatus("To Be Requested");
            } else if(monitoredItem.Status === "2") {
                setStatus("Pending");
            } else if(monitoredItem.Status === "3") {
                setStatus("Completed");
            } else if(monitoredItem.Status === "4" || monitoredItem.Status === "5" ) {
                setStatus("Further Information Required");
            }
        }
    },[monitoredItem])
    return (
        <MonitoredItemContainer>
            <MonitordItemPairHolder>
                <MonitoredItemLabel>Fund Name: </MonitoredItemLabel>
                <MonitoredItemValue>{monitoredItem?.["Fund Full Name"]}</MonitoredItemValue>
            </MonitordItemPairHolder>
            <MonitordItemPairHolder>
                <MonitoredItemLabel>Ringfenced: </MonitoredItemLabel>
                <MonitoredItemValue>{monitoredItem?.["Ring-fenced"] ? "Yes" : "No"}</MonitoredItemValue>
            </MonitordItemPairHolder>
            <MonitordItemPairHolder>
                <MonitoredItemLabel>Confirmed: </MonitoredItemLabel>
                <MonitoredItemValue>{monitoredItem?.["Data Confirmed"] ? "Yes" : "No"}</MonitoredItemValue>
            </MonitordItemPairHolder>
            <MonitordItemPairHolder>
                <MonitoredItemLabel>Status: </MonitoredItemLabel>
                <MonitoredItemValue>{status}</MonitoredItemValue>
            </MonitordItemPairHolder>
            <MonitordItemPairHolder>
                <MonitoredItemLabel>Last Update: </MonitoredItemLabel>
                <MonitoredItemValue>{monitoredItem?.["Last Update Datetime"].substring(0,10)}</MonitoredItemValue>
            </MonitordItemPairHolder>
            <MonitordItemPairHolder>
                <MonitoredItemLabel>Last Update Made By: </MonitoredItemLabel>
                <MonitoredItemValue>{monitoredItem?.["Last Update By"]}</MonitoredItemValue>
            </MonitordItemPairHolder>
        </MonitoredItemContainer>
    )
};

export default MonitoredAsset