import { FunctionComponent, useState, useEffect } from "react";
import { 
    UserDesktopViewContainer,
    WidgetsContainer 
} from "styles/funds/UserDesktopView.styled";
import AddWidgetComponent from "./widgets/AddWidget";
import { getItem } from "utils/localStorage";
 import AverageOnboardingTime from "./onboarding/AverageOnboardingTime";
 import CcIdProgress from "./onboarding/CcIdProgress";
 import WidgetComponent from "./widgets/WidgetComponent";
 import AssetOnboardingSummaryCategory from "./clientTemplateRequest/AssetOnboardingSummaryCategory";
import LoadingSpinner from "components/LoadingSpinner";
import { useAppData } from "hooks/useAppData";
import { ClientRequestTemplateType } from "types/party";
import { getAccessToken } from "services/auth0/auth0";
import {
    FUNDS_DATA_REPOSITORY_BUCKET_NAME,
    CRTS_CCIDS_BUYLISTS_PATH,
    HUBWISE_FUNDS_DETAILS_PATH
} from "config/googleCloudConfig";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import HuwiseInstrumentManagerFundsUpdateAreaComponent from "./masterBuylist/masterBuylistDashboard/DashboardFundsUpdateArea";
import { TemporaryMasterInstrumentsFundManagerType, FundType } from "types/funds";
import { CrtType, InstrumentsManagerType } from "types/party";
import MonitoredAsset from "./MonitoredAsset";

const UserDesktopViewComponent: FunctionComponent =  () => {
    const [userPreferences, setUserPreferences] = useState<string[]>([]);
    const [itemsToMonitor, setItemsToMonitor]= useState<FundType[]>([]);
    const [globalPreferences, setGlobalPreferences]= useState<{email: string, preferences: [], itemsToMonitor: FundType[] }[]>([]);
    const [crtData, setCrtData]= useState<ClientRequestTemplateType[]>();
    const [isOption,setIsOption] = useState<boolean>(false);
    const [activeId, setActiveId]= useState<number>();
    const [updated, setUpdated]= useState<boolean>(false);
    const [allFunds, setAllFunds] = useState<FundType[]>([]);
    const [fundManagersData, setFundManagersData]= useState<InstrumentsManagerType[]>([]);
    const [fundsToAddArray, setFundsToAddArray] = useState<{[key: string]: {"Record Id": string; "ISIN": string; "SEDOL": string}[]}[]>([])
    const [isLoading, setIsLoading]= useState<boolean>(false);
    const [ masterList, setMasterList ]= useState<TemporaryMasterInstrumentsFundManagerType[]>([]);
    const [buylistsInfo, setBuylistsInfo]= useState<{"Buylist Code": string, "Buylist Description": string}[]>([]);
    const { user } = useAppData();
    const token = getAccessToken();

    useEffect(()=>{
        if(updated) {
            setIsLoading(true);
            const globalPrefferences= getItem("userPreferences", []);
            if(globalPrefferences.length > 0) {
                globalPrefferences.map((item: {email: string, preferences: [], itemsToMonitor: FundType[]}) => {
                    if(item.email === user?.email) {
                        setUserPreferences(item.preferences);
                        setItemsToMonitor(item.itemsToMonitor);
                    }
                })
            } 
            setUpdated(false);
            setIsLoading(false);
        }
    },[updated])

    useEffect(()=>{
        setGlobalPreferences(getItem("userPreferences", []));   
    },[])

    useEffect(()=>{    
        if(token) {
            setIsLoading(true);
            const indexFile = "index.json";
            const payload = {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName": indexFile,
                "filePath": `${CRTS_CCIDS_BUYLISTS_PATH}`
            };

            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payload, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: ClientRequestTemplateType[])=> {
                setCrtData(data);
                setIsLoading(false);
            });

            const masterBuylist = "master_buylist.json";
            const instrumentsPayload = {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName": masterBuylist,
                "filePath": `${HUBWISE_FUNDS_DETAILS_PATH}`
            };

            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, instrumentsPayload, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: TemporaryMasterInstrumentsFundManagerType[])=> {
                setMasterList(data);
            });

            const buylistFileName= "all_buylists.json"

            const buylistsPayload= {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName": buylistFileName,
                "filePath": `${HUBWISE_FUNDS_DETAILS_PATH}`
            }

            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, buylistsPayload, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: {"Buylist Code": string, "Buylist Description": string}[])=> {
                setBuylistsInfo(data);
            });

            // get CRT information
            const crtFile= "crt_0000001.json";
            const payloadForCrtFile= {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName": crtFile,
                "filePath": `${CRTS_CCIDS_BUYLISTS_PATH}`
            }

            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payloadForCrtFile, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: CrtType)=>{
                if(data) {
                    setFundManagersData(data["Instruments Manager List"]);
                    if(data["Instruments Manager List"] && data["Instruments Manager List"].length > 0) {
                        data["Instruments Manager List"].map((fundManager) => {
                        const newObj: {[key: string]: {"Record Id": string; "ISIN": string; "SEDOL": string}[]}= {[fundManager.PID]:[] }
                        if(fundManager["Instruments List"] && fundManager["Instruments List"].length > 0) {
                            fundManager["Instruments List"].map((fund) => {
                            const newFundsArray= allFunds;
                            newFundsArray.push(fund);
                            setAllFunds(newFundsArray);
                            newObj[fundManager.PID].push({"Record Id": fund["Record Id"], "ISIN": fund.ISIN, "SEDOL": fund.SEDOL});
                            })
                            const newArr= fundsToAddArray;
                            newArr.push(newObj);
                            setFundsToAddArray(newArr);
                        }
                    })
                } }
            }) 
        }
    },[token]);

    useEffect(()=>{
        if(globalPreferences.length > 0) {
            globalPreferences.map((item: {email: string, preferences: string[], itemsToMonitor: FundType[]}) => {
                if(item.email === user?.email) {
                    setUserPreferences(item.preferences);
                    setItemsToMonitor(item.itemsToMonitor);
                }
            })
        } else {
            setUserPreferences([]);
        }
    },[globalPreferences])

    console.log("items to monitor: ", itemsToMonitor);
    
    return isLoading ? <LoadingSpinner /> : (
        <UserDesktopViewContainer>
            <AddWidgetComponent userPreferences={userPreferences} itemsToMonitor={itemsToMonitor} setUpdated= {setUpdated}/>
            <WidgetsContainer>
                <WidgetComponent 
                    isOption={isOption}
                    setIsOption={setIsOption}
                    title="Average Onboarding Completion Time" 
                    element={<AverageOnboardingTime data={crtData} showTitle={false}/>}
                    display={userPreferences.find(acc => acc === "Onboarding Time") ? "true" : "false"}
                    id={1}
                    activeId= {activeId}
                    setActiveId = {setActiveId}
                    userPreferences= {userPreferences}
                    name= "Onboarding Time"
                    setUpdated= {setUpdated}
                />
                <WidgetComponent 
                    isOption={isOption}
                    setIsOption={setIsOption}
                    title="CCID Progress" 
                    element={<CcIdProgress data={crtData} showTitle={false}/>}
                    display={userPreferences.find(acc => acc === "CCID Progress") ? "true" : "false"}
                    id={2}
                    activeId= {activeId}
                    setActiveId = {setActiveId}
                    userPreferences= {userPreferences}
                    name= "CCID Progress"
                    setUpdated= {setUpdated}
                />
                <WidgetComponent 
                    isOption={isOption}
                    setIsOption={setIsOption}
                    title="Asset Onboarding Request" 
                    element={<AssetOnboardingSummaryCategory 
                        fundManagers={fundManagersData.length} 
                        funds={allFunds.length}
                        allFunds={allFunds}
                        isMyView = {true}
                    /> }
                    display={userPreferences.find(acc => acc === "Asset Onboarding") ? "true" : "false"}
                    id={3}
                    activeId= {activeId}
                    setActiveId= {setActiveId}
                    userPreferences= {userPreferences}
                    name= "Asset Onboarding"
                    setUpdated= {setUpdated}
                />
                <WidgetComponent 
                    isOption={isOption}
                    setIsOption={setIsOption}
                    title="Instruments Updates" 
                    element={<HuwiseInstrumentManagerFundsUpdateAreaComponent data={masterList} buylistsInfo={buylistsInfo} showTitle={false} boxShadow={false}/>}
                    display={userPreferences.find(acc => acc === "Instruments") ? "true" : "false"}
                    id={4}
                    activeId= {activeId}
                    setActiveId = {setActiveId}
                    userPreferences= {userPreferences}
                    name= "Instruments"
                    setUpdated= {setUpdated}
                />
                {itemsToMonitor.length > 0 && itemsToMonitor.map((fund: FundType, index: number) => (
                    <WidgetComponent 
                        isOption={isOption}
                        setIsOption={setIsOption}
                        title="Monitor Asset"
                        element={<MonitoredAsset monitoredItem={fund} />}
                        display={userPreferences.find(acc => acc === "Monitor Item") ? "true" : "false"}
                        id={5+index}
                        activeId= {activeId}
                        setActiveId = {setActiveId}
                        userPreferences= {userPreferences}
                        name= "Monitor Item"
                        setUpdated= {setUpdated}
                        monitoredItem= {fund}
                        itemsToMonitor={itemsToMonitor}
                    />
                ))}
                
            </WidgetsContainer>
        </UserDesktopViewContainer>
    )
};

export default UserDesktopViewComponent