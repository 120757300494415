// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

import {
  LandingPageMenuContainer,
  LandingPageMenuMain,
} from "styles/landingPageMenue/LandingPageMenu.styled";
import { useAppData } from "hooks/useAppData";
import { pageDetails } from "constants/landingPageMenu";
import LandingPageCard from "../landingPageCard/LandingPageCard";
import { useState } from "react";
import useEffectOnce from "hooks/useEffectOnce";
import NoAccessComponent from "components/noAccess/NoAccessComponent";
import LoadingSpinner from "components/LoadingSpinner";

function LandingPageMenu() {
  const { isDidoDemo, isT360, isUserPermission, isUserPartOfOrganization } = useAppData();
  const [isAnyCard, setIsAnyCard] = useState<boolean>(false);
  const [isLoading, setIsLoading]= useState<boolean>(false);

  useEffectOnce(()=>{
    setIsLoading(true);
    pageDetails({ isDidoDemo, isT360, isUserPermission, isUserPartOfOrganization }).map((item) => {
      if (item.isUserAllowed === true) {
        setIsAnyCard(true);
        setIsLoading(false);
      }
    })
    setIsLoading(false);
  })


  return (
    <LandingPageMenuContainer>
      <LandingPageMenuMain>
        {isLoading && <LoadingSpinner />}
        {!isLoading && 
        pageDetails({ isDidoDemo, isT360, isUserPermission, isUserPartOfOrganization }).map(
          (item, i) =>
            item.isUserAllowed && (
              <LandingPageCard
                key={i}
                landingPageCardDetails={{
                  link: item.link,
                  iconURL: item.iconURL,
                  iconURLHover: item.iconURLHover,
                  text: item.text,
                }}
              />
            ),
        )}
        {!isLoading && !isAnyCard && (
          <NoAccessComponent />
        )}
      </LandingPageMenuMain>
    </LandingPageMenuContainer>
  );
}

export default LandingPageMenu;
