import { useEffect, useState } from "react";
import {
  Container,
  Banner,
  RightWrapper,
  LeftWrapper,
  GeneralCover,
  ContactCover,
  LeftContainer,
} from "styles/Alert.styled";
import { GeneralDetails } from "components/party/GeneralDetails";
import { ContactInfo } from "components/party/ContactInfo";
import GroupedDetails from "components/party/GroupedDetails";
import LoadingSpinner from "components/LoadingSpinner";
import NoData from "components/noData/NoData";
import { isEmpty } from "lodash";
import { useAppData } from "hooks/useAppData";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { GeneralDetailsType } from "types/party";
import { useToast } from "hooks/useToast";
import { TRACKER } from "constants/tracker";
import { OcrDocResponseItem, PartyDetails, SelectedDocument } from "../../types";
import DocumentsUpload from "../../components/alert/docsUploadAndSaving/DocumentUpload";
import AlertWaitingIcon from "../../components/alert/AlertWaitingIcon";
import { CHECKMATE_OCR_GCF_URL } from "../../config/googleCloudFunctionsConfig";
import ConfirmDocumentsData from "../../components/alert/docsUploadAndSaving/ConfirmDocumentsData";
import { getAccessToken } from "services/auth0/auth0";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import { GET_PARTY_DETAILS } from "../../config/googleCloudFunctionsConfig";

function OnboardingCheck() {
  const { toast } = useToast();
  const [waitApiResponse, setWaitApiResponse] = useState<boolean>(false);
  const [ocrResponseData, setOcrResponseData] = useState<OcrDocResponseItem[]>([]);
  const [generalDetails, setGeneralDetails] = useState<GeneralDetailsType | null>(null);
  const [profileDetails, setProfileDetails] = useState<PartyDetails>();
  const [loading, setLoading] = useState(false);
  const { pid: id } = useAppData();
  const token = getAccessToken();
  const { track } = useTrackActivity();

  const { user } = useAppData();
  const params = {
    pid: generalDetails?.id,
  }

  const handleSubmitForOCR = (array: SelectedDocument[]) => {
    const formData = new FormData();
    formData.append("client_code", "hsbc1234");
    formData.append("date", Date.now().toString());

    // add all the files to the FormData
    array.forEach((selectedDocument: SelectedDocument) => {
      formData.append(selectedDocument.type, selectedDocument.file);
    });

    async function postData(url: string): Promise<OcrDocResponseItem[]> {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      return response.json();
    }

    setWaitApiResponse(true);

    postData(CHECKMATE_OCR_GCF_URL)
      .then((data) => {
        setWaitApiResponse(false);
        setOcrResponseData(data);
      })
      .catch(() => {
        // TODO: re-add ErrorDisplay
      });
  };

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, TRACKER.ALERT);
  });

  useEffect(() => {
    if (token && id && user?.email) {
      setLoading(true);
      callGCFunctionWIthAccessToken(token, GET_PARTY_DETAILS, params, "test", "test")
        .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
        .then((data) => {
          if (data) {
            // get general details from data and set
            setGeneralDetails(data?.["General Details"]!);

            // set the rest of the data as profileDetails
            const details = { ...data };

            delete details?.["General Details"];

            setProfileDetails(details);
          }
          setLoading(false);
        })
        .catch((err: Error) => toast(err.toString()));
    }
  }, [token, id]);

  return (
    <Container>
      <Banner>
        {/* left wrapper starts here */}
        <LeftWrapper>
          <LeftContainer>
            <DocumentsUpload onSubmit={handleSubmitForOCR} ocrResponse={ocrResponseData} />
            {waitApiResponse && <AlertWaitingIcon />}
            {!waitApiResponse && ocrResponseData?.length > 0 && (
              <ConfirmDocumentsData ocrResponse={ocrResponseData} />
            )}
          </LeftContainer>
        </LeftWrapper>
        {/* left wrapper ends here */}

        <RightWrapper>
          {loading ? (
            <LoadingSpinner />
          ) : !isEmpty(generalDetails) && !loading ? (
            <>
              <GeneralCover>
                <GeneralDetails isAlert data={generalDetails} />
              </GeneralCover>
              <ContactCover>
                <ContactInfo data={profileDetails?.["Contact Information"]} showMap={false} />
              </ContactCover>
            </>
          ) : (
            <NoData />
          )}
        </RightWrapper>
      </Banner>
      <div className="container">
        <GroupedDetails data={profileDetails!} partyId={id} />
      </div>
    </Container>
  );
}

export default OnboardingCheck;
